import React, { ChangeEvent } from 'react'
import { ZiInput } from '@/components'
// import { Button } from 'antd'
import styles from './index.module.scss'
// import { read } from 'clipboardy'
import { SetupPageProps } from '@/common/types'
import { useSetState } from '@/hooks'
import * as Enums from '@/common/enums'
import { Popup, Button } from 'zati-ui-web'
import 'zati-ui-web/es/Popup/index.scss'
import inviteCodeValid from '@/assets/img/setup/invite-code-valid.png'
import { useTranslation } from 'react-i18next'
import { reportXman, formatPrice } from '@/utils'
import { uploadOpenChannel } from '@/service/accountCenter'
import { getChannel, reportCommonException } from '@/service/nativeCall'
import { SetupUserInfo } from '@/common/schemas/setup/'
import { Timing } from '@/common/enums/time.enum'

function InviteCode({
  nextPage,
  setupInfo,
  submitSetup
}: {
  nextPage: SetupPageProps['nextPage']
  setupInfo: SetupUserInfo
  submitSetup: (data: any) => Promise<unknown>
}) {
  const [state, setState] = useSetState({
    code: '',
    isError: false,
    errorMsg: '',
    show: false,
    rewardAmountInviter: '',
    rewardAmountInvitee: ''
  })
  const [nextLoading, setNextLoading] = useSetState({ loading: false })
  const { t } = useTranslation()
  const { code, isError, errorMsg, show, rewardAmountInvitee } = state
  const invalidVode = code.trim() === ''
  const onChange = (el: ChangeEvent<HTMLInputElement>) => {
    setState({ code: (el.currentTarget as any).trimValue, isError: false })
  }
  // const onPaste = async () => {
  //   // 读取剪切板内容需要用户授权同意, 不同意会报错
  //   try {
  //     const text = await read()
  //     if (text) {
  //       setState({ code: text, isError: false })
  //     }
  //   } catch (err) {
  //     console.log(`paste error:`, err)
  //   }
  // }

  const onSubmit = async (noCode = false) => {
    submitSetup({
      loading: true,
      dataType: Enums.OpenStep.STEP_INVITE_CODE,
      openData: {
        inviteMsg: {
          inviteCode: noCode ? '' : code
        }
      }
    })
      .then((resp: any) => {
        setNextLoading({ loading: false })
        if (!noCode && resp?.inviteMsg?.rewardAmountInvitee) {
          // 弹窗展示邀请人所得奖励
          setState({ show: true, rewardAmountInvitee: resp?.inviteMsg?.rewardAmountInvitee })
        } else {
          nextPage()
        }
      })
      .catch((error) => {
        setNextLoading({ loading: false })
        setState({ isError: true, errorMsg: error.message })
      })
    try {
      /**
       * 上传渠道码 如果有邀请码，渠道码固定为 zaonemgm001
       * 否则从App的协议 `getChannel` 获取渠道码
       */
      let openChannel = 'zaonemgm001'
      if (noCode) {
        const channelInfo = await getChannel()
        openChannel = (channelInfo as any)?.data?.channelCode || ''
      }
      uploadOpenChannel({
        openAccNo: setupInfo.openAccNo,
        openChannel: openChannel || 'Organic'
      })
    } catch (error) {
      reportCommonException('inviteCode-onSubmit', Error('上传渠道码失败'))
      console.log(`上传渠道码失败: `, error)
    }
  }

  const onJump = (): void => {
    const { loading } = nextLoading
    if (loading) {
      return
    }
    setNextLoading({ loading: true })
    reportXman('clickHelp', '点击帮TA')
    onSubmit(true)
  }

  const onClose = (): void => {
    setState({ show: false })
    setTimeout(nextPage, 100)
  }

  return (
    <div className={styles.page}>
      <h1 className="page-title">
        {t('za_invest_setup_invite_input_invite_code', '请输入你的邀请码')}
      </h1>
      <div className={styles.codeInput}>
        <ZiInput
          maxLength={20}
          placeholder={t('za_invest_setup_invite_code', '邀请码')}
          value={code}
          onChange={onChange}
        />
        {/* <span className={styles.paste} onClick={onPaste}>
          {t('za_invest_setup_invite_paste', '粘贴')}
        </span> */}
      </div>
      {isError && <div className={styles.errorMsg}>{errorMsg}</div>}
      <p className={styles.inviteDesc}>
        {t(
          'za_invest_setup_invite_desc',
          '請確認開戶及任何投資決定均是由你自行作出的，而不是因為邀請人的建議、推銷或游說。  '
        )}
      </p>
      <div className={styles.footer}>
        <Button
          className="zi-btn"
          type="primary"
          block
          disabled={invalidVode}
          throttleTime={Timing.DEBOUNCE}
          onClick={() => onSubmit()}
        >
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
        <p className={styles.noInviteCode} onClick={onJump}>
          {t('za_invest_setup_invite_no_code_skip', '没有邀请码，跳过')}
        </p>
      </div>
      <Popup
        className={styles.popupWraper}
        mode="center"
        closeOnClickMask={false}
        show={show}
        onClose={onClose}
      >
        <div className={styles.popupContent}>
          <img className={styles.pupupImg} src={inviteCodeValid} alt="invite code" />
          <h3 className={styles.validCode}>
            {t('za_invest_setup_invite_code_is_valid', '邀請碼有效')}
          </h3>
          <div className={styles.normalText}>
            {t('za_invest_setup_invite_open_count_get_coin', '成功開戶並加入新股易即可獲 ZA Coin')}
          </div>
          <div className={styles.coinNum}>{formatPrice(rewardAmountInvitee, true)}</div>
          <Button
            className="zi-btn"
            type="primary"
            block
            throttleTime={Timing.DEBOUNCE}
            onClick={onClose}
          >
            {t('za_invest_setup_invite_continue', '继续')}
          </Button>
        </div>
      </Popup>
    </div>
  )
}

export default InviteCode
