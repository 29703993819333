import React from 'react'
import { Button } from 'zati-ui-web'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import { Timing } from '@/common/enums/time.enum'
import classNames from 'classnames'

interface Props {
  text?: string
  onClick?: () => void
  className?: string
}
function App(props: Props) {
  const { t } = useTranslation()
  const { text, onClick, className } = props
  return (
    <div className={classNames(styles.horizontalMarginN8, className)}>
      <Button
        className="zi-btn"
        type="primary"
        block
        throttleTime={Timing.DEBOUNCE}
        onClick={onClick}
        {...props}
      >
        {text || t('za_invest_setup_next_step', '下一步')}
      </Button>
    </div>
  )
}

export default App
