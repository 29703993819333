export enum DictionaryKeys {
  // 资产净值
  ACCESS_AMOUNT = 'access_amount',
  // 教育情况
  EDUCATION = 'education',
  // 收入情况
  INCOME = 'income',
  // 收入来源
  INCOME_SOURCE = 'income_source',
  // 行业
  INDUSTRY = 'industry',
  // 婚姻状态
  MARITAL_STATUS = 'marital_status',
  // 职业类型
  OCCUPATION = 'occupation',
  // 支持的号码前缀
  PHONE_CODE = 'phone_code',
  // 岗位
  POSITION = 'position',
  // 投资目的
  PURPOSE = 'purpose',
  // 性别
  SEX = 'sex',
  // 证件类型
  ID_TYPE = 'id_type',
  // 风险等级描述
  RISK_LEVEL = 'risk_level'
}
