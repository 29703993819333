import cssVars from 'css-vars-ponyfill'
import { lightTheme, darkTheme } from './css-variable'

export type Theme = 'light' | 'dark'

export const getTheme = (): string => document.querySelector('html')?.dataset.theme || ''

export const initTheme = (theme: Theme): void => {
  const oldTheme = getTheme()
  if (oldTheme !== theme) {
    document.documentElement.setAttribute('data-theme', theme)
  }
  cssVars({
    watch: true, // 当添加，删除或修改其<link>或<style>元素的禁用或href属性时，ponyfill将自行调用
    variables: theme === 'light' ? lightTheme : darkTheme, // variables自定义属性名/值对的集合
    onlyLegacy: true, // false 默认将css变量编译为浏览器识别的css样式true当浏览器不支持css变量的时候将css变量编译为识别的css
    exclude: '#__vconsole'
  })
}

export const observeTheme = (): void => {
  // Select the node that will be observed for mutations
  const targetNode = document.querySelector('html')

  // Options for the observer (which mutations to observe)
  const config = { attributes: true }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList: MutationRecord[]): void {
    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'data-theme') {
          initTheme(targetNode?.dataset.theme as Theme)
        }
      }
    }
  }
  if (targetNode) {
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config)

    // Later, you can stop observing
    // observer.disconnect()
  }
}
