import React from 'react'
import PropTypes from 'prop-types'
import CardItem from './CardItem'
import { Card } from '@/common/types'

function CardList(props: any): JSX.Element {
  const { list, onItemClick, itemClass = '' } = props
  return (
    <div>
      {list.map((card: Card) => (
        <CardItem card={card} key={card.id} onClick={onItemClick} className={itemClass} />
      ))}
    </div>
  )
}

CardList.propTypes = {
  list: PropTypes.array,
  onItemClick: PropTypes.func,
  itemClass: PropTypes.string
}

export default CardList
