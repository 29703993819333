import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import PieChart from '../PieChart'
import classnames from 'classnames'

function LoadingMask<T extends { className?: string }>(props: T): JSX.Element {
  const { className, ...rest } = props
  return (
    <div className={classnames(styles.container, className)}>
      <PieChart {...rest} />
    </div>
  )
}

LoadingMask.propTypes = {
  className: PropTypes.string,
  ...PieChart.propTypes
}

export default LoadingMask
