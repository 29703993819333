import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  RefForwardingComponent
} from 'react'
import styles from './index.module.scss'
import { useSetState } from '@/hooks'

interface Props {
  getValue: (code: string) => void
  length: number
  tipMessage?: string | null
}
interface State {
  isFocus: boolean
  boxLength: number[]
  numList: string[]
  num: string
}

interface CodeInputHandles {
  updateState: (state: Partial<State>) => void
}

const CodeInputGroups: RefForwardingComponent<CodeInputHandles, Props> = (
  props: Props,
  ref
): JSX.Element => {
  const { getValue, length = 6, tipMessage } = props
  const contentRef = useRef<HTMLInputElement>(null)
  const [state, setState] = useSetState<State>({
    isFocus: false,
    boxLength: [],
    numList: [],
    num: ''
  })
  const { isFocus, num, boxLength, numList } = state

  const getBoxLength = (length: number): number[] => {
    switch (length) {
      case 4:
        return [0, 1, 2, 3]
      case 6:
        return [0, 1, 2, 3, 4, 5]
      default:
        return [0, 1, 2, 3, 4, 5]
    }
  }

  useEffect(() => {
    setState({ boxLength: getBoxLength(length) })
  }, [length, setState])

  const textChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value.replace(/[^\d]/g, '')
    setState({ num: value, numList: value.split('') })
    getValue(value)
  }

  const setFocus = (): void => {
    contentRef.current?.focus()
    setState({ isFocus: true })
  }

  const getShowValue = (value: string, index: number): string | JSX.Element => {
    // 当前焦点不展示占位符号
    if (numList.length === index && isFocus) {
      return ''
    } else {
      return value
    }
  }

  useImperativeHandle(ref, () => ({
    updateState(state: Partial<State>): void {
      state.numList = []
      state.num = ''
      setState(state)
    }
  }))

  return (
    <div className={styles.rootContainer} onClick={setFocus}>
      <input
        ref={contentRef}
        className={styles.boxInput}
        maxLength={length}
        value={num}
        onBlur={(): void => setState({ isFocus: false })}
        onChange={textChange}
      />
      <div className={styles.boxContainers}>
        {boxLength.map((item, index) => (
          <div key={index}>
            {getShowValue(numList[item], item)}
            <span
              className={styles.shake}
              style={{ display: item === numList.length && isFocus ? 'block' : 'none' }}
            >
              |
            </span>
          </div>
        ))}
      </div>

      {tipMessage && tipMessage.trim() !== '' ? (
        <div className={styles.tipMessage}>{tipMessage}</div>
      ) : null}
    </div>
  )
}

export default forwardRef(CodeInputGroups)
