import styles from './index.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import riskExplainData from '@/pages/risk/data/riskExplainData'
// 风险问卷结果静态内容部分
function Result(): JSX.Element {
  const { t } = useTranslation()

  return (
    <div className={styles.riskContent}>
      <p className={styles.firstMsg}>{t('4413_risk_result_firstline_msg', '风险取向的有效期为一年（从今天起记）。如你的情况或投资目标有变动，请重新填写问券，以便重新评估你的风险取向。')}</p>
      {/* <p
        dangerouslySetInnerHTML={{
          __html: t('4413_risk_result_confirm_msg', '<p>我确认：<br />1.了解客户风险取向的定义<br />2.同意我的客户风险取向<br />3.所提及的资料是完整、准确及最新的</p>')
        }}
      /> */}
      <div className={styles.statement}>
        {riskExplainData?.map((item) => {
          return (
            <div key={item.title} className={styles.item}>
              <div className={styles.title}>{t(item.title, item.titleDefault)}</div>
              <div className={styles.desc}>{t(item.desc, item.descDefault)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Result