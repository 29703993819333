export enum InsuranceStatus {
  APPLICATION_SUBMITTED = '申请已提交',
  TURN_DOWN = '已驳回',
  FUNDS_TRANSFERRED = '已转出款项',
  FUNDS_TRANSFER_FAILURE = '转出失败',
  COMPLETED = '已完成',
  REFUNDED = '已退款'
}

export interface PolicyCompany {
  companyName: string
  companyCode: string
  logoUrl: string
}

export interface PolicyNo extends PolicyCompany {
  companyId: string
  policyNo: string
}

export interface PageSizeNumReq {
  pageNum: number
  pageSize: number
}

export interface MoneyFund {
  fundCode: string // 基金代码
  fundName: string // 基金名
  fundAmt: number // 基金余额
}

export interface QueryMyMoneyFundRes {
  pageNum: number
  pageSize: number
  total: string
  list: MoneyFund[]
}

export interface QueryAssetRes {
  totalHolding: number // 总持仓
  fundHolding: number // 基金持仓
  stockHolding: number // 股票持仓
  balance: number // 账户余额
  openInsurance: string // 是否开通过保费通 Y=是 N=否
  /**
   * 0或空=符合条件显示出金按钮
   * 1=引导去入金 2=引导买基金
   */
  jumpType: number
}

export interface PayPremiumReq {
  policyNo: string
  companyCode: string
  redeemFundCode: string
  redeemAmt: number
  photos: string[]
  passEncry: string
  did: string
}

export interface PayPremiumRes {
  bizNo: string
}

export interface QueryClientPolicyNoRes {
  pageNum: string
  pageSize: string
  total: string
  list: PolicyNo[]
}

export interface DeleteClientPolicyNoReq {
  policyNo: string
}

export interface AddClientPolicyNoReq {
  policyNo: string
  companyId: string
}

export interface QueryCompanyListRes {
  pageNum: string
  pageSize: string
  total: string
  list: PolicyCompany[]
}

export interface QueryClientBillRes {
  bizNo: string
  status: string
  redeemAmt: number
  companyName: string
  companyCode: string
  applyTime: string
  policyNo: string
  logoUrl: string
}

export interface QueryPaymentDetailReq {
  bizNo: string
}

export interface QueryPaymentDetailRes {
  bizNo: string
  policyNo: string
  status: string
  redeemFundCode: string
  redeemFundName: string
  redeemAmt: string
  redeemRealAmt: string
  companyName: string
  companyCode: string
  applyTime: string
  photos: string[]
  rejectReason: string[]
}

export interface QueryPaymentDetailRes {
  bizNo: string
  policyNo: string
  status: string
  redeemFundCode: string
  redeemFundName: string
  redeemAmt: string
  redeemRealAmt: string
  companyName: string
  companyCode: string
  applyTime: string
  photos: string[]
  rejectReason: string[]
}
