/*
 * @Author: Aissen
 * @Date: 2019-05-29 14:57:42
 * @Description: '正则校验工具库'
 * @Last Modified by: lishaojia
 * @Last Modified time: 2020-06-30 19:48:33
 * @ToDo: '矫正fuction请使用correct前缀，校验合法性请使用isLegal前缀'
 */

import { isEmpty as _isEmpty, isNumber, isBoolean, isDate } from 'lodash'

function isEmpty(value: any): boolean {
  return _isEmpty(value) && !isNumber(value) && !isBoolean(value) && !isDate(value)
}

/**
 * 检测合适的身份证号
 *
 * 限输入数字及大写英文字母，输入字符限制9个以内
 * https://zh.wikipedia.org/wiki/香港身份證#cite_note-A1B2-25
 * 身份证号码需做本地格式检验：
 * (1) 检验组成部分：由1或2个英文字母+6个数字+（校验码）；
 * (2) 检验校验码：如身份证号为P103265（校验码），
 * a.（校验码）将起首字母转为字母在A至Z中的次序，A转为10，B转为11，如此类推。还有一个隐藏空格[SPACE] 在 Z（35）之后，为36
 * b. 唯一一个首字母之前需要补一个空格`SPACE`为36=3，将每组数字除以11得余数。
 * c. 将所有数字施加比重，比重为：
 *     双字母时，第一个起首字母为9
 *     第二个或唯一一个起首字母为8
 *     第一个数字为7
 *     第二个数字为6
 *     第三个数字为5
 *     第四个数字为4
 *     第五个数字为3
 *     第六个数字为2
 * d. 将数字和比重的乘积再求除以11的余数相加然后求和
 * e. 将积项和除以11，得余数
 * f. 从余数得出校验码：
 *      余数为0：校验码为0
 *      余数非0：将11减去余数，得差
 *      差为1至9：校验码为该差
 *      差为10：校验码为A
 * @param {idNumber} hkId
 */
function isHKIdNumber(hkId: string): boolean {
  if (isEmpty(hkId)) {
    return false
  }

  // Test
  // idNumber = 'ZP103265(9)'
  // 去括号
  const idNumber = hkId.replace('(', '').replace(')', '')

  if (idNumber.length > 9 || idNumber.length < 8) {
    return false
  }

  const hkIdPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/
  const matchArray = idNumber.match(hkIdPat)

  if (matchArray === null) {
    return false
  }

  const kNumber = idNumber.substring(idNumber.length - 7, idNumber.length - 1)
  let count = 0
  for (let i = 0; i < kNumber.length; i++) {
    const countI = (parseInt(kNumber.charAt(i), 10) - 0) * (7 - i)
    count += countI % 11
  }

  const alphabet = idNumber.substring(0, idNumber.length - 7)
  if (alphabet.length === 1) {
    // 如果只有一个字母补一个空格=36%11=3 * 9 % 11
    count += (3 * 9) % 11
  }
  let index = 0
  for (let j = alphabet.length - 1; j >= 0; j--) {
    // 英文字母的 ASCII (10進制):a 97 ~ z 122 所以減去 96 就是在字母表的位置，然后映射A到10
    let countJ = alphabet.charAt(j).toLowerCase().charCodeAt(0) - 96 + 9 // A 对应 10
    countJ = countJ % 11
    countJ = (countJ * (8 + index)) % 11
    index++
    count += countJ
  }

  let last = '0'
  const m = count % 11
  if (m === 0) {
    last = '0'
  } else if (m === 1) {
    last = 'A'
  } else {
    last = String(11 - m)
  }

  return idNumber.substring(idNumber.length - 1, idNumber.length) === last
}

/**
 * 中国大陆身份证号码15位正则
 */
const idReg15 = /^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$/

/**
 * 中国大陆身份证号码18位正则
 */
const idReg18 = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

/**
 * 中国大陆身份证号码校验
 * @param num 身份证号码
 * @returns boolean
 */
const isMainlandIdentifyNum = (num: string) => {
  return idReg15.test(num) || idReg18.test(num)
}

/**
 * 大中国陆手机号码校验
 * @param num 待检验的手机号码
 * @returns boolean
 */
const isMainlandPhoneNum = (num: string) => {
  return /^1\d{10}$/.test(num)
}

/**
 * 邮箱正则
 */
const emailExp = /\w+@([0-9a-zA-Z]+[-0-9a-zA-Z]*)(\.[0-9a-zA-Z]+[-0-9a-zA-Z]*)+$/

/**
 * 邮箱校验
 * @param num 待检验的邮箱
 * @returns boolean
 */
const isLegalEmail = (num: string) => emailExp.test(num)

const ZARegularExpUtils = {
  isHKIdNumber,
  isMainlandIdentifyNum,
  isMainlandPhoneNum,
  isLegalEmail
}

export default ZARegularExpUtils
