/*
 * 用于获取开户类型以及权限:判断初始值是否存在，不存在则调接口取，call cb1，存在则call cb2
 * initValue:初始值
 * inEffect:是否用useEffect
 * cb:回调函数，参数为开户类型,权限map等信息
 */
import { useEffect } from 'react'
import { getSetupType, setuptypeType } from '@/utils/business'
import { showError } from '@/utils'
import { callCloseWebview } from '@/service/nativeCall'
import { queryGrayDetail, GrayDetailOut } from '@/service/commonModule'

/**
 * @description 灰度控制配置
 */
export interface AuthMap {
  /**
   * @description 子账户入金分别对CA和HK开户类型进行控制
   */
  vasAuths: {
    [propName: string]: boolean
  }
  /**
   * @description 上次入金信息模块控制
   */
  lastInfo: boolean
  autoCashIn: boolean
  insurance: boolean
  exchange?: boolean
}
export interface CallbackParams {
  setupType: setuptypeType
  authMap: AuthMap
}

const allAuth = {
  HK: false,
  CA: false
}

const transferAuthData = (data: GrayDetailOut): AuthMap => {
  allAuth.HK = !!data?.hkvas?.switchOpen
  allAuth.CA = true // CA完全开放
  // const autoCashin = {}
  console.log(data, 'switchOpen')

  // 特殊的数据结构的key自己处理，其他的循环取,这里为后端的pub/grayDetail返回的key
  const specialKeys = ['hkvas', 'lastcashin']
  const result: any = {}
  Object.keys(data || {}).forEach((key: string) => {
    if (specialKeys.indexOf(key) < 0) {
      result[key] = !!data[key]?.switchOpen
    }
  })
  result.vasAuths = allAuth
  result.lastInfo = !!data?.lastcashin?.switchOpen
  return result
}

export default function useAuthAndSetupType(
  initValue: setuptypeType,
  inEffect: boolean,
  cbs: Function[]
) {
  const handler = () => {
    const [cb1, cb2] = cbs
    ;(async function handle() {
      if (!initValue) {
        try {
          const [setupType, grayDetai] = await Promise.all([getSetupType(), queryGrayDetail()])
          const authMap = transferAuthData(grayDetai)
          cb1?.({ setupType, authMap })
        } catch (err) {
          if (!err.__CANCEL__) {
            showError({ message: err.message }, callCloseWebview)
          }
        }
      } else {
        cb2?.()
      }
    })()
  }
  if (inEffect) {
    useEffect(handler, [])
  } else {
    handler()
  }
}
