import React, { useRef, useEffect, useCallback } from 'react'
import styles from './index.module.scss'
import SignCanvas from 'react-signature-canvas'
import { Button, Popup } from 'zati-ui-web'
import { Checkbox } from 'antd'
import Icon from '@ant-design/icons'
import classnames from 'classnames'
import { useWinSize, useSetState } from '@/hooks'
import { ReactComponent as IconDelete } from '@/assets/img/setup/icon-delete.svg'
import { useTranslation } from 'react-i18next'
import LoadingItem from '../LoadingItem'
import { Timing } from '@/common/enums/time.enum'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

const MAX_SECOND = 3

export const pipeData = ({ canvas, ctx, callback, image, setIsShow, canvasRef }: any): void => {
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.drawImage(image, image.width / -2, image.height / -2)
  callback(canvas.toDataURL(), canvasRef.current?.isEmpty())
  setIsShow(false)
}
const changeSignatureColor = (arr: any): any => {
  const changeColor = (itemArr: any) => {
    itemArr.forEach((i: any) => {
      if (Array.isArray(i)) {
        changeColor(i)
      }
      i.color = '#000'
    })
  }
  changeColor(arr)
  return arr
}
function SignatureCanvas({ callback, isShow, setIsShow, title }: any): JSX.Element {
  const { t } = useTranslation()
  const canvasRef = useRef<SignCanvas>(null)
  const { width: clientWidth } = useWinSize()
  const timerRef = useRef<number>()
  const [state, setState] = useSetState({
    show: false,
    check: false,
    second: MAX_SECOND
  })
  const { show, second, check } = state
  const getBase64 = useCallback(() => {
    if (canvasRef.current) {
      const sigCanvas = canvasRef.current
      let dataArr = sigCanvas.toData()
      if (dataArr.length === 0) {
        callback('', true)
        return
      }
      dataArr = changeSignatureColor(sigCanvas.toData())
      sigCanvas.fromData(dataArr)
      const srcBase64 = sigCanvas.toDataURL()
      const canvas = sigCanvas.getCanvas()
      const ctx: any = canvas.getContext('2d')
      const image = new Image()
      image.onload = function () {
        pipeData({ canvas, ctx, callback, setIsShow, image, canvasRef })
      }
      image.src = srcBase64
      setTimeout(() => {
        setState({ second: MAX_SECOND })
      }, 0)
    }
  }, [callback, setIsShow, setState])
  const unSetBase64 = (): void => {
    if (canvasRef.current) {
      canvasRef.current.clear()
    }
  }
  const theme = document.documentElement.getAttribute('data-theme')

  const onClick = () => {
    if (canvasRef.current) {
      const sigCanvas = canvasRef.current
      let dataArr = sigCanvas.toData()
      if (dataArr.length === 0) {
        callback('', true)
        return
      }
    }

    setState({ show: true, second: MAX_SECOND })
    timerRef.current = window.setInterval(() => {
      setState((preState) => {
        if (preState.second > 0) {
          return { second: preState.second - 1 }
        }
        return {}
      })
    }, 1000)
  }

  useEffect(() => {
    if (second === 0 && show) {
      getBase64()
      setState({
        show: false
      })
      window.clearInterval(timerRef.current)
    }
  }, [getBase64, second, setState, show])

  const onCancel = () => {
    setState({ show: false })
    window.clearInterval(timerRef.current)
    timerRef.current = 0
  }

  const handleCheck = (e: CheckboxChangeEvent) => {
    setState({ check: e.target.checked })
  }
  useEffect(() => {
    return () => {
      const body = document.querySelector('body') as HTMLBodyElement
      body.style.position = ''
    }
  }, [])
  return (
    <div
      className={classnames(styles.contentWrapper, {
        [styles.none]: !isShow
      })}
    >
      <div>
        <h3 className={styles.title}>
          <p>{title}</p>
          <Icon component={IconDelete} className={styles.iconDelete} onClick={unSetBase64} />
        </h3>
        <div className={styles.drawWrapper}>
          <SignCanvas
            penColor={theme === 'dark' ? 'rgba(255,255,255,0.87)' : '#000'}
            canvasProps={{
              width: clientWidth,
              height: 300,
              className: 'sigCanvas'
            }}
            ref={canvasRef}
          />
        </div>
        <p className={styles.chekBox}>
          <Checkbox className={styles.chek} value={check} onChange={handleCheck} />
          <span>
            {t(
              'za_invest_setup_idcard_page_check_title',
              '我认可为本人签名并且授权相关文件使用该签名进行签署'
            )}
          </span>
        </p>
        {!show && (
          <div className="footer-btn-box">
            <Button
              type="primary"
              disabled={!check}
              block
              onClick={onClick}
              throttleTime={Timing.DEBOUNCE}
            >
              {t('za_invest_setup_idcard_page_confirm', '确认')}
            </Button>
          </div>
        )}
      </div>
      <Popup
        mode="bottom"
        show={show}
        showClose={false}
        className={styles.cancelPopup}
        onClose={onCancel}
      >
        <div className={styles.cancel}>
          <LoadingItem message={t('za_invest_setup_signature_loading_text', '加载中')} />
          <div className={styles.cancelTextLine}>
            {second !== undefined ? second : 0}S{' '}
            <span className={styles.cancelText} onClick={onCancel}>
              {t('za_invest_setup_signature_cancel_text', '撤销提交')}
            </span>
          </div>
        </div>
      </Popup>
    </div>
  )
}

export default SignatureCanvas
