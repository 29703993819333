import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { updateSignatureData, selectSignatureData } from '@/pages/setup/store/slices/riskShow'
import { Button } from 'zati-ui-web'
import {
  ZiCheckbox,
  ZiProgressBar,
  SignatureCanvas,
  ZiLoadingPage,
  ZiLink,
  SetupTitle
} from '@/components'
import { SetupPageProps } from '@/common/types'
import {
  OpenStep,
  CheckBoxValue,
  OpenStatus,
  FileBizType,
  UploadBase64,
  JsEvent
} from '@/common/enums/'
import { PdfStatus } from '@/common/enums/setup/pdfStatus.enum'
import { useSetState, useSubmitSetup } from '@/hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateUserSetupInfo,
  selectUserSetupInfo,
  selectPdfCodeList,
  updatePdfCodeList,
  selectPdfKeyMap,
  updatePdfKeyMap
} from '@/pages/setup/store/slices/userSetupInfo'
import { getPdfListCode, queryPdfStatus, uploadFileBase64 } from '@/service/fileCenter'
import {
  callDownload,
  callOpenPDF,
  callNativeSetBackBtn,
  postEventToNative
} from '@/service/nativeCall'
import classnames from 'classnames'
import { base64WithoutHeader, showError } from '@/utils/'
import { PdfCodeItem } from '@/common/schemas/setup/'
import { List } from 'antd-mobile'
import { Timing } from '@/common/enums/time.enum'

const CheckBoxItem = ZiCheckbox.CheckBoxItem
const { Item: ListItem } = List

interface SignatureProps extends Pick<SetupPageProps, 'nextPage' | 'showBackBtn'> {
  selectUserSetupInfo: typeof selectUserSetupInfo
  selectSignatureData: typeof selectSignatureData
  selectPdfCodeList: typeof selectPdfCodeList
  selectPdfKeyMap: typeof selectPdfKeyMap
  updatePdfCodeList: typeof updatePdfCodeList
  updateUserSetupInfo: typeof updateUserSetupInfo
  updatePdfKeyMap: typeof updatePdfKeyMap
  updateSignatureData: typeof updateSignatureData
}

function Signature({
  nextPage,
  showBackBtn,
  selectUserSetupInfo,
  selectSignatureData,
  selectPdfCodeList,
  selectPdfKeyMap,
  updatePdfCodeList,
  updateUserSetupInfo,
  updatePdfKeyMap,
  updateSignatureData
}: SignatureProps): JSX.Element {
  const setupInfo = useSelector(selectUserSetupInfo)
  const signatureData = useSelector(selectSignatureData)
  const pdfCodeList = useSelector(selectPdfCodeList)
  const pdfKeyMap = useSelector(selectPdfKeyMap)
  const submitSetup = useSubmitSetup()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [state, setState] = useSetState({
    loading: true,
    showSignature: false,
    isAllowPromotion: signatureData.isAllowPromotion
  })
  const { showSignature, loading, isAllowPromotion } = state

  useEffect(() => {
    const fetchPdfList = async (): Promise<void> => {
      try {
        const result = await getPdfListCode()
        dispatch(updatePdfCodeList(result.list))
        setState({ loading: false })
      } catch (e) {
        setState({ loading: false })
        console.log(e)
      }
    }
    fetchPdfList()
  }, [dispatch, setState, updatePdfCodeList])

  useEffect(() => {
    if (showSignature) {
      callNativeSetBackBtn({
        onClick: (): void => {
          setState({ showSignature: false })
        }
      })
    } else {
      showBackBtn?.()
    }
  }, [setState, showBackBtn, showSignature])

  const callOpenPdfHl = async (item: {
    objectKey: string
    name: string
    type: string
  }): Promise<void> => {
    let filePath
    const { objectKey, name, type } = item
    // 读取缓存,如果PDF被打开过,直接从缓存拿下载的PDF文件路径
    if (pdfKeyMap[objectKey]) {
      filePath = pdfKeyMap[objectKey]
    } else {
      setState({ loading: true })
      try {
        const result = await callDownload({
          fileName: name + type,
          objectKey: objectKey,
          path: '/filegw/file/baseFile/fileDownload'
        })
        // 将下载到的PDF文件路径保存到store做缓存
        updatePdfKeyMap({ [objectKey]: result.filePath })
        filePath = result.filePath
      } catch (error) {
        console.log(`PDF[${name}]下载失败, `, error)
      }
      setState({ loading: false })
    }

    if (filePath) {
      callOpenPDF({ path: filePath })
    } else {
      showError({})
    }
  }

  const openPdfCode = async (item: PdfCodeItem): Promise<void> => {
    queryPdfStatus({ pdfCode: item.code, bizId: setupInfo.openAccNo }).then((res) => {
      if (res.status === PdfStatus.GEN_SUCCESS) {
        callOpenPdfHl({ objectKey: res.obsKey, name: item.name, type: item.type })
      } else {
        showError({
          message: t(
            'za_invest_stepup_signature_page_open_pdf_error_message',
            '正在生成PDF，请稍后重试'
          )
        })
      }
    })
  }

  const submit = async (imgData: string, isEmpty: boolean): Promise<void> => {
    if (isEmpty) {
      showError({
        message: t('za_invest_setup_riskShow_page_signature_is_empty_error', '请进行签名')
      })
      return
    }
    dispatch(updateSignatureData({ imgBase64: imgData }))
    const uploadBase64Res = await uploadFileBase64({
      fileBizType: FileBizType.ID_CARD,
      files: [
        base64WithoutHeader({
          fileBase64: imgData,
          fileName: UploadBase64.FILE_NAME,
          fileType: UploadBase64.FILE_TYPE
        })
      ]
    })
    dispatch(
      updateSignatureData({
        imgBase64: imgData,
        signature: uploadBase64Res.objectKey[0],
        isAllowPromotion
      })
    )
    await submitSetup({
      dataType: OpenStep.STEP_AGREEMENT,
      openData: {
        agreementSignatureMsg: {
          signature: uploadBase64Res.objectKey[0],
          isAllowPromotion: isAllowPromotion
        }
      }
    })

    const newSetupInfo = {
      ...setupInfo
    }
    newSetupInfo.openStatus = OpenStatus.AUDIT_WAITING
    dispatch(updateUserSetupInfo(newSetupInfo))
    postEventToNative({ name: JsEvent.OPEN_ACCOUNT })
    postEventToNative({ name: JsEvent.IPO_ACCOUNT_STATUS })
    nextPage()
  }

  const setShowSignature = (show: boolean) => {
    setState({ showSignature: show })
  }
  console.log(`isAllowPromotion`, isAllowPromotion)
  return (
    <div className={styles.contentWrapper}>
      <div className="commonPadding">
        <ZiProgressBar
          current={5}
          title={t('za_invest_setup_signature_step_title')}
          progress={90}
        />
      </div>
      <SetupTitle text={t('za_invest_setup_signature_page_title')} />

      <List className={classnames('panel', styles.protocolPanel)}>
        {pdfCodeList.map((item) => (
          <ListItem
            key={item.code}
            onClick={(): void => {
              openPdfCode(item)
            }}
          >
            <span className={styles.protocol}>{item.name}</span>
          </ListItem>
        ))}
        <ListItem>
          <ZiLink className={styles.protocol} href={t('za_invest_setup_agreement_pdf_4')}>
            {t('za_invest_setup_agreement_4', '客戶協議')}
          </ZiLink>
        </ListItem>
        <ListItem>
          <ZiLink className={styles.protocol} href={t('za_invest_setup_agreement_pdf_6')}>
            {t('za_invest_setup_agreement_6', 'Risk Disclosure Statement')}
          </ZiLink>
        </ListItem>
      </List>

      <div
        className={classnames(styles.statement, 'commonPadding')}
        dangerouslySetInnerHTML={{
          __html:
            t('za_invest_setup_signature_statement') + t('za_invest_setup_signature_statement_2')
        }}
      />

      <div className="commonPadding">
        <ZiCheckbox
          className={styles.checkboxs}
          onChange={(val: string[]): void => {
            const isAllow = val.includes('subscription') ? CheckBoxValue.Y : CheckBoxValue.N
            setState({
              isAllowPromotion: isAllow
            })
          }}
        >
          <CheckBoxItem value="subscription" defaultChecked={isAllowPromotion === CheckBoxValue.Y}>
            {t('za_invest_setup_signature_page_checkbox_txt1')}
          </CheckBoxItem>
        </ZiCheckbox>
      </div>
      <div className="footer-btn-box">
        <Button
          className="zi-btn"
          type="primary"
          onClick={(): void => {
            setShowSignature(true)
          }}
          block
          throttleTime={Timing.DEBOUNCE}
        >
          {t('za_invest_setup_signature_page_button_txt')}
        </Button>
      </div>
      {showSignature && (
        <SignatureCanvas
          isShow={showSignature}
          setIsShow={setShowSignature}
          callback={submit}
          title={t('za_invest_setup_sig_title', '签署姓名')}
        />
      )}
      <ZiLoadingPage show={loading} />
    </div>
  )
}

export default Signature
