import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ZiProgressBar } from '@/components'
import { Button, Icon } from 'zati-ui-web'
import IdCard1 from '@/assets/img/mainland-setup/id-card-1.png'
import IdCard2 from '@/assets/img/mainland-setup/id-card-2.png'
import PlusIcon from '@/assets/img/mainland-setup/plus.svg'
import { useSetState } from '@/hooks'
import { startTakeID, reportCommonException } from '@/service/nativeCall'
import { TakeIDResType } from '@/common/schemas'
import { downLoadFileBase64, uploadFileBase64 } from '@/service/fileCenter'
import { submitToRecognize, queryRecognizeResult } from '@/service/accountCenter'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { useSelector, useDispatch } from 'react-redux'
import { updateRecognizeData } from '../../store/slices/identify'
import { SetupPageProps } from '@/common/types'
import { showError, toggleLoading } from '@/utils'
import { selectUploadCard, updateUploadCard } from '../../store/slices/uploadCard'
import { Timing } from '@/common/enums/time.enum'
import IdCardExample from '@/components/IdCardExample'
import IdCardMainLandCorrect from '@/assets/img/id-card-example/id-card-correct.svg'
import IdCardMainLandMarginal from '@/assets/img/id-card-example/id-card-marginal.svg'
import IdCardMainLandFuzzy from '@/assets/img/id-card-example/id-card-fuzzy.svg'
import IdCardMainLandFlash from '@/assets/img/id-card-example/id-card-flash.svg'

// const recognizeResult = {
//   result: '2',
//   idNo: '43052819910318053X',
//   firstName: '珂',
//   lastName: '郑',
//   zhName: '郑珂',
//   sex: '2',
//   nation: '',
//   birthdate: '',
//   provinceCode: 'P6',
//   cityCode: 'C77',
//   area: '',
//   addrDetail: 'xxx小区xx号',
//   email: '72759@qq.com',
//   idStartDate: '',
//   idValidDate: '',
//   idIssuedBy: ''
// }

const typeMap = {
  0: '3',
  1: '4'
} as {
  [key: string]: '3' | '4'
}

const exampleSrcDic = {
  correct: IdCardMainLandCorrect,
  marginal: IdCardMainLandMarginal,
  fuzzy: IdCardMainLandFuzzy,
  flash: IdCardMainLandFlash
}

function UploadIdCard({ nextPage }: SetupPageProps) {
  const openInfo = useSelector(selectUserSetupInfo)
  const uploadCard = useSelector(selectUploadCard)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  // dispatch(updateRecognizeData(recognizeResult))
  const [state, setState] = useSetState<{
    face: TakeIDResType['data']
    emblem: TakeIDResType['data']
    objectKeys: { [key: string]: string }
  }>({
    face: undefined,
    emblem: undefined,
    objectKeys: {
      '0': uploadCard.idFaceFileKey,
      '1': uploadCard.idNationalFileKey
    }
  })

  const { face, emblem, objectKeys } = state

  useEffect(() => {
    const { idFaceFileKey, idNationalFileKey } = uploadCard
    if (idFaceFileKey) {
      downLoadFileBase64({ fileKey: idFaceFileKey, needBase64: true }).then((resp) => {
        setState({
          face: {
            type: '3',
            filePath: resp.fileName,
            base64Image: resp.fileBase64
          }
        })
      })
    }
    if (idNationalFileKey) {
      downLoadFileBase64({ fileKey: idNationalFileKey, needBase64: true }).then((resp) => {
        setState({
          emblem: {
            type: '4',
            filePath: resp.fileName,
            base64Image: resp.fileBase64
          }
        })
      })
    }
  }, [uploadCard, setState])

  const onClick = async (index: 0 | 1) => {
    try {
      const res = await startTakeID({ type: typeMap[index] })
      const fileName = index === 0 ? 'face' : 'emblem'
      // 某些iphone机型直接上传会失败
      await new Promise((resolve) => {
        window.setTimeout(resolve, 100)
      })
      /* istanbul ignore else */
      if (res?.base64) {
        setState({
          [fileName]: {
            base64Image: res.base64
          }
        })
        uploadFileBase64({
          files: [
            {
              fileName,
              fileType: 'image/png',
              fileBase64: res.base64
            }
          ]
        })
          .then((uploadRes) => {
            const newObjectKey = { ...objectKeys }
            newObjectKey[index] = uploadRes?.objectKey[0]
            setState({ objectKeys: newObjectKey })
          })
          .catch((error) => {
            setState({ [fileName]: '' })
            reportCommonException('startTakeID-uploadFileBase64', error)
            showError(error)
          })
      } else {
        reportCommonException(
          'jsbridge-uploadFileBase64',
          Error('jsbridge未返回base64,也可能安卓点了物理返回')
        )
      }
    } catch (err) {
      // 此时err是个object
      reportCommonException('startTakeID', err)
      // 选择照片中断不弹窗
      if (err.code === '0') {
        return
      }
      // showError(err)
    }
  }

  const isValid = () => {
    return objectKeys['0'] && objectKeys['1']
  }

  const getResult = (ocrSerialNo: string, callback?: () => void) => {
    /* istanbul ignore else */
    if (ocrSerialNo) {
      queryRecognizeResult({ ocrSerialNo }, false).then((res) => {
        if (res.result === '1') {
          // 处理中，200毫秒后再次请求
          setTimeout(() => {
            getResult(ocrSerialNo, callback)
          }, 200)
        } else if (res.result === '2') {
          // 处理成功场景
          callback?.()
          dispatch(updateRecognizeData(res))
          dispatch(
            updateUploadCard({
              idFaceFileKey: objectKeys[0],
              idNationalFileKey: objectKeys[1]
            })
          )
          nextPage()
          /* istanbul ignore else */
        } else if (res.result === '3') {
          // 处理失败场景
          callback?.()
          showError({ message: res?.resultDesc })
        }
      })
    }
  }

  const submit = async () => {
    try {
      toggleLoading(true)
      const ocrInfo = await submitToRecognize(
        {
          openAccNo: openInfo.openAccNo,
          ocrType: 'idFace_National',
          // mock data
          // fileKey:
          //   'zaip-dev-invest/2021/01/28/identityImgOne.jpg,zaip-dev-invest/2021/01/28/identityImgTwo.jpg'
          fileKey: `${objectKeys['0']},${objectKeys['1']}`
        },
        false
      )
      const { ocrSerialNo } = ocrInfo
      /* istanbul ignore else */
      if (ocrSerialNo) {
        getResult(ocrSerialNo, () => {
          toggleLoading(false)
        })
      }
    } catch (err) {
      console.log(`get ocrInfo error`, err)
    }
  }

  const RetryBtn = ({ onClick }: { onClick: () => void }) => {
    return (
      <div className={styles.imgTips} onClick={onClick}>
        <Icon icon={PlusIcon} className={styles.plus} />
        {t('za_invest_msetup_upload_id_retry', '重新拍摄')}
      </div>
    )
  }

  const IdCard = ({
    index,
    card,
    defaultSrc
  }: {
    index: 0 | 1
    card: TakeIDResType['data']
    defaultSrc: string
  }) => (
    <div className={styles.card}>
      {!card?.base64Image && (
        <p className={styles.cardDesc}>
          {index === 0
            ? t('za_invest_msetup_upload_id_card_face', '身份证人像面')
            : t('za_invest_msetup_upload_id_card_emblem', '身份证国徽面')}
        </p>
      )}
      <img
        src={card?.base64Image ? `data:image/jpeg;base64,${card.base64Image}` : defaultSrc}
        alt="identify image"
        onClick={() => {
          /* istanbul ignore else */
          if (!card?.base64Image) {
            onClick(index)
          }
        }}
      />
      {card?.base64Image && <RetryBtn onClick={() => onClick(index)} />}
    </div>
  )

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar current={1} title={t('za_invest_setup_progressBar_01Title', '个人资料')} />
      </div>
      <h2 className="page-title">{t('za_invest_msetup_upload_id_card', '请拍摄证件')}</h2>
      <div className={styles.idCardView}>
        <IdCard index={0} card={face} defaultSrc={IdCard1} />
        <IdCard index={1} card={emblem} defaultSrc={IdCard2} />
      </div>
      <IdCardExample srcDic={exampleSrcDic} />
      <div className="footer-btn-box">
        <Button
          type="primary"
          block
          onClick={submit}
          throttleTime={Timing.DEBOUNCE_LONG}
          disabled={!isValid()}
        >
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
      </div>
    </div>
  )
}

export default UploadIdCard
