import React, { useRef, useEffect } from 'react'
import styles from './index.module.scss'
import lottie from 'lottie-web'

interface LoadingItemProps {
  message?: React.ReactNode
}

function LoadingItem({ message }: LoadingItemProps) {
  const loadingRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (loadingRef.current) {
      lottie.loadAnimation({
        container: loadingRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        animationData: require('../../assets/img/lottie/loading.json')
      })
    }
  }, [])
  return (
    <div className={styles.loadingBox}>
      <div ref={loadingRef} className={styles.loading} />
      {message && <div className={styles.loadingText}>{message}</div>}
    </div>
  )
}

export default LoadingItem
