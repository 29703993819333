import React, { useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { ZiProgressBar, SetupTitle } from '@/components'
import { useDispatch } from 'react-redux'
import { updateIdcardInfo } from '@/pages/setup/store/slices/idCard'
import { callNativeEKYC } from '@/service/nativeCall'
import * as Enums from '@/common/enums'
import * as Schemas from '@/common/schemas'
import classnames from 'classnames'
import { SetupPageProps } from '@/common/types'
import idCardOld from '@/assets/img/setup/id-card.png'
import idCardNew from '@/assets/img/setup/id-card-new.png'
import IdCardExample from '@/components/IdCardExample'
import IdCardHKCorrect from '@/assets/img/id-card-example/id-card-hk-correct.svg'
import IdCardHKMarginal from '@/assets/img/id-card-example/id-card-hk-marginal.svg'
import IdCardHKFuzzy from '@/assets/img/id-card-example/id-card-hk-fuzzy.svg'
import IdCardHKFlash from '@/assets/img/id-card-example/id-card-hk-flash.svg'

const exampleSrcDic = {
  correct: IdCardHKCorrect,
  marginal: IdCardHKMarginal,
  fuzzy: IdCardHKFuzzy,
  flash: IdCardHKFlash
}

function SetupPre(props: SetupPageProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [idCardType, setIdCardType] = useState<Enums.IdCardType>()

  const onNextStep = (type: Enums.IdCardType): void => {
    callNativeEKYC({ type, bizType: Enums.BizType.ACCOUNT }).then((res: Schemas.EkycReturnType) => {
      const identifyData = ekycRetDataPipe(res)
      console.log(`identifyData`, identifyData)
      dispatch(updateIdcardInfo(identifyData))
      props.nextPage()
    })
  }

  const cardTypeChange = (type: Enums.IdCardType): void => {
    setIdCardType(type)
    onNextStep(type)
  }

  // 转换ekyc数据格式
  const ekycRetDataPipe = (ekycRetData: Schemas.EkycReturnType): Schemas.IdentifyType => {
    return {
      firstName: ekycRetData.firstName,
      lastName: ekycRetData.lastName,
      zhName: ekycRetData.cnName,
      engName: `${ekycRetData.lastName} ${ekycRetData.firstName}`,
      idType: `${ekycRetData.idType}_${ekycRetData.permanentFlag}`,
      idNo: ekycRetData.idNo,
      sex: ekycRetData.sex,
      birthdate: String(new Date(ekycRetData.birthday).getTime()),
      birthAddr: ekycRetData.birthplace,
      country: ekycRetData.permanentFlag === 'Y' ? [ekycRetData.issueCountry] : []
    }
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={1}
          title={t('za_invest_setup_progressBar_01Title', '01 基础资料')}
          progress={50}
        />
      </div>
      <div className={styles.setupPreBody}>
        <SetupTitle text={t('za_invest_setup_pre_page_title', '请准备以下材料')} />
        <div className={classnames(styles.idCardView)}>
          <div
            className={classnames(styles.cardBox, {
              [styles.activeCardBox]: idCardType === Enums.IdCardType.OLD_IDCARD
            })}
            onClick={(): void => cardTypeChange(Enums.IdCardType.OLD_IDCARD)}
          >
            <div className={styles.cardText}>
              <p className={styles.main}>
                {t('za_invest_setup_pre_page_old_id_card', '智能身份证')}
              </p>
            </div>
            <img src={idCardOld} />
          </div>
          <div
            className={classnames(styles.cardBox, {
              [styles.activeCardBox]: idCardType === Enums.IdCardType.NEW_IDCARD
            })}
            onClick={(): void => cardTypeChange(Enums.IdCardType.NEW_IDCARD)}
          >
            <div className={styles.cardText}>
              <p className={styles.main}>
                {t('za_invest_setup_pre_page_new_id_card', '新智能身份证')}
              </p>
              <p className={styles.sub}>
                {t('za_invest_setup_pre_page_new_id_card_tips', '2018年11月推出')}
              </p>
            </div>
            <img src={idCardNew} />
          </div>
        </div>
        <IdCardExample srcDic={exampleSrcDic} />
      </div>
    </div>
  )
}

export default SetupPre
