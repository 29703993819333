import { getCACertDN, getCACerti, queryCAApplyResult, signCA } from '@/service/ca'
import { createKeypair, formatSubject, createPKCS1, createPKCS10 } from './caThird'
import { CaResType } from '@/common/schemas'
import { toggleLoading } from '@/utils'
import { ZiModal } from '@/components'
import { callCloseWebview, reportCommonException } from '@/service/nativeCall'
import { TFunction } from 'i18next'
import CryptoJS from 'crypto-js'

interface caParams {
  caSerialNo: string
  successFn: () => void
  failFn: (res: CaResType) => void
  t: TFunction
}
// 获取certP10
const getCertP10 = (certDN: any, callback: (certP10: string) => void) => {
  const bits = 2048
  const workers = 2
  const startTime = new Date().getTime()
  // 创建公私钥对
  createKeypair(bits, workers)
    .then((keyPair) => {
      console.log(`生成 keypair 耗时= ${new Date().getTime() - startTime} ms`)
      console.log(keyPair)
      window.CAKeyPair = keyPair
      const subject = formatSubject(certDN)
      const certP10 = createPKCS10(keyPair, subject) // 根据主题生成 p10
      console.log(certP10, 'certP10')
      callback(certP10)
    })
    .catch((err) => console.error(err))
}

// 获取主题与请求证书
export const getCertDN = async (openAccNo: string, callback: (res?: any) => void) => {
  let result
  try {
    result = await getCACertDN({
      openAccNo
    })
  } catch (e) {
    callback?.()
    return
  }
  if (!result.caSerialNo) {
    callback?.()
    return
  }
  const { certDN, caSerialNo } = result
  getCertP10(certDN, async (certP10) => {
    let rsaMd5 = ''
    try {
      rsaMd5 = CryptoJS.MD5(JSON.stringify(window.CAKeyPair)).toString()
    } catch (err) {
      console.error(err)
      reportCommonException('getCertP10', Error(`getCACerti saMd5 make error`))
    }
    try {
      await getCACerti({ caSerialNo, certP10, rsaMd5 })
      callback?.(caSerialNo)
    } catch (err) {
      console.error(err)
      callback?.()
    }
  })
}

// 获取证书请求结果
export const queryCaApplyResult = (params: caParams) => {
  window.caTimeout = null
  let times = 0
  let hadResultCnt = 0
  const mainFunc = async () => {
    const { caSerialNo, successFn, failFn, t } = params
    if (!caSerialNo) {
      return
    }
    if (times > 30) {
      ZiModal.alert(null, t('4541_ca_overtimes_desc'), [
        {
          text: t('4541_ca_overtimes_quit'),
          onPress: (): void => {
            // 退出 , 关闭webview
            callCloseWebview()
          }
        },
        {
          text: t('4541_ca_overtimes_retry'),
          onPress: (): void => {
            // 重试
            times = 0
            mainFunc()
            return
          }
        }
      ])
      return
    }
    ++times
    toggleLoading(true)
    let res: any
    try {
      res = await queryCAApplyResult({ caSerialNo })
    } catch (e) {
      console.log(e, '接口报错')
      toggleLoading(false)
      return
    }
    const { applyResultStatus, fieHash } = res
    let p1SignData
    let keyPairCopy = window.CAKeyPair
    let rsaMd5 = ''
    try {
      rsaMd5 = CryptoJS.MD5(JSON.stringify(keyPairCopy)).toString()
    } catch (err) {
      console.error(err)
      reportCommonException('getCertP10', Error(`signCA saMd5 make error`))
    }
    clearCaTimeout()

    if (hadResultCnt > 0) {
      reportCommonException('mainFunc', Error(`hadResultCnt:${hadResultCnt}`))
    } else {
      switch (applyResultStatus) {
        case 'APPLY_SUCCESS':
          hadResultCnt++
          // 请求成功, 中断setTimeout, 请求签名
          p1SignData = createPKCS1(keyPairCopy, fieHash)

          try {
            await signCA({ p1SignData, caSerialNo, rsaMd5 })
            toggleLoading(false)
          } catch (e) {
            console.log(e, 'ca签名异常')
            return
          }
          successFn()
          break
        case 'APPLY_FAIL':
          hadResultCnt++
          toggleLoading(false)
          failFn(res)
          break
        default:
          // 考虑设置一个超时次数，否则一直无响应一直请求
          window.caTimeout = setTimeout(() => {
            mainFunc()
          }, 500)
          break
      }
    }
  }
  mainFunc()
}
export const clearCaTimeout = () => {
  if (window.caTimeout) {
    clearTimeout(window.caTimeout)
  }
}
