import React, { useContext } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import { useSetState } from '@/hooks'
import { CheckboxContext } from '../index'
interface CheckBoxItemProps {
  children: any
  className?: string
  onChange?: (checked: boolean, val?: any) => void
  defaultChecked?: boolean
  value?: string
  labelClickable?: boolean
}

const noop = () => { }

function CheckBoxItem(props: CheckBoxItemProps): JSX.Element {
  const { onContextChange } = useContext(CheckboxContext)
  const {
    children,
    className,
    onChange,
    defaultChecked = false,
    value,
    labelClickable = true
  } = props
  const [state, setState] = useSetState({ checked: defaultChecked })
  const { checked } = state
  const handleClick = (): void => {
    onChange?.(!checked, value)
    setState({ checked: !checked })
    onContextChange?.(!checked, value)
  }

  return (
    <div
      className={classnames(styles.checkboxItem, className)}
      onClick={labelClickable ? handleClick : noop}
    >
      <div className={styles.iconContainer}>
        <span
          onClick={labelClickable ? noop : handleClick}
          className={classnames(
            styles.checked,
            checked ? styles.iconCheckActive : styles.iconCheck
          )}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default CheckBoxItem
