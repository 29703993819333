import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

const signatrueData = {
  imgBase64: '',
  signature: '',
  isAllowPromotion: 'Y'
}

type State = ReturnType<() => typeof signatrueData>

export const slice = createSlice({
  name: 'riskShow',
  initialState: {
    signatrueData
  },
  reducers: {
    updateSignatureData(state, action) {
      if (isEmpty(action.payload)) {
        return
      }
      state.signatrueData = {
        ...state.signatrueData,
        ...action.payload
      }
    }
  }
})

export const { updateSignatureData } = slice.actions

export const selectSignatureData = (state: { riskShow: { signatrueData: State } }) =>
  state.riskShow.signatrueData

export default slice.reducer

