/*
 * @Description:
 * @Autor: zhiwen.wu
 * @Date: 2021-11-18 20:23:50
 * @LastEditors: zhiwen.wu
 * @LastEditTime: 2021-12-16 14:26:58
 */
export enum PagePathMoneyRecord {
  PREFIX = '/money-record/',
  PAGE_HOME = '/',
  PAGE_CASHIN_DETAIL = '/cashin-detail/',
  PAGE_CASHOUT_DETAIL = '/cashout-detail/',
  PAGE_FINANCIALDETAIL = '/financial-detail/',
  PAGE_RECORD_LIST = '/record-list/',
  PAGE_FINANCIAL_RECORD_DETSIL = '/financial-record-detail/',
  PAGE_INSURANCE_DETAIL = '/insurance-detail/'
  // PAGE_EXCHANGE = '/exchange/',
  // PAGE_EXCHANGE_RESULT = '/exchange-result/',
  // PAGE_EXCHANGE_LIST_DETAIL = '/exchange-list-detail/',
  // PAGE_EXCHANGE_FUND_RECORD_LIST = '/exchange-fund-record-list/'
}

export enum CashIoTypes {
  FPS = 'FPS',
  SUB_ACCT = 'SUB_ACCT'
}
