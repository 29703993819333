/*
 * @Description:
 * @Autor: zhiwen.wu
 * @Date: 2021-11-18 20:23:50
 * @LastEditors: zhiwen.wu
 * @LastEditTime: 2021-12-16 11:16:21
 */
export enum PagePathCashIn {
  PAGE_HOME = '/',
  PAGE_DEPOSIT_CA = '/deposit-ca/',
  PAGE_DEPOSIT_FPS = '/deposit-fps/',
  PAGE_DEPOSIT_FPS_OLD = '/deposit-fps-old/',
  PAGE_DEPOSIT_INTRO = '/deposit-intro/',
  PAGE_TRANSFER_INFO_FPS = '/transfer-info-fps/',
  PAGE_SUBACCOUNT = '/sub-account/',
  PAGE_SUBACCOUNT_INFO = '/sub-account-info/',
  PAGE_TRANSFER_INFO_CA = '/transfer-info-ca/',
  PAGE_RESULT = '/result/',
  PAGE_DEPOSIT_FPS_NEW = '/deposit-fps-new/',
  CURRENCY = '/currency',
  ACCOUNTAREA = '/account-area'
}

export enum SetupTypeMap {
  CA = 'CA',
  HK = 'HK'
}
