import React, { useEffect } from 'react'
import styles from './index.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { selectCountryDic } from '../../store/slices/init'
import { ZiSelect, ZiInput, ZiProgressBar, ZiModal } from '@/components'
import { useTranslation } from 'react-i18next'
import { Icon, Button, Toast } from 'zati-ui-web'
import IconPlus from '@/assets/img/setup/plus.svg'
import { useSetState, useSubmitMSetup } from '@/hooks'
import { v4 as uuid } from 'uuid'
import IconDelete from '@/assets/img/setup/delete.svg'
import { SetupPageProps } from '@/common/types'
import * as Enums from '@/common/enums'
import { pick, trim } from 'lodash'
import { selectTaxMsg, updateTaxMag } from '../../store/slices/taxation'
import { TaxMsgType } from '@/common/schemas/setup/account.interface'
import { selectIdentify } from '@/pages/msetup/store/slices/identify'
import { Timing } from '@/common/enums/time.enum'

function Taxation({ nextPage }: SetupPageProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const submitSetup = useSubmitMSetup()
  const idCardInfo = useSelector(selectIdentify) || {}
  const countryOps = useSelector(selectCountryDic)
  const taxMsg = useSelector(selectTaxMsg)
  const [state, setState] = useSetState({
    list: taxMsg.map((item) => {
      const temp = { ...item }
      if (!temp.key) {
        temp.key = uuid()
      }
      return temp
    })
  })

  const { list } = state
  useEffect(() => {
    if (list[0].key === 'first' && list[0].taxNumber === '') {
      const taxList = [...list]
      taxList[0].taxNumber = idCardInfo?.idNo
      dispatch(updateTaxMag(taxList))
    }
  }, [list, dispatch, idCardInfo.idNo])
  const addItem = (): void => {
    if (list.length < 5) {
      setState({
        list: list.concat({
          regionValue: '',
          taxNumber: '',
          key: uuid()
        })
      })
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      }, 0)
    } else {
      Toast.warn?.(t('za_invest_setup_tax_max_regions', '至多可申报五个地区'))
    }
  }

  const onDelete = (index: number): void => {
    list.splice(index, 1)
    setState({ list })
  }

  const comfirmDelete = (index: number): void => {
    ZiModal.alert(
      '',
      <p className={styles.modalMessage}>
        {t('za_invest_setup_taxation_delete_comfirm_msg', '删除税务国家/地区？')}
      </p>,
      [
        {
          text: t('za_invest_cashin_transfer_alert_cancel_txt', '取消')
        },
        {
          text: t('za_invest_cashin_transfer_alert_ok_txt', '确定'),
          onPress() {
            onDelete(index)
          }
        }
      ]
    )
  }

  const updateFieldValue = (field: keyof TaxMsgType['taxAreaInfo'][0]) => {
    return (key: string, value: string): void => {
      const oldList = list.map((item) => {
        const temp = { ...item }
        if (temp.key === key && field !== 'key') {
          temp[field] = value
        }
        return temp
      })
      setState({ list: oldList })
    }
  }

  const updateRegion = updateFieldValue('regionValue')

  const updateTaxNumber = updateFieldValue('taxNumber')

  const onSubmit = async (): Promise<void> => {
    if (list.some((item) => trim(item.regionValue) === '' || trim(item.taxNumber) === '')) {
      Toast.fail?.(
        t('za_invest_setup_taxation_info_not_allow_empty', `税务国家/地区或税务编号不能为空`)
      )
      return
    }
    try {
      const taxList = list.map((item) => pick(item, ['regionValue', 'taxNumber']))
      await submitSetup({
        dataType: Enums.OpenStep.STEP_TAX,
        openData: {
          taxMsg: { taxAreaInfo: taxList }
        }
      })
      dispatch(updateTaxMag(list))
      nextPage()
    } catch (e) {
      console.log(e)
    }
  }

  const onChange = (key: string, e: React.ChangeEvent<HTMLInputElement>): void => {
    updateTaxNumber(key, e.currentTarget.value)
  }

  return (
    <div className={styles.page}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={2}
          title={t('za_invest_setup_career_page_progress_title')}
          progress={40}
        />
      </div>
      <h2 className="page-title">
        {t('za_invest_setup_tax_comfirm_tax_area', '请确认你的纳税地区')}
      </h2>
      {list.map((item, index) => (
        <div className={styles.panel} key={item.key}>
          {index > 0 && (
            <div className={styles.deleteLine}>
              <Icon icon={IconDelete} onClick={() => comfirmDelete(index)} />
            </div>
          )}
          <ZiSelect
            value={item.regionValue}
            label={t('za_invest_setup_tax_country_or_area', '税务国家/地区')}
            className={styles.selectArea}
            options={countryOps}
            canSearch
            onChange={(val) => updateRegion(item.key, val)}
          />
          <ZiInput
            placeholder={t('za_invest_setup_tax_number', '税务编号')}
            value={item.taxNumber}
            maxLength={50}
            onChange={(e) => onChange(item.key, e)}
          />
          {item.regionValue === 'CN' && (
            <p className={styles.tips}>
              {t('za_invest_setup_taxation_tips', '中国内地税务编号为身份证号')}
            </p>
          )}
        </div>
      ))}
      <div className={styles.addItem} onClick={addItem}>
        <Icon icon={IconPlus} className={styles.plusIcon} />
        {t('za_invest_setup_taxation_add', '另一税务国家/地区')}
      </div>
      <div className="footer-btn-box">
        <Button type="primary" block throttleTime={Timing.DEBOUNCE} onClick={onSubmit}>
          {t('za_invest_setup_idcard_page_confirm', '确认')}
        </Button>
      </div>
    </div>
  )
}

export default Taxation
