import React from 'react'
import PropTypes from 'prop-types'
import NavBar from '../NavBar'
import classnames from 'classnames'
import styles from './index.module.scss'

function NavBarPage(props: any): JSX.Element {
  const { className, children, ...rest } = props
  return (
    <div className={classnames(styles.pageContainer, className)}>
      <NavBar {...rest} fixedTop />
      <div className={styles.pageContent}>{children}</div>
    </div>
  )
}

NavBarPage.propTypes = {
  className: PropTypes.string,
  ...NavBar.propTypes
}

export default NavBarPage
