import { getEnv } from '@/utils'

const isPrd = getEnv() === 'prd'

export const pubkey = isPrd
  ? `
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2ZU2bvXGm4koFFv1FypODoL/tA48BdTf9zYfu14ksQH8UEVqf2W7CIj3fYEoAsP9JJHfVtdZ6+JDNTZjplpx4NLiCSXmXU2nik5JoxT/K3O2qIrJBzz1NgpNN2VP/rcFcY8hOoYINQVH2SgVrWt1fP7zPaag/FLNAl4HJl4G5eyiwG5pvFqOaDs1Kg1p+kHJKvq50x2wN6Uv972mTNsRKvE1J7S/XNALrnbdWy70KeFxQ7csiB+fIvJPn3yxWFLQVFX8xjh7IglohvKbcsQ0YKwpDDwtLuP6XdcsMn1GxO4qLHU90G3F6ISzACmQDqdjB4uH60/z4bshrH83X7K/pwIDAQAB`
  : `
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu5JdLRiYycopW2FekrjGigzNyW58+O28W2M51Nq3jDMZ1XDE1uDBOAir+Layib1cpmenm6f0cEEWgIVnXTARlRrV3pDBXglFV4sQajrPPgXVkRfFKGFvikjYYOaJ9Sp4d2EZl4tX0TOqsh+SMpbWE4ezmpOx+MA3XiV9YCTg09YqhWqDOG7MuywLFs06bXzlzpb2nwcBQf6kbpMYCDSbItjai82Rj6aISWG7fJMzFtyx7+3ch2mBWOt4a362AVQPK05YDd02J0wlBNJ0zfzyPHUG/rpODcsAUsKO6Pd2Xa/5b2nLGRN0kJpEhigU6NSFSlDbDQ5HCo41YPotQZqNvQIDAQAB`

export const HMAC_KEY = isPrd ? 'ZATjwVD9wuBdHI' : 'ZA_jwVD9w0BdHI'

export const IV = 'cfqgWgjXgSotQxuD'

export const API_VERSION = 'v1.0.0'

export enum MOCKTYPE {
  YAPI = 'yapimock',
  LOCAL = 'localmock'
}

export const YAPI_MOCK_PREFIX = 'https://apicms.zatech.online/mock/971'

export const APPKEY = '2bd8e3a5cffe45e5921a4c1eeacf1b7b'

export const APPNAME = 'AAIM'

export const CHANNELID = 'Invest'

export const OS = 'H5'

export const MOCKDID =
  'ZmVhZmE2Yzc0ODExMjEzNTdlY2UyN2RkNWNmZGZkODYtY2JiMjk3YzAxNGE5NDZiY2FkOTExZDBlZjliNDJkZjkwMDAyLWhrekRScmprTmNjNWFsVFFTc2NIK1pDTkhsWT0='

export const TLENGTH = 32

export const REQID = 16
