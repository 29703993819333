import React from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'

interface IndexProps {
  stepsList: string[]
  currentIndex: number
  className?: string
}
type Status = 0 | 1 | 2 // 0:已完成 1:进行中 2:未完成
interface ItemType {
  desc: string
  status: Status
}
function Index(props: IndexProps): JSX.Element {
  const { stepsList, currentIndex, className } = props
  const classNameArr = [styles.finished, styles.pending]
  const processedStepList: ItemType[] = stepsList.map((item: string, idx: number) => {
    const getStatus = (curIdx: number) => {
      if (curIdx === currentIndex) return 1
      else if (curIdx < currentIndex) return 0
      return 2
    }
    return {
      desc: item,
      status: getStatus(idx)
    }
  })
  return (
    <ul className={classnames(styles.stepContainer, className)}>
      {processedStepList.map(({ desc, status }, index: number) => (
        <li className={classnames(styles.stepItem, classNameArr[status])} key={`step-${index}`}>
          <span className={styles.stepIndex}>{index + 1}</span>
          <p className={styles.stepDesc}>{desc}</p>
        </li>
      ))}
    </ul>
  )
}

export default Index
