import { createSlice } from '@reduxjs/toolkit'
import { FileItem } from '@/common/types'
import { isEmpty, isString } from 'lodash'

export interface UploadConsentState {
  uploadConsent: {
    files: FileItem[]
    objectKeys: string[]
    organizationName: string
    centralNumber: string
  }
}

export const counterSlice = createSlice({
  name: 'uploadConsent',
  initialState: {
    objectKeys: [],
    files: [],
    organizationName: '',
    centralNumber: ''
  },
  reducers: {
    updateFiles(state, action): void {
      state.files = action.payload
    },
    updateObjectKeys(state, action): void {
      if (isEmpty(action.payload)) {
        return
      }
      if (isString(action.payload)) {
        try {
          action.payload = JSON.parse(action.payload)
        } catch (e) {
          console.log(e)
        }
      }
      state.objectKeys = action.payload
    },
    updateEmployerAgreeInfo(state, action): void {
      if (isEmpty(action.payload)) {
        return
      }
      const keys = Object.keys(action.payload)
      keys.forEach((k: string) => {
        // employerAgree 是上传的图片
        if (k === 'employerAgree') {
          let objectKeys = action.payload.employerAgree
          if (isString(objectKeys)) {
            try {
              objectKeys = JSON.parse(objectKeys)
            } catch (e) {
              console.log(e)
            }
          }
          state.objectKeys = objectKeys
        } else {
          state[k as keyof typeof state] = action.payload[k]
        }
      })
    }
  }
})

export const { updateFiles, updateObjectKeys, updateEmployerAgreeInfo } = counterSlice.actions

export const selectFiles = (state: UploadConsentState): FileItem[] => state.uploadConsent.files
export const selectObjectKeys = (state: UploadConsentState): string[] =>
  state.uploadConsent.objectKeys
export const selectEmployerAgreeInfo = (state: UploadConsentState) => state.uploadConsent

export default counterSlice.reducer
