import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Image, Button } from 'zati-ui-web'
import styles from './index.module.scss'
import ActionSheet from '@/components/ActionSheet'

interface IndexProps {
  show: boolean
  title: string
  icon: string
  content: string[]
  btnText: string
  handleClose: () => void
}

function Index(props: IndexProps): JSX.Element {
  const { show, title, icon, content, btnText, handleClose } = props
  return (
    <ActionSheet show={show} onClose={handleClose}>
      <h3 className={styles.tipsInPopTit}>{title}</h3>
      <Image className={styles.tipsInPopIcon} src={icon} />
      <ul className={styles.tipsInPopList}>
        {content.map((item: string) => (
          <li
            className={styles.tipsInPopItem}
            key={uuidv4()}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </ul>
      <Button block width={268} className={styles.tipsInPopBtn} onClick={handleClose}>
        {btnText}
      </Button>
    </ActionSheet>
  )
}

export default Index
