/*
 * @Description:内地，香港身份证拍摄要求展示组件
 * @Autor: zhiwen.wu
 * @Date: 2021-08-05 20:10:59
 * @LastEditors: zhiwen.wu
 * @LastEditTime: 2021-08-10 16:59:15
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import rightIcon from '@/assets/img/id-card-example/right.png'
import errorIcon from '@/assets/img/id-card-example/error.png'

import styles from './index.module.scss'

interface IIdCardExampleItem {
  src: string
  desc: string
}
interface IIdCardExample {
  [key: string]: IIdCardExampleItem
}

interface ISrcDic {
  [key: string]: string
}

interface Props {
  srcDic: ISrcDic
}

const IdCardExample = ({ srcDic }: Props) => {
  const { t } = useTranslation()
  const idCardExampleDic: IIdCardExample = {
    correct: {
      src: srcDic['correct'],
      desc: t('za_invest_msetup_upload_id_card_correct', '正确示例')
    },
    marginal: {
      src: srcDic['marginal'],
      desc: t('za_invest_msetup_upload_id_card_marginal', '边角缺失')
    },
    fuzzy: {
      src: srcDic['fuzzy'],
      desc: t('za_invest_msetup_upload_id_card_fuzzy', '信息模糊')
    },
    flash: {
      src: srcDic['flash'],
      desc: t('za_invest_msetup_upload_id_card_flash', '闪光强烈')
    }
  }

  const idCarExampleItem = (type: string, idx: number) => {
    const info = idCardExampleDic[type]
    const iconUrl = type === 'correct' ? rightIcon : errorIcon
    return (
      <div key={idx} className={styles.idCardExampleItem}>
        <img src={info.src} alt="identify image" className={styles.idCardExampleItemImg} />
        <div className={styles.idCardExampleItemTxt}>
          <img
            src={iconUrl}
            alt="identify status icon"
            className={
              type === 'correct' ? styles.idCardItemStatusRight : styles.idCardItemStatusError
            }
          />
          <span>{info.desc}</span>
        </div>
      </div>
    )
  }

  const idCardExampleList = ['correct', 'marginal', 'fuzzy', 'flash']

  return (
    <div className={styles.photoIdCard}>
      <div className={styles.line} />
      <p className={styles.photoIdCardTitle}>
        {t('za_invest_msetup_upload_photo_id_card_tip', '拍摄提示')}
      </p>
      <div className={styles.idCardExample}>
        {idCardExampleList.map((item, idx) => {
          return idCarExampleItem(item, idx)
        })}
      </div>
    </div>
  )
}

export default IdCardExample
