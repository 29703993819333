import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEmpty, pick } from 'lodash'
import { QueryRecognizeResultRes } from '@/common/schemas'

const pageData = {
  addrDetail: '',
  area: [] as string[],
  email: '',
  firstName: '',
  idNo: '',
  lastName: '',
  zhName: '',
  birthdate: '',
  idStartDate: '',
  idIssuedBy: '',
  idValidDate: '',
  provinceCode: '',
  cityCode: ''
}

export type IdentifyPageData = ReturnType<() => typeof pageData>

const identifySlice = createSlice({
  name: 'identify',
  initialState: {
    pageData,
    recognizeData: {}
  },
  reducers: {
    updateIdentify(state, action: PayloadAction<Partial<IdentifyPageData>>) {
      if (isEmpty(action.payload)) {
        return
      }
      state.pageData = {
        ...state.pageData,
        ...action.payload
      }
    },
    /**
     *
     * 仅作 OCR 识别证件结果保存
     */
    updateRecognizeData(state, action: PayloadAction<Partial<QueryRecognizeResultRes>>) {
      const { payload } = action
      if (isEmpty(payload)) {
        return
      }
      state.recognizeData = {
        ...state.recognizeData,
        ...payload
      }
      let area = []
      if (payload?.provinceCode) {
        area.push(payload.provinceCode)
        if (payload?.cityCode) {
          area.push(payload?.cityCode)
        }
      }
      const partialData = pick(state.recognizeData, Object.keys(state.pageData))
      // TODO
      state.pageData = {
        ...state.pageData,
        ...partialData,
        area
      }
    }
  }
})

export const { updateIdentify, updateRecognizeData } = identifySlice.actions

export const selectIdentify = (state: { identify: { pageData: IdentifyPageData } }) => {
  return state.identify.pageData
}
export const selectRecognizeData = (state: {
  identify: { recognizeData: QueryRecognizeResultRes }
}) => {
  return state.identify.recognizeData
}

export default identifySlice.reducer
