import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Button } from 'zati-ui-web'
import styles from './index.module.scss'
import iconModalClose from '@/assets/img/modal-close.svg'

export interface ZaModalProp {
  iconUrl: any
  line1: string
  line2: string
  line3: string
  confirmText: string
  cancelText: string
  hideClose: boolean
  hide: boolean
  children: React.ReactNode
  onConfirm: () => void
  onClose: () => void
  hideModal: boolean
  customIcon: any
  noBackground: boolean
}

function ZaModal(prop: Partial<ZaModalProp>, ref: any): JSX.Element | null {
  const {
    onConfirm,
    onClose,
    iconUrl,
    line1,
    line2,
    line3 = '',
    confirmText,
    cancelText,
    hideClose,
    children,
    hide,
    customIcon,
    noBackground
  } = prop
  const [show, setShow] = useState(false)
  const hideModal = () => {
    setShow(false)
  }
  const showModal = () => {
    setShow(true)
  }
  useImperativeHandle(ref, () => ({
    showModal,
    hideModal
  }))
  if (!show) {
    return null
  }
  const handleClose = () => {
    hideModal()
    onClose?.()
  }
  const handleConfirm = () => {
    hideModal()
    onConfirm?.()
  }
  if (hide) {
    return null
  }
  return (
    <div data-testid="zaModal" className={styles.modal}>
      <div className={styles.modalHelp} />
      <div className={noBackground ? styles.modalNoBg : styles.modalBg}>
        <div className={styles.modalContent}>
          {iconUrl && <img className={styles.icon} src={iconUrl} height={119} />}
          {customIcon}
          <div className={styles.padding30}>
            {line1 && <p className={styles.line1}>{line1}</p>}
            {line2 && <p className={styles.line2}>{line2}</p>}
            {line3 && <p className={styles.line3}>{line3}</p>}
            {children}
          </div>
          {confirmText && (
            <Button
              data-testid="zaModalBtn"
              width={268}
              height={44}
              type="primary"
              onClick={handleConfirm}
              className={styles.modalBtn}
            >
              {confirmText}
            </Button>
          )}
          {cancelText && (
            <div className={styles.cancelTxt} onClick={handleClose}>
              {cancelText}
            </div>
          )}
        </div>
      </div>
      {!hideClose && (
        <p className={styles.closeWrap}>
          <img src={iconModalClose} alt="" onClick={handleClose} />
        </p>
      )}
    </div>
  )
}

export default forwardRef(ZaModal)
