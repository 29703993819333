import React, { useState, useImperativeHandle, forwardRef } from 'react'
import styles from './index.module.scss'
import { SearchBar, List } from 'antd-mobile'
import { BankGroupType, BankListType, BankType } from '@/common/schemas/card-mgmt/index.interface'
import classnames from 'classnames'
import { BankListComponentPropsType } from './index.interface'
import { CheckCircleFilled } from '@ant-design/icons'
import { ActionSheet } from '@/components'
import { useTranslation } from 'react-i18next'

const Item = List.Item

function BankList(props: BankListComponentPropsType, ref: any): JSX.Element | null {
  const [isSearching, setIsSearching] = useState(false)
  const [searchList, setSearchList] = useState([]) as [BankType[], any]
  const bankList: BankListType = props.bankList
  const [isShow, setIsShow] = useState(false)
  const [activeBank, setActiveBank] = useState(props.activeBank || {}) as [BankType, any]
  const { t } = useTranslation()

  useImperativeHandle(ref, () => ({
    show: (): void => {
      setIsShow(true)
    },
    hide: (): void => {
      setIsShow(false)
    }
  }))

  const onClickItem = (item: BankType): void => {
    setIsShow(false)
    setActiveBank(item)
    setIsSearching(false)
    props.callBack(item)
  }

  const commonList = (
    <List
      className={styles.list}
      renderHeader={(): string => t('za_invest_card-mgmt_banklist_common_bank_title', '常用银行')}
    >
      {bankList.common.map((item) => {
        return (
          <Item
            wrap
            multipleLine
            key={item.value}
            onClick={(): void => {
              onClickItem(item)
            }}
          >
            {item.desc}({item.value})
            {item.value === activeBank.value ? <CheckCircleFilled /> : null}
          </Item>
        )
      })}
    </List>
  )
  const letterList = bankList.list.map((group: BankGroupType) => {
    return Object.keys(group).map((attr) => {
      const listItem = group[attr].map((listItem: BankType) => {
        return (
          <Item
            wrap
            multipleLine
            key={listItem.value}
            onClick={(): void => {
              onClickItem(listItem)
            }}
            extra={listItem.value === activeBank.value ? <CheckCircleFilled /> : null}
          >
            {listItem.desc}({listItem.value})
          </Item>
        )
      })
      return (
        <List className={styles.list} key={attr} renderHeader={(): string => attr}>
          {listItem}
        </List>
      )
    })
  })
  const onSearch = (val: string): void => {
    if (val) {
      setIsSearching(true)
    } else {
      setIsSearching(false)
    }
    if (!val) {
      setSearchList([])
      return
    }
    const list = bankList.list
    const result: BankType[] = []
    list.forEach((groupItem: BankGroupType) => {
      for (const attr in groupItem) {
        if (Object.prototype.hasOwnProperty.call(groupItem, attr)) {
          groupItem[attr].forEach((item: BankType) => {
            const reg = RegExp(val, 'i')
            if (reg.test(item.desc) || reg.test(item.value)) {
              result.push(item)
            }
          })
        }
      }
    })
    setSearchList(result)
  }
  const close = (): void => {
    setIsShow(false)
    setIsSearching(false)
  }
  return (
    <ActionSheet show={isShow} showClose={false} onClose={close} className={styles.bankListSheet}>
      <div className={styles.searchWrapper}>
        <SearchBar
          placeholder={t(
            'za_invest_card_mgmt_banklist_search_placeholder',
            '输入银行编号/银行名称进行查找'
          )}
          onChange={onSearch}
          className={styles.search}
          // showCancelButton
          // onCancel={close}
          cancelText={t('za_invest_card_mgmt_searchbar_cancel_txt', '取消')}
        />
      </div>
      <div className={classnames([styles.contentWrapper, { [styles.show]: true }])}>
        <h3 className={styles.title}>
          {t('za_invest_card_mgmt_banklist_select_bank', '选择银行')}
        </h3>
        {/* <p className={styles.desc}>
          银行绑定时长可能会有不同，推荐选择有实时标签的银行，处理时效更快，具体处理时间以所选银行为准。
        </p> */}
        {!isSearching && commonList}
        {!isSearching && letterList}
        {isSearching && (
          <List
            renderHeader={(): string => t('za_invest_card_mgmt_banklist_searching_txt', '搜索中')}
          >
            {searchList.map((item) => {
              return (
                <Item
                  wrap
                  multipleLine
                  key={item.value}
                  onClick={(): void => {
                    onClickItem(item)
                  }}
                >
                  {item.desc}({item.value})
                </Item>
              )
            })}
          </List>
        )}
      </div>
    </ActionSheet>
  )
}

export default forwardRef(BankList)
