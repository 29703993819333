export enum SetupErrorCode {
  // 开户过期
  EXPIRE = 'OPA0105'
}

// 跳转登录
export const AuthErrorCode = ['GWPA300', 'GWPA301', '30006', 'UCSA004']

// 交易密码
export const TradeAuthErrorCode = ['GWPA600', 'GWPA601', 'UCSA460']
