import React, { useEffect, useCallback } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { ZiProgressBar, ZiInput, ZiSelect, ZiCascader } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { selectCountryDic } from '../../store/slices/init'
import { selectAddressInfo, updateAddressInfo } from '../../store/slices/address'
import styles from './index.module.scss'
import { useSetState, useSubmitMSetup } from '@/hooks'
import * as Schemas from '@/common/schemas'
import * as Enums from '@/common/enums'
import { getCountryDictionary } from '@/service/commonModule'
import { SelectOptionItem } from '@/common/types'
import { cloneDeep, pick } from 'lodash'
import { Button } from 'zati-ui-web'
import { Timing } from '@/common/enums/time.enum'
import { reportCommonException } from '@/service/nativeCall'

interface State {
  addressError: boolean
  areaList: SelectOptionItem[]
  areaValue: string[]
}

export interface AddressSubmitData extends Omit<Schemas.AddrType, 'area'> {
  area: string
}

function Address({ nextPage }: any): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const addressInfo = useSelector(selectAddressInfo)
  const countryDic = useSelector(selectCountryDic)
  const dispatch = useDispatch()
  const submitSetup = useSubmitMSetup()
  const [state, setState] = useSetState<State>({
    addressError: false,
    areaList: [],
    areaValue: addressInfo.area
  })
  const { areaList, addressError, areaValue } = state
  useEffect(() => {
    getCountryDictionary({ code: 'HK', loading: 1 }).then((resp) => {
      const list = resp.list?.map((item) => {
        return {
          value: item.code,
          label: item.name,
          order: item.order,
          children: []
        }
      })
      setState({ areaList: list })
    })
  }, [setState])

  const onSubmit = async (submitData: AddressSubmitData): Promise<void> => {
    await submitSetup({
      dataType: Enums.OpenStep.STEP_ADDRESS,
      openData: { addrMsg: submitData }
    })
    dispatch(updateAddressInfo(submitData))
    nextPage()
  }

  const onCheck = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as Schemas.AddrType
      if (!addressError) {
        const data = { ...values, ...values.address, area: values?.area?.join(',') }
        delete data.address
        onSubmit(data)
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
      reportCommonException('msetupAddress-onCheck', errorInfo)
    }
  }

  const rules: Schemas.FormRuleList = {
    room: [
      // { required: true, message: t('za_invest_setup_address_input_error1', '请输入室') },
      // {
      //   pattern: /^[\da-zA-Z#&+-‘,./ ]+$/,
      //   message: t('za_invest_setup_address_input_error2', '请输入正确的室')
      // }
    ],
    floor: [
      // { required: true, message: t('za_invest_setup_address_input_error3', '请输入楼层') },
      // {
      //   pattern: /^[\da-zA-Z#&+-‘,./ ]+$/,
      //   message: t('za_invest_setup_address_input_error4', '请输入正确的楼层')
      // }
    ],
    seat: [
      // { required: true, message: t('za_invest_setup_address_input_error5', '请输入座') },
      // {
      //   pattern: /^[\da-zA-Z#&+-‘,./ ]+$/,
      //   message: t('za_invest_setup_address_input_error6', '请输入正确的座')
      // }
    ],
    street: [
      {
        required: true,
        message: t('za_invest_setup_address_input_error7', '请输入大厦/屋苑/街道')
      }
    ],
    address: [
      {
        validateTrigger: '',
        validator() {
          const addressValue = form.getFieldValue('address')
          const addressEmpty = !addressValue.seat && !addressValue.room && !addressValue.floor
          setState({ addressError: addressEmpty })
          return Promise.resolve(true)
        }
      }
    ],
    area: [
      {
        required: true,
        message: t('za_invest_setup_address_input_error9', '请输入区域'),
        validator(_, value) {
          if (value?.length < 2 || value?.some((it: string) => !it)) {
            return Promise.reject(
              new Error(t('za_invest_setup_address_input_error9', '请输入区域'))
            )
          }
          return Promise.resolve()
        }
      }
    ],
    country: [
      { required: true, message: t('za_invest_setup_address_input_error10', '请选择国家/地区') }
    ]
  }

  // 获取远程区域数据
  const areaChange = useCallback(
    (value: string[]): void => {
      const list = cloneDeep(areaList)
      const matchItem = list?.find((it) => it.value === value[0])
      if (matchItem?.children?.length === 0) {
        getCountryDictionary({ code: value[0], loading: 1 }).then((resp) => {
          matchItem.children = resp.list.map((item) => {
            return {
              value: item.code,
              label: item.name,
              order: item.order
            }
          })
          setState({ areaList: list, areaValue: value })
          form.setFieldsValue({ area: value })
        })
      } else {
        setState({ areaValue: value })
        form.setFieldsValue({ area: value })
      }
    },
    [areaList, form, setState]
  )

  useEffect(() => {
    const formValues = {
      ...addressInfo,
      address: pick(addressInfo, ['room', 'floor', 'seat'])
    }
    form.setFieldsValue(formValues)
  }, [addressInfo, form])

  useEffect(() => {
    // 初始数据只有一级时请求对应的二级数据
    if (addressInfo.area?.length === 1 && areaList.length > 0) {
      areaChange(addressInfo.area)
    } else if (addressInfo.area?.length > 1 && areaList.length > 0) {
      let flag = false
      const matchArea = areaList.find((it) => it.value === addressInfo.area[0])
      flag = matchArea
        ? !matchArea.children?.find((item) => item.value === addressInfo.area[1])
        : false
      if (flag) {
        areaChange(addressInfo.area)
      }
    }
  }, [addressInfo, areaChange, areaList])

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={1}
          title={t('za_invest_setup_progressBar_01Title', '个人资料')}
          progress={60}
        />
      </div>

      <div className={styles.addressBody}>
        <div className={styles.pageTitle}>
          {t('za_invest_setup_address_page_title', '联系信息与居住地址')}
        </div>
        <div className={styles.formContainer}>
          <Form form={form}>
            <div className={styles.inputContainer}>
              <Form.Item className={styles.formItem} name="phone" validateFirst>
                <ZiInput
                  disabled
                  placeholder={t('za_invest_setup_address_input_placeHolder7', '手机')}
                />
              </Form.Item>
              <Form.Item className={styles.formItem} name="email" validateFirst>
                <ZiInput
                  disabled
                  placeholder={t('za_invest_setup_address_input_placeHolder8', '邮箱')}
                />
              </Form.Item>
              <Form.Item
                validateFirst
                rules={rules.address}
                name="address"
                validateTrigger="address"
              >
                <Input.Group>
                  <Row className={styles.formItem}>
                    <Col span={7}>
                      <Form.Item
                        className={styles.formItem}
                        name={['address', 'room']}
                        rules={[...rules.address, ...rules.room]}
                        validateFirst
                      >
                        <ZiInput
                          placeholder={t('za_invest_setup_address_input_placeHolder1', '室')}
                          maxLength={12}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={7}>
                      <Form.Item
                        className={styles.formItem}
                        name={['address', 'floor']}
                        rules={[...rules.address, ...rules.floor]}
                        validateFirst
                      >
                        <ZiInput
                          placeholder={t('za_invest_setup_address_input_placeHolder2', '楼层')}
                          maxLength={12}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={7}>
                      <Form.Item
                        className={styles.formItem}
                        name={['address', 'seat']}
                        rules={[...rules.address, ...rules.seat]}
                        validateFirst
                      >
                        <ZiInput
                          placeholder={t('za_invest_setup_address_input_placeHolder3', '座')}
                          maxLength={12}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                {addressError && (
                  <div style={{ color: '#f45' }}>
                    {t('za_invest_setup_address_input_error11', '室/楼层/座至少填写一项')}
                  </div>
                )}
              </Form.Item>
              <Form.Item
                className={styles.formItem}
                name="street"
                rules={rules.street}
                validateFirst
              >
                <ZiInput
                  placeholder={t('za_invest_setup_address_input_placeHolder4', '大厦/屋苑/街道')}
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item className={styles.formItem} name="area" validateFirst rules={rules.area}>
                <ZiCascader
                  label={t('za_invest_setup_address_input_placeHolder5', '区域')}
                  options={areaList}
                  onChange={areaChange}
                  value={areaValue}
                />
              </Form.Item>
              {/* 默认香港，不可修改 */}
              <Form.Item
                className={styles.formItem}
                name="country"
                rules={rules.country}
                validateFirst
              >
                <ZiSelect
                  label={t('za_invest_setup_address_input_placeHolder6', '国家/地区')}
                  readOnly
                  options={countryDic}
                />
              </Form.Item>
            </div>

            <div className="footer-btn-box">
              <Button
                className="zi-btn"
                type="primary"
                block
                throttleTime={Timing.DEBOUNCE}
                onClick={onCheck}
              >
                {t('za_invest_setup_next_step', '下一步')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Address
