import React from 'react'
import { useSelector } from 'react-redux'
import InviteCode from '@/components/setup/InviteCode/index'
import { SetupPageProps } from '@/common/types'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { useSubmitSetup } from '@/hooks'

const InviteCodePage = ({ nextPage }: SetupPageProps) => {
  const submitSetup = useSubmitSetup()
  const setupInfo = useSelector(selectUserSetupInfo)
  return <InviteCode nextPage={nextPage} setupInfo={setupInfo} submitSetup={submitSetup} />
}

export default InviteCodePage
