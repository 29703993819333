import React, { useEffect } from 'react'
import { ZiSelect } from '@/components'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import * as Schemas from '@/common/schemas'
import getFormFn from './formData'
import { InitDictionaryResType } from '@/common/schemas/setup/enum.interface'
import mockDictionary from './mockDictionary'
import { ToolTip, Icon, Button } from 'zati-ui-web'
import CircleTips from '@/assets/img/setup/icon-circle-tips.svg'
import { Timing } from '@/common/enums/time.enum'
import { reportCommonException } from '@/service/nativeCall'

interface Props {
  dictionary: InitDictionaryResType
  submitFn: (submitData: Schemas.IncomeType) => void
  initialValues: Schemas.IncomeType
  throttleSubmit?: boolean
}

function RiskForm(props: Props): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { dictionary = mockDictionary, submitFn, initialValues, throttleSubmit } = props
  const data = getFormFn(t, dictionary)

  useEffect(() => {
    if (form) {
      form.setFieldsValue(initialValues)
    }
  }, [form, initialValues])

  const submit = async (submitData: Schemas.IncomeType): Promise<void> => {
    // 调用外层提交的方法
    submitFn(submitData)
  }

  const onCheck = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as Schemas.IncomeType
      submit(values)
    } catch (errorInfo) {
      reportCommonException('RiskForm-onCheck', errorInfo)
      console.log('Failed:', errorInfo)
    }
  }
  const strToJson = (str: string) => {
    if (typeof str === 'string') {
      try {
        return JSON.parse(str)
      } catch (e) {
        reportCommonException('RiskForm-strToJson', Error(e))
        console.log(e, '风险问卷后台返回了非json字符串')
        return false
      }
    }
  }
  const renderItem = (item: any) => {
    const label = item?.label
    let labelJson = strToJson(label) || {}
    return (
      <>
        <p className="titleStyle">{labelJson?.title}</p>
        <p className="descStyle">{labelJson?.content}</p>
      </>
    )
  }
  const renderLabel = (item: any) => {
    const label = item?.label
    let labelJson = strToJson(label) || {}
    return labelJson.title
  }

  return (
    <div className={styles.riskForm}>
      <div className={styles.pageTitle}>
        {t('4413_setup_finance_page_title', '財務及個人狀況')}
        <ToolTip
          placement="bottom"
          overlay={t(
            '4413_setup_finance_desc',
            '本問卷旨在了解你的風險取向及投資需要。其內容假設你的風險取向取決於你的財務狀況、投資知識及經驗、投資目標、流動性需要、對風險及回報的期望。本問卷的結果是根據你於某一時間所提供的資料而作出。若你未能提供完整、準確、最新的資料，會影響安安投資對你的風險取向、投資需要及所提供產品的評估。'
          )}
          className={styles.tooltip}
          overlayClassName="uploadTipsOverlay"
          trigger="click"
        >
          <Icon icon={CircleTips} />
        </ToolTip>
      </div>
      <div className={styles.pageSubTitle}>
        {t('4413_setup_finance_page_description', '请修改我们为你预设的选项')}
      </div>
      <div className={styles.formContainer}>
        <Form form={form}>
          <div className={styles.inputContainer}>
            {data?.map((item, index) => (
              <Form.Item
                key={index}
                className={styles.formItem}
                name={item.name}
                rules={[
                  {
                    required: true,
                    message: item.message
                  }
                ]}
              >
                {item.name === 'investTarget' ? (
                  <ZiSelect
                    className="targetSelect"
                    label={item.selectLabel}
                    options={item.dataSource}
                    renderItem={renderItem}
                    renderLabel={renderLabel}
                  />
                ) : (
                  <ZiSelect label={item.selectLabel} options={item.dataSource} />
                )}
              </Form.Item>
            ))}
          </div>
          <div className={styles.horizontalMarginN8}>
            <Button
              className="zi-btn"
              type="primary"
              block
              throttleTime={throttleSubmit ? Timing.DEBOUNCE : 0}
              onClick={onCheck}
            >
              {t('4413_setup_next_step', '确认')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default RiskForm
