export const countryDicData = [
  {
    value: 'AM',
    label: '亚美尼亚',
    order: '1'
  },
  {
    value: 'AQ',
    label: '南极洲',
    order: '1'
  },
  {
    value: 'BN',
    label: '文莱',
    order: '1'
  },
  {
    value: 'BS',
    label: '巴哈马',
    order: '1'
  },
  {
    value: 'CN',
    label: '中国内地',
    order: '1'
  },
  {
    value: 'CO',
    label: '哥伦比亚',
    order: '1'
  },
  {
    value: 'CV',
    label: '佛得角',
    order: '1'
  },
  {
    value: 'CW',
    label: '库拉索',
    order: '1'
  },
  {
    value: 'DK',
    label: '丹麦',
    order: '1'
  },
  {
    value: 'EC',
    label: '厄瓜多尔',
    order: '1'
  },
  {
    value: 'GQ',
    label: '赤道几内亚',
    order: '1'
  },
  {
    value: 'HK',
    label: '香港特别行政区',
    order: '1'
  },
  {
    value: 'HT',
    label: '海地',
    order: '1'
  },
  {
    value: 'JP',
    label: '日本',
    order: '1'
  },
  {
    value: 'KH',
    label: '柬埔寨',
    order: '1'
  },
  {
    value: 'LA',
    label: '老挝',
    order: '1'
  },
  {
    value: 'MK',
    label: '马其顿',
    order: '1'
  },
  {
    value: 'MO',
    label: '澳门特别行政区',
    order: '1'
  },
  {
    value: 'PW',
    label: '帕劳',
    order: '1'
  },
  {
    value: 'SZ',
    label: '斯威士兰',
    order: '1'
  },
  {
    value: 'TW',
    label: '台湾',
    order: '1'
  },
  {
    value: 'AF',
    label: '阿富汗',
    order: '2'
  },
  {
    value: 'AS',
    label: '美属萨摩亚',
    order: '2'
  },
  {
    value: 'BH',
    label: '巴林',
    order: '2'
  },
  {
    value: 'CA',
    label: '加拿大',
    order: '2'
  },
  {
    value: 'CF',
    label: '中非',
    order: '2'
  },
  {
    value: 'CG',
    label: '刚果（布）',
    order: '2'
  },
  {
    value: 'CM',
    label: '喀麦隆',
    order: '2'
  },
  {
    value: 'CX',
    label: '圣诞岛',
    order: '2'
  },
  {
    value: 'DM',
    label: '多米尼克',
    order: '2'
  },
  {
    value: 'ER',
    label: '厄立特里亚',
    order: '2'
  },
  {
    value: 'FK',
    label: '福克兰群岛（马尔维纳斯）',
    order: '2'
  },
  {
    value: 'GR',
    label: '希腊',
    order: '2'
  },
  {
    value: 'GT',
    label: '危地马拉',
    order: '2'
  },
  {
    value: 'HM',
    label: '赫德岛和麦克唐纳岛',
    order: '2'
  },
  {
    value: 'IO',
    label: '英属印度洋领地',
    order: '2'
  },
  {
    value: 'KP',
    label: '朝鲜',
    order: '2'
  },
  {
    value: 'LV',
    label: '拉脱维亚',
    order: '2'
  },
  {
    value: 'NA',
    label: '纳米比亚',
    order: '2'
  },
  {
    value: 'PN',
    label: '皮特凯恩',
    order: '2'
  },
  {
    value: 'SE',
    label: '瑞典',
    order: '2'
  },
  {
    value: 'TJ',
    label: '塔吉克斯坦',
    order: '2'
  },
  {
    value: 'XK',
    label: '科索沃',
    order: '2'
  },
  {
    value: 'AX',
    label: '奥兰群岛',
    order: '3'
  },
  {
    value: 'BB',
    label: '巴巴多斯',
    order: '3'
  },
  {
    value: 'BD',
    label: '孟加拉国',
    order: '3'
  },
  {
    value: 'CD',
    label: '刚果（金）',
    order: '3'
  },
  {
    value: 'CH',
    label: '瑞士',
    order: '3'
  },
  {
    value: 'CY',
    label: '塞浦路斯',
    order: '3'
  },
  {
    value: 'CZ',
    label: '捷克',
    order: '3'
  },
  {
    value: 'DO',
    label: '多米尼加',
    order: '3'
  },
  {
    value: 'FO',
    label: '法罗群岛',
    order: '3'
  },
  {
    value: 'HN',
    label: '洪都拉斯',
    order: '3'
  },
  {
    value: 'HU',
    label: '匈牙利',
    order: '3'
  },
  {
    value: 'IN',
    label: '印度',
    order: '3'
  },
  {
    value: 'KY',
    label: '开曼群岛',
    order: '3'
  },
  {
    value: 'LB',
    label: '黎巴嫩',
    order: '3'
  },
  {
    value: 'NP',
    label: '尼泊尔',
    order: '3'
  },
  {
    value: 'PT',
    label: '葡萄牙',
    order: '3'
  },
  {
    value: 'RU',
    label: '俄罗斯联邦',
    order: '3'
  },
  {
    value: 'TD',
    label: '乍得',
    order: '3'
  },
  {
    value: 'TZ',
    label: '坦桑尼亚',
    order: '3'
  },
  {
    value: 'UG',
    label: '乌干达',
    order: '3'
  },
  {
    value: 'AL',
    label: '阿尔巴尼亚',
    order: '4'
  },
  {
    value: 'BY',
    label: '白俄罗斯',
    order: '4'
  },
  {
    value: 'CC',
    label: '科科斯（基林）群岛',
    order: '4'
  },
  {
    value: 'CL',
    label: '智利',
    order: '4'
  },
  {
    value: 'CR',
    label: '哥斯达黎加',
    order: '4'
  },
  {
    value: 'DE',
    label: '德国',
    order: '4'
  },
  {
    value: 'DJ',
    label: '吉布提',
    order: '4'
  },
  {
    value: 'FI',
    label: '芬兰',
    order: '4'
  },
  {
    value: 'ID',
    label: '印度尼西亚',
    order: '4'
  },
  {
    value: 'KZ',
    label: '哈萨克斯坦',
    order: '4'
  },
  {
    value: 'LS',
    label: '莱索托',
    order: '4'
  },
  {
    value: 'MG',
    label: '马达加斯加',
    order: '4'
  },
  {
    value: 'NC',
    label: '新喀里多尼亚',
    order: '4'
  },
  {
    value: 'NI',
    label: '尼加拉瓜',
    order: '4'
  },
  {
    value: 'SV',
    label: '萨尔瓦多',
    order: '4'
  },
  {
    value: 'TH',
    label: '泰国',
    order: '4'
  },
  {
    value: 'UA',
    label: '乌克兰',
    order: '4'
  },
  {
    value: 'BE',
    label: '比利时',
    order: '5'
  },
  {
    value: 'CU',
    label: '古巴',
    order: '5'
  },
  {
    value: 'DZ',
    label: '阿尔及利亚',
    order: '5'
  },
  {
    value: 'FJ',
    label: '斐济',
    order: '5'
  },
  {
    value: 'FR',
    label: '法国',
    order: '5'
  },
  {
    value: 'GA',
    label: '加蓬',
    order: '5'
  },
  {
    value: 'IR',
    label: '伊朗',
    order: '5'
  },
  {
    value: 'KM',
    label: '科摩罗',
    order: '5'
  },
  {
    value: 'KR',
    label: '韩国',
    order: '5'
  },
  {
    value: 'LR',
    label: '利比里亚',
    order: '5'
  },
  {
    value: 'MW',
    label: '马拉维',
    order: '5'
  },
  {
    value: 'NE',
    label: '尼日尔',
    order: '5'
  },
  {
    value: 'NZ',
    label: '新西兰',
    order: '5'
  },
  {
    value: 'SH',
    label: '圣赫勒拿',
    order: '5'
  },
  {
    value: 'TK',
    label: '托克劳',
    order: '5'
  },
  {
    value: 'TL',
    label: '东帝汶',
    order: '5'
  },
  {
    value: 'UY',
    label: '乌拉圭',
    order: '5'
  },
  {
    value: 'AD',
    label: '安道尔',
    order: '6'
  },
  {
    value: 'BZ',
    label: '伯利兹',
    order: '6'
  },
  {
    value: 'CK',
    label: '库克群岛',
    order: '6'
  },
  {
    value: 'GF',
    label: '法属圭亚那',
    order: '6'
  },
  {
    value: 'GH',
    label: '加纳',
    order: '6'
  },
  {
    value: 'GI',
    label: '直布罗陀',
    order: '6'
  },
  {
    value: 'GM',
    label: '冈比亚',
    order: '6'
  },
  {
    value: 'IQ',
    label: '伊拉克',
    order: '6'
  },
  {
    value: 'KN',
    label: '圣基茨和尼维斯',
    order: '6'
  },
  {
    value: 'LY',
    label: '利比亚',
    order: '6'
  },
  {
    value: 'ME',
    label: '黑山',
    order: '6'
  },
  {
    value: 'MY',
    label: '马来西亚',
    order: '6'
  },
  {
    value: 'NG',
    label: '尼日利亚',
    order: '6'
  },
  {
    value: 'SG',
    label: '新加坡',
    order: '6'
  },
  {
    value: 'TG',
    label: '多哥',
    order: '6'
  },
  {
    value: 'TO',
    label: '汤加',
    order: '6'
  },
  {
    value: 'UZ',
    label: '乌兹别克斯坦',
    order: '6'
  },
  {
    value: 'AO',
    label: '安哥拉',
    order: '7'
  },
  {
    value: 'BJ',
    label: '贝宁',
    order: '7'
  },
  {
    value: 'CI',
    label: '科特迪瓦',
    order: '7'
  },
  {
    value: 'ES',
    label: '西班牙',
    order: '7'
  },
  {
    value: 'GE',
    label: '格鲁吉亚',
    order: '7'
  },
  {
    value: 'GN',
    label: '几内亚',
    order: '7'
  },
  {
    value: 'IM',
    label: '英国属地曼岛',
    order: '7'
  },
  {
    value: 'LC',
    label: '圣卢西亚',
    order: '7'
  },
  {
    value: 'LI',
    label: '列支敦士登',
    order: '7'
  },
  {
    value: 'MV',
    label: '马尔代夫',
    order: '7'
  },
  {
    value: 'NL',
    label: '荷兰',
    order: '7'
  },
  {
    value: 'NU',
    label: '纽埃',
    order: '7'
  },
  {
    value: 'PF',
    label: '法属波利尼西亚',
    order: '7'
  },
  {
    value: 'TT',
    label: '特立尼达和多巴哥',
    order: '7'
  },
  {
    value: 'VA',
    label: '梵蒂冈',
    order: '7'
  },
  {
    value: 'VU',
    label: '瓦努阿图',
    order: '7'
  },
  {
    value: 'AI',
    label: '安圭拉',
    order: '8'
  },
  {
    value: 'BM',
    label: '百慕大',
    order: '8'
  },
  {
    value: 'GL',
    label: '格陵兰',
    order: '8'
  },
  {
    value: 'GW',
    label: '几内亚比绍',
    order: '8'
  },
  {
    value: 'HR',
    label: '克罗地亚',
    order: '8'
  },
  {
    value: 'IL',
    label: '以色列',
    order: '8'
  },
  {
    value: 'JE',
    label: '泽西岛',
    order: '8'
  },
  {
    value: 'LT',
    label: '立陶宛',
    order: '8'
  },
  {
    value: 'ML',
    label: '马里',
    order: '8'
  },
  {
    value: 'NF',
    label: '诺福克岛',
    order: '8'
  },
  {
    value: 'PM',
    label: '圣皮埃尔和密克隆',
    order: '8'
  },
  {
    value: 'SY',
    label: '叙利亚',
    order: '8'
  },
  {
    value: 'TF',
    label: '法属南部领地',
    order: '8'
  },
  {
    value: 'TN',
    label: '突尼斯',
    order: '8'
  },
  {
    value: 'VE',
    label: '委内瑞拉',
    order: '8'
  },
  {
    value: 'AG',
    label: '安提瓜和巴布达',
    order: '9'
  },
  {
    value: 'BT',
    label: '不丹',
    order: '9'
  },
  {
    value: 'EH',
    label: '西撒哈拉',
    order: '9'
  },
  {
    value: 'GD',
    label: '格林纳达',
    order: '9'
  },
  {
    value: 'IT',
    label: '意大利',
    order: '9'
  },
  {
    value: 'KE',
    label: '肯尼亚',
    order: '9'
  },
  {
    value: 'KI',
    label: '基里巴斯',
    order: '9'
  },
  {
    value: 'LU',
    label: '卢森堡',
    order: '9'
  },
  {
    value: 'MT',
    label: '马耳他',
    order: '9'
  },
  {
    value: 'NO',
    label: '挪威',
    order: '9'
  },
  {
    value: 'NR',
    label: '瑙鲁',
    order: '9'
  },
  {
    value: 'PH',
    label: '菲律宾',
    order: '9'
  },
  {
    value: 'TR',
    label: '土耳其',
    order: '9'
  },
  {
    value: 'VC',
    label: '圣文森特和格林纳丁斯',
    order: '9'
  },
  {
    value: 'WF',
    label: '瓦利斯和富图纳',
    order: '9'
  },
  {
    value: 'AR',
    label: '阿根廷',
    order: '10'
  },
  {
    value: 'BO',
    label: '玻利维亚',
    order: '10'
  },
  {
    value: 'GP',
    label: '瓜德罗普',
    order: '10'
  },
  {
    value: 'JM',
    label: '牙买加',
    order: '10'
  },
  {
    value: 'KG',
    label: '吉尔吉斯斯坦',
    order: '10'
  },
  {
    value: 'KW',
    label: '科威特',
    order: '10'
  },
  {
    value: 'MH',
    label: '马绍尔群岛',
    order: '10'
  },
  {
    value: 'RE',
    label: '留尼汪',
    order: '10'
  },
  {
    value: 'TM',
    label: '土库曼斯坦',
    order: '10'
  },
  {
    value: 'WS',
    label: '萨摩亚',
    order: '10'
  },
  {
    value: 'ZA',
    label: '南非',
    order: '10'
  },
  {
    value: 'ZM',
    label: '赞比亚',
    order: '10'
  },
  {
    value: 'AW',
    label: '阿鲁巴',
    order: '11'
  },
  {
    value: 'BA',
    label: '波黑',
    order: '11'
  },
  {
    value: 'GS',
    label: '南乔治亚岛和南桑德韦奇岛',
    order: '11'
  },
  {
    value: 'GU',
    label: '关岛',
    order: '11'
  },
  {
    value: 'JO',
    label: '约旦',
    order: '11'
  },
  {
    value: 'MQ',
    label: '马提尼克',
    order: '11'
  },
  {
    value: 'QA',
    label: '卡塔尔',
    order: '11'
  },
  {
    value: 'RO',
    label: '罗马尼亚',
    order: '11'
  },
  {
    value: 'SM',
    label: '圣马力诺',
    order: '11'
  },
  {
    value: 'TC',
    label: '特克斯和凯科斯群岛',
    order: '11'
  },
  {
    value: 'ZW',
    label: '津巴布韦',
    order: '11'
  },
  {
    value: 'AU',
    label: '澳大利亚',
    order: '12'
  },
  {
    value: 'BW',
    label: '博茨瓦纳',
    order: '12'
  },
  {
    value: 'GB',
    label: '英国',
    order: '12'
  },
  {
    value: 'GG',
    label: '格恩西岛',
    order: '12'
  },
  {
    value: 'MR',
    label: '毛利塔尼亚',
    order: '12'
  },
  {
    value: 'RW',
    label: '卢旺达',
    order: '12'
  },
  {
    value: 'SS',
    label: '南苏丹',
    order: '12'
  },
  {
    value: 'ST',
    label: '圣多美和普林西比',
    order: '12'
  },
  {
    value: 'TV',
    label: '图瓦卢',
    order: '12'
  },
  {
    value: 'AT',
    label: '奥地利',
    order: '13'
  },
  {
    value: 'BV',
    label: '布维岛',
    order: '13'
  },
  {
    value: 'GY',
    label: '圭亚那',
    order: '13'
  },
  {
    value: 'MU',
    label: '毛里求斯',
    order: '13'
  },
  {
    value: 'SA',
    label: '沙特阿拉伯',
    order: '13'
  },
  {
    value: 'VN',
    label: '越南',
    order: '13'
  },
  {
    value: 'AZ',
    label: '阿塞拜疆',
    order: '14'
  },
  {
    value: 'BR',
    label: '巴西',
    order: '14'
  },
  {
    value: 'SN',
    label: '塞内加尔',
    order: '14'
  },
  {
    value: 'VG',
    label: '英属维尔京群岛',
    order: '14'
  },
  {
    value: 'YT',
    label: '马约特',
    order: '14'
  },
  {
    value: 'BG',
    label: '保加利亚',
    order: '15'
  },
  {
    value: 'EG',
    label: '埃及',
    order: '15'
  },
  {
    value: 'MX',
    label: '墨西哥',
    order: '15'
  },
  {
    value: 'RS',
    label: '塞尔维亚',
    order: '15'
  },
  {
    value: 'YE',
    label: '也门',
    order: '15'
  },
  {
    value: 'BF',
    label: '布基纳法索',
    order: '16'
  },
  {
    value: 'EE',
    label: '爱沙尼亚',
    order: '16'
  },
  {
    value: 'FM',
    label: '密克罗尼西亚联邦',
    order: '16'
  },
  {
    value: 'SC',
    label: '塞舌尔',
    order: '16'
  },
  {
    value: 'BI',
    label: '布隆迪',
    order: '17'
  },
  {
    value: 'ET',
    label: '埃塞俄比亚',
    order: '17'
  },
  {
    value: 'MD',
    label: '摩尔多瓦',
    order: '17'
  },
  {
    value: 'SL',
    label: '塞拉利昂',
    order: '17'
  },
  {
    value: 'IE',
    label: '爱尔兰',
    order: '18'
  },
  {
    value: 'IS',
    label: '冰岛',
    order: '18'
  },
  {
    value: 'MC',
    label: '摩纳哥',
    order: '18'
  },
  {
    value: 'SX',
    label: '圣马丁',
    order: '18'
  },
  {
    value: 'MN',
    label: '蒙古',
    order: '19'
  },
  {
    value: 'MP',
    label: '北马里亚纳',
    order: '19'
  },
  {
    value: 'OM',
    label: '阿曼',
    order: '19'
  },
  {
    value: 'SK',
    label: '斯洛伐克',
    order: '19'
  },
  {
    value: 'AE',
    label: '阿联酋',
    order: '20'
  },
  {
    value: 'MS',
    label: '蒙特塞拉特',
    order: '20'
  },
  {
    value: 'PK',
    label: '巴基斯坦',
    order: '20'
  },
  {
    value: 'SI',
    label: '斯洛文尼亚',
    order: '20'
  },
  {
    value: 'MA',
    label: '摩洛哥',
    order: '21'
  },
  {
    value: 'PS',
    label: '巴勒斯坦',
    order: '21'
  },
  {
    value: 'SB',
    label: '所罗门群岛',
    order: '21'
  },
  {
    value: 'MZ',
    label: '莫桑比克',
    order: '22'
  },
  {
    value: 'PA',
    label: '巴拿马',
    order: '22'
  },
  {
    value: 'SO',
    label: '索马里',
    order: '22'
  },
  {
    value: 'LK',
    label: '斯里兰卡',
    order: '23'
  },
  {
    value: 'MM',
    label: '缅甸',
    order: '23'
  },
  {
    value: 'PG',
    label: '巴布亚新几内亚',
    order: '23'
  },
  {
    value: 'PE',
    label: '秘鲁',
    order: '24'
  },
  {
    value: 'PY',
    label: '巴拉圭',
    order: '24'
  },
  {
    value: 'SD',
    label: '苏丹',
    order: '24'
  },
  {
    value: 'PL',
    label: '波兰',
    order: '25'
  },
  {
    value: 'SR',
    label: '苏里南',
    order: '25'
  },
  {
    value: 'PR',
    label: '波多黎各',
    order: '26'
  },
  {
    value: 'SJ',
    label: '斯瓦尔巴岛和扬马延岛',
    order: '26'
  },
  {
    value: 'VI',
    label: '美属维尔京群岛',
    order: '27'
  }
]
