import { queryAccountInfoByAccountType } from '@/service/commonModule'
import { SetupType } from '@/common/enums/setup'

export type setuptypeType = SetupType.HK | SetupType.CA
// 两种用法，callback or then/catch
export const getSetupType = async (cb?: Function) => {
  let setupType = SetupType.HK
  try {
    let { applyType } = await queryAccountInfoByAccountType()
    if (applyType === '21') {
      setupType = SetupType.CA
    }
    if (cb) {
      cb(null, { setupType })
    }
    return Promise.resolve(setupType)
  } catch (e) {
    if (!e.__CANCEL__) {
      if (cb) {
        cb(e, { setupType })
      }
      return Promise.reject(e)
    }
  }
}
