import React from 'react'
import styles from './index.module.scss'

interface Props {
  text?: string
}
function App(props: Props) {
  const { text } = props
  return <h2 className={styles.pageTitle}>{text}</h2>
}

export default App
