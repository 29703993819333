import styles from './index.module.scss'
import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { getLang } from '@/utils'
// 风险问卷结果头部模块

enum RiskNum {
  OVERDUE = '-1'
}

interface RiskLveelMap {
  [key: string]: string
}

interface Props {
  reRiskTestNum: string
  riskResultDate: string
  riskResultLevel: string
}

function Result(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { reRiskTestNum, riskResultDate, riskResultLevel } = props
  const riskLevelDic: RiskLveelMap = {
    '1': t('4413_risk_result_1', '保守型'),
    '2': t('4413_risk_result_2', '穏定收入型'),
    '3': t('4413_risk_result_3', '平衡型'),
    '4': t('4413_risk_result_4', '增长型'),
    '5': t('4413_risk_result_5', '进取型')
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.result}>
        <div className={styles.middle}>
          <div className={styles.title}>
            {t('4413_risk_result_page_risk_result_title', '您的风险评估结果')}
          </div>
          <div className={styles.desc}>{riskLevelDic[riskResultLevel]}</div>
        </div>
        <div className={classnames(styles.explain, [styles[getLang()]])}>
          <div className={styles.item}>
            <div className={styles.title}>
              {t('4413_risk_result_page_risk_result_explain_residue_degree', '剩餘評估次數')}
            </div>
            <div className={styles.desc}>
              {reRiskTestNum === RiskNum.OVERDUE
                ? '- -'
                : `${reRiskTestNum}${t(
                  '4413_risk_result_page_risk_result_explain_residue_degree_unit',
                  '次'
                )}`}
            </div>
          </div>
          <div className={styles.verticalLine} />
          <div className={styles.item}>
            <div className={styles.title}>
              {t('4413_risk_result_page_risk_result_explain_assess_date', '評估時間')}
            </div>
            <div className={styles.desc}>
              {riskResultDate && moment(Number(riskResultDate)).format('YYYY-MM-DD')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Result