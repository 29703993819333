import { createSlice } from '@reduxjs/toolkit'
import * as Schemas from '@/common/schemas'
import { isEmpty } from 'lodash'

export const counterSlice = createSlice({
  name: 'income',
  initialState: {
    pageData: {
      // riskResultLevel: '',
      // riskResultDate: '',
      // reRiskTestNum: '',
      // riskSubmitResult: {
      eduDegree: '3',
      annualIncome: '3',
      totalMoney: '3',
      investTarget: '2',
      isInvestSecurities: '1',
      isInvestDerivative: '1',
      isInvestMutual: '1',
      isInvestFixed: '1',
      investLimit: '3',
      investInterested: '3',
      riskHandle: '3',
      needCash: '3'
      // }
    },
    riskMarkMsg: {
      reRiskTestNum: '',
      riskResultDate: '',
      riskResultLevel: ''
    }
  },
  reducers: {
    updateRiskResult(state, action): void {
      if (isEmpty(action.payload)) {
        return
      }
      state.riskMarkMsg = action.payload
    },
    updateIncomeInfo(state, action): void {
      if (isEmpty(action.payload)) {
        return
      }
      const {
        eduDegree,
        annualIncome,
        totalMoney,
        investTarget,
        isInvestSecurities,
        isInvestDerivative,
        isInvestMutual,
        isInvestFixed,
        investLimit,
        investInterested,
        riskHandle,
        needCash
      } = action.payload
      if (
        eduDegree === 'null' &&
        annualIncome === 'null' &&
        totalMoney === 'null' &&
        investTarget === 'null' &&
        isInvestSecurities === 'null' &&
        isInvestDerivative === 'null' &&
        isInvestMutual === 'null' &&
        isInvestFixed === 'null' &&
        investLimit === 'null' &&
        investInterested === 'null' &&
        riskHandle === 'null' &&
        needCash === 'null'
      ) {
        return
      }
      state.pageData = action.payload
    }
  }
})

export const { updateIncomeInfo, updateRiskResult } = counterSlice.actions

export const selectIncomeInfo = (state: any): Schemas.IncomeType => {
  return state.income.pageData
}
export const selectRiskMarkMsg = (state: any): Schemas.riskResultMsg => {
  return state.income.riskMarkMsg
}

export default counterSlice.reducer
