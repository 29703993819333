import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
// import PropTypes from 'prop-types'
import { ActionSheet } from '@/components'
import classnames from 'classnames'
import TouchFeedback from 'rmc-feedback'
import styles from './index.module.scss'
import { Row, Col, Input } from 'antd'
import iconArrowRight from '@/assets/img/icon-arrow-right.svg'
import { SelectOptionItem } from '@/common/types'
import { useTranslation } from 'react-i18next'
import { debounce } from 'lodash'
import { Icon } from 'zati-ui-web'
import IconSearch from '@/assets/img/setup/search.svg'

enum Mode {
  Single = 'single',
  Multiple = 'multiple'
}
interface SelectProps {
  className?: string
  value?: unknown
  options?: SelectOptionItem[]
  label?: string
  mode?: Mode
  onChange?: (value: any) => void
  renderLabel?: (item: SelectOptionItem) => any
  disabled?: boolean
  readOnly?: boolean
  canSearch?: boolean
  border?: boolean
  showArrow?: boolean
  renderItem?: (item: SelectOptionItem) => React.ReactNode
  onClose?: () => void
  hideLabel?: boolean
}
export interface SelectHandles {
  openSelect: () => void
}

const Select: ForwardRefRenderFunction<SelectHandles, SelectProps> = (
  props: SelectProps,
  ref
): JSX.Element => {
  // function Select(props: SelectProps): JSX.Element {
  const { t } = useTranslation()
  const {
    className,
    value,
    options = [],
    onChange,
    mode = Mode.Single,
    label,
    renderLabel = (item: SelectOptionItem): any => item.label,
    disabled = false,
    readOnly = false,
    border = true,
    showArrow = true,
    renderItem,
    onClose,
    hideLabel = false
  } = props
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState<SelectOptionItem>()
  const [selectOptions, setSelectOptions] = useState(options)
  useEffect(() => {
    setSelectOptions(options)
  }, [options])
  const handleClose = (): void => {
    setShow(false)
    setSelectOptions(options)
    onClose?.()
  }
  const openSelect = (): void => {
    if (!disabled && !readOnly) {
      setShow(true)
    }
  }
  const handleClickItem = (item: SelectOptionItem): void => {
    onChange?.(item.value)
    setSelectedItem(item)
    if (mode === Mode.Single) {
      setShow(false)
      onClose?.()
    }
  }
  const getLabel = (): any => {
    let sitem
    if (selectedItem) {
      return renderLabel(selectedItem)
    } else if ((sitem = [...options].find((item: SelectOptionItem) => item.value === value))) {
      return renderLabel(sitem)
    }
    return selectedItem
  }

  const delayedHandleSearch = debounce((eventData) => {
    const filterOptions = options.filter(
      (item) => item.label.indexOf(eventData.target.value.trim()) > -1
    )
    setSelectOptions(filterOptions)
  }, 500)

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const eventData = { target: e.target }
    delayedHandleSearch(eventData)
  }

  useEffect(() => {
    if (selectedItem && selectedItem.value !== value) {
      setSelectedItem([...options].find((item: SelectOptionItem) => item.value === value))
    }
  }, [value, options, selectedItem])

  useImperativeHandle(ref, () => ({
    openSelect(): void {
      setShow(true)
    }
  }))
  return (
    <div
      className={classnames(styles.selectContainer, className, {
        [styles.disabled]: disabled,
        [styles.readOnly]: readOnly
      })}
    >
      {label && <div className={styles.label}>{label}</div>}
      {!hideLabel && (
        <Row
          className={classnames(styles.selectLine, { [styles.border]: border })}
          onClick={openSelect}
        >
          <Col span={22} className={styles.selectInput}>
            {getLabel()}
          </Col>
          <Col
            span={2}
            className={classnames(styles.selectArrow, { [styles.selectArrowHide]: !showArrow })}
          >
            <img className={styles.iconSize} src={iconArrowRight} alt="select" />
          </Col>
        </Row>
      )}
      <ActionSheet show={show} onClose={handleClose} className={className}>
        {props.canSearch ? (
          <div className={styles.search}>
            <Input onChange={(e): void => onSearch(e)} prefix={<Icon icon={IconSearch} />} />
          </div>
        ) : null}
        {[...selectOptions].map((item: SelectOptionItem) => (
          <div key={`${item.value}`} className={styles.selectItemRow}>
            <TouchFeedback activeClassName={styles.touchActive}>
              <Row
                className={styles.selectItem}
                onClick={(): void => {
                  handleClickItem(item)
                }}
              >
                <Col span={22} className={styles.selectLabel}>
                  {renderItem ? renderItem(item) : item.label}
                </Col>
                {value === item.value && (
                  <Col span={2} className={classnames(styles.activeIcon, 'textAlignCenter')}>
                    <i className="icon-radio-active" />
                  </Col>
                )}
              </Row>
            </TouchFeedback>
          </div>
        ))}
        {selectOptions.length === 0 && (
          <div className={styles.noData}>{t('za_invest_setup_no_data') || '暂无数据'}</div>
        )}
      </ActionSheet>
    </div>
  )
}

export default forwardRef(Select)
