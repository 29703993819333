import React from 'react'
import { ZiProgressBar, RiskForm } from '@/components'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectIncomeInfo,
  updateIncomeInfo,
  updateRiskResult
} from '@/pages/setup/store/slices/finance'
import { SetupPageProps } from '@/common/types'
import * as Schemas from '@/common/schemas'
import { selectInitDic } from '../../store/slices/init'
import { useSubmitSetup } from '@/hooks'
import { OpenStep, JsEvent } from '@/common/enums'
import { postEventToNative } from '@/service/nativeCall'

function Finance({ nextPage }: SetupPageProps): JSX.Element {
  const { t } = useTranslation()
  const submitSetup = useSubmitSetup()
  const inComeInfo = useSelector(selectIncomeInfo)
  const dictionary = useSelector(selectInitDic)
  const dispatch = useDispatch()
  const submit = async (submitData: Schemas.IncomeType): Promise<void> => {
    const result: any = await submitSetup({
      dataType: OpenStep.STEP_RISK,
      openData: {
        [OpenStep.STEP_RISK]: { riskSubmitResult: submitData }
      }
    })
    dispatch(updateIncomeInfo(submitData))
    dispatch(updateRiskResult(result?.riskMarkMsg))
    postEventToNative({ name: JsEvent.EVENT_AFTER_RISK_ASSESS })
    nextPage()
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={3}
          title={t('za_invest_setup_progressBar_03Title_1', '03 財務及風險評估')}
          progress={65}
        />
      </div>
      <RiskForm dictionary={dictionary} submitFn={submit} throttleSubmit initialValues={inComeInfo} />
    </div>
  )
}

export default Finance
