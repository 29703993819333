import React from 'react'
import { SetupRiskShow } from '@/components'
import { SetupPageProps } from '@/common/types'
import { useSubmitSetup } from '@/hooks/'
import { selectIsDelegation } from '../../store/slices/init'
import { useSelector } from 'react-redux'

function RiskShow({ nextPage }: SetupPageProps): JSX.Element {
  const submitSetup = useSubmitSetup()
  const isDelegation = useSelector(selectIsDelegation)

  return <SetupRiskShow isDelegation={isDelegation} nextPage={nextPage} submitSetup={submitSetup} />
}

export default RiskShow
