import { Toast } from 'zati-ui-web'

const showErrorToast = (msg: string) => {
  return Toast.fail?.(msg, {
    top: '60%',
    duration: 1000
  })
}

const showWarnToast = (msg: string) => {
  return Toast.warn?.(msg, {
    top: '60%',
    duration: 1000
  })
}

const showSuccessToast = (msg: string) => {
  return Toast.success?.(msg, {
    top: '60%',
    duration: 1000
  })
}

export { showErrorToast, showWarnToast, showSuccessToast }
