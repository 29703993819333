import styles from './index.module.scss'
import React from 'react'
import { ZiProgressBar, RiskResult, RiskHeader } from '@/components'
import { useTranslation } from 'react-i18next'
import { SetupPageProps } from '@/common/types'
import { OpenStep } from '@/common/enums'
import { Button } from 'zati-ui-web'
import { Timing } from '@/common/enums/time.enum'
import * as Schemas from '@/common/schemas'

interface Props {
  isDelegation: boolean
  riskResult: Schemas.riskResultMsg
  submitSetup: (data: any) => Promise<unknown>
  nextPage: SetupPageProps['nextPage']
  prePage: SetupPageProps['prePage']
}

function Result(props: Props): JSX.Element {
  const { t } = useTranslation()
  const { isDelegation, riskResult, submitSetup, nextPage, prePage } = props
  const { riskResultLevel, riskResultDate, reRiskTestNum } = riskResult
  // 重新评估的事件
  const reassessFn = () => {
    prePage()
  }
  // 去到下一步的事件
  const confirmFn = async () => {
    // 需要修改
    await submitSetup({
      dataType: OpenStep.STEP_RISK_MARK_CONFIRM,
      openData: {}
    })
    nextPage()
  }
  const getNextDom = (level: string) => {
    let dom = (
      <div className={styles.nextWrapper}>
        <Button className="zi-btn" type="primary" block onClick={confirmFn}>
          {t('4413_setup_confirm_and_continue', '确认并继续')}
        </Button>
        <p className={styles.redo} onClick={reassessFn}>
          {t('4413_setup_redo', '重新评估')}
        </p>
      </div>
    )
    if (!isDelegation) {
      // 非一户双开不做判断
      return dom
    }
    switch (level) {
      case '1':
        dom = (
          <div className={styles.nextWrapper}>
            <p className={styles.warnings}>
              {t('4413_setup_cant_join', '你的风险承受能力不足以加入新股易')}
            </p>
            <Button
              className="zi-btn"
              type="primary"
              block
              throttleTime={Timing.DEBOUNCE}
              onClick={reassessFn}
            >
              {t('za_invest_setup_redo', '重新评估')}
            </Button>
            <p className={styles.redo} onClick={confirmFn}>
              {t('4413_setup_understand', '明白并继续')}
            </p>
          </div>
        )
        break
      case '2':
        dom = (
          <div className={styles.nextWrapper}>
            <p className={styles.warnings}>
              {t(
                '4413_setup_sure_join',
                '你的风险取向与新股易不完全匹配，是否确认承担风险并继续开通?'
              )}
            </p>
            <Button
              className="zi-btn"
              type="primary"
              block
              throttleTime={Timing.DEBOUNCE}
              onClick={confirmFn}
            >
              {t('4413_setup_confirm_continue', '确认继续')}
            </Button>
            <p className={styles.redo} onClick={reassessFn}>
              {t('4413_setup_redo', '重新评估')}
            </p>
          </div>
        )
        break
      default:
        dom = (
          <div className={styles.nextWrapper}>
            <Button
              className="zi-btn"
              type="primary"
              block
              throttleTime={Timing.DEBOUNCE}
              onClick={confirmFn}
            >
              {t('4413_setup_confirm_and_continue', '确认并继续')}
            </Button>
            <p className={styles.redo} onClick={reassessFn}>
              {t('4413_setup_redo', '重新评估')}
            </p>
          </div>
        )
        break
    }
    return dom
  }
  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={3}
          title={t('4413_setup_progressBar_03Title_1', '03 財務及風險評估')}
          progress={65}
        />
      </div>
      <RiskHeader
        riskResultLevel={riskResultLevel}
        reRiskTestNum={reRiskTestNum}
        riskResultDate={riskResultDate}
      />
      <RiskResult />
      {getNextDom(riskResultLevel)}
    </div>
  )
}

export default Result