import React from 'react'
import styles from './index.module.scss'
import { Icon, Button } from 'zati-ui-web'
import IconSecurity from '@/assets/img/setup/security.svg'
import { useTranslation } from 'react-i18next'
import { SetupPageProps } from '@/common/types'
import { useSubmitMSetup } from '@/hooks'
import { OpenStep } from '@/common/enums'
import { ZiLink, SetupTitle, ZiModal } from '@/components'
import { getLang } from '@/utils'
import { Timing } from '@/common/enums/time.enum'

function OpenAccount({ nextPage }: SetupPageProps) {
  const { t } = useTranslation()
  const submitSetup = useSubmitMSetup()
  const submit = async () => {
    await submitSetup({
      dataType: OpenStep.STEP_OPEN_ACCOUNT,
      openData: {}
    })
    nextPage()
  }
  const handleJump = (): void => {
    const openScope = window.localStorage.getItem('openScope')
    ZiModal.alert(<p>{t('4541_jumpto_hksetup_tip', '确认跳转至香港开户吗？')}</p>, '', [
      {
        text: t('4541_jumpto_hksetup_tip_cancel', '取消'),
        onPress: (): void => {
          return
        }
      },
      {
        text: t('4541_jumpto_hksetup_tip_confirm', '确定'),
        onPress: (): void => {
          window.location.href = `/${getLang()}/setup?openScope=${openScope}`
          return
        }
      }
    ])
  }
  return (
    <div className={styles.contentWrapper}>
      <SetupTitle text={t('za_invest_setup_open_title', '开立投资账户')} />
      <div className={styles.panel}>
        <p className={styles.panelTitle}>{t('za_invest_setup_open_tips_title', '开户贴士')}</p>
        <p className={styles.panelText}>
          <i>1</i>
          {t('4541_setup_open_tips_1', '全程线上开户，平均2分钟完成申请')}
        </p>
        <p className={styles.panelText}>
          <i>2</i>
          {t('4541_setup_open_tips_2', '支持18岁或以上中国居民开户')}
        </p>
        <p className={styles.panelText}>
          <i>3</i>
          {t('4541_setup_open_tips_3', '仅需中国大陆身份证')}
        </p>
      </div>
      <div className={styles.flex}>
        <div className={styles.left}>
          <Icon icon={IconSecurity} className={styles.iconSecurity} />
        </div>
        <p className={styles.right}>{t('za_invest_setup_open_company_info')}</p>
      </div>
      <div className="footer-btn-box">
        <p className={styles.mainTips}>
          {t('za_invest_setup_open__to_agree', '点击下一步即同意')}
          <span className="interactive-text">
            <ZiLink href={t('za_invest_setup_agreement_pdf_1')}>
              {t('za_invest_setup_agreement_1', '私隠政策聲明')}
            </ZiLink>
            、
            <ZiLink href={t('za_invest_setup_agreement_pdf_2')}>
              {t('za_invest_setup_agreement_2', '收集個人資料聲明')}
            </ZiLink>
            、
            <ZiLink href={t('za_invest_setup_agreement_pdf_3')}>
              {t('za_invest_setup_agreement_3', 'ZA Coin 條款及細則')}
            </ZiLink>
          </span>
        </p>
        <Button
          className="zi-btn"
          type="primary"
          block
          throttleTime={Timing.DEBOUNCE}
          onClick={submit}
        >
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
        <p className={styles.footerDesc} onClick={handleJump}>
          {t('4541_setup_usehk', '使用香港身份证开户')}
        </p>
      </div>
    </div>
  )
}

export default OpenAccount
