import React from 'react'
import Result from '@/components/setup/Result'
import { selectRiskMarkMsg } from '../../store/slices/finance'
import { selectOpenScope } from '../../store/slices/init'
import { selectRejectInfo } from '../../store/slices/reject'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { SetupPageProps } from '@/common/types'

const ResultPage = ({ nextPage }: SetupPageProps) => {
  return (
    <Result
      nextPage={nextPage}
      selectRiskMarkMsg={selectRiskMarkMsg}
      selectOpenScope={selectOpenScope}
      selectRejectInfo={selectRejectInfo}
      selectUserSetupInfo={selectUserSetupInfo}
    />
  )
}

export default ResultPage
