import { createSlice } from '@reduxjs/toolkit'
import { isString, isEmpty } from 'lodash'
import * as Schemas from '@/common/schemas'

// todo 已抽成公共类型，后面删除
export interface UserData {
  checked: string[]
  remarks?: any[]
}
interface OrderData {
  otherMsgShow: string
  userData: Schemas.UserData
}

interface State {
  otherData: OrderData
}

export const allOrterDataValues = [
  'orderDataFinalAndUniq',
  'orderDataIssuedTransactionInstructions',
  'orderDataNotAmerican',
  'orderDataBankruptcy',
  'orderDataSecuritiesPractitioner',
  'orderDataRelationWithAnAn',
  'orderDataImportantPublicOffice',
  'orderDataIllegal'
]

export const slice = createSlice({
  name: 'otherData',
  initialState: {
    userData: {
      checked: [...allOrterDataValues],
      remarks: []
    }
  },
  reducers: {
    updateOtherMsgShow(state, action): void {
      if (isEmpty(action.payload)) {
        return
      }
      if (isString(action.payload)) {
        try {
          action.payload = JSON.parse(action.payload)
        } catch (error) {
          console.error(error)
        }
      }
      const { checked = [], remarks = [] } = action.payload
      state.userData = {
        checked,
        remarks
      }
    },
    updateUserData(state, action): void {
      state.userData = action.payload
    }
  }
})

export const { updateOtherMsgShow, updateUserData } = slice.actions

export const selectUserData = (state: State): Schemas.UserData => state.otherData.userData

export default slice.reducer
