import React, { useState } from 'react'
import styles from './index.module.scss'

interface IndexProps {
  children: string | React.ReactNode
  hoverContent: string | React.ReactNode
  left: string
}

function Index(props: IndexProps): JSX.Element {
  const [show, setShow] = useState(false)
  const { children, hoverContent, left } = props

  const handleClick = () => {
    setShow(!show)
  }
  return (
    <div className={styles.wrapper}>
      <div onClick={handleClick}>{children}</div>
      {show ? (
        <div className={styles.hoverContent}>
          <div className={styles.triangle} style={{ left }} />
          {hoverContent}
        </div>
      ) : null}
    </div>
  )
}

export default Index
