import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ActionSheet } from '@/components'
import classnames from 'classnames'
import TouchFeedback from 'rmc-feedback'
import styles from './index.module.scss'
import { Row, Col } from 'antd'
import iconSelectChecked from '@/assets/img/icon-radio-active.svg'
import iconArrowRight from '@/assets/img/icon-arrow-right.svg'
import { SelectOptionItem } from '@/common/types'
import { useTranslation } from 'react-i18next'

interface SelectProps {
  className?: string
  value?: unknown
  options?: SelectOptionItem[]
  onChange?: (value: any) => void
  disabled?: boolean
  readOnly?: boolean
  sheetFoot?: any
  tipMessage?: string | null
  placeholder?: string
}

function BankCardSelect(props: SelectProps): JSX.Element {
  const { t } = useTranslation()
  const {
    className,
    value,
    options = [],
    onChange,
    disabled = false,
    readOnly = false,
    tipMessage,
    sheetFoot,
    placeholder
  } = props
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState<SelectOptionItem>()
  const handleClose = (): void => {
    setShow(false)
  }
  const openSelect = (): void => {
    if (!disabled && !readOnly) {
      setShow(true)
    }
  }
  const handleClickItem = (item: SelectOptionItem): void => {
    onChange?.(item.value)
    setSelectedItem(item)
    setShow(false)
  }
  const getLabel = (): any => {
    let sitem
    if (selectedItem) {
      return selectedItem.label
    } else if ((sitem = [...options].find((item: SelectOptionItem) => item.value === value))) {
      return sitem.label
    }
    return selectedItem
  }
  const showLabel = (): boolean => {
    return disabled || readOnly || (value !== '' && !!value)
  }

  useEffect(() => {
    if (selectedItem && selectedItem.value !== value) {
      setSelectedItem([...options].find((item: SelectOptionItem) => item.value === value))
    }
  }, [value, options, selectedItem])
  return (
    <div
      className={classnames(styles.bankSelectContainer, className, { [styles.disabled]: disabled })}
    >
      {placeholder && <div className={classnames(styles.placeholder, { [styles.placeholderUp]: showLabel() })}>{placeholder}</div>}
      <Row className={styles.selectLine} onClick={openSelect}>
        <Col span={22} className={styles.selectInput}>
          {getLabel()}
        </Col>
        <Col span={2} className={classnames(styles.selectIcon, styles.selectInputIcon, "textAlignRight")}>
          <img className={styles.iconSize} src={iconArrowRight} alt="select" />
        </Col>
      </Row>
      {tipMessage && tipMessage.trim() !== '' ? (
        <Row className={styles.sleectTip}>{tipMessage}</Row>
      ) : null}
      <ActionSheet show={show} onClose={handleClose} className="bank-card-select">
        <div className={styles.contentWrapper}>
          <div className={(styles.tagsGroup, styles.tagsTitle)}>
            {t('za_invest_bank_card_select_component_placeholder', '选择银行')}
          </div>
          <div className={styles.listContainer}>
            {[...options].map((item: SelectOptionItem) => (
              <div key={`${item.value}`}>
                <TouchFeedback activeClassName={styles.touchActive}>
                  <Row
                    className={styles.selectItem}
                    onClick={(): void => {
                      handleClickItem(item)
                    }}
                  >
                    <Col span={22} className={styles.selectLabel}>
                      {item.label}
                    </Col>
                    {value === item.value && (
                      <Col span={2} className={classnames("textAlignCenter", styles.selectIcon)}>
                        <img className={styles.iconSize} src={iconSelectChecked} alt="checked" />
                      </Col>
                    )}
                  </Row>
                </TouchFeedback>
              </div>
            ))}
          </div>
          {options.length === 0 && (
            <div className={styles.noData}>
              {t('za_invest_all_bank_card_data_empty') || '暂时没有可出金的银行卡'}
            </div>
          )}
          {sheetFoot ? sheetFoot : null}
        </div>
      </ActionSheet>
    </div>
  )
}

BankCardSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  sheetFoot: PropTypes.element,
  tipMessage: PropTypes.string
}

export default BankCardSelect
