import React, { useEffect } from 'react'
import { Input, InputProps } from 'antd'
import classnames from 'classnames'
import styles from './index.module.scss'
import { useSetState } from '@/hooks'
import { isIOS } from '@/utils'
export interface ZiInputProps extends InputProps {
  transform?: (arg: any) => void
  border?: boolean
  onFocus?: () => void
}

function ZiInput(props: ZiInputProps): JSX.Element {
  const {
    className,
    placeholder,
    onChange,
    value = null,
    style = {},
    transform,
    onFocus,
    onBlur,
    border = true,
    maxLength,
    ...rest
  } = props
  const [state, setState] = useSetState({
    isFocus: false,
    innerValue: value
  })
  const { isFocus, innerValue } = state
  useEffect(() => {
    setState((prevState) => {
      if (prevState.innerValue !== value) {
        return {
          innerValue: value
        }
      }
      return {}
    })
  }, [value, setState])
  const showLabel = (): boolean => {
    return rest.disabled || rest.readOnly || isFocus || (innerValue !== '' && !!innerValue)
  }
  const handleFocus = (): void => {
    setState({
      isFocus: true
    })
    onFocus?.()
  }
  const handleBlur = (e: any): void => {
    setState({
      isFocus: false
    })
    onBlur?.(e)
    if (isIOS) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: 0
      })
    }
  }
  const handleChange = (e: any): void => {
    e.currentTarget.trimValue = e.currentTarget.value.trim()
    if (transform && typeof transform === 'function') {
      e.currentTarget.value = transform(e.currentTarget.value)
    }
    setState({
      innerValue: e.currentTarget.value
    })
    onChange?.(e)
  }
  const handleInput = (e: any): void => {
    if (rest?.type && rest?.type === 'tel') {
      let innerValue = e.currentTarget.value?.replace(/[^0-9]/g, '')
      setTimeout(() => {
        setState({
          innerValue
        })
        onChange?.(innerValue)
      }, 0)
    } else {
      props?.onInput?.(e)
    }
  }
  return (
    <div
      style={style}
      className={classnames(styles.inputContainer, 'zi-input-container', {
        [styles.isFocus]: isFocus,
        [styles.border]: border
      })}
    >
      <div className={classnames(styles.inputLabel, { [styles.labelUp]: showLabel() })}>
        {placeholder}
      </div>
      <Input
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onInput={handleInput}
        className={classnames(styles.inputInner, className)}
        value={innerValue as InputProps['value']}
        maxLength={maxLength}
        {...rest}
      />
    </div>
  )
}

export default ZiInput
