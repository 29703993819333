import { createSlice } from '@reduxjs/toolkit'
import * as Schemas from '@/common/schemas'

interface Init {
  list: Schemas.TaxMsgType['taxAreaInfo']
}

interface InitState {
  taxation: Init
}

export const counterSlice = createSlice({
  name: 'taxation',
  initialState: {
    list: [
      {
        regionValue: 'CN',
        taxNumber: '',
        key: 'first'
      }
    ]
  },
  reducers: {
    updateTaxMag(state, action): void {
      state.list = action.payload || state.list
    }
  }
})

export const { updateTaxMag } = counterSlice.actions

export const selectTaxMsg = (state: InitState): Schemas.TaxMsgType['taxAreaInfo'] => {
  console.log(`state.taxation`, state)
  return state.taxation.list
}

export default counterSlice.reducer
