import IdCard from '../pages/idCard/index'
import Address from '../pages/address/index'
import Career from '../pages/career/index'
import Finance from '../pages/finance/index'
import OtherData from '../pages/otherData/index'
import RiskShow from '../pages/riskShow/index'
import Signature from '../pages/signature/index'
import UploadConsent from '../pages/uploadConsent/index'
import NavsPage from '../pages/navs'
import IdType from '../pages/idType'
import SetupPre from '../pages/setupPre'
import Result from '../pages/result/index'
import InviteCode from '../pages/inviteCode/index'
import Taxation from '../pages/taxation'
import { PagePathSetup } from '@/common/enums/index'
import SequentialControl from '../business/SequentialControl/'
import OpenAccount from '../pages/openAccount'
import RiskConfirm from '../pages/riskMarkConfirm'

const routes = [
  { path: PagePathSetup.PAGE_RESULT, component: SequentialControl(Result) },
  { path: PagePathSetup.PAGE_SIGNATURE, component: SequentialControl(Signature) },
  { path: PagePathSetup.PAGE_RISK_SHOW, component: SequentialControl(RiskShow) },
  { path: PagePathSetup.PAGE_OTHER_DATA, component: SequentialControl(OtherData) },
  { path: PagePathSetup.PAGE_FINANCE, component: SequentialControl(Finance) },
  { path: PagePathSetup.PAGE_RISK_CONFIRM, component: SequentialControl(RiskConfirm) },
  { path: PagePathSetup.PAGE_CAREER, component: SequentialControl(Career) },
  { path: PagePathSetup.PAGE_ADDRESS, component: SequentialControl(Address) },
  { path: PagePathSetup.PAGE_IDCARD, component: SequentialControl(IdCard) },
  { path: PagePathSetup.PAGE_UPLOAD_CONTENT, component: SequentialControl(UploadConsent) },
  { path: '/navs/', component: SequentialControl(NavsPage) },
  { path: PagePathSetup.PAGE_ID_TYPE, component: SequentialControl(IdType) },
  { path: PagePathSetup.PAGE_INVITE_CODE, component: SequentialControl(InviteCode) },
  { path: PagePathSetup.PAGE_SETUP_PRE, component: SequentialControl(SetupPre) },
  { path: PagePathSetup.PAGE_OPEN_ACCOUNT, component: SequentialControl(OpenAccount) },
  { path: PagePathSetup.PAGE_TAXATIOIN, component: SequentialControl(Taxation) },
  { path: PagePathSetup.PAGE_HOME, component: SequentialControl(OpenAccount) }
]

export default routes
