import { createSlice } from '@reduxjs/toolkit'
import { ProfessType } from '@/common/schemas/setup/account.interface'
import * as Schemas from '@/common/schemas'
// todo 要抽成公共变量
export const jobTypeWithCompany = ['A', 'B', 'C']
// todo 后面要删除
export interface CareerType {
  jobType: string
  companyName: string
  industry: string
}

interface Init {
  pageData: ProfessType
  userData: Schemas.CareerType
}

interface InitState {
  career: Init
}

export const counterSlice = createSlice({
  name: 'career',
  initialState: {
    userData: {
      jobType: '',
      companyName: '',
      industry: []
    }
  },
  reducers: {
    updateUserData(state, action): void {
      const newData = action.payload || {}
      if (!Array.isArray(newData.industry) && newData.industry) {
        newData.industry = [newData.industry, newData.position]
      }
      state.userData = { ...state.userData, ...newData }
    }
  }
})

export const { updateUserData } = counterSlice.actions

export const selectCareerUserData = (state: InitState): Schemas.CareerType => state.career.userData

export default counterSlice.reducer
