export enum PagePathSetup {
  PAGE_HOME = '/',
  PAGE_SIGNATURE = '/signature/',
  PAGE_RISK_SHOW = '/risk-show/',
  PAGE_OTHER_DATA = '/other-data/',
  PAGE_FINANCE = '/finance/',
  PAGE_RISK_CONFIRM = '/risk-confirm/',
  PAGE_CAREER = '/career/',
  PAGE_ADDRESS = '/address/',
  PAGE_IDCARD = '/idcard/',
  PAGE_ID_TYPE = '/id-type/',
  PAGE_SETUP_PRE = '/id-pre/',
  PAGE_RESULT = '/result/',
  PAGE_UPLOAD_CONTENT = '/upload-consent/',
  PAGE_INVITE_CODE = '/invite-code/',
  PAGE_OPEN_ACCOUNT = '/open-account/',
  PAGE_TAXATIOIN = '/taxation/',
  /**
   * 内地开户页面
   */
  PAGE_UPLOAD_ID_CARD = '/upload-id-card/',
  PAGE_CONFIRM_IDENTIFY = '/confirm-identify/',
  PAGE_ADDTIONAL_IDENTIFY = '/additional-identify/',
  PAGE_VALID_BANK = '/valid-bank/',
  PAGE_CA = '/ca/'
}

export enum JumpPath {
  CASHIN = 'cashin',
  ENTRUSTEDACCOUNT = 'entrusted-account',
  RISK = 'risk'
}
