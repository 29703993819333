import React, { useState } from 'react'
import styles from './index.module.scss'

interface TabItem {
  title: string
}
export interface TabsProps {
  initialTab?: number
  children?: React.ReactNode
  tabs: TabItem[]
  onChange?: (tab: TabItem, index: number) => void
}

// eslint-disable-next-line no-param-reassign
const uid = ((uuid = 0) => (): string => `${uuid++}`)()

export default function Tabs(props: TabsProps): JSX.Element {
  const { tabs, onChange, children, initialTab = 0 } = props
  const [current, setCurrent] = useState(initialTab)
  const onTabClick = (index: number): void => {
    onChange?.(tabs[index], index)
    setCurrent(index)
  }
  const childList = React.Children.map(children, (child: any, index) => (
    <div className={current === index ? styles.activeTabContent : styles.tabContent}>
      {React.cloneElement(child, { key: uid() })}
    </div>
  ))
  return (
    <div>
      {tabs.map((item, index) => (
        <button
          key={item.title}
          className={current === index ? styles.activeTag : styles.tag}
          onClick={(): void => {
            onTabClick(index)
          }}
        >
          {item.title}
        </button>
      ))}
      {childList}
    </div>
  )
}
