import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { ZiProgressBar, ZiInput, ZiCascader, SetupTitle } from '@/components'
import { Button } from 'zati-ui-web'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import * as Enums from '@/common/enums'
import * as Schemas from '@/common/schemas'
import { regularExpUtils } from '@/utils'
import { useSetState, useSubmitMSetup } from '@/hooks'
import { SelectOptionItem, SetupPageProps } from '@/common/types'
import { cloneDeep, omit } from 'lodash'
import { getCountryDictionary } from '@/service/commonModule'
import {
  selectIdentify,
  updateIdentify,
  IdentifyPageData
} from '@/pages/msetup/store/slices/identify'
import { useSelector, useDispatch } from 'react-redux'
import { CascaderHandles } from '@/components/Cascader'
import { selectAddressInfo } from '../../store/slices/address'
import { Timing } from '@/common/enums/time.enum'

export interface IdentifyState {
  areaList: SelectOptionItem[]
  areaValue: string[]
  isInit: boolean
}

const transformArea = (list: Schemas.CountryDictionaryType[]): SelectOptionItem[] => {
  return list?.map((item) => {
    const result: SelectOptionItem = {
      value: item.code,
      label: item.name,
      order: item.order
    }
    // 省份的code P2(P开头), 城市的code为C76（C开头）
    if (item.hasNext && item.code.startsWith('P')) {
      result.children = []
    }
    return result
  })
}

function Identify({ nextPage }: SetupPageProps) {
  const ref1 = useRef<CascaderHandles>(null)
  const identifyData = useSelector(selectIdentify)
  const addrMsg = useSelector(selectAddressInfo)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const submitSetup = useSubmitMSetup()
  const [form] = Form.useForm<IdentifyPageData>()
  const [state, setState] = useSetState<IdentifyState>({
    areaList: [],
    areaValue: [],
    isInit: false
  })

  const { areaList, areaValue, isInit } = state

  const submit = async () => {
    try {
      const values = await form.validateFields()

      dispatch(updateIdentify(values))
      const [provinceCode, cityCode = ''] = values.area
      const copyValues = omit(values, ['area'])
      let province = ''
      let city = ''
      const provinceItem = areaList?.find((item) => item.value === provinceCode)
      // 获取省份或直辖市
      if (provinceCode) {
        if (provinceItem) {
          province = provinceItem.label
        }
      }
      // 获取城市名
      if (cityCode && provinceItem?.children) {
        const cityItem = provinceItem?.children?.find((item) => item.value === cityCode)
        if (cityItem) {
          city = cityItem.label
        }
      }
      // 提交数据
      await submitSetup({
        dataType: Enums.OpenStep.STEP_INLAND_IDENTIFY,
        openData: {
          inlandIdentifyMsg: {
            ...copyValues,
            provinceCode,
            cityCode,
            province,
            city
          }
        }
      })
      nextPage()
    } catch (err) { }
  }

  useEffect(() => {
    form.setFieldsValue({ ...identifyData, email: addrMsg.email })
  }, [addrMsg.email, form, identifyData])

  // 获取远程区域数据
  const areaChange = (value: string[]): void => {
    const list = cloneDeep(areaList)
    const matchItem = list?.find((it) => it.value === value[0])
    if (matchItem?.children?.length === 0) {
      getCountryDictionary({ code: value[0], loading: 1 }).then((resp) => {
        matchItem.children = transformArea(resp.list)
        setState({ areaList: list, areaValue: value })
        form.setFieldsValue({ area: value })
        form.validateFields(['area'])
      })
    } else {
      setState({ areaValue: value })
      form.setFieldsValue({ area: value })
    }
  }

  useEffect(() => {
    if (!isInit) {
      setState({ areaValue: [] })
      // 获取省份列表
      getCountryDictionary({
        code: 'CN',
        loading: 1
      }).then(async (resp) => {
        let area = identifyData.area
        if (identifyData.area.length === 0 && identifyData.provinceCode) {
          area = [identifyData.provinceCode, identifyData.cityCode]
        }
        const provinceCode = area[0]
        const provinceList = transformArea(resp?.list)
        setState({ areaList: cloneDeep(provinceList) })
        const provinceItem = provinceList?.find((item) => item.value === provinceCode)
        // 获取城市列表
        if (provinceItem) {
          try {
            const citys = await getCountryDictionary({
              code: provinceCode,
              loading: 1
            })
            provinceItem.children = transformArea(citys.list)
            setState({ areaList: provinceList, areaValue: area })
          } catch (err) {
            console.log(`获取城市列表失败：`, err)
          }
        } else {
          setState({ areaValue: identifyData.area })
        }
      })
    }
  }, [isInit, setState, identifyData.area, identifyData.provinceCode, identifyData.cityCode])

  useEffect(() => {
    const area = form.getFieldValue('area')
    if (areaValue.length && (areaValue[0] !== area[0] || areaValue[1] !== area[1])) {
      form.setFieldsValue({ area: areaValue })
      if (ref1.current) {
        ref1.current.updateVals()
      }
    }
  }, [form, areaValue])

  const rules: Schemas.FormRuleList = {
    engName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_engName', '请输入姓名（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_engName',
          '请输入正确的姓名（英文）'
        )
      }
    ],
    lastName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_lastName', '请输入姓（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_lastName',
          '请输入正确的姓（英文）'
        )
      }
    ],
    firstName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_firstName', '请输入名（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_firstName',
          '请输入正确的名（英文）'
        )
      }
    ],
    zhName: [
      {
        required: true,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_zhName',
          '请输入正确的姓名（中文）'
        )
      },
      {
        pattern: /^\p{Unified_Ideograph}+$/u,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_zhName',
          '请输入正确的姓名（中文）'
        )
      }
    ],
    addrDetail: [
      {
        required: true,
        message: t('za_invest_msetup_identify_address_require', '请填写详细地址')
      }
    ],
    idNo: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_idNo', '请输入身份证号码')
      },
      (): any => ({
        validator(_: unknown, value: string): Promise<unknown> {
          // TODO value 符合内地身份证的正则
          const isIdCard = regularExpUtils.isMainlandIdentifyNum(value)
          if (!isIdCard) {
            return Promise.reject(
              Error(
                t(
                  'za_invest_setup_idcard_page_form_verify_correct_idNo',
                  '身份证号码格式错误，请重新输入'
                )
              )
            )
          }
          return Promise.resolve()
        }
      })
    ],
    area: [
      {
        required: true,
        message: t('za_invest_setup_address_input_error9', '请输入区域'),
        validator: (_: unknown, value: string[]) => {
          if (value?.length < 1) {
            return Promise.reject(
              new Error(t('za_invest_setup_address_input_error9', '请输入区域'))
            )
          } else if (value?.length === 1) {
            const matchItem = areaList.find((item) => item.value === value[0])
            if (matchItem?.children?.length) {
              return Promise.reject(
                new Error(t('za_invest_setup_address_input_error9_1', '请完善区域信息'))
              )
            }
          }
          return Promise.resolve()
        }
      }
    ],
    email: [
      {
        required: true,
        message: t('za_invest_msetup_setup_identify_contact_email_require', '请输入联系邮箱')
      },
      {
        validator(_: unknown, value: string) {
          const isValidEmail = regularExpUtils.isLegalEmail(value)
          if (isValidEmail) {
            return Promise.resolve()
          }
          return Promise.reject(
            Error(
              t(
                'za_invest_msetup_identify_page_form_verify_correct_email',
                '邮箱格式错误，请重新输入'
              )
            )
          )
        }
      }
    ]
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar current={1} title={t('za_invest_setup_progressBar_01Title', '个人资料')} />
      </div>
      <SetupTitle text={t('za_invest_msetup_setup_confirm_identify', '确认身份与联系信息')} />
      <div className="panel">
        <Form form={form}>
          <Form.Item
            className={styles.formItem}
            name="lastName"
            rules={rules.lastName}
            validateFirst
          >
            <ZiInput
              placeholder={t('za_invest_setup_idcard_page_form_label_lastName', '姓（英文）')}
              maxLength={60}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="firstName"
            rules={rules.firstName}
            validateFirst
          >
            <ZiInput
              placeholder={t('za_invest_setup_idcard_page_form_label_firstName', '名（英文）')}
              maxLength={60}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="zhName"
            rules={rules.zhName}
            validateFirst
          >
            <ZiInput
              placeholder={t('za_invest_setup_idcard_page_form_label_zhName', '中文名')}
              maxLength={30}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="idNo"
            rules={rules.idNo}
            validateFirst
          >
            <ZiInput
              placeholder={t('za_invest_setup_idcard_page_form_label_idNo', '身份证号码')}
              maxLength={18}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="area"
            validateFirst
            rules={rules.area}
          >
            <ZiCascader
              ref={ref1}
              label={t('za_invest_setup_address_input_placeHolder5', '所在区域')}
              options={areaList}
              onChange={areaChange}
              value={areaValue}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="addrDetail"
            rules={rules.addrDetail}
            validateFirst
          >
            <ZiInput
              placeholder={t('za_invest_msetup_setup_address_detail', '详细地址')}
              maxLength={200}
            />
          </Form.Item>
          <Form.Item name="email" validateFirst className={styles.formItem}>
            <ZiInput
              disabled
              placeholder={t('za_invest_msetup_setup_contact_email', '联系邮箱')}
              maxLength={100}
            />
          </Form.Item>
        </Form>
        <div className={styles.subText}>
          {t('za_invest_msetup_setup_contact_email_purpose', '用于接收交易信息与结单')}
        </div>
      </div>
      <div className="footer-btn-box">
        <Button type="primary" block throttleTime={Timing.DEBOUNCE_LONG} onClick={submit}>
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
      </div>
    </div>
  )
}

export default Identify
