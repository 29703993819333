import NavBar from './NavBar'
import CardList from './CardList/index'
import NavBarPage from './NavBarPage'
import ZiFileUpload from './FileUpload'
import ActionSheet from './ActionSheet'
import ZiSelect from './Select'
import ZiInput from './Input'
import ZiCheckbox from './CheckBox'
import ZiProgressBar from './ProgressBar'
import { Toast, Modal, Accordion } from 'antd-mobile'
import SignatureCanvas from './SignatureCanvas'
import ZiCascader from './Cascader'
import ZiMoneyInput from './MoneyInput'
import ZiResultPage from './ResultPage'
import BankCardSelect from './BankCardSelect'
import CodeInputGroups from './CodeInputGroups'
import BankList from './BankList'
import ZiPicturePreview from './PicturePreview'
import ZiDatePicker from './DatePicker'
import ZiLoadingPage from './LoadingPage'
import ZiTabs from './Tabs'
import RiskResult from './RiskResult' // 风险问卷结果页静态数据组件
import RiskHeader from './RiskHeader' // 风险问卷结果页静态数据组件
import RiskForm from './RiskForm' // 风险问卷表单，开户和重新评估页面使用
import Seagull from './Seagull'
import ZiLink from './Link'
import NextBtn from './NextBtn'
import SetupTitle from './setup/Title'
import LoadingItem from './LoadingItem'
import SpreadFlowers from './SpreadFlowers'
import SetupInviteCode from './setup/InviteCode'
import SetupRiskMarkConfirm from './setup/RiskMarkConfirm'
import SetupRiskShow from './setup/RiskShow'
import SetupCareer from './setup/Career'
import TipsList from './TipsList'
import AccountItem from './AccountItem'
import TipsInPop from './TipsInPop'
import CashInProgressBar from './CashInProgressBar'
import Header from './Header'
import InsuranceDetail from './InsuranceDetail'
import HoverTip from './HoverTip'

// @ts-ignore
const ZiToast = Toast
const ZiModal = Modal
const ZiAccordion = Accordion

export {
  NavBar,
  CardList,
  NavBarPage,
  ZiFileUpload,
  ActionSheet,
  ZiSelect,
  ZiInput,
  ZiToast,
  ZiModal,
  ZiAccordion,
  ZiCheckbox,
  ZiProgressBar,
  SignatureCanvas,
  ZiCascader,
  ZiMoneyInput,
  ZiResultPage,
  BankCardSelect,
  CodeInputGroups,
  BankList,
  ZiPicturePreview,
  ZiDatePicker,
  ZiLoadingPage,
  ZiTabs,
  RiskResult,
  RiskHeader,
  RiskForm,
  Seagull,
  ZiLink,
  NextBtn,
  SetupTitle,
  LoadingItem,
  SpreadFlowers,
  SetupInviteCode,
  SetupRiskMarkConfirm,
  SetupRiskShow,
  SetupCareer,
  TipsList,
  AccountItem,
  TipsInPop,
  CashInProgressBar,
  Header,
  InsuranceDetail,
  HoverTip
}
