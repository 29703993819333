import { CardColor } from '@/common/enums/card-mgmt/'
const CardToColor: Iterable<readonly [string, CardColor]> = [
    ['387', CardColor.GREEN],
    ['024', CardColor.GREEN],
    ['012', CardColor.RED],
    ['004', CardColor.RED],
    ['029', CardColor.RED],
    ['028', CardColor.RED],
    ['016', CardColor.RED],
    ['040', CardColor.RED],
    ['015', CardColor.RED],
    ['018', CardColor.RED],
    ['020', CardColor.RED],
    ['039', CardColor.RED],
    ['041', CardColor.ORANGE],
    ['061', CardColor.ORANGE],
    ['035', CardColor.ORANGE],
    ['250', CardColor.BLUE],
    ['003', CardColor.BLUE],
    ['009', CardColor.BLUE],
    ['043', CardColor.BLUE],
    ['128', CardColor.BLUE],
    ['038', CardColor.BLUE],
    ['025', CardColor.BLUE],
]
export const ColorList: CardColor[] = [CardColor.GREEN, CardColor.RED, CardColor.ORANGE, CardColor.BLUE]
export const CardToColorMap: Map<string, CardColor> = new Map(CardToColor)
