import { createSlice } from '@reduxjs/toolkit'
import { SetupUserInfo, PdfItemType, PdfCodeItem } from '@/common/schemas/setup/'
import { isEmpty } from 'lodash'

interface UserSetupInfoState {
  userSetupInfo: {
    userSetupInfo: SetupUserInfo
    pdfList: PdfItemType[]
    pdfCodeList: PdfCodeItem[]
    pdfObsKeyMap: {
      [key: string]: string
    }
  }
}

export const slice = createSlice({
  name: 'userSetupInfo',
  initialState: {
    userSetupInfo: {},
    pdfList: [],
    pdfCodeList: [],
    pdfObsKeyMap: {}
  },
  reducers: {
    updateUserSetupInfo(state, action): void {
      state.userSetupInfo = action.payload
    },
    updatePdfList(state, action): void {
      state.pdfList = action.payload
    },
    updatePdfCodeList(state, action): void {
      state.pdfCodeList = action.payload
    },
    updatePdfKeyMap(state, action) {
      if (!isEmpty(action.payload)) {
        state.pdfObsKeyMap = {
          ...state.pdfObsKeyMap,
          ...action.payload
        }
      }
    }
  }
})

export const {
  updateUserSetupInfo,
  updatePdfList,
  updatePdfCodeList,
  updatePdfKeyMap
} = slice.actions

export const selectUserSetupInfo = (state: UserSetupInfoState): SetupUserInfo =>
  state.userSetupInfo.userSetupInfo

export const selectPdfList = (state: UserSetupInfoState): PdfItemType[] =>
  state.userSetupInfo.pdfList

export const selectPdfCodeList = (state: UserSetupInfoState): PdfCodeItem[] =>
  state.userSetupInfo.pdfCodeList

export const selectPdfKeyMap = (state: UserSetupInfoState) => state.userSetupInfo.pdfObsKeyMap

export default slice.reducer
