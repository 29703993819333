import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import { getCertDN, queryCaApplyResult, clearCaTimeout } from '../../utils'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { useSelector } from 'react-redux'
import { postEventToNative } from '@/service/nativeCall'
import { JsEvent } from '@/common/enums/'
import { useHistory } from 'react-router-dom'
import { CaResType } from '@/common/schemas'
import { selectRoutes } from '../../store/slices/routes'
import { toggleLoading } from '@/utils'
import loadingMsg from '@/assets/img/caloading.png'
import { ZiModal } from '@/components'
import { useSetState } from '@/hooks'
const alert = ZiModal.alert

function Ca({ nextPage }: { nextPage: any }): JSX.Element {
  const { t } = useTranslation()
  const setupInfo = useSelector(selectUserSetupInfo)
  const history = useHistory()
  const routes: string[] = useSelector(selectRoutes)
  const [state, setState] = useSetState({
    isInit: false
  })
  const { isInit } = state
  const caSuccessFn = useCallback(() => {
    postEventToNative({ name: JsEvent.OPEN_ACCOUNT })
    postEventToNative({ name: JsEvent.IPO_ACCOUNT_STATUS })
    // 跳到资料待审核页面
    nextPage()
  }, [nextPage])
  const caFailFn = useCallback((res: CaResType) => {
    const { resultCode, resultDesc } = res
    let buttons
    if (resultCode === '308') { // 信息失败
      buttons = [{
        text: t('4541_msetup_back_page', '返回页面'),
        onPress: (): void => {
          return
        }
      }, {
        text: t('4541_msetup_retry', '重新尝试'),
        onPress: (): void => {
          // 去到当前开户信息数组或者驳回数组中的第一步。 history.push('/question/')
          history.push(routes[0])
        }
      }
      ]
    } else if (resultCode === '307') {
      buttons = [{
        text: t('4541_msetup_back_page', '返回页面'),
        onPress: (): void => {
          return
        }
      }]
    } else if (resultCode === '309') {
      buttons = [{
        text: t('4541_msetup_iknow', '我知道了'),
        onPress: (): void => {
          return
        }
      }]
    }
    alert(
      null,
      resultDesc, // 后台返回文案
      buttons
    )
  }, [history, routes, t])

  useEffect(() => {
    if(!isInit) {
      toggleLoading(true)
      setState({
        isInit: true
      })
      // 获取主题与请求证书
      getCertDN(setupInfo.openAccNo, (caSerialNo) => {
        if (!caSerialNo) {
          // TODO 假设第一二步出现异常，此时要补一种异常情况, 待和产品确认
          return
        }
        queryCaApplyResult({
          caSerialNo,
          successFn: caSuccessFn,
          failFn: caFailFn,
          t: t
        })
      })
    }
  }, [caFailFn, caSuccessFn, isInit, setState, setupInfo.openAccNo, t])
  useEffect(() => {
    return () => {
      clearCaTimeout()
    }
  })

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.animIcon}>
        <img src={loadingMsg} />
      </div>
      <p className={styles.tip}>{t('4541_msetup_waiting_ca', '申请提交中，请稍后查看')}</p>
    </div>
  )
}

export default Ca