import { createSlice } from '@reduxjs/toolkit'
import * as Schemas from '@/common/schemas'

export const counterSlice = createSlice({
  name: 'address',
  initialState: {
    pageData: {
      country: 'HK',
      area: [],
      street: '',
      room: '',
      floor: '',
      seat: '',
      phoneCode: '',
      phone: '',
      email: ''
    }
  },
  reducers: {
    updateAddressInfo(state, action): void {
      // 目前只能默认香港
      const data = action.payload || state.pageData
      data.country = 'HK'
      if (!data?.phone?.startsWith('+')) {
        data.phone = data.phone ? `${data.phoneCode} ${data.phone}` : ''
      }
      if (typeof data.area === 'string') {
        data.area = data?.area?.split(',') || ''
      }
      state.pageData = {
        ...state.pageData,
        ...data
      }
    }
  }
})

export const { updateAddressInfo } = counterSlice.actions

export const selectAddressInfo = (state: any): Schemas.AddrType => state.address.pageData

export default counterSlice.reducer
