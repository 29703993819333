import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectRoutes, selectDefaultRoutes } from '../../store/slices/routes'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { selectUserData } from '../../store/slices/otherData'
import { selectRejectInfo } from '../../store/slices/reject'
import { JpageOpt } from '@/common/schemas'
import { OpenStatus, PagePathSetup } from '@/common/enums/index'
import { setupStep2Path, toggleGlobalLoading } from '@/utils/index'
import { callNativeSetBackBtn, callCloseWebview, callNativeSetRightBtn } from '@/service/nativeCall'
import { ZiModal } from '@/components/'
import { useTranslation } from 'react-i18next'

let isInit = false
function SequentialControl(BaseComponent: Function): Function {
  function WrapperComponent(props: any): JSX.Element {
    const { t } = useTranslation()
    const history = useHistory()
    const routes: string[] = useSelector(selectRoutes)
    const defaultRoutes: string[] = useSelector(selectDefaultRoutes)
    const userSetupInfo = useSelector(selectUserSetupInfo)
    const rejectInfo = useSelector(selectRejectInfo)
    const otherData = useSelector(selectUserData)
    let isLeaving = false
    useEffect(() => {
      if (!isInit && userSetupInfo.openStatus) {
        toggleGlobalLoading()
        isInit = true
        const nextRoute = setupStep2Path(userSetupInfo?.openStep)
        switch (userSetupInfo.openStatus) {
          case OpenStatus.OPENING:
            console.log(nextRoute, 'nextRoute')
            history.push(nextRoute)
            break
          case OpenStatus.AUDIT_WAITING:
          case OpenStatus.REJECT:
          case OpenStatus.COMPLETE:
          case OpenStatus.FAIL:
          case OpenStatus.AML_FAIL:
            history.push(PagePathSetup.PAGE_RESULT)
            break
          default:
            history.push(PagePathSetup.PAGE_OPEN_ACCOUNT)
        }
      }
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0
        })
      }, 0)
    }, [history, rejectInfo, userSetupInfo.openStatus, userSetupInfo.openStep])

    function nextPage(): void {
      console.log('inside nextPage')
      let currentPage: string = history.location.pathname
      console.log('currentPage:', currentPage)
      const targetRoutes = routes.length === 0 ? defaultRoutes : routes
      console.log('targetRoutes:', targetRoutes)
      // 驳回结果页 nextPage 应该跳转至驳回路由的第一个页面
      if (
        userSetupInfo.openStatus === OpenStatus.REJECT &&
        currentPage === PagePathSetup.PAGE_RESULT
      ) {
        history.push(targetRoutes[0])
        return
      }

      if (
        currentPage === PagePathSetup.PAGE_UPLOAD_CONTENT &&
        !targetRoutes.includes(PagePathSetup.PAGE_UPLOAD_CONTENT)
      ) {
        currentPage = PagePathSetup.PAGE_OTHER_DATA
      }
      const index = targetRoutes.indexOf(currentPage)
      console.log('index:', index)
      let targetIndex = index + 1

      /**
       * 驳回了其它资料和雇主同意书时，勾选了 orderDataSecuritiesPractitioner 需要跳过雇主同意书
       */
      if (
        currentPage === PagePathSetup.PAGE_OTHER_DATA &&
        otherData.checked.includes('orderDataSecuritiesPractitioner') &&
        targetRoutes[targetIndex] === PagePathSetup.PAGE_UPLOAD_CONTENT
      ) {
        targetIndex += 1
      }

      // 路由数组的最后一个页面的下一页应展示结果页
      if (targetIndex >= targetRoutes.length) {
        history.push(`${PagePathSetup.PAGE_RESULT}?from=${currentPage.replace(/\//g, '')}`)
      } else if (targetIndex > -1) {
        history.push(targetRoutes[targetIndex])
      } else {
        console.log(`currentPage ${currentPage} 不在路由数组: ${targetRoutes} 中`)
        history.push(PagePathSetup.PAGE_INVITE_CODE)
      }
    }

    function prePage(): void {
      const currentPage: string = history.location.pathname
      const targetRoutes = routes.length === 0 ? defaultRoutes : routes
      const index = targetRoutes.indexOf(currentPage)
      // 判断如果是雇主同意书页面且路由中包含其它资料披露页的路由，则直接跳转至其它资料披露页å
      if (
        currentPage === PagePathSetup.PAGE_UPLOAD_CONTENT &&
        targetRoutes.includes(PagePathSetup.PAGE_OTHER_DATA)
      ) {
        history.push(PagePathSetup.PAGE_OTHER_DATA)
        return
      }
      const targetIndex = index - 1
      if (currentPage === PagePathSetup.PAGE_RISK_SHOW) {
        if (otherData.checked.includes('orderDataSecuritiesPractitioner')) {
          history.push(PagePathSetup.PAGE_OTHER_DATA)
        } else {
          history.push(PagePathSetup.PAGE_UPLOAD_CONTENT)
        }
      } else if (targetIndex >= 0) {
        history.push(targetRoutes[targetIndex])
      } else {
        // targetIndex < 0, 再按返回按钮, 应该关闭 webview
        if (targetIndex < 0) {
          callCloseWebview()
          return
        }
        if (targetRoutes.includes(PagePathSetup.PAGE_OTHER_DATA)) {
          history.push(PagePathSetup.PAGE_OTHER_DATA)
        } else {
          callCloseWebview()
        }
      }
    }

    function jumpPage({ targetPage }: JpageOpt): void {
      history.replace(targetPage)
    }

    const handleBackBtnHandle = (): void => {
      const currentPage: string = history.location.pathname
      if (
        currentPage === PagePathSetup.PAGE_OPEN_ACCOUNT ||
        currentPage === PagePathSetup.PAGE_HOME ||
        currentPage === PagePathSetup.PAGE_RESULT
      ) {
        callCloseWebview()
      } else {
        prePage()
      }
    }

    const handleRightBtnHandle = (): void => {
      if (isLeaving) return
      isLeaving = true
      const currentPage: string = history.location.pathname
      const showModal = document.querySelector('#quitOpenAccount')
      if (currentPage === PagePathSetup.PAGE_RESULT) {
        callCloseWebview()
      } else if (!showModal) {
        ZiModal.alert(
          <p id="quitOpenAccount">{t('za_invest_setup_alert_out_title_txt', '退出将中断开户')}</p>,
          '',
          [
            {
              text: t('za_invest_setup_alert_out_ok_txt', '退出'),
              onPress: callCloseWebview
            },
            {
              text: t('za_invest_setup_alert_out_cancel_txt', '继续开户'),
              onPress: (): void => {
                isLeaving = false
                callNativeSetRightBtn({
                  text: t('za_invest_callNativeSetRightBtn_txt', '关闭'),
                  onClick: handleRightBtnHandle
                })
              }
            }
          ]
        )
      }
    }

    const showBackBtn = (): void => {
      callNativeSetBackBtn({
        // 左边按钮不可以有这个参数
        // notNeedCallback: true,
        onClick: handleBackBtnHandle
      })
    }

    useEffect(() => {
      showBackBtn()
      callNativeSetRightBtn({
        text: t('za_invest_callNativeSetRightBtn_txt', '关闭'),
        onClick: handleRightBtnHandle
      })
    })
    return (
      <BaseComponent
        {...props}
        nextPage={nextPage}
        prePage={prePage}
        jumpPage={jumpPage}
        showBackBtn={showBackBtn}
      />
    )
  }
  return WrapperComponent
}

export default SequentialControl
