import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ZiProgressBar, ZiInput, ZiSelect, ZiDatePicker, SetupTitle } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { selectIdcardInfo, updateIdcardInfo } from '@/pages/setup/store/slices/idCard'
import { useSubmitSetup, useSetState } from '@/hooks'
import { selectInitDic, selectCountryDic } from '@/pages/setup/store/slices/init'
import moment from 'moment'
import classnames from 'classnames'
import styles from './index.module.scss'
import * as Enums from '@/common/enums'
import * as Schemas from '@/common/schemas'
import { SetupPageProps } from '@/common/types'
import { regularExpUtils } from '@/utils'
import { Button } from 'zati-ui-web'
import { Timing } from '@/common/enums/time.enum'
import { reportCommonException } from '@/service/nativeCall'

function IdCard(props: SetupPageProps): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const submitSetup = useSubmitSetup()

  const cardInfo = useSelector(selectIdcardInfo)
  const initDic = useSelector(selectInitDic)
  const countryOps = useSelector(selectCountryDic)
  const { sex = [], idType = [] } = initDic

  const [state, setState] = useSetState({
    isInit: false,
    editing: true
  })
  const { isInit, editing } = state
  const [country, setCountry] = useState((): string[] => {
    const country = cardInfo.country
    return country?.length ? country : ['']
  })
  const [visible, setVisible] = useState(false)

  const minBirthdate = new Date('1900-01-01')
  const maxBirthdate = new Date(
    moment().subtract(18, 'years').subtract(1, 'days').format('YYYY-MM-DD')
  )

  useEffect(() => {
    if (!isInit) {
      const birthdate = cardInfo.birthdate ? Number(cardInfo.birthdate) : cardInfo.birthdate
      form.setFieldsValue({
        ...cardInfo,
        birthdate,
        engName: `${cardInfo.firstName} ${cardInfo.lastName}`
      })
      setState({ isInit: true })
    }
  }, [cardInfo, form, isInit, setState])

  const onEdit = (): void => {
    setState({ editing: true })
  }

  // const addCountry = (): void => {
  //   if (country.length >= 4) return
  //   form.setFieldsValue({ country: country.concat(['']) })
  //   setCountry(country.concat(['']))
  // }

  const delCountry = (index: number): void => {
    form.setFieldsValue({ country: [...country.slice(0, index), ...country.slice(index + 1)] })
    return setCountry([...country.slice(0, index), ...country.slice(index + 1)])
  }

  const onChange = (index: number, value: any): void => {
    const tempArray = [...country]
    tempArray[index] = value
    form.setFieldsValue({ country: tempArray })
    return setCountry(tempArray)
  }

  const onDatePickerClick = (): void => {
    setVisible(true)
  }

  const onDatePickerOk = ({ visible }: any): void => {
    setVisible(visible)
  }

  const onDatePickerDismiss = ({ visible }: any): any => {
    setVisible(visible)
  }

  const pipeSubmitData = (formData: any): Schemas.IdentifyType => {
    if (!editing) {
      // 未修改时页面没有firstName和lastName
      formData.lastName = cardInfo.lastName
      formData.firstName = cardInfo.firstName
    } else {
      // 修改时页面没有engName
      formData.engName = `${formData.firstName} ${formData.lastName}`
    }

    formData.birthdate = String(new Date(formData.birthdate).getTime())
    return formData
  }

  const execSubmit = async (submitData: any): Promise<void> => {
    await submitSetup({
      dataType: Enums.OpenStep.STEP_SELFIDENTIFY,
      openData: {
        identifyMsg: submitData
      }
    })
    dispatch(updateIdcardInfo(submitData))
    props.nextPage()
  }

  const onCheck = async (): Promise<void> => {
    try {
      const values = await form.validateFields()
      const submitData = pipeSubmitData(values)
      execSubmit(submitData)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
      reportCommonException('idCard-onCheck', errorInfo)
    }
  }

  const rules: Schemas.FormRuleList = {
    engName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_engName', '请输入姓名（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_engName',
          '请输入正确的姓名（英文）'
        )
      }
    ],
    lastName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_lastName', '请输入姓（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_lastName',
          '请输入正确的姓（英文）'
        )
      }
    ],
    firstName: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_firstName', '请输入名（英文）')
      },
      {
        pattern: /^[\da-zA-Z-‘,. ]+$/,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_firstName',
          '请输入正确的名（英文）'
        )
      }
    ],
    zhName: [
      {
        pattern: /^\p{Unified_Ideograph}+$/u,
        message: t(
          'za_invest_setup_idcard_page_form_verify_correct_zhName',
          '请输入正确的姓名（中文）'
        )
      }
    ],
    sex: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_sex', '请选择性别')
      },
      {
        enum: ['2', '3'],
        message: t('za_invest_setup_idcard_page_form_verify_correct_sex', '请选择正确的性别')
      }
    ],
    birthdate: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_birthdate', '请选择出生日期')
      },
      {
        validator(_, value) {
          if (value) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error(
              t('za_invest_setup_idcard_page_form_verify_required_birthdate', '请选择出生日期')
            )
          )
        }
      }
    ],
    idNo: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_idNo', '请输入身份证号码')
      },
      (): any => ({
        validator(_: unknown, value: string): Promise<unknown> {
          const isHkIdCard = regularExpUtils.isHKIdNumber(value)
          if (!isHkIdCard) {
            return Promise.reject(
              Error(
                t(
                  'za_invest_setup_idcard_page_form_verify_correct_idNo',
                  '身份证号码格式错误，请重新输入'
                )
              )
            )
          }
          return Promise.resolve()
        }
      })
    ],
    idType: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_idType', '请选择身份证类型')
      }
    ],
    birthAddr: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_birthAddr', '请选择出生地')
      }
    ],
    country: [
      {
        required: true,
        message: t('za_invest_setup_idcard_page_form_verify_required_country', '请选择国籍')
      }
    ]
  }

  const countryItems = country.map((item, index) => {
    return (
      <Form.Item
        key={index}
        name={['country', index]}
        rules={[
          { required: true, message: t('setup_Idcard_inputcountry', 'Please input your country!') }
        ]}
      >
        <ZiSelect
          label={t('za_invest_setup_idcard_page_form_label_country', '国籍 (国家/地区)')}
          value={item}
          options={countryOps}
          onChange={(target): void => onChange(index, target)}
          canSearch
          // border={editing}
          showArrow={editing}
        />
        {country.length > 1 && index !== 0 ? (
          <div className={styles.delCountryTip}>
            <MinusCircleOutlined
              className="dynamic-delete-button"
              style={{ margin: '4px 8px 4px 0px' }}
              onClick={(): void => {
                delCountry(index)
              }}
            />
            {t('za_invest_setup_idcard_page_form_handle_country_delete', '删除')}
          </div>
        ) : null}
      </Form.Item>
    )
  })

  const countryItemsDisable = country.map((item, index) => {
    return (
      <Form.Item
        key={index}
        name={['country', index]}
        className={styles.confirmCountry}
        rules={[
          { required: true, message: t('setup_Idcard_inputcountry', 'Please input your country!') }
        ]}
      >
        <ZiSelect
          label={t('za_invest_setup_idcard_page_form_label_country', '国籍 (国家/地区)')}
          value={item}
          options={countryOps}
          onChange={(target): void => onChange(index, target)}
          readOnly={!editing}
          // border={editing}
          showArrow={editing}
        />
      </Form.Item>
    )
  })

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={1}
          title={t('za_invest_setup_progressBar_01Title', '01 基础资料')}
          progress={55}
        />
      </div>
      <div className={styles.idCardBody}>
        <SetupTitle text={t('za_invest_setup_idcard_page_title', '编辑身份证信息')} />
        <div className={styles.formContainer}>
          <Form form={form}>
            <div className={styles.inputContainer}>
              {!editing ? (
                <Form.Item
                  className={classnames(styles.formItem, {
                    [styles.confirm]: !editing,
                    [styles.readonly]: !editing
                  })}
                  name="engName"
                  rules={rules.engName}
                  validateFirst
                >
                  <ZiInput
                    placeholder={t('za_invest_setup_idcard_page_form_label_engName', '英文名')}
                    readOnly={!editing}
                    // border={editing}
                    maxLength={100}
                  />
                </Form.Item>
              ) : (
                <Row>
                  <Col span={11}>
                    <Form.Item
                      className={classnames(styles.formItem, { [styles.confirm]: !editing })}
                      name="lastName"
                      rules={rules.lastName}
                      validateFirst
                    >
                      <ZiInput
                        placeholder={t(
                          'za_invest_setup_idcard_page_form_label_lastName',
                          '姓（英文）'
                        )}
                        // transform={(val: string): string => val.toUpperCase()}
                        // border={editing}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} />
                  <Col span={11}>
                    <Form.Item
                      className={classnames(styles.formItem, { [styles.confirm]: !editing })}
                      name="firstName"
                      rules={rules.firstName}
                      validateFirst
                    >
                      <ZiInput
                        placeholder={t(
                          'za_invest_setup_idcard_page_form_label_firstName',
                          '名（英文）'
                        )}
                        // transform={(val: string): string => val.toUpperCase()}
                        // border={editing}
                        maxLength={50}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {!editing ? (
                <>
                  <Form.Item
                    className={classnames(styles.formItem, {
                      [styles.confirm]: !editing,
                      [styles.readonly]: !editing
                    })}
                    name="zhName"
                    rules={rules.zhName}
                    validateFirst
                  >
                    <ZiInput
                      placeholder={t('za_invest_setup_idcard_page_form_label_zhName', '中文名')}
                      readOnly={!editing}
                      // border={editing}
                      maxLength={50}
                    />
                  </Form.Item>
                  <Form.Item
                    className={classnames(styles.formItem, {
                      [styles.confirm]: !editing,
                      [styles.readonly]: !editing
                    })}
                    name="sex"
                    rules={rules.sex}
                    validateFirst
                  >
                    <ZiSelect
                      label={t('za_invest_setup_idcard_page_form_label_sex', '性别')}
                      options={sex}
                      readOnly={!editing}
                      // border={editing}
                      showArrow={editing}
                      className={styles.borderBottomFix}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    className={classnames(styles.formItem, { [styles.confirm]: !editing })}
                    name="zhName"
                    rules={rules.zhName}
                    validateFirst
                  >
                    <ZiInput
                      placeholder={t('za_invest_setup_idcard_page_form_label_zhName', '中文名')}
                      readOnly={!editing}
                      maxLength={50}
                      // border={editing}
                    />
                  </Form.Item>
                  <Form.Item
                    className={classnames(styles.formItem, { [styles.confirm]: !editing })}
                    name="sex"
                    rules={rules.sex}
                  >
                    <ZiSelect
                      label={t('za_invest_setup_idcard_page_form_label_sex', '性别')}
                      options={sex}
                      readOnly={!editing}
                      // border={editing}
                      showArrow={editing}
                    />
                  </Form.Item>
                </>
              )}

              <Form.Item
                className={classnames(styles.formItem, {
                  [styles.confirm]: !editing,
                  [styles.readonly]: !editing
                })}
                name="birthdate"
                rules={rules.birthdate}
                validateFirst
              >
                <ZiDatePicker
                  rightArrow={editing}
                  border
                  readOnly={!editing}
                  onHandleClick={onDatePickerClick}
                  onHandleOk={onDatePickerOk}
                  onHandleDismiss={onDatePickerDismiss}
                  minDate={minBirthdate}
                  maxDate={maxBirthdate}
                  mode="date"
                  visible={visible}
                  format={(date: Date): string => moment(Number(date)).format('DD-MM-YYYY')}
                  title={t(
                    'za_invest_setup_idcard_page_form_label_title_birthdate',
                    '请选择出生日期'
                  )}
                  label={t(
                    'za_invest_setup_idcard_page_form_label_birthdate',
                    '出生日期（DD-MM-YYY）'
                  )}
                />
              </Form.Item>

              <Form.Item
                className={classnames(styles.formItem, {
                  [styles.confirm]: !editing,
                  [styles.readonly]: !editing
                })}
                name="idNo"
                rules={rules.idNo}
                validateFirst
              >
                <ZiInput
                  placeholder={t('za_invest_setup_idcard_page_form_label_idNo', '身份证号码')}
                  // transform={(val: string): string => val.toUpperCase()}
                  readOnly={!editing}
                  // border={editing}
                  maxLength={9}
                />
              </Form.Item>
              <Form.Item
                className={classnames(styles.formItem, {
                  [styles.confirm]: !editing,
                  [styles.readonly]: !editing
                })}
                name="idType"
                rules={rules.idType}
                validateFirst
              >
                <ZiSelect
                  label={t('za_invest_setup_idcard_page_form_label_idType', '身份证类型')}
                  options={idType}
                  readOnly={!editing}
                  // border={editing}
                  showArrow={editing}
                />
              </Form.Item>

              {!editing ? (
                <>
                  <Form.Item
                    className={classnames([styles.formItem, styles.readonly])}
                    name="birthAddr"
                    rules={rules.birthAddr}
                    validateFirst
                  >
                    <ZiSelect
                      label={t(
                        'za_invest_setup_idcard_page_form_label_birthAddr',
                        '出生地 (国家/地区)'
                      )}
                      options={countryOps}
                      readOnly={!editing}
                      // border={editing}
                      showArrow={editing}
                    />
                  </Form.Item>
                  <Form.Item
                    className={classnames([styles.noMarginBottom, styles.readonly])}
                    name={['country', 0]}
                    rules={rules.country}
                    validateFirst
                  >
                    {countryItemsDisable}
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    className={classnames(styles.formItem, { [styles.confirm]: !editing })}
                    name="birthAddr"
                    rules={rules.birthAddr}
                    validateFirst
                  >
                    <ZiSelect
                      label={t(
                        'za_invest_setup_idcard_page_form_label_birthAddr',
                        '出生地 (国家/地区)'
                      )}
                      options={countryOps}
                      readOnly={!editing}
                      // border={editing}
                      showArrow={editing}
                      canSearch
                    />
                  </Form.Item>
                  <Form.Item name="country" validateFirst>
                    {countryItems}
                  </Form.Item>
                </>
              )}

              {/* {editing && (
                <div className={styles.addCountryTip} onClick={addCountry}>
                  <PlusCircleOutlined className={styles.actionIcon} />
                  {t('za_invest_setup_idcard_page_add_country', '添加另一国籍')}
                </div>
              )} */}
            </div>

            {!editing && (
              <div className={styles.tip}>
                {t('za_invest_setup_idcard_page_is_wrong', '信息有误？')}
                <span onClick={onEdit}>
                  {t('za_invest_setup_idcard_page_immediately_change', '立即修改')}
                </span>
              </div>
            )}

            <div className={styles.horizontalMarginN8}>
              <Button
                className="zi-btn"
                type="primary"
                throttleTime={Timing.DEBOUNCE}
                block
                onClick={onCheck}
              >
                {t('za_invest_setup_idcard_page_confirm', '确认')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default IdCard
