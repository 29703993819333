import React, { useEffect } from 'react'
import { ZiProgressBar, ZiSelect, ZiInput, ZiCascader, SetupTitle } from '@/components'
import { Form } from 'antd'
import styles from './index.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SetupPageProps } from '@/common/types'
import { OpenStep } from '@/common/enums'
import { useSetState } from '@/hooks'
import { cloneDeep } from 'lodash'
import * as Schemas from '@/common/schemas'
import { selectCareerUserData, updateUserData } from '@/pages/setup/store/slices/career'
import { selectInitDic } from '@/pages/setup/store/slices/init'
import { Timing } from '@/common/enums/time.enum'
import { Button } from 'zati-ui-web'
const jobTypeWithCompany = ['A', 'B', 'C']

interface Props {
  nextPage: SetupPageProps['nextPage']
  submitSetup: (data: any) => Promise<unknown>
  selectCareerUserData: typeof selectCareerUserData
  selectInitDic: typeof selectInitDic
  updateUserData: typeof updateUserData
}

function Career(props: Props): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectInitDic, selectCareerUserData, updateUserData, submitSetup } = props
  const dictionary: any = useSelector(selectInitDic)
  const userData: Schemas.CareerType = useSelector(selectCareerUserData)
  const [state, setState] = useSetState({
    showCompany: jobTypeWithCompany.includes(userData.jobType),
    companyRequired: true
  })
  const { incomeSource = [], position = [], occupation = [], industry = [] } = dictionary
  const { showCompany, companyRequired } = state
  const industryList = cloneDeep(industry)
  industryList.forEach((item: { children: any }) => {
    item.children = position
  })
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(userData)
  }, [form, userData])

  useEffect(() => {
    setState({ companyRequired: form.getFieldValue('jobType') !== 'C' })
  }, [form, setState])

  const updateFiled = (payload: any): void => {
    const oldValue = form.getFieldsValue()
    const newValue = { ...oldValue, ...payload }
    const storeValue = cloneDeep(newValue)
    if (showCompany) {
      storeValue['industry'] = newValue.industry[0]
      storeValue['position'] = newValue.industry[1]
    }
    dispatch(updateUserData(storeValue))
    // 职业类型发生变化时，重置表单
    if (payload?.jobType) {
      form.resetFields()
    }
    form.setFieldsValue(newValue)
  }
  const industryChange = (val: any): void => {
    updateFiled({ industry: val })
  }
  const jobChange = (val: any): void => {
    // 自雇（C）行业与公司非必填
    setState({
      companyRequired: val !== 'C',
      showCompany: jobTypeWithCompany.includes(val)
    })
    const relativeFields = ['industry', 'companyName']
    relativeFields.forEach((field) => {
      if (form.getFieldError(field).length) {
        form.validateFields([field])
      }
    })
    updateFiled({ jobType: val })
  }

  const incomeChange = (val: string): void => {
    updateFiled({ moneySource: val })
  }

  const companyChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let companyName = ''
    if (e.currentTarget) {
      companyName = e.currentTarget.value
    }
    updateFiled({ companyName })
  }
  const onCheck = async (): Promise<void> => {
    const values = await form.validateFields()
    if (!values.moneySource) {
      return
    }
    let submitData = {
      ...values
    }
    if (showCompany) {
      submitData = {
        ...values,
        industry: values.industry[0],
        position: values.industry[1]
      }
    }
    await submitSetup({
      dataType: OpenStep.STEP_PROFESSION,
      openData: {
        [OpenStep.STEP_PROFESSION]: submitData
      }
    })
    props.nextPage()
  }

  let industryValue: string[] = []
  try {
    if (Array.isArray(userData.industry)) {
      industryValue = [...userData.industry]
    }
  } catch (error) {
    console.log('error', error)
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={2}
          title={t('za_invest_setup_career_page_progress_title')}
          progress={40}
        />
      </div>
      <div className={styles.careerBody}>
        <SetupTitle text={t('za_invest_setup_career_page_title', '職業資料及財富來源')} />
        <Form form={form} className={styles.careerForm}>
          <div className={styles.inputContainer}>
            <Form.Item
              name="jobType"
              rules={[
                { required: true, message: t('za_invest_setup_career_page_require_job_type') }
              ]}
            >
              <ZiSelect
                label={t('za_invest_setup_career_page_job_type')}
                onChange={jobChange}
                options={occupation}
              />
            </Form.Item>
            {showCompany && (
              <>
                <Form.Item
                  name="industry"
                  rules={[
                    {
                      required: companyRequired,
                      message: t('za_invest_setup_career_page_require_industry'),
                      validator(_, value) {
                        console.log(value, 'value')
                        if (!companyRequired || (Array.isArray(value) && value.length >= 2 && !value.includes(undefined))) {  // || value?.some((it: unknown) => it)
                          return Promise.resolve()
                        }
                        if (Array.isArray(value) && (value?.length < 2 || value.includes(undefined))) {
                          return Promise.reject(
                            new Error(t('za_invest_setup_career_page_require_industry_detail'))
                          )
                        }
                        return Promise.reject(
                          new Error(t('za_invest_setup_career_page_require_industry'))
                          )
                        }
                     }
                  ]}
                >
                  <ZiCascader
                    label={t('za_invest_setup_career_page_industry')}
                    onChange={industryChange}
                    options={industryList}
                    value={industryValue}
                  />
                </Form.Item>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: companyRequired,
                      message: t('za_invest_setup_career_page_require_company_name')
                    }
                  ]}
                >
                  <ZiInput
                    placeholder={t('za_invest_setup_career_page_company_name')}
                    maxLength={50}
                    onChange={companyChange}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              className={styles.formItem}
              name="moneySource"
              rules={[
                {
                  required: true,
                  message: t(
                    'za_invest_setup_finance_input_error1',
                    'Please input your moneySource!'
                  )
                }
              ]}
            >
              <ZiSelect
                onChange={incomeChange}
                label={t('za_invest_setup_finance_label1', '資金來源')}
                options={incomeSource}
              />
            </Form.Item>
          </div>
          <Form.Item className="footer-btn-box">
            <Button
              className="zi-btn"
              type="primary"
              throttleTime={Timing.DEBOUNCE}
              onClick={onCheck}
              block
            >
              {t('za_invest_setup_next_step')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Career
