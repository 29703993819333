export default {
  "annualIncome": [
    {
      "value": "1",
      "order": "1",
      "label": "< $120,001"
    },
    {
      "value": "2",
      "order": "2",
      "label": "$120,001 - $500,000"
    },
    {
      "value": "3",
      "order": "3",
      "label": "$500,001 - $1,000,000"
    },
    {
      "value": "4",
      "order": "4",
      "label": "$1,000,001 - $2,000,000"
    },
    {
      "value": "5",
      "order": "5",
      "label": "> $2,000,000"
    }
  ],
  "eduDegree": [
    {
      "value": "1",
      "order": "1",
      "label": "小學或以下"
    },
    {
      "value": "2",
      "order": "2",
      "label": "中學"
    },
    {
      "value": "3",
      "order": "3",
      "label": "大專或預科"
    },
    {
      "value": "4",
      "order": "4",
      "label": "大學或本科"
    },
    {
      "value": "5",
      "order": "5",
      "label": "碩士或以上"
    }
  ],
  "investInterested": [
    {
      "value": "1",
      "order": "1",
      "label": "潛在回報3%，潛在損失3%"
    },
    {
      "value": "2",
      "order": "2",
      "label": "潛在回報8%，潛在損失8%"
    },
    {
      "value": "3",
      "order": "3",
      "label": "潛在回報15%，潛在損失15%"
    },
    {
      "value": "4",
      "order": "4",
      "label": "潛在回報25%，潛在損失25%"
    },
    {
      "value": "5",
      "order": "5",
      "label": "潛在回報40%，潛在損失40%"
    }
  ],
  "investLimit": [
    {
      "value": "1",
      "order": "1",
      "label": "少於 1年"
    },
    {
      "value": "2",
      "order": "2",
      "label": "3年或以下"
    },
    {
      "value": "3",
      "order": "3",
      "label": "5年或以下"
    },
    {
      "value": "4",
      "order": "4",
      "label": "10年或以下"
    },
    {
      "value": "5",
      "order": "5",
      "label": "超過10年"
    }
  ],
  "investTarget": [
    {
      "value": "1",
      "order": "1",
      "label": '{"title":"Wealth Accumulation","content":"Maximize total return by all means with primary consideration being capital appreciation"}'
    },
    {
      "value": "2",
      "order": "2",
      "label": '{"title":"Wealth Accumulation","content":"Maximize total return by all means with primary consideration being capital appreciation"}'
    },
    {
      "value": "3",
      "order": "3",
      "label": '{"title":"Wealth Accumulation","content":"Maximize total return by all means with primary consideration being capital appreciation"}'
    }
  ],
  "isInvestDerivative": [
    {
      "value": "1",
      "order": "1",
      "label": "有"
    },
    {
      "value": "2",
      "order": "2",
      "label": "没有"
    }
  ],
  "isInvestFixed": [
    {
      "value": "1",
      "order": "1",
      "label": "有"
    },
    {
      "value": "2",
      "order": "2",
      "label": "没有"
    }
  ],
  "isInvestMutual": [
    {
      "value": "1",
      "order": "1",
      "label": "有"
    },
    {
      "value": "2",
      "order": "2",
      "label": "没有"
    }
  ],
  "isInvestSecurities": [
    {
      "value": "1",
      "order": "1",
      "label": "有"
    },
    {
      "value": "2",
      "order": "2",
      "label": "没有"
    }
  ],
  "needCash": [
    {
      "value": "1",
      "order": "1",
      "label": "100%"
    },
    {
      "value": "2",
      "order": "2",
      "label": "75%"
    },
    {
      "value": "3",
      "order": "3",
      "label": "50%"
    },
    {
      "value": "4",
      "order": "4",
      "label": "25%"
    },
    {
      "value": "5",
      "order": "5",
      "label": "0%"
    }
  ],
  "riskHandle": [
    {
      "value": "1",
      "order": "1",
      "label": "立即出售投資，止損離場"
    },
    {
      "value": "2",
      "order": "2",
      "label": "出售大部份的投資，並持有餘下的投資"
    },
    {
      "value": "3",
      "order": "3",
      "label": "賣出售小部份的投資，並持有餘下的投資"
    },
    {
      "value": "4",
      "order": "4",
      "label": "繼續持有投資，靜觀其變"
    },
    {
      "value": "5",
      "order": "5",
      "label": "增持投資以捕捉跌市機會"
    }
  ],
  "totalMoney": [
    {
      "value": "1",
      "order": "1",
      "label": "< $100,001"
    },
    {
      "value": "2",
      "order": "2",
      "label": "$100,001 - $500,000"
    },
    {
      "value": "3",
      "order": "3",
      "label": "$500,001 - $4,000,000"
    },
    {
      "value": "4",
      "order": "4",
      "label": "$4,000,001 - $8,000,000"
    },
    {
      "value": "5",
      "order": "5",
      "label": "> $8,000,000"
    }
  ]
}