import request from './lib/'
import * as Schemas from '@/common/schemas'
import { CardListResType, UserInfo } from '@/common/schemas/card-mgmt/index.interface'

export async function getUserRiskResult(data = {}): Promise<Schemas.RiskQueryResType> {
  return request('/user/risk/query', {
    method: 'post',
    data: data
  })
}

export async function submitUserRiskAnswer(data = {}): Promise<Schemas.RiskQueryResType> {
  return request('/user/risk/submit', {
    method: 'post',
    data: data
  })
}

export function getUserInfo(data = {}): Promise<UserInfo> {
  return request('/user/info/queryBaseMsg', {
    method: 'post',
    data: data
  })
}

export function getBankCardList(data = {}): Promise<CardListResType> {
  return request('/aggr/bank/list', {
    method: 'post',
    data: data
  })
}

export function deleteCard(bankCardId: string): Promise<any> {
  return request('/aggr/bank/unbind', {
    method: 'post',
    data: { cardId: bankCardId }
  })
}

export function bindCard(data = {}): Promise<any> {
  return request('/aggr/bank/bind', {
    method: 'post',
    data: data
  })
}

export function getIpoStatus(): Promise<any> {
  return request('/stock/easyIpoPlan/userJoinStatus', {
    method: 'post',
    data: {}
  })
}
// 获取交易状态
export function getTradeStatus(): Promise<any> {
  return request('/stock/easyIpoPlan/tradeStatus', {
    method: 'post',
    data: {}
  })
}
// 暂停交易
export function suspendTrade(instanceId: string): Promise<any> {
  return request('/stock/easyIpoPlan/suspendTrade', {
    method: 'post',
    data: { instanceId }
  })
}
// 恢复交易
export function recoverTrade(instanceId: string): Promise<any> {
  return request('/stock/easyIpoPlan/recoverTrade', {
    method: 'post',
    data: { instanceId }
  })
}
