import { useCallback } from 'react'
import { submitSetupInfo } from '@/service/accountCenter'
import { selectUserSetupInfo } from '@/pages/setup/store/slices/userSetupInfo'
import { useSelector, useDispatch } from 'react-redux'
import { updateRiskResult } from '@/pages/setup/store/slices/finance'

const jsonParseable = (val: any): boolean => {
  let res = true
  try {
    JSON.parse(val)
  } catch (error) {
    res = false
  }
  return res
}

function useSubmitSetup(): (data: any) => Promise<unknown> {
  const setupInfo = useSelector(selectUserSetupInfo)
  const dispatch = useDispatch()
  const callback = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        submitSetupInfo({
          openAccNo: setupInfo.openAccNo,
          ...data
        })
          .then((resp) => {
            if (resp?.riskMarkMsg) {
              dispatch(updateRiskResult(resp.riskMarkMsg))
            }
            resolve(resp)
          })
          .catch((error) => {
            reject(jsonParseable(error) ? JSON.parse(error) : error)
          })
      }),
    [dispatch, setupInfo.openAccNo]
  )
  return callback
}

export default useSubmitSetup
