export const defaultStep = [
  'index',
  'inviteMsg',
  'idPre',
  'selfIdentifyMsg',
  'addrMsg',
  'taxMsg',
  'professMsg',
  'riskMarkMsg',
  'riskMarkConfirm', //
  'otherMsgShow',
  'riskDisclosure', //
  'agreementAndSignature' //
]
// 开户进度
export enum OpenStep {
  // id type
  STEP_IDPRE = 'idPre',
  // id type
  STEP_IDTYPE = 'idType',
  // 用户身份信息
  STEP_IDENTIFY = 'identifyMsg',
  // 用户自己修改的用户信息
  STEP_SELFIDENTIFY = 'selfIdentifyMsg',
  // 居住地址信息
  STEP_ADDRESS = 'addrMsg',
  // 税务信息
  STEP_TAX = 'taxMsg',
  // 职业信息
  STEP_PROFESSION = 'professMsg',
  // 收入信息
  STEP_INCOME = 'incomeMsg',
  // 风险问卷
  STEP_RISK = 'riskMarkMsg',
  // 风险问卷结果确认
  STEP_RISK_MARK_CONFIRM = 'riskMarkConfirm',
  // 其他资料披露
  STEP_OTHER_SHOW = 'otherMsgShow',
  // 风险披露
  STEP_RISK_SHOW = 'riskDisclosure',
  // 协议与签名
  STEP_AGREEMENT = 'agreementAndSignature',
  // 提交开户审核
  STEP_SUBMIT = 'submitAudit',
  // 审核中
  STEP_WAITING = 'auditing',
  // 开户成功
  STEP_SUCCESS = 'success',
  // 开户失败
  STEP_FAIL = 'fail',
  // 雇主同意书
  STEP_EMPLOYER_AGREE = 'uploadEmployerAgree',
  // MGM 邀请码
  STEP_INVITE_CODE = 'inviteMsg',
  // 开户首页
  STEP_OPEN_ACCOUNT = 'index',

  // 上传身份证信息
  STEP_UPLOAD_ID_CARD = 'uploadIdCardMsg',
  // 确认身份信息 （内地开户）
  STEP_INLAND_IDENTIFY = 'selfInlandIdentifyMsg',
  // 补充身份信息
  STEP_ADDITIONAL_IDENTIFY = 'replenishIdentifyMsg',
  // 鉴权银行信息
  STEP_VALID_BANK = 'validBankMsg',
  // ocr活体识别
  STEP_CHECK_LIVING = 'checkLiving',
  // CA
  SETP_CA = 'ca'
}
