import { createSlice } from '@reduxjs/toolkit'
import * as Schemas from '@/common/schemas'

export const counterSlice = createSlice({
  name: 'validBank',
  initialState: {
    pageData: {
      authBankCardNo: '',
      reservedPhone: ''
    },
    caInfo: {
      caSerialNo: ''
    }
  },
  reducers: {
    updateValidBankInfo(state, action): void {
      state.pageData = action.payload || state.pageData
    },
    updateCaInfo(state, action): void {
      state.caInfo = action.payload || state.caInfo
    }
  }
})

export const { updateValidBankInfo, updateCaInfo } = counterSlice.actions

export const selectValidBankInfo = (state: any): Schemas.ValidBankMsg => state.validBank?.pageData

export const selectCaInfo = (state: any): Schemas.CaInfoType => state.validBank?.caInfo

export default counterSlice.reducer