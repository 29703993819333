import React, { useRef, useEffect } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'
import { Button } from 'zati-ui-web'
import { useSelector } from 'react-redux'
import { selectRejectInfo } from '@/pages/setup/store/slices/reject'
import { selectUserSetupInfo } from '@/pages/setup/store/slices/userSetupInfo'
import { OpenStatus, DelegationOpenStatus } from '@/common/enums/'
import { getLang, urlQuery } from '@/utils/'
import { JumpPath } from '@/common/enums/setup/pagePath.enum'
import { SetupPageProps } from '@/common/types'
import lottie from 'lottie-web'
import { callCloseWebview, navigateTo } from '@/service/nativeCall'
import { SpreadFlowers } from '@/components'
import classNames from 'classnames'
import { selectRiskMarkMsg } from '@/pages/setup/store/slices/finance'
import { selectOpenScope } from '@/pages/setup/store/slices/init'
interface RejectType {
  title: string
  message: string | React.ReactNode
  button?: string
  handler: (...arg: any) => void
  isFail?: boolean
}

const INVEST_ORIGIN = window.location.origin

const NotFound = () => {
  const onReload = () => window.location.reload()
  return (
    <div className="textAlignCenter">
      <div>404 Not Found!</div>
      <Button onClick={onReload}>reload page</Button>
    </div>
  )
}

interface ResultProps {
  nextPage: SetupPageProps['nextPage']
  selectRejectInfo: typeof selectRejectInfo
  selectUserSetupInfo: typeof selectUserSetupInfo
  selectRiskMarkMsg: typeof selectRiskMarkMsg
  selectOpenScope: typeof selectOpenScope
}

const openScopeFromEntrance = window.localStorage.getItem('openScope') || '' // 判断新股易入口还是基金入口
// 是否从新股易进入开户流程
const comeFromIpo = openScopeFromEntrance.includes('delegation') // 新股易入口

const openDelegation = () => {
  const url = `${INVEST_ORIGIN}/${getLang()}/${JumpPath.ENTRUSTEDACCOUNT}/`
  // console.log(url)
  window.location.href = url
}

function Result({
  nextPage,
  selectRejectInfo,
  selectUserSetupInfo,
  selectRiskMarkMsg,
  selectOpenScope
}: ResultProps): JSX.Element {
  const from = urlQuery('from') // 开户过程的结果
  const rejectInfo = useSelector(selectRejectInfo)
  const userSetupInfo = useSelector(selectUserSetupInfo)
  const riskMarkMsg = useSelector(selectRiskMarkMsg) // 风险等级判断是否是C1
  const openScope = useSelector(selectOpenScope) // 用于判断是一户双开还是单开
  const successAnimRef = useRef<HTMLDivElement>(null)
  const failureAnimRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { delegationOpenStatus, openStatus, isApplyDelegation } = userSetupInfo

  useEffect(() => {
    if (successAnimRef.current) {
      lottie.loadAnimation({
        container: successAnimRef.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: true,
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        animationData: require('../../../assets/img/lottie/success.json')
      })
    }
    if (failureAnimRef.current) {
      lottie.loadAnimation({
        container: failureAnimRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        animationData: require('../../../assets/img/lottie/failure.json')
      })
    }
  }, [openStatus])

  const toCashin = () => {
    navigateTo({
      url: `${INVEST_ORIGIN}/${getLang()}/${JumpPath.CASHIN}/`,
      hasHeader: true
    })
  }
  // 判断一户双开or 补开
  const isDelegation = openScope.includes('delegation')
  // openScope 不含 delegation，则视为补开流程；如果风险等级大于C1, 则展示补开全权委托按钮
  const showDelegationCard =
    !openScope.includes('delegation') &&
    riskMarkMsg.riskResultLevel !== '1' &&
    isApplyDelegation === 'N'

  // 1号牌结果页，提示开通9号牌，用户点击再跳9号牌流程
  const DelegationCard = () => {
    return (
      <div className={classNames(styles.footerCard, styles.delegationCard)}>
        <div className={styles.footerText}>
          <p className={styles.footerTitle}>
            {t('za_invest_setup_result_page_delegation_text_title', '立即開通新股易？')}
          </p>
          <p className={styles.footerSubTitle}>
            {t('za_invest_setup_result_page_delegation_sub_title', '新股易專家揀抽賣理財唔洗煩')}
          </p>
        </div>
        <Button type="primary" block className={styles.footerBtn} onClick={openDelegation}>
          {t('za_invest_setup_result_page_delegation_btn', '立即开通')}
        </Button>
        <p className={styles.cashin} onClick={toCashin}>
          {t('za_invest_setup_result_page_chahin_first', '暂不开通，去入金')}
        </p>
      </div>
    )
  }

  // 提交成功等待审核、9号牌开户成功， 审核中
  const renderWaiting = (message = t('za_invest_setup_result_page_title1'), showFlower = false) => {
    return (
      <>
        <div className="textAlignCenter">
          {/* 撒花效果 */}
          {showFlower && <SpreadFlowers />}
          <div ref={successAnimRef} className={styles.animIcon} key={openStatus} />
        </div>
        <h2 className={styles.title}>{message}</h2>
        {showDelegationCard ? ( // 展示提示开通9号牌或者推荐入金
          DelegationCard()
        ) : (
          <div className={styles.footerCard}>
            <div className={styles.footerText}>
              <p className={styles.footerTitle}>
                {t('za_invest_setup_result_page_footer_text_title')}
              </p>
            </div>
            <Button type="primary" block className={styles.footerBtn} onClick={toCashin}>
              {t('za_invest_setup_result_page_btn1', '快捷入金')}
            </Button>
          </div>
        )}
      </>
    )
  }

  // 审核拒绝
  const renderReject = ({ title, message, handler, button, isFail = false }: RejectType) => {
    return (
      <>
        <div className="textAlignCenter">
          <div ref={failureAnimRef} className={styles.animIcon} key={openStatus} />
        </div>
        <h2 className={styles.title}>{title}</h2>
        {isFail && (
          <p className={styles.desc}>
            {t('za_invest_setup_result_fail_message_1', '已入金资金3个工作日内退款')}
          </p>
        )}
        <div className={styles.desc}>{message}</div>
        <div className="footer-btn-box">
          <Button type="primary" onClick={handler} block>
            {button || t('za_invest_setup_result_back_to_home', '回到首页')}
          </Button>
        </div>
      </>
    )
  }

  const getRejectMessage = () => {
    const reasons = rejectInfo?.rejectReason?.split(';') || []
    if (reasons.length === 1 && reasons[0].length < 20) {
      return <div className="textAlignCenter">{reasons[0]}</div>
    }
    return reasons?.map((str) => (
      <p className={styles.reasonMsg} key={`${str}`}>
        {str}
      </p>
    ))
  }

  // 9号牌处理结果，isApplyDelegation === 'Y'时
  const renderDelagationResult = () => {
    switch (delegationOpenStatus) {
      case DelegationOpenStatus.OPENING: // 1
        openDelegation()
        return null
      case DelegationOpenStatus.AUDIT_WAITING: // 2
        return renderWaiting()
      case DelegationOpenStatus.REJECT: // 3
        return renderReject({
          title: t(
            'za_invest_setup_result_delegation_open_fail_title',
            '抱歉，我们暂时无法为你开通新股易'
          ),
          message: getRejectMessage(),
          handler: callCloseWebview
        })
      case DelegationOpenStatus.COMPLETE: // 4
        return renderWaiting(
          t('za_invest_setup_result_delegation_open_success_title', '开户审核成功'),
          true
        )
      default:
        // 所有条件都没有命中，避免出现空白页面
        return NotFound()
    }
  }

  const getDelegationDom = () => {
    if (isApplyDelegation === 'N') {
      openDelegation()
      return null
    }
    return renderDelagationResult()
  }

  // openStatus为2的处理
  const renderWaitingDom = () => {
    if (!isDelegation && comeFromIpo && isApplyDelegation === 'N') {
      // 新股易入口，单开
      openDelegation()
      return null
    }
    if (!isDelegation && (comeFromIpo || isApplyDelegation === 'Y')) {
      // 单开 && （新股易入口 || 基金入口且isApplyDelegation=Y）
      return renderDelagationResult()
    }
    return renderWaiting()
  }

  const getDom = () => {
    if (from) {
      // 开户过程跳转，以前流程
      return renderWaiting()
    }
    switch (openStatus) {
      case OpenStatus.AUDIT_WAITING: // 2
        return renderWaitingDom()
      case OpenStatus.COMPLETE: // 4
        return getDelegationDom()
      case OpenStatus.REJECT: // 3
        return renderReject({
          title: t('za_invest_setup_result_page_title2'),
          message: getRejectMessage(),
          handler: nextPage,
          button: t('za_invest_setup_result_page_btn_toFixed', '去修改')
        })
      case OpenStatus.FAIL: // 5
      case OpenStatus.AML_FAIL: // 99
        return renderReject({
          isFail: true,
          title: t('za_invest_setup_result_fail_title', '抱歉，暂时无法为你开户'),
          message: getRejectMessage(),
          handler: callCloseWebview
        })
      default:
        // 所有条件都没有命中，避免出现空白页面
        return NotFound()
    }
  }

  return <div className={styles.contentWrapper}>{openStatus && getDom()}</div>
}

export default Result
