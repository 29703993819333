import { ZiProgressBar, ZiInput, ZiDatePicker } from '@/components'
import classNames from 'classnames'
import React, { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import { Form } from 'antd'
import moment from 'moment'
import { useSetState, useSubmitMSetup } from '@/hooks'
import { selectIdentify, updateIdentify } from '../../store/slices/identify'
import { useDispatch, useSelector } from 'react-redux'
import { pick, forEach } from 'lodash'
import * as Schemas from '@/common/schemas'
import * as Enums from '@/common/enums'
import { Button, Radio } from 'zati-ui-web'
import { SetupPageProps } from '@/common/types'
import ZatiRadio from 'zati-ui-web/lib/Radio/Radio'
import { Timing } from '@/common/enums/time.enum'
import { reportCommonException } from '@/service/nativeCall'

const minValidDate = new Date('1900-01-01')
const maxValidDate = new Date(moment().add(100, 'years').subtract(1, 'days').format('YYYY-MM-DD'))
const maxBirthdate = new Date(
  moment().subtract(18, 'years').subtract(1, 'days').format('YYYY-MM-DD')
)

const dateKeys = ['birthdate', 'idStartDate', 'idValidDate']

const TERM = {
  SHORT: 'SHORT',
  LONG: 'LONG'
}

const LONG_TERM_VALUE = '长期'

function AdditionalIdentifyInfo({ nextPage }: SetupPageProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const identify = useSelector(selectIdentify)
  const [form] = Form.useForm()
  const [state, setState] = useSetState({
    visibleDateStart: false,
    visibleDateEnd: false,
    visibleBirthdate: false,
    term: identify.idValidDate === LONG_TERM_VALUE ? TERM.LONG : TERM.SHORT
  })
  const submitSetup = useSubmitMSetup()
  const defaultOptions = [
    { label: t('za_invest_msetup_additional_identify_short_term', '非长期'), value: TERM.SHORT },
    { label: t('za_invest_msetup_additional_identify_long_term', '长期'), value: TERM.LONG }
  ]
  useEffect(() => {
    const info = pick(identify, ['birthdate', 'idStartDate', 'idIssuedBy', 'idValidDate'])
    type InfoKey = keyof typeof info
    dateKeys.forEach((k) => {
      if (info[k as InfoKey]) {
        info[k as InfoKey] = moment(info[k as InfoKey]) as any
      }
    })
    // 长期身份证要重新赋值，避免出现日期格式化错误
    if (identify.idValidDate === LONG_TERM_VALUE) {
      info.idValidDate = t('za_invest_msetup_additional_identify_long_term', '长期')
    }
    form.setFieldsValue(info)
  }, [form, identify, t])

  const { visibleDateStart, visibleDateEnd, visibleBirthdate, term } = state

  const rules: Schemas.FormRuleList = {
    birthdate: [
      {
        required: true,
        message: t('za_invest_msetup_addtional_identify_required_birthdate', '请选择出生日期')
      },
      {
        validator(_, value) {
          if (moment(value).isAfter(form.getFieldValue('idStartDate'))) {
            return Promise.reject(
              Error(
                t(
                  'za_invest_msetup_addtional_identify_error_1',
                  '出生日期不能晚于身份证有效期（开始）'
                )
              )
            )
          }
          if (value) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error(t('za_invest_msetup_addtional_identify_required_birthdate', '请选择出生日期'))
          )
        }
      }
    ],
    idStartDate: [
      {
        required: true,
        message: t(
          'za_invest_msetup_addtional_identify_required_valid_start_date',
          '请输入证件有效期起始日期'
        )
      },
      {
        validator(_, value) {
          if (moment(value).isAfter(form.getFieldValue('idValidDate'))) {
            return Promise.reject(
              Error(
                t(
                  'za_invest_msetup_addtional_identify_error_2',
                  '身份证有效期（开始）不能晚于身份证有效期（结束）'
                )
              )
            )
          }
          if (value) {
            return Promise.resolve()
          }

          return Promise.reject(
            new Error(
              t(
                'za_invest_msetup_addtional_identify_required_valid_start_date',
                '请输入证件有效期起始日期'
              )
            )
          )
        }
      }
    ],
    idIssuedBy: [
      {
        required: true,
        message: t('za_invest_msetup_addtional_identify_required_issue_by', '请输入签发机关')
      }
    ],
    idValidDate: [
      {
        required: true,
        message: t(
          'za_invest_msetup_addtional_identify_required_valid_start_end',
          '请输入证件有效期到期时间	'
        )
      },
      {
        validator(_, value) {
          if (moment(value).isBefore(form.getFieldValue('idStartDate'))) {
            return Promise.reject(
              Error(
                t(
                  'za_invest_msetup_addtional_identify_error_3',
                  '身份证有效期（结束）不能早于身份证有效期（开始）'
                )
              )
            )
          }
          if (value) {
            return Promise.resolve()
          }

          return Promise.reject(
            new Error(
              t(
                'za_invest_msetup_addtional_identify_required_valid_start_end',
                '请输入证件有效期到期时间'
              )
            )
          )
        }
      }
    ]
  }

  const validateDate = () => {
    dateKeys.forEach((k) => {
      if (form.getFieldValue(k)) {
        form.validateFields([k])
      }
    })
  }

  const toggleVisibleStart = ({ visible }: any) => {
    setState({ visibleDateStart: visible })
    validateDate()
  }

  const toggleVisibleEnd = ({ visible }: any) => {
    setState({ visibleDateEnd: visible })
    validateDate()
  }

  const toggleVisibleBirthdate = ({ visible }: any) => {
    setState({ visibleBirthdate: visible })
    validateDate()
  }

  const submit = async () => {
    try {
      const values = await form.validateFields()

      forEach(values, (v: any, k: string) => {
        if (moment.isMoment(v)) {
          values[k] = v.format('YYYY-MM-DD')
          return
        }
        if (values[k] instanceof Date || /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(v)) {
          values[k] = moment(v).format('YYYY-MM-DD')
          return
        }
        if (/^[0-9]+$/g.test(values[k])) {
          values[k] = moment(new Date(v)).format('YYYY-MM-DD')
        }
      })

      if (term === TERM.LONG) {
        values.idValidDate = LONG_TERM_VALUE
      }

      dispatch(updateIdentify(values))

      await submitSetup({
        dataType: Enums.OpenStep.STEP_ADDITIONAL_IDENTIFY,
        openData: {
          [Enums.OpenStep.STEP_ADDITIONAL_IDENTIFY]: values
        }
      })
      nextPage()
    } catch (err) {
      reportCommonException('addionalIdentitify-onsubmit', err)
    }
  }
  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const val = ev.target.value
    setState({ term: val })
    if (val === TERM.LONG) {
      form.setFieldsValue({
        idValidDate: t('za_invest_msetup_additional_identify_long_term', '长期')
      })
    } else {
      form.setFieldsValue({ idValidDate: '' })
    }
  }

  return (
    <div className={classNames('contentWrapper', styles.page)}>
      <div className="commonPadding">
        <ZiProgressBar current={1} title={t('za_invest_setup_progressBar_01Title', '个人资料')} />
        <h2 className="page-title">
          {t('za_invest_msetup_addtional_identify_title', '请确认以下补充信息与您身份证信息一致')}
        </h2>
      </div>
      <Form form={form} className="panel">
        <Form.Item
          className={classNames(styles.formItem)}
          name="idIssuedBy"
          rules={rules.idIssuedBy}
          validateFirst
        >
          <ZiInput
            placeholder={t('za_invest_msetup_additional_identify_issuing_authority', '签发机关')}
          />
        </Form.Item>
        <div className={styles.termRadio}>
          <Radio.Group value={term} onChange={onChange}>
            {defaultOptions.map((item, index) => (
              <ZatiRadio key={index} {...item} />
            ))}
          </Radio.Group>
        </div>
        <Form.Item
          className={classNames(styles.formItem)}
          name="idStartDate"
          rules={rules.idStartDate}
          validateFirst
        >
          <ZiDatePicker
            rightArrow
            border
            onHandleClick={() => setState({ visibleDateStart: true })}
            onHandleOk={toggleVisibleStart}
            onHandleDismiss={toggleVisibleStart}
            minDate={minValidDate}
            maxDate={maxValidDate}
            mode="date"
            visible={visibleDateStart}
            format={(date: Date): string => moment(Number(date)).format('YYYY-MM-DD')}
            label={t(
              'za_invest_msetup_additional_identify_valid_date_start',
              '身份证有效期（开始）'
            )}
          />
        </Form.Item>
        {term === TERM.SHORT ? (
          <Form.Item
            className={classNames(styles.formItem)}
            name="idValidDate"
            rules={rules.idValidDate}
            validateFirst
          >
            <ZiDatePicker
              rightArrow
              border
              onHandleClick={() => setState({ visibleDateEnd: true })}
              onHandleOk={toggleVisibleEnd}
              onHandleDismiss={toggleVisibleEnd}
              minDate={minValidDate}
              maxDate={maxValidDate}
              mode="date"
              visible={visibleDateEnd}
              format={(date: Date): string => moment(Number(date)).format('YYYY-MM-DD')}
              label={t(
                'za_invest_msetup_additional_identify_valid_date_end',
                '身份证有效期（结束）'
              )}
            />
          </Form.Item>
        ) : (
          <Form.Item className={classNames(styles.formItem)} name="idValidDate">
            <ZiInput
              readOnly
              placeholder={t(
                'za_invest_msetup_additional_identify_valid_date_end',
                '身份证有效期（结束）'
              )}
            />
          </Form.Item>
        )}
        <Form.Item
          className={classNames(styles.formItem)}
          name="birthdate"
          rules={rules.birthdate}
          validateFirst
        >
          <ZiDatePicker
            rightArrow
            border
            onHandleClick={() => setState({ visibleBirthdate: true })}
            onHandleOk={toggleVisibleBirthdate}
            onHandleDismiss={toggleVisibleBirthdate}
            minDate={minValidDate}
            maxDate={maxBirthdate}
            mode="date"
            visible={visibleBirthdate}
            format={(date: Date): string => moment(Number(date)).format('YYYY-MM-DD')}
            label={t('za_invest_msetup_additional_identify_birthdate', '出生日期（YYYY-MM-DD）')}
          />
        </Form.Item>
      </Form>
      <div className="footer-btn-box">
        <Button type="primary" block onClick={submit} throttleTime={Timing.DEBOUNCE}>
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
      </div>
    </div>
  )
}

export default AdditionalIdentifyInfo
