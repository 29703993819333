export enum MoneyHandleStatus {
  // 处理中
  PROCESSING = '0',
  // 已到账
  RECEIVED = '1',
  // 已驳回
  REJECTED = '2',
  // 已汇出
  REMITTED = '3',
  // 已撤回
  WITHDRAW = '4'
}
