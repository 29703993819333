// import { devTokenMap } from './../../utils/requestConfig'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  getEnv,
  cookie,
  urlQuery,
  toggleLoading,
  toggleGlobalLoading,
  showError,
  getBaseUrl,
  getDeafultMsg
} from '@/utils/index'
import { SetupErrorCode, AuthErrorCode, TradeAuthErrorCode } from '@/common/enums/errorCode.enum'
import { ZiModal } from '@/components'
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
import {
  API_VERSION,
  pubkey,
  IV,
  MOCKTYPE,
  YAPI_MOCK_PREFIX,
  HMAC_KEY,
  APPKEY,
  APPNAME,
  CHANNELID,
  OS,
  TLENGTH,
  REQID
} from '@/common/enums/baseService.enum'
import { getCrypt } from '@/app.config'
import {
  callSystemInfo,
  callLogin,
  callTradePassword,
  callCloseWebview,
  reportCommonException,
  syncLogout
} from '../nativeCall'
import { v4 as uuidv4 } from 'uuid'
// import requestConfig from '@/utils/requestConfig'
import { addPending, removePending, clearAll } from './requestPending'
import { devTokenMap } from '@/common/enums'

interface enhanceAxiosRequestConfig extends AxiosRequestConfig {
  loading?: boolean
  notip?: boolean
  ignoreRepeat?: boolean
}

interface responseConfig extends AxiosRequestConfig {
  notip?: boolean
}

interface enhanceAxiosResponseConfig extends AxiosResponse {
  config: responseConfig
}

const isUatOrPrd = ['uat', 'prd'].includes(getEnv())

const getBackStage = (): string => cookie.get('backstage') || urlQuery('backstage')

const t = uuidv4().slice(0, TLENGTH)

export const needEncrypt = (url?: string, noEncryptPattern: string[] = []) => {
  let isNeed = true
  if (url) {
    const noEncryptUrl = ['filegw/file', ...noEncryptPattern]
    isNeed = !noEncryptUrl.some((item) => url.includes(item))
  }
  return isNeed
}

export const aesEncrypt = (data: any, t: string, IV: string): string => {
  const key = CryptoJS.enc.Latin1.parse(t)
  const iv = CryptoJS.enc.Latin1.parse(IV)
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC
    // padding: CryptoJS.pad.Pkcs7
  })
  return `${encrypted}`
}

export const aesDecrypt = (word: any, t: string, IV: string): string => {
  const key = CryptoJS.enc.Latin1.parse(t)
  const iv = CryptoJS.enc.Latin1.parse(IV)
  const decrypt = CryptoJS.AES.decrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC
    // padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

export const rsaEncrypt = (data: string, pubkey: string): string => {
  const encrypt = new JSEncrypt()

  encrypt.setPublicKey(pubkey)
  return encrypt.encrypt(data)
}

export const HmacSHA256Encrypt = (data: string, HMAC_KEY: string): string => {
  const hash = CryptoJS.HmacSHA256(data, HMAC_KEY)
  const hashInBase64 = CryptoJS.enc.Hex.stringify(hash)
  return hashInBase64
}

export const genUrl = (url: any, backstage: string): string => {
  if (backstage === MOCKTYPE.YAPI) {
    return `${YAPI_MOCK_PREFIX}${url}`
  } else if (backstage === MOCKTYPE.LOCAL) {
    return `${url}`
  } else {
    return getBaseUrl(url, getEnv())
  }
}

export const genSign = (opt: any, t: string): string => {
  let objMap: any = [
    'apiversion',
    'appkey',
    'body',
    'channelid',
    'did',
    'invtoken',
    'language',
    'os',
    'reqid',
    'timestamp',
    'token',
    'tt'
  ]
  objMap = objMap.sort().reverse()
  let waitSign = ''
  objMap.forEach((item: string) => {
    if (!opt[item]) {
      return
    }
    if (waitSign === '') {
      waitSign = `${item}=${opt[item]}`
    } else {
      waitSign = waitSign + `&${item}=${opt[item]}`
    }
  })
  waitSign += `&t=${t}`
  return HmacSHA256Encrypt(waitSign, HMAC_KEY)
}

export const getDid = (() => {
  let did = ''
  if (window?.localStorage.getItem('AppDid')) {
    did = window?.localStorage.getItem('AppDid') || ''
    return () => {
      return did
    }
  } else {
    return async () => {
      if (!did) {
        const data = await callSystemInfo()
        did = data.did
        window?.localStorage.setItem('AppDid', did)
      }
      return did
    }
  }
})()

const instance = axios.create({
  withCredentials: true, // 默认请求是否带上cookie
  timeout: 10000 // 默认10s timeout
})

instance.interceptors.request.use((config: enhanceAxiosRequestConfig) => {
  let notNeedLoading = false
  if (!config.cancelToken) {
    config.cancelToken = new axios.CancelToken((cancel) => {
      const callback = (url: string) => {
        notNeedLoading = true
        cancel(
          JSON.stringify({
            ignoreRepeat: config.ignoreRepeat || false, // 控制是否忽略错误，即不上报
            info: url
          })
        )
      }
      removePending(config, callback)
      addPending(config)
    })
  }
  if (!notNeedLoading && (!config.hasOwnProperty('loading') || config.loading)) {
    toggleLoading(true)
  }
  return config
})

instance.interceptors.request.use(async (configs) => {
  const isCrypt = getCrypt()
  const { url, headers, data, ...options } = configs
  const tt = rsaEncrypt(t, pubkey)
  const reqid = uuidv4().slice(0, REQID)
  const timestamp = Date.now()
  const body =
    isCrypt && needEncrypt(url) ? aesEncrypt(JSON.stringify(data), t, IV) : JSON.stringify(data)
  const did = await getDid()
  const reqHeaders: any = {
    apiversion: API_VERSION,
    appkey: APPKEY,
    appname: APPNAME,
    channelid: CHANNELID,
    language: ((): string => {
      const pathname = location.pathname ? location.pathname : ''
      const languagePrefix = pathname.split('/')[1]
      switch (languagePrefix) {
        case 'en':
          return 'US_en'
        case 'hk':
          return 'CN_hk'
        case 'cn':
          return 'CN_zh'
        default:
          return 'CN_hk'
      }
    })(),
    os: isCrypt ? OS : null,
    reqid: reqid,
    timestamp: timestamp,
    tt: tt,
    invtoken: cookie.get('invToken') || (isUatOrPrd ? '' : devTokenMap[getEnv()]),
    // 改过名字: ssoToken -> token
    token: cookie.get('ssoToken') || cookie.get('token') || (isUatOrPrd ? '' : 'test')
  }
  if (did) {
    reqHeaders.did = did
  }

  return {
    ...options,
    headers: {
      ...headers,
      ...reqHeaders,
      sign: genSign(
        {
          ...reqHeaders,
          body: body
        },
        t
      ),
      'content-Type': 'application/json'
    },
    url: genUrl(url, getBackStage()),
    data: body
  }
})

instance.interceptors.response.use(
  async (response: enhanceAxiosResponseConfig) => {
    removePending(response.config)
    if (!response?.config.hasOwnProperty('loading') || (response as any)?.config?.loading) {
      toggleLoading(false)
    }
    const isCrypt = getCrypt()
    const { headers } = response
    let { data } = response
    // 这里需要要求后端加上 Access-Control-Expose-Headers: respenc
    if (Number(headers.respenc) === 1 && isCrypt && needEncrypt(response?.config?.url)) {
      data = aesDecrypt(data, t, IV)
      data = JSON.parse(data)
    }
    // 文件流下载直接返回
    const contentType = response?.headers?.['content-type']
    if (
      /fileDownload/.test(response?.config?.url || '') ||
      response?.config?.url?.includes('file/getFile') || // getFile貌似没有用到
      contentType.includes('image/') ||
      contentType === 'application/pdf'
    ) {
      return data
    }
    // 错误信息处理
    if (data?.hasOwnProperty('code') && Number(data.code) !== 0 && !getBackStage()) {
      // 开户单号过期，则reaload整个应用
      // Performance &&
      //   (Performance as any).addError({
      //     msg: data.code + ':' + data.message + '-:' + response?.config?.url + ':--:' + data.respId,
      //     resourceUrl: window.location.href
      //   })
      reportCommonException(
        'openAccNo expire',
        Error(`${data.code}:${data.message}-:${response?.config?.url}:--:${data.respId}`)
      )
      if (data.code === SetupErrorCode.EXPIRE) {
        const msgObj = getDeafultMsg()
        ZiModal.alert(msgObj.tips, data.message, [
          {
            text: msgObj.btnText,
            onPress: (): void => {
              location.reload()
            }
          }
        ])
      } else if (AuthErrorCode.indexOf(data.code) !== -1) {
        reportCommonException('request error', data.code)
        syncLogout() // app新提供的方法
        await callLogin()
        callCloseWebview() // 重新登陆后关闭webview
      } else if (TradeAuthErrorCode.indexOf(data.code) !== -1) {
        callTradePassword()
      } else {
        reportCommonException('request error', data.message)
        // 避免初始化接口报错被全局loading覆盖的问题
        toggleGlobalLoading(false)
        const { showErrorModal = true } = response.config as any
        if (showErrorModal) {
          console.log(showErrorModal)
          showError({ message: data.message })
        }
      }
      return Promise.reject(data)
    }
    return data.data
  },
  (err: Error): Promise<Error> => {
    clearAll()
    if (axios.isCancel(err)) {
      const msg = JSON.parse(err.message)
      msg.ignoreRepeat !== true && reportCommonException('repeated request', err)
    } else {
      reportCommonException('request error', err)
      toggleGlobalLoading(false)
      toggleLoading(false)
      showError('')
    }
    return Promise.reject(err)
  }
)

export default instance
