import { createSlice } from '@reduxjs/toolkit'
import { RejectInfoType } from '@/common/schemas/setup/account.interface'

interface RejectState {
  reject: {
    rejectInfo: RejectInfoType
  }
}

export const slice = createSlice({
  name: 'reject',
  initialState: {
    rejectInfo: {
      rejectStep: [],
      rejectReason: ''
    }
  },
  reducers: {
    updateRejectInfo(state, action): void {
      state.rejectInfo = action.payload
    }
  }
})

export const { updateRejectInfo } = slice.actions

export const selectRejectInfo = (state: RejectState): RejectInfoType => state.reject.rejectInfo

export default slice.reducer
