import React, { useEffect } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ZiProgressBar, ZiInput, NextBtn, SetupTitle, ZiModal } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import styles from './index.module.scss'
import { useSubmitMSetup } from '@/hooks'
import * as Schemas from '@/common/schemas'
import * as Enums from '@/common/enums'
import {
  selectValidBankInfo,
  updateValidBankInfo,
  updateCaInfo
} from '../../store/slices/validBank'
import { selectUserSetupInfo } from '../../store/slices/userSetupInfo'
import { selectRoutes } from '../../store/slices/routes'
import { reportCommonException, startSelfie } from '@/service/nativeCall'
import { getCertDN } from '../../utils'
import { commonReg } from '@/utils'
const alert = ZiModal.alert

function AuthIdentity({ nextPage, jumpPage }: any): JSX.Element {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const submitSetup = useSubmitMSetup()
  const initialValues = useSelector(selectValidBankInfo)
  const routes: string[] = useSelector(selectRoutes)
  const setupInfo = useSelector(selectUserSetupInfo)
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])
  const onSubmit = async (submitData: Schemas.ValidBankMsg): Promise<void> => {
    await submitSetup({
      dataType: Enums.OpenStep.STEP_VALID_BANK,
      openData: { validBankMsg: submitData }
    })
    dispatch(updateValidBankInfo(submitData))
    // 判断是否需要活体识别
    if (routes.includes(Enums.OpenStep.STEP_CHECK_LIVING)) {
      // 调取活体识别api
      console.log('调用了活体识别api', setupInfo.openAccNo)
      try {
        const res = await startSelfie({ params: { bizNo: setupInfo.openAccNo } })
        // 某些iphone机型直接上传会失败
        await new Promise((resolve) => {
          window.setTimeout(resolve, 100)
        })
        if (res?.data?.result) {
          // 活体识别请求成功， 继续请求证书操作
          getCertDN(setupInfo.openAccNo, (caSerialNo) => {
            console.log(caSerialNo, ' caSerialNo')
            if (caSerialNo) {
              // 如接口异常，caSerialNo为undefined
              dispatch(updateCaInfo({ caSerialNo }))
            }
            nextPage()
          })
        }
      } catch (e) {
        // 上报错误
        // 0004——跳转到身份证上传页面
        // -1——抛出异常错误
        reportCommonException('validBank-ocrInfo', e)
        if (e?.code === '0004') {
          // 跳转至身份证上传页
          jumpPage({ targetPage: Enums.PagePathSetup.PAGE_UPLOAD_ID_CARD })
        }
        if (e?.code === '-1') {
          // -10, -11,-12, -13为app点击返回健等正常逻辑抛出的错误
          alert(null, t('4541_msetup_network_err', '网络异常，请重试'), [
            {
              text: t('4541_msetup_know', '知道了'),
              onPress: (): void => {
                return
              }
            }
          ])
        }
      }
    } else {
      nextPage()
    }
  }

  const onCheck = async (): Promise<void> => {
    try {
      const values = (await form.validateFields()) as Schemas.ValidBankMsg
      onSubmit(values)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo)
      reportCommonException('validBank-onCheck', errorInfo)
    }
  }

  const rules = {
    authBankCardNo: [
      {
        required: true,
        message: t('4541_msetup_authBankCardNo_error', '请输入银行卡号')
      }
    ],
    reservedPhone: [
      {
        pattern: commonReg.phone,
        message: t('4541_msetup_reservedPhone_error2', '请输入正确的银行预留手机号码')
      }
    ]
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="progress-wrapper">
        <ZiProgressBar
          current={1}
          title={t('za_invest_setup_progressBar_01Title', '个人资料')}
          progress={60}
        />
      </div>
      <div className={styles.authBody}>
        <SetupTitle text={t('4541_msetup_auth_page_title', '认证身份信息')} />
        <div className={styles.formContainer}>
          <Form form={form}>
            <div className={styles.inputContainer}>
              <div>
                <Form.Item
                  className={styles.formItem}
                  name="authBankCardNo"
                  validateFirst
                  rules={rules.authBankCardNo}
                >
                  <ZiInput
                    placeholder={t('4541_msetup_cardnumber', '银行卡号')}
                    type="tel"
                    maxLength={30}
                  />
                </Form.Item>
                <p className={styles.cardTips}>
                  {t('4541_msetup_card_tips', '仅限银联借记卡，信息用于验证身份。')}
                </p>
              </div>
              <Form.Item
                className={styles.formItem}
                name="reservedPhone"
                validateFirst
                rules={rules.reservedPhone}
              >
                <ZiInput
                  placeholder={t('4541_msetup_bank_phonenumber', '银行预留手机号码')}
                  type="tel"
                  maxLength={11}
                />
              </Form.Item>
            </div>
            <NextBtn onClick={onCheck} className="commonPadding" />
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AuthIdentity
