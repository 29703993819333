import { useCallback } from 'react'
import { submitMSetupInfo } from '@/service/accountCenter'
import { selectUserSetupInfo } from '@/pages/msetup/store/slices/userSetupInfo'
import { useSelector } from 'react-redux'
import { jsonParseable } from '@/utils'

function useSubmitSetup(): (data: any) => Promise<unknown> {
  const setupInfo = useSelector(selectUserSetupInfo)

  const callback = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        submitMSetupInfo({
          openAccNo: setupInfo.openAccNo,
          ...data
        })
          .then((resp) => {
            resolve(resp)
          })
          .catch((error) => {
            reject(jsonParseable(error) ? JSON.parse(error) : error)
          })
      }),
    [setupInfo.openAccNo]
  )
  return callback
}

export default useSubmitSetup
