/** 碎片枚举数据 */

// 布尔类型
export enum LocalBool {
  FALSE,
  TRUE
}

// 字符串布尔值
export enum StringBoolean {
  TRUE = '1',
  FALSE = '0'
}

export enum StringBooleanRaw {
  TRUE = 'true',
  FALSE = 'false'
}

export enum StringBoolAlias {
  TRUE = 'Y',
  FALSE = 'N'
}

export enum StringOptionLetter {
  LETTER_A = 'A',
  LETTER_B = 'B',
  LETTER_C = 'C',
  LETTER_D = 'D',
  LETTER_E = 'E',
  LETTER_F = 'F'
}

export enum StringJudgeStatus {
  SUCCESS = 'success',
  FAILURE = 'failure'
}
