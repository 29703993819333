import request from './lib/'
import * as Schemas from '@/common/schemas'

export function getAccountProtocolList(data = {}): any {
  return request('/account/protocol/list', {
    method: 'post',
    data
  })
}

// 获取开户流水单
export async function getOpenAccNo(
  data: Schemas.OpenAccNoReqType
): Promise<Schemas.OpenAccNoResType> {
  return request('/account/open/apply', {
    method: 'post',
    data
  })
}

// 开户提交接口
export async function submitSetupInfo(data: any): Promise<any> {
  return request('/aggr/hk/fund/open/submit', {
    method: 'post',
    data
  })
}

// 获取开户资料
export async function getAccountOpenInfo(data = {}): Promise<Schemas.AccountInfoResType> {
  return request('/aggr/hk/fund/open/query', {
    method: 'post',
    data
  })
}

// 开户提交接口（内地）
export async function submitMSetupInfo(data: any): Promise<any> {
  return request('/aggr/zh/fund/open/submit', {
    method: 'post',
    data
  })
}

// 获取开户资料（内地）
export async function getMSetupAccountOpenInfo(data = {}): Promise<Schemas.AccountInfoResType> {
  return request('/aggr/zh/fund/open/query', {
    method: 'post',
    data
  })
}

// ocr-提交证件识别接口
export async function submitToRecognize(
  data: Schemas.OcrSubmitToRecognizeReq,
  loading: boolean
): Promise<Schemas.SubmitToRecognizeRes> {
  return request('/account/ocr/submitToRecognize', {
    method: 'post',
    data,
    loading
  })
}

// ocr-查询证件识别结果接口
export async function queryRecognizeResult(
  data: Schemas.OcrQueryRecognizeResultReq,
  loading: boolean
): Promise<Schemas.QueryRecognizeResultRes> {
  return request('/account/ocr/queryRecognizeResult', {
    method: 'post',
    data,
    loading
  })
}

/**
 * 上传渠道号接口
 * @param data
 * @returns
 */
export async function uploadOpenChannel(data: Schemas.OpenUploadOpenChannelReq): Promise<any> {
  return request('/account/open/uploadOpenChannel', {
    method: 'post',
    data,
    showErrorModal: false
  })
}

// 获取开户信息
export async function getOpenQueryInfo(data = {}): Promise<any> {
  return request('/account/open/query/submit/info', {
    method: 'post',
    data
  })
}
