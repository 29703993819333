import { combineReducers } from '@reduxjs/toolkit'

import routesReducer from './routes'
import idCardReducer from './idCard'
import riskShowReducer from './riskShow'
import initReducer from './init'
import careerReducer from './career'
import otherDataReducer from './otherData'
import userSetupInfoReducer from './userSetupInfo'
import rejectReducer from './reject'
import addressReducer from './address'
import incomeReducer from './finance'
import uploadConsentReducer from './uploadConsent'
import taxationReducer from './taxation'
import identifyReducer from './identify'
import validBankReducer from './validBank'
import uploadCardReducer from './uploadCard'

export default combineReducers({
  routes: routesReducer,
  idCard: idCardReducer,
  riskShow: riskShowReducer,
  init: initReducer,
  career: careerReducer,
  otherData: otherDataReducer,
  userSetupInfo: userSetupInfoReducer,
  reject: rejectReducer,
  address: addressReducer,
  income: incomeReducer,
  uploadConsent: uploadConsentReducer,
  taxation: taxationReducer,
  identify: identifyReducer,
  validBank: validBankReducer,
  uploadCard: uploadCardReducer
})
