import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enResource from '@/locales/en_US.json'
import hkResource from '@/locales/zh_HK.json'
import cnResource from '@/locales/zh_CN.json'
import keyResource from '@/locales/KEY.json'
import axios from 'axios'
import { getEnv, getLang } from '@/utils/index'
import { SEAGULL_HOST } from '@/common/enums/host.enum'

const i18nKeyMap: any = {
  en: 'en_US',
  hk: 'zh_HK',
  cn: 'zh_CN',
  key: 'KEY'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: enResource
      },
      hk: {
        translations: hkResource
      },
      cn: {
        translations: cnResource
      },
      key: {
        translations: keyResource
      }
    },
    fallbackLng: 'hk',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
    react: {
      bindI18n: 'loaded'
    }
  })

export async function updateI18nWords(i18nKeyMap: any, lang: string): Promise<void> {
  // Object.keys(i18nKeyMap).forEach(async (attr) => {
  const result = await axios.get(
    `https://${
      SEAGULL_HOST[getEnv()]
    }/seagull/i18n-request?spaceName=za-invest-portal-web&languageType=${i18nKeyMap[lang]}`
  )
  if (result.status === 200 && result.data.success && result.data.code === 200) {
    i18n.addResources(lang, 'translations', result.data.data.contentMap)
    i18n.emit('loaded')
  }
  // })
}

;(async () => {
  const lang = getLang()
  console.log('getLang---' + lang)
  // 动态更新当前语言就可以了
  await updateI18nWords(i18nKeyMap, lang)
})()

export default i18n
