import React from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
export interface LoadingPage {
  className?: string
  style?: React.CSSProperties
  show?: boolean
}

const LoadingPage = (props: LoadingPage): JSX.Element | null => {
  const { className, style, show = false } = props
  if (!show) {
    return null
  }

  const zaLoading = window.loadingGifBase64

  return (
    <div className={classnames(styles.container, className)} style={style}>
      <span className={styles.loadingImgBox}>
        <img src={zaLoading} alt="loading" />
      </span>
    </div>
  )
}
export default LoadingPage
