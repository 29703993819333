/**
 * 开户状态
 */
export enum OpenStatus {
  /**
   * 进行中
   */
  OPENING = '1',
  /**
   * 开户成功
   */
  COMPLETE = '4',
  /**
   * 开户失败
   */
  FAIL = '5',
  /**
   * 审核已驳回
   */
  REJECT = '3',
  /**
   * 等待审核
   */
  AUDIT_WAITING = '2',
  /**
   * AML 命中
   */
  AML_FAIL = '99'
}

/**
 * 全权委托开户状态
 */
export enum DelegationOpenStatus {
  /**
   * 开户中
   */
  OPENING = '1',
  /**
   * 等待审核
   */
  AUDIT_WAITING = '2',
  /**
   * 审核已驳回
   */
  REJECT = '3',
  /**
   * 开户成功
   */
  COMPLETE = '4'
}
