import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

interface Init {
  cardMsg: {
    idFaceFileKey: string
    idNationalFileKey: string
  }
}

interface InitState {
  uploadCard: Init
}

export const counterSlice = createSlice({
  name: 'uploadCard',
  initialState: {
    cardMsg: {
      idFaceFileKey: '',
      idNationalFileKey: ''
    }
  },
  reducers: {
    updateUploadCard(state, action): void {
      if (!isEmpty(action.payload)) {
        state.cardMsg = {
          ...state.cardMsg,
          ...action.payload
        }
      }
    }
  }
})

export const { updateUploadCard } = counterSlice.actions

export const selectUploadCard = (state: InitState) => state.uploadCard.cardMsg

export default counterSlice.reducer
