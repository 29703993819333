export enum MoneyRecordType {
  // 全部
  ALL = 'all',
  // 入金
  CASHIN = '11',
  // 出金
  CASHOUT = '12',
  // 换汇
  EXCHANGE = '13'
}
