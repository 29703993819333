import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
  useRef
} from 'react'
import classnames from 'classnames'
import { Row, Col } from 'antd'
import { useSetState } from '@/hooks'
import './index.scss'
import { CurrencyType } from '@/common/enums/currencyType.enum'
import { isIOS, formatMoney } from '@/utils'
import { List, InputItem } from 'antd-mobile'
import { ZiSelect } from '@/components'
import Icon from '@ant-design/icons'
import { ReactComponent as IconHkd } from '@/assets/img/cashin/icon-hkd.svg'
import { ReactComponent as IconUsd } from '@/assets/img/cashin/icon-usd.svg'
import { ReactComponent as IconArrow } from '@/assets/img/cashin/icon-arrow-down.svg'
import { SelectHandles } from '@/components/Select'

const currencyList = [
  {
    value: 'HKD',
    label: 'HKD',
    disabled: true,
    icon: <Icon component={IconHkd} className="money-input-currency-icon" />
  },
  {
    value: 'USD',
    label: 'USD',
    disabled: true,
    icon: <Icon component={IconUsd} className="money-input-currency-icon" />
  }
]

const onlyHkdCurrencyList = [
  {
    value: 'HKD',
    label: 'HKD',
    disabled: true,
    icon: <Icon component={IconHkd} className="money-input-currency-icon" />
  }
]

const onlyUSdCurrencyList = [
  {
    value: 'USD',
    label: 'USD',
    disabled: true,
    icon: <Icon component={IconUsd} className="money-input-currency-icon" />
  }
]

const moneyRegx = /^\d+((\.)?\d{0,2})?$/
const zeroStartRegx = /^00[0-9]*$/
const MAX_NUMBER = 100000000
const TRANSVALUE = 999999

export interface MoneyInputProps {
  className?: string
  inputProps?: unknown
  options?: any[]
  onChange?: (vals: { inputValue: string; selectValue: string }) => void
  onInputChange?: (val: string) => void
  onSelectChange?: (val: string) => void
  errMsg?: string
  initSelectValue?: string
  defaultValue?: any
  label?: string
  //  true => select选择币种不可选 false => 可选
  disabledSelect?: boolean
  onlyHkd?: boolean
  onlyOneType?: string
  onBlur?: (val: string) => void
}

export interface MoneyInputState {
  isFocus: boolean
  inputValue: string
  inputValueRaw: string
  selectValue: string
  openCurrency: boolean
}

export interface MoneyInputHandles {
  updateState: (state: Partial<MoneyInputState>) => void
}

const isIPhone = /\biPhone\b|\biPod\b/i.test(window.navigator.userAgent)
let moneyKeyboardWrapProps: any
if (isIPhone) {
  moneyKeyboardWrapProps = {
    onTouchStart: (e: Event): void => e.preventDefault()
  }
}

const MoneyInput: ForwardRefRenderFunction<MoneyInputHandles, MoneyInputProps> = (
  props: MoneyInputProps,
  ref
): JSX.Element => {
  let {
    className,
    inputProps,
    defaultValue,
    options = currencyList,
    onChange,
    onInputChange,
    onSelectChange,
    onBlur,
    errMsg,
    initSelectValue,
    label,
    disabledSelect,
    onlyHkd = false,
    onlyOneType = ''
  } = props
  if (onlyOneType === 'HKD') {
    options = onlyHkdCurrencyList
  } else if (onlyOneType === 'USD') {
    options = onlyUSdCurrencyList
  }
  if (onlyHkd) {
    options = onlyHkdCurrencyList
  }
  const [state, setState] = useSetState<MoneyInputState>({
    isFocus: false,
    inputValue: '',
    inputValueRaw: '',
    selectValue: initSelectValue || CurrencyType.CURRENCY_HKD,
    openCurrency: false
  })
  const { inputValue, inputValueRaw, isFocus, selectValue, openCurrency } = state
  const selectRef = useRef<SelectHandles>(null)
  useEffect(() => {
    setState({
      inputValue: formatMoney(defaultValue),
      inputValueRaw: defaultValue
    })
  }, [defaultValue, setState])

  useEffect(() => {
    if (initSelectValue) {
      setState({ selectValue: initSelectValue })
    }
  }, [initSelectValue, setState])

  useImperativeHandle(ref, () => ({
    updateState(state: Partial<MoneyInputState>): void {
      state.inputValue = formatMoney(Number(state.inputValueRaw as string))
      setState(state)
    }
  }))

  const onInputFocus = (): void => {
    setState({
      isFocus: true,
      inputValue: inputValueRaw
    })
  }

  const onInputBlur = (): void => {
    setState({
      isFocus: false,
      inputValue: inputValueRaw ? formatMoney(Number(inputValueRaw)) : inputValue
    })
    onBlur?.(inputValue)
  }

  const handleSelectChange = (val: string): void => {
    setState({
      selectValue: val
    })
    onSelectChange?.(val)
    onChange?.({
      inputValue: inputValueRaw,
      selectValue: val
    })
  }

  const moneyChange = (val: string): void => {
    if (
      (moneyRegx.test(val) || val === '') &&
      !zeroStartRegx.test(val) &&
      Number(val) < MAX_NUMBER
    ) {
      setState({
        inputValueRaw: val,
        inputValue: val
      })
      onInputChange?.(val)
      onChange?.({
        inputValue: val,
        selectValue
      })
    } else {
      setState({
        inputValue: inputValueRaw
      })
      onChange?.({
        inputValue: inputValueRaw,
        selectValue
      })
    }
  }

  const clickCurrency = (): void => {
    if (options.length <= 1 || disabledSelect) return
    if (selectRef?.current) {
      selectRef.current.openSelect()
      setState({
        openCurrency: true
      })
    }
  }
  const handleSelectClose = (): void => {
    setState({ openCurrency: false })
  }

  return (
    <div className="money-input-wrapper">
      <div className={classnames(className, 'money-input', { 'money-input-focused': isFocus })}>
        <div className="money-input-label">{label}</div>
        <Row
          className={classnames('money-input-content', {
            'money-input-content-little': Number(inputValueRaw) > TRANSVALUE
          })}
        >
          <Col
            span={options.length > 1 ? 6 : 4}
            className="money-input-currency"
            onClick={clickCurrency}
          >
            <span data-testid="currencyValue">{selectValue}</span>
            {options.length > 1 && (
              <Icon
                component={IconArrow}
                className={classnames('money-input-icon-arrow', {
                  'money-input-icon-arrow-active': openCurrency
                })}
              />
            )}
          </Col>
          <Col span={18} className={classnames('money-input-wrapper-input', { 'is-ios': isIOS })}>
            <List>
              <InputItem
                {...inputProps}
                onChange={moneyChange}
                moneyKeyboardAlign="left"
                onBlur={onInputBlur}
                onFocus={onInputFocus}
                value={inputValue}
                type="money"
                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
              />
            </List>
          </Col>
        </Row>
      </div>
      {errMsg && <p className="err-msg">{errMsg}</p>}
      <ZiSelect
        ref={selectRef}
        showArrow={false}
        value={selectValue}
        options={options}
        onChange={handleSelectChange}
        onClose={handleSelectClose}
        renderItem={(item): React.ReactNode => (
          <>
            {item.icon}
            {item.label}
          </>
        )}
        hideLabel
      />
    </div>
  )
}

export default forwardRef(MoneyInput)
