import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import './index.scss'
import Icon from '@ant-design/icons'
import { ReactComponent as IconClose } from '@/assets/img/icon-close.svg'
import { ReactComponent as IconBack } from '@/assets/img/icon-back.svg'

export interface ActionSheetProps {
  className?: string
  show?: boolean
  onClose?: () => void
  onCancel?: () => void
  children?: React.ReactNode
  mode?: 'top-bottom' | 'bottom-top' | 'center'
  closeOnClickModal?: boolean
  showClose?: boolean
  header?: React.ReactNode
  contentTop?: string
  showBack?: boolean
  onBack?: () => void
}
function ActionSheet(props: ActionSheetProps): JSX.Element | null {
  const {
    className,
    show = false,
    showClose = true,
    onClose,
    onCancel,
    children,
    closeOnClickModal = true,
    mode = '',
    header,
    contentTop = '0',
    showBack = false,
    onBack
  } = props
  const [visible, setVisible] = useState(show)
  let container = document.querySelector('.action-sheet-container')
  if (!container) {
    container = document.createElement('div')
    container.classList.add('action-sheet-container')
    document.body.appendChild(container)
  }

  const handleCancel = (): void => {
    setVisible(false)
    onCancel?.()
    onClose?.()
  }

  const handleBack = (): void => {
    onBack?.()
  }

  const clickModal = (): void => {
    if (!closeOnClickModal) return
    handleCancel()
    setTimeout(() => {
      if (container) {
        document.body.removeChild(container)
      }
    }, 0)
  }

  useEffect(() => {
    setVisible(show)
  }, [show])

  // 解决弹窗时的滚动穿透和滚动条回到顶部的问题
  useEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement
    const recovery = () => {
      body.style.position = ''
      const top = body.style.top
      document.documentElement.scrollTop = document.body.scrollTop = -parseInt(top, 10)
      body.style.top = ''
    }
    if (!visible) {
      recovery()
    } else {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      body.style.top = `-${scrollTop}px`
      body.style.position = 'fixed'
    }
    return () => {
      body.style.position = ''
    }
  }, [visible])


  const renderHeaderIcon = () => {
    const showHeaderIcon = showClose || showBack

    if (!showHeaderIcon) {
      return null
    }
    const icon = showClose ? IconClose : IconBack
    const handleIconClick = () => {
      if (showClose) {
        handleCancel()
      }
      if (showBack) {
        handleBack()
      }
    }
    return (
      <div className="action-sheet-close">
        <Icon component={icon} className="active-sheet-off-btn" onClick={handleIconClick} />
      </div>
    )
  }

  const Comp: JSX.Element = (
    <div className={classnames('action-sheet', className)}>
      <div className={classnames('action-sheet-content', mode)} style={{ marginTop: contentTop }}>
        { renderHeaderIcon()}
        {header}
        <div className="action-sheet-body">{children}</div>
      </div>
      <div className="action-sheet-mask" onClick={clickModal} />
    </div>
  )
  if (!visible) {
    return null
  }
  return ReactDOM.createPortal(Comp, container)
}

export default ActionSheet
