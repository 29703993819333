import { createSlice } from '@reduxjs/toolkit'
import * as Schemas from '@/common/schemas'
import { isEmpty } from 'lodash'

const identifyDefaultData: Schemas.IdentifyType = {
  birthAddr: '',
  birthdate: '',
  // country: ['01'],
  country: [],
  engName: '',
  firstName: '',
  idNo: '',
  idType: '',
  lastName: '',
  sex: '',
  zhName: ''
}
const countrysDefaultOps = [
  { label: '香港', value: 'HK' },
  { label: '内地', value: 'CN' },
  { label: '澳门', value: 'MO' }
]
const idCardTypeDefaultOps = [{ label: '香港居民身份证', value: 'ID' }]

export const counterSlice = createSlice({
  name: 'idCard',
  initialState: {
    pageData: identifyDefaultData,
    sexOps: [],
    countryOps: countrysDefaultOps,
    idCardTypeOps: idCardTypeDefaultOps
  },
  reducers: {
    updateIdcardInfo(state, action): void {
      if (!isEmpty(action.payload)) {
        state.pageData = action.payload
      }
    }
  }
})

export const { updateIdcardInfo } = counterSlice.actions

export const selectIdcardInfo = (state: any): Schemas.IdentifyType => state.idCard.pageData

export default counterSlice.reducer
