export enum devTokenMap {
  dev = '',
  dev01 = '',
  sit = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfciI6ImxIRHlsWnllMTQiLCJleHAiOjE2MzgxNTQzOTEsInVzZXJJZCI6IjU0MTE3NTU2NzQzODMwNzMyOCJ9.KyFBkZDNfc_O0W0LdCzPveHZN5IVCiGArlXluU7TiT8',
  sit01 = '',
  uat = '',
  uat01 = '',
  prd = ''
}

export const defaultDev = 'dev'
