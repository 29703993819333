import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

function PieChart(props: any): JSX.Element {
  const { data = [0.6, 0.4], dataColor = ['#FFFFFF', 'transparent'], diameter = 16 } = props
  const pieCanvas = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const drawPie = (): void => {
      if (!pieCanvas.current) return
      const pCanvas = pieCanvas.current

      let angleStart = 0;
        let angleEnd;
        let angle

      const ctx = pCanvas.getContext('2d')

      if (!ctx) return

      for (let i = 0; i < data.length; i++) {
        angle = 2 * Math.PI * data[i]

        angleEnd = angleStart + angle

        ctx.beginPath()

        ctx.fillStyle = dataColor[i]

        ctx.arc(pCanvas.width / 2, pCanvas.height / 2, pCanvas.width / 2, angleStart, angleEnd)

        ctx.lineTo(pCanvas.width / 2, pCanvas.height / 2)

        ctx.closePath()

        ctx.fill()

        angleStart = angleEnd // 设置画下一个扇形的起点位置
      }
    }
    drawPie()
  }, [data, dataColor])
  return <canvas width={diameter} height={diameter} ref={pieCanvas} />
}

PieChart.propTypes = {
  diameter: PropTypes.number, // 直径
  data: PropTypes.arrayOf(PropTypes.number), // 扇形的占比
  dataColor: PropTypes.arrayOf(PropTypes.string) // 扇形的颜色
}

export default PieChart
