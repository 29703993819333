import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import userIcon from '@/assets/img/setup/icon-user.svg'
import styles from './index.module.scss'
import { Button } from 'zati-ui-web'
import { ZiProgressBar, SetupTitle } from '@/components'
import { SetupPageProps } from '@/common/types'
import { OpenStep } from '@/common/enums/'
import { Accordion } from 'antd-mobile'
import { Timing } from '@/common/enums/time.enum'
const ZiAccordion = Accordion
const Panel = ZiAccordion.Panel

function RiskShow({
  nextPage,
  submitSetup,
  isDelegation
}: {
  nextPage: SetupPageProps['nextPage']
  submitSetup: (data: any) => Promise<unknown>
  isDelegation: boolean
}): JSX.Element {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const submit = async (): Promise<void> => {
    await submitSetup({
      dataType: OpenStep.STEP_RISK_SHOW,
      openData: {}
    })
    nextPage()
  }

  const listData = [
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title1', '證券交易的風險'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc1',
        '證券價格有時可能會非常波動。證券價格可升可跌，甚至變成毫無價值。買賣證券未必一定能夠賺取利潤，反而可能會招致損失。'
      )
    },
    {
      header: t(
        'za_invest_setup_riskShow_page_risk_content_title2',
        '在香港以外地方收取或持有的客戶資產的風險'
      ),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc2',
        '持牌人或註冊人在香港以外地方收取或持有的客戶資產，是受到有關海外司法管轄區的適用法律及規例所監管的。這些法律及規例與《證券及期貨條例》( 第 571 章 ) 及根據該條例制訂的規則可能有所不同。因此，有關客戶資產將可能不會享有賦予在香港收取或持有的客戶資產的相同保障。'
      )
    },
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title3', '交易設施'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc3',
        '電子交易的設施是以電腦組成系統來進行交易指示傳遞、執行、配對、登記或交易結算。然而，所有設施及系統均有可能會暫時中斷或失靈，而你就此所能獲得的賠償或會受制於系統供應商、市場、結算公司及／或參與者商號就其所承擔的責任所施加的限制。由於這些責任限制可以各有不同，你應向為你進行交易的商號查詢這方面的詳情。'
      )
    },
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title4', '電子交易'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc4',
        '透過某個電子交易系統進行買賣，可能會與透過其他電子交易系統進行買賣有所不同。如果你透過某個電子交易系統進行買賣，便須承受該系統帶來的風險，包括有關系統硬件或軟件可能會失靈的風險。系統失靈可能會導致你的交易指示不能根據指示執行，甚或完全不獲執行。'
      )
    },
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title5', '存放的現金及財產'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc5',
        '如果你為在本地或海外進行的交易存放款項或其他財產，你應瞭解清楚該等款項或財產會獲得哪些保障，特別是在有關商號破產或無力償債時的保障。至於能追討多少款項或財產一事，可能須受限於具體法例規定或當地的規則。在某些司法管轄區，收回的款項或財產如有不足之數，則可認定屬於你的財產將會如現金般按比例分配予你。'
      )
    },
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title6', '佣金及其他收費'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc6',
        '在開始交易之前，你先要清楚瞭解你必須繳付的所有佣金、費用或其他收費。這些費用將直接影響你可獲得的淨利潤(如有)或增加你的虧損。'
      )
    },
    {
      header: t('za_invest_setup_riskShow_page_risk_content_title7', '貨幣風險'),
      content: t(
        'za_invest_setup_riskShow_page_risk_content_desc7',
        '以外幣計算的合約買賣所帶來的利潤或招致的虧損(不論交易是否在你本身所在的司法管轄區或其他地區進行)，均會在需要將合約的單位貨幣兌換成另一種貨幣時受到匯率波動的影響。'
      )
    }
  ]
  if (isDelegation) {
    // 非一户双开不需要新股易
    listData.push({
      header: t('za_invest_setup_riskShow_page_risk_content_title8', '新股易披露'),
      content: t('za_invest_setup_riskShow_page_risk_content_desc8')
    })
  }

  return (
    <div className={styles.contentWrapper}>
      <div className="padding">
        <ZiProgressBar
          current={4}
          title={t('za_invest_setup_riskShow_page_title', '风险披露')}
          progress={80}
        />
      </div>
      <SetupTitle text={t('za_invest_setup_riskShow_page_title', '风险披露')} />
      <section className="popup-bg">
        <ul className={styles.iconText}>
          <li className={styles.left}>
            <img src={userIcon} alt="" />
          </li>
          <li className={styles.right}>
            <p
              dangerouslySetInnerHTML={{
                __html: t('za_invest_setup_riskShow_page_iconText1', '持牌代表 <span>ZA</span>')
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('za_invest_setup_riskShow_page_iconText2', '牌照号: <span>6060</span>')
              }}
            />
          </li>
        </ul>
        <p
          className={styles.subDesc}
          dangerouslySetInnerHTML={{
            __html: t('za_invest_setup_riskShow_page_subDesc', '本人现在向你作出以下风险提示：')
          }}
        />
        <ZiAccordion
          defaultActiveKey={listData.map((_, index) => `${index}`)}
          accordion={false}
          className={styles.accordion}
        >
          {listData.map((item, index) => (
            <Panel key={`${index}`} header={item.header} className="pad">
              <span dangerouslySetInnerHTML={{ __html: item.content }} />
            </Panel>
          ))}
        </ZiAccordion>
      </section>
      <div className="padding">
        <Button
          className="zi-btn"
          type="primary"
          throttleTime={Timing.DEBOUNCE}
          onClick={(): void => {
            submit()
          }}
          block
        >
          {t('za_invest_setup_riskShow_page_btn_signature_txt', '点击签名')}
        </Button>
      </div>
    </div>
  )
}

export default RiskShow
