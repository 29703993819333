import React from 'react'
import { useSelector } from 'react-redux'
import { selectRiskMarkMsg } from '../../store/slices/finance'
import { SetupRiskMarkConfirm } from '@/components'
import { SetupPageProps } from '@/common/types'
import { useSubmitSetup } from '@/hooks'
import { selectIsDelegation } from '../../store/slices/init'

function Result({ nextPage, prePage }: SetupPageProps): JSX.Element {
  const submitSetup = useSubmitSetup()
  const riskResult = useSelector(selectRiskMarkMsg) || {}
  const isDelegation = useSelector(selectIsDelegation)

  return (
    <SetupRiskMarkConfirm
      riskResult={riskResult}
      submitSetup={submitSetup}
      isDelegation={isDelegation}
      nextPage={nextPage}
      prePage={prePage}
    />
  )
}

export default Result
