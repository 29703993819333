import { cookie } from '@/utils/index'

export const isForceHttpRequest = true
export const getCrypt = () => {
  const needCryptEnvs = new Set(['uat', 'uat01', 'prd'])
  const curEnv = process.env.REACT_APP_DEPLOY_ENV || ''
  if (needCryptEnvs.has(curEnv) || cookie.get('isCrypt') === 'true') {
    return true
  }
  return false
}
