import { SelectOptionItem, ListItemType, Base64Item } from '@/common/types'
import { OpenStep, PagePathSetup, defaultDev } from '@/common/enums/index'
import { EnvType } from '@/common/schemas/env.interface'
import Cookie from 'cookie'
import { getI18n, useTranslation } from 'react-i18next'
import { ZiModal } from '@/components'
import regularExpUtils from './regularExpUtils'
import accounting from 'accounting'
import moment from 'moment'
import { isDate } from 'lodash'
import { initTheme, observeTheme } from '@/assets/js/theme'
import { INVEST_HOST, INVEST_FILE_HOST } from '@/common/enums/host.enum'
import i18next from 'i18next'
import ReactDOMServer from 'react-dom/server'
import React, { isValidElement } from 'react'

function getEnv(): EnvType {
  console.log(defaultDev, 'defaultDev')
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV
  const ENVSFORBUILD = new Set(['dev01', 'sit', 'sit01', 'uat', 'uat01', 'prd'])
  return ENVSFORBUILD.has(String(deployEnv)) ? (deployEnv as EnvType) : defaultDev
}

interface CookieOption {
  path?: string
  domain?: string
  expires?: Date | number
  secure?: boolean
}

const cookie = {
  get: function (name: string): string {
    if (!global.document || !name) {
      return ''
    }
    const cookieString = global?.document?.cookie || ''
    const cookieMap = Cookie.parse(cookieString) || {}

    return decodeURIComponent(cookieMap[name] || '')
  },
  set: function (name: string, value: string, param: CookieOption): void {
    if (!global.document) {
      return
    }
    let text = encodeURIComponent(name) + '=' + encodeURIComponent(value)
    const expires = param.expires
    if (expires instanceof Date) {
      text += ';expires=' + expires.toUTCString()
    } else if (typeof expires === 'number') {
      const now = new Date()
      const time = now.getTime()
      const expireTime = time + expires * 1000
      now.setTime(expireTime)
      text += ';expires=' + now.toUTCString()
    }
    if (param.path) {
      text += ';path=' + param.path
    }
    if (param.domain) {
      text += ';domain=' + param.domain
    }
    if (param.secure) {
      text += ';secure'
    }
    global.document.cookie = text
  },
  unset: function (name: string): void {
    this.set(name, '', {
      path: '/',
      domain: '.' + window.location.hostname.split('.').slice(-2).join('.'),
      expires: new Date(0),
      secure: false
    })
  }
}

function urlQuery(key: string, _url?: string): string {
  if (!global.location) {
    return ''
  }
  const url = _url || global.location.href
  const reg = new RegExp('[?&#]' + key + '=([^&#]*)', 'i')
  const match = url.match(reg)
  let result

  if (match) {
    try {
      result = decodeURIComponent(match[1]) || ''
      return result
    } catch (e) {}
  }
  return ''
}

let uid = 0

const addUidToListItem = (list: any[]): void => {
  list.forEach((item) => {
    if (!item.hasOwnProperty('id') || item.id === undefined) {
      item.id = `${uid++}`
    }
  })
}
const width = document.documentElement.clientWidth
const remBaseWidth = 375
const pxToRem = (px: number, rootValue = 100): string => {
  const rate = remBaseWidth / width
  return `${px / rootValue / rate}rem`
}

const transformSelectItem = (list: ListItemType[]): SelectOptionItem[] => {
  return list.map((item: ListItemType) => {
    const result: SelectOptionItem = {
      value: item.value,
      label: item.desc
    }
    if (item.children) {
      result.children = transformSelectItem(item.children)
    }
    return result
  })
}

function setupStep2Path(step: string): string {
  switch (step) {
    case OpenStep.STEP_IDPRE:
      return PagePathSetup.PAGE_SETUP_PRE
    case OpenStep.STEP_IDTYPE:
      return PagePathSetup.PAGE_ID_TYPE
    case OpenStep.STEP_IDENTIFY:
      return PagePathSetup.PAGE_IDCARD
    case OpenStep.STEP_SELFIDENTIFY:
      return PagePathSetup.PAGE_IDCARD

    case OpenStep.STEP_ADDRESS:
      return PagePathSetup.PAGE_ADDRESS

    case OpenStep.STEP_TAX:
      return PagePathSetup.PAGE_TAXATIOIN

    case OpenStep.STEP_PROFESSION:
      return PagePathSetup.PAGE_CAREER

    // case OpenStep.STEP_INCOME:
    //   return PagePathSetup.PAGE_FINANCE

    case OpenStep.STEP_RISK:
      return PagePathSetup.PAGE_FINANCE

    case OpenStep.STEP_RISK_MARK_CONFIRM:
      return PagePathSetup.PAGE_RISK_CONFIRM

    case OpenStep.STEP_OTHER_SHOW:
      return PagePathSetup.PAGE_OTHER_DATA

    case OpenStep.STEP_RISK_SHOW:
      return PagePathSetup.PAGE_RISK_SHOW

    case OpenStep.STEP_SUBMIT:
      return PagePathSetup.PAGE_SIGNATURE

    case OpenStep.STEP_WAITING:
      return PagePathSetup.PAGE_RESULT

    case OpenStep.STEP_SUCCESS:
      return PagePathSetup.PAGE_RESULT

    case OpenStep.STEP_FAIL:
      return PagePathSetup.PAGE_RESULT
    case OpenStep.STEP_INVITE_CODE:
      return PagePathSetup.PAGE_INVITE_CODE
    case OpenStep.STEP_OPEN_ACCOUNT:
      return PagePathSetup.PAGE_OPEN_ACCOUNT
    case OpenStep.STEP_AGREEMENT:
      return PagePathSetup.PAGE_SIGNATURE

    case OpenStep.STEP_EMPLOYER_AGREE:
      return PagePathSetup.PAGE_UPLOAD_CONTENT

    default:
      return PagePathSetup.PAGE_OPEN_ACCOUNT
  }
}

function msetupStep2Path(step: string): string {
  // 内地开户映射
  const {
    STEP_OPEN_ACCOUNT,
    STEP_UPLOAD_ID_CARD,
    STEP_INLAND_IDENTIFY,
    STEP_VALID_BANK,
    SETP_CA,
    STEP_ADDITIONAL_IDENTIFY,
    STEP_TAX,
    STEP_PROFESSION,
    STEP_RISK,
    STEP_RISK_MARK_CONFIRM,
    STEP_OTHER_SHOW,
    STEP_RISK_SHOW,
    STEP_SUBMIT,
    STEP_WAITING,
    STEP_SUCCESS,
    STEP_FAIL,
    STEP_CHECK_LIVING,
    STEP_AGREEMENT,
    STEP_EMPLOYER_AGREE,
    STEP_INVITE_CODE
  } = OpenStep
  const {
    PAGE_OPEN_ACCOUNT,
    PAGE_UPLOAD_ID_CARD,
    PAGE_CONFIRM_IDENTIFY,
    PAGE_VALID_BANK,
    PAGE_CA,
    PAGE_ADDTIONAL_IDENTIFY,
    PAGE_TAXATIOIN,
    PAGE_CAREER,
    PAGE_FINANCE,
    PAGE_RISK_CONFIRM,
    PAGE_OTHER_DATA,
    PAGE_RISK_SHOW,
    PAGE_SIGNATURE,
    PAGE_RESULT,
    PAGE_UPLOAD_CONTENT,
    PAGE_INVITE_CODE
  } = PagePathSetup
  const stepMap: any = {
    [STEP_OPEN_ACCOUNT]: PAGE_OPEN_ACCOUNT,
    [STEP_UPLOAD_ID_CARD]: PAGE_UPLOAD_ID_CARD,
    [STEP_INLAND_IDENTIFY]: PAGE_CONFIRM_IDENTIFY,
    [STEP_VALID_BANK]: PAGE_VALID_BANK,
    [SETP_CA]: PAGE_CA,
    [STEP_ADDITIONAL_IDENTIFY]: PAGE_ADDTIONAL_IDENTIFY,
    [STEP_TAX]: PAGE_TAXATIOIN,
    [STEP_PROFESSION]: PAGE_CAREER,
    [STEP_RISK]: PAGE_FINANCE,
    [STEP_RISK_MARK_CONFIRM]: PAGE_RISK_CONFIRM,
    [STEP_OTHER_SHOW]: PAGE_OTHER_DATA,
    [STEP_RISK_SHOW]: PAGE_RISK_SHOW,
    [STEP_SUBMIT]: PAGE_SIGNATURE,
    [STEP_WAITING]: PAGE_RESULT,
    [STEP_FAIL]: PAGE_RESULT,
    [STEP_SUCCESS]: PAGE_RESULT,
    [STEP_AGREEMENT]: PAGE_SIGNATURE,
    [STEP_EMPLOYER_AGREE]: PAGE_UPLOAD_CONTENT,
    [STEP_CHECK_LIVING]: STEP_CHECK_LIVING, // 活体识别不需要页面
    [STEP_INVITE_CODE]: PAGE_INVITE_CODE
  }
  return stepMap[step] || PAGE_OPEN_ACCOUNT
}

const getFileType = (filePath: string): string => {
  let type = ''
  const chunks = filePath.split('.')
  if (chunks.length) {
    type = chunks.pop() as string
  }
  return type
}

function blobToDataUrl(blob: Blob): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      resolve(reader.result)
    })
    reader.addEventListener('error', reject)
    reader.readAsDataURL(blob)
  })
}

const getLang = (): string => {
  // return i18next.language || 'hk'
  return i18next.language || (getI18n() && getI18n().language) || 'hk'
}

const getDeafultMsg = () => {
  const lang = location.pathname.replace(/\/(hk|en|cn)\/.+/, '$1') || 'hk'
  const obj: any = {
    // 常量
    en: {
      title: 'Message',
      message: 'Network error',
      btnText: 'OK',
      tips: 'Reminder'
    },
    hk: {
      title: '通知',
      message: '網絡異常',
      btnText: '確認',
      tips: '溫馨提示'
    },
    cn: {
      title: '温馨提示',
      message: '网络异常',
      btnText: '确认',
      tips: '温馨提示'
    }
  }
  return obj[lang]
}

const getShowErrorSingleton = () => {
  const msgObj = getDeafultMsg()
  let errorModal: { close: () => void } | null
  const errorHandler = (err: any, cb?: Function) => {
    if (errorModal) {
      errorModal = null
      return
    }
    errorModal = ZiModal.alert(msgObj?.title, err.message || err.msg || msgObj?.message, [
      {
        text: msgObj?.btnText,
        onPress: (): void => {
          errorModal = null
          cb?.()
        }
      }
    ])
  }
  return errorHandler
}

// 单例模式解决多个错误弹窗
const showError = getShowErrorSingleton()

const isIOS = /(iPhone|iPad|iPod|iOS|Safari)/i.test(navigator?.userAgent)

const base64WithHeader = <T extends Base64Item>(item: T): T => {
  if (!item.fileBase64) return item
  const header = `data:${item.fileType};base64,`
  const flag = item.fileBase64.startsWith(header)
  return { ...item, fileBase64: flag ? item.fileBase64 : header + item.fileBase64 }
}

const base64WithoutHeader = <T extends Base64Item>(item: T): T => {
  if (!item.fileBase64) return item
  const header = `data:${item.fileType};base64,`
  const flag = item.fileBase64.startsWith(header)
  return { ...item, fileBase64: flag ? item.fileBase64.slice(header.length) : item.fileBase64 }
}

const formatMoney = (num: number | string, precision = 2): string => {
  return accounting.formatNumber([Number(num)], { precision })[0]
}

const formatAmount = (amount: number | string): string => {
  return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatDateTime = (date: string | number | Date): string => {
  if (!date) return '- -'
  if (isDate(date)) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  } else {
    return moment(Number(date)).format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * 用于首次进入页面
 * 关闭/打开 全局loading 白色背景
 * @param show {boolean} 是否显示
 */
const toggleGlobalLoading = (show = false): void => {
  const type = show ? 'remove' : 'add'
  const globalType = !show ? 'remove' : 'add'
  const loadingContainer = document?.querySelector('.za-loading-container')
  loadingContainer?.classList.remove('za-page-loading')
  loadingContainer?.classList[type]('hide')
  loadingContainer?.classList[globalType]('global-loading')
}

/**
 * 用于请求时 loading
 * 关闭/打开 全局loading 透明背景
 * @param show {boolean} 是否显示
 */
const toggleLoading = (show = true): void => {
  const loadingContainer = document?.querySelector('.za-loading-container')
  if (loadingContainer?.classList.contains('global-loading')) {
    return
  }
  const type = show ? 'remove' : 'add'
  loadingContainer?.classList.add('za-page-loading')
  loadingContainer?.classList[type]('hide')
}
/**
 * 初始化应用
 * globalLoading 用于设置全局loading
 */
const initApp = ({ globalLoading } = { globalLoading: false }): void => {
  if (!globalLoading) {
    toggleGlobalLoading()
  }
  initTheme('dark')
  observeTheme()
}

const isInvest = (): boolean => {
  return /invest/i.test(navigator.userAgent)
}
/**
 * 获取axios请求的baseUrl,和文件上传下载相关的baseUrl是filegw 其他事的是appgw
 */
const getBaseUrl = (url: string | undefined, env: EnvType): string => {
  if (typeof url === 'undefined') {
    return `${INVEST_HOST[env]}${url}`
  }
  if (url.indexOf('filegw') > 0) {
    return `${INVEST_FILE_HOST[env]}${url}`
  } else {
    return `${INVEST_HOST[env]}${url}`
  }
}
/**
 * 体验xman上报
 */
const reportXman = (type: string, message: string): void => {
  if (typeof window._XFLOW_ !== 'undefined') {
    console.log(type + message)
    // window._XFLOW_.pushEvent(['click', type, message, {}])
  }
}

function formatPrice(num: any, int: boolean) {
  // console.log(num)
  if (num === undefined || num === null || num === '') {
    return ''
  }
  let formatNum = num
  if (int) {
    formatNum = parseInt(formatNum, 10)
  }
  formatNum = String(formatNum)
  formatNum = formatNum.replace(/(\d+\.\d{2})\d+/, '$1')
  formatNum = formatNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return formatNum
}

const jsonParseable = (val: any): boolean => {
  let res = true
  try {
    JSON.parse(val)
  } catch (error) {
    res = false
  }
  return res
}
const commonReg = {
  // 常用正则
  phone: /^(?:1\d{10}|\d{8})$/ // 手机号
}

const changeLanguage = () => {
  const { i18n } = useTranslation()
  const langArr = new Set(['key', 'hk', 'en', 'cn'])
  const urlLangStr = window.location.pathname.split('/')[1]
  if (langArr.has(urlLangStr)) {
    i18n.changeLanguage(urlLangStr)
  }
}

function getQueryStrAfterHash(url?: string) {
  let urlCopy = url
  if (typeof urlCopy !== 'string' || !urlCopy) {
    urlCopy = location.href
  }
  urlCopy = urlCopy.split('#')[1]
  if (!urlCopy) {
    return ''
  }
  urlCopy = urlCopy.split('?')[1]
  return urlCopy || ''
}

function getParamsAfterHash(url?: string) {
  let newUrl = url
  if (typeof newUrl !== 'string' || !newUrl) {
    newUrl = location.href
  }
  newUrl = newUrl.split('#')[1]
  if (!newUrl) {
    return {}
  }
  newUrl = newUrl.split('?')[1]
  if (!newUrl) {
    return {}
  }
  return newUrl.split('&').reduce(function (result: Record<string, any>, param) {
    const [key, value] = param.split('=')
    result[key] = value
    return result
  }, {})
}

/**
 * 格式化接口错误code及message，格式 message(code)
 *
 * @param code
 * @param message
 */
function formatErrorMsg(code: string, message: string) {
  return `${message}(${code})`
}

/**
 * 解决浮点数乘除法的问题
 *
 * @param value:number
 * @param unit: number
 */
const mathTransfer = (value: number, unit: number) => {
  return value * unit
}

const template = (paramsStr = '', replaceMap: any = {}) => {
  // /(?<={)[^}]+(?=})/g 有些不支持 <的语法
  let str = paramsStr
  let array = str.match(/(?={)[^}]+}/g) || []
  if (!str || Object.prototype.toString.call(replaceMap) !== '[object Object]') {
    return str
  }

  array.forEach((item) => {
    let key = item.replace('{', '').replace('}', '').replace(/\s+/, '')
    if (replaceMap[key]) {
      let value = replaceMap[key]
      if (isValidElement(value)) {
        value = ReactDOMServer.renderToString(value)
      }
      str = str.replace(item, value)
    }
  })

  return str
}

// 关闭子账户入金相关功能
const showSubAccount = true
const closeSubAccount = (t: Function) => {
  const desc = t(
    'za_invest_cashin_subaccount_desc',
    '<p>客戶您好， <br /> <br /> 由於ZA Bank子賬戶服務正進行系統升級，因此暫停相關服務，而Za One的子賬戶入金功能亦即時暫停。<br /> 如需入金，請使用FPS入金功能進行存款。<br />如有任何疑問，歡迎以在線客服、電郵(ZAOne@za.group)或致電（+852 36653688）與我們聯絡。謝謝！</p>'
  )
  if (showSubAccount) {
    showError({
      message: (
        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: unescape(desc) }} />
      )
    })
  }
}

export {
  closeSubAccount,
  showError,
  setupStep2Path,
  getEnv,
  cookie,
  urlQuery,
  addUidToListItem,
  pxToRem,
  transformSelectItem,
  getFileType,
  blobToDataUrl,
  getLang,
  isIOS,
  base64WithHeader,
  base64WithoutHeader,
  regularExpUtils,
  formatMoney,
  formatAmount,
  formatDateTime,
  toggleGlobalLoading,
  toggleLoading,
  initApp,
  isInvest,
  getBaseUrl,
  msetupStep2Path,
  jsonParseable,
  reportXman,
  formatPrice,
  getDeafultMsg,
  commonReg,
  changeLanguage,
  getQueryStrAfterHash,
  getParamsAfterHash,
  formatErrorMsg,
  mathTransfer,
  template,
  showSubAccount
}
