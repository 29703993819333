import React from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import { Row, Col } from 'antd'
import iconCopy from '@/assets/img/cashin/icon-copy.svg'
import copy from 'copy-to-clipboard'
import { ZiToast } from '@/components'
import { useTranslation } from 'react-i18next'
import { ToolTip } from 'zati-ui-web'
import CircleTips from '@/assets/img/setup/icon-circle-tips.svg'

export type AccountItemProps = {
  title: string
  content: string
  className?: string
  showCopy?: boolean
  forceTips?: string
  titlePopover?: boolean
  titlePopContent?: string
  onClickCopy?: () => void
} & React.HTMLAttributes<any>

function AccountItem(props: AccountItemProps): JSX.Element {
  const { t } = useTranslation()
  const {
    title,
    content,
    className,
    forceTips,
    showCopy = false,
    titlePopover = false,
    titlePopContent,
    onClickCopy,
    ...rest
  } = props
  const onCopy = (): void => {
    copy(content)
    ZiToast.success(t('za_invest_cashin_deposi_copy', '复制成功'), 1)
    onClickCopy?.()
  }
  return (
    <Row className={classnames(styles.wrapper, className)} {...rest}>
      <Col span={24}>
        <div className={styles.title}>
          <span className={styles.titleValue}>{title}</span>
          <span className={styles.forceTips}>{forceTips}</span>
          {titlePopover && (
            <ToolTip
              placement="bottom"
              overlay={titlePopContent || title}
              className={styles.tooltip}
              overlayClassName="tipsOverlay"
              trigger="click"
            >
              &nbsp;
              <img className={styles.tipsIcon} src={CircleTips} alt="" />
            </ToolTip>
          )}
        </div>
        <p className={styles.content}>
          {content}&nbsp;
          {showCopy && content && (
            <img className={styles.copyIcon} src={iconCopy} alt="copy" onClick={onCopy} />
          )}
        </p>
      </Col>
    </Row>
  )
}

export default AccountItem
