import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import IdTypeItem from './idTypeItem'
import selectIdTypeHk from '@/assets/img/setup/icon-select-id-type-hk.svg'
import selectIdTypeZh from '@/assets/img/setup/icon-select-id-type-zh.svg'
import { SetupPageProps } from '@/common/types'
import { SetupTitle } from '@/components'

function SelectIdType(props: SetupPageProps): JSX.Element {
  const { t } = useTranslation()

  const list = [
    {
      typeName: t('za_invest_setup_id_type_page_item_name_hk', '香港地區'),
      typeDesc: t(
        'za_invest_setup_id_type_page_item_description_hk',
        '香港第二代身份證，新智能香港身份證'
      ),
      icon: selectIdTypeHk
    },
    {
      typeName: t('za_invest_setup_id_type_page_item_name_zh', '中國大陸地區'),
      typeDesc: t('za_invest_setup_id_type_page_item_description_zh', '即将推出，敬请期待'),
      disabled: true,
      icon: selectIdTypeZh
    }
  ]

  const onIdCardClick = (isDisable: boolean): void => {
    if (!isDisable) {
      props.nextPage()
    }
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.idTypeBody}>
        <SetupTitle text={t('za_invest_setup_id_type_page_title', '请選擇開戶類型')} />
        {/* <div className="page-sub-title">
            <div className={styles.marginRight25}>
              {t(
                'za_invest_setup_id_type_page_description',
                'AAIM 投資開戶全城僅需 3 分鐘，個人資料均加密傳輸，僅用於身份驗證。'
              )}
            </div>
          </div> */}
        <div className={styles.listWrapper}>
          {list.map((cardType: any) => (
            <IdTypeItem card={cardType} key={cardType.typeName} onItemClick={onIdCardClick} />
          ))}
        </div>
        <div className={styles.decalres}>
          {t(
            'za_invest_setup_id_type_page_decalres',
            'Investment service is provided by AA Investment Management Limited ("AAIM"). AAIM is a licensed corporation regulated by the Securities and Futures Commission (CE No. BQF712) to carry on Types 1, 4 and 9 of regulated activities.​'
          )}
        </div>
      </div>
    </div>
  )
}

export default SelectIdType
