import React from 'react'
import SignaturePage from '@/components/setup/SignaturePage'
import { updateSignatureData, selectSignatureData } from '@/pages/setup/store/slices/riskShow'
import { SetupPageProps } from '@/common/types'

import {
  updateUserSetupInfo,
  selectUserSetupInfo,
  selectPdfCodeList,
  updatePdfCodeList,
  selectPdfKeyMap,
  updatePdfKeyMap
} from '../../store/slices/userSetupInfo'

function Signature({ nextPage, showBackBtn }: SetupPageProps): JSX.Element {
  return (
    <SignaturePage
      nextPage={nextPage}
      showBackBtn={showBackBtn}
      updateUserSetupInfo={updateUserSetupInfo}
      selectUserSetupInfo={selectUserSetupInfo}
      selectPdfCodeList={selectPdfCodeList}
      updatePdfCodeList={updatePdfCodeList}
      selectPdfKeyMap={selectPdfKeyMap}
      updatePdfKeyMap={updatePdfKeyMap}
      updateSignatureData={updateSignatureData}
      selectSignatureData={selectSignatureData}
    />
  )
}

export default Signature
