import React, { useEffect, useCallback } from 'react'
import { Form, Input } from 'antd'
import { Button } from 'zati-ui-web'
import { useTranslation } from 'react-i18next'
import { ZiCheckbox, ZiProgressBar, ZiModal } from '@/components'
import styles from './index.module.scss'
import { SetupPageProps } from '@/common/types'
import { OpenStep, PagePathSetup } from '@/common/enums/index'
import { useSetState, useSubmitSetup } from '@/hooks'
import {
  updateUserData,
  selectUserData,
  allOrterDataValues
} from '@/pages/setup/store/slices/otherData'
import { useSelector, useDispatch } from 'react-redux'
import { forEach, isArray } from 'lodash'
import classnames from 'classnames'
import { Timing } from '@/common/enums/time.enum'

const CheckBoxItem = ZiCheckbox.CheckBoxItem

interface RenderItem {
  value: string
  label: string
  defaultChecked: boolean
  placeholder?: string
  needReason?: boolean
}

function OtherData({ jumpPage, nextPage }: SetupPageProps): JSX.Element {
  const [form] = Form.useForm()
  const submitSetup = useSubmitSetup()
  const dispatch = useDispatch()
  const userData = useSelector(selectUserData)
  const { t } = useTranslation()
  const [state, setState] = useSetState({
    disabled: false,
    isInit: false,
    checkValues: userData.checked,
    showTips: !userData.checked.includes('orderDataSecuritiesPractitioner')
  })
  const { disabled, isInit, checkValues, showTips } = state

  const checkDisabled = (checked: string[]): boolean => {
    return allOrterDataValues.slice(0, 4).some((item) => !checked.includes(item))
  }

  const onCheckChange = useCallback(
    (vals: string[]): void => {
      setState({
        showTips: !vals.includes('orderDataSecuritiesPractitioner'),
        disabled: allOrterDataValues.slice(0, 4).some((item) => !vals.includes(item)),
        checkValues: vals
      })
      form.setFieldsValue({ ckecked: vals })
    },
    [form, setState]
  )

  useEffect(() => {
    if (!isInit) {
      const { checked = [], remarks } = userData
      const newFormValues: { checked: string[];[key: string]: any } = {
        checked
      }
      if (isArray(remarks)) {
        remarks.forEach((item) => {
          newFormValues[item.checkName] = item.reason
        })
      }
      setState({
        isInit: true,
        checkValues: checked,
        disabled: checkDisabled(checked)
      })
      form.setFieldsValue(newFormValues)
      onCheckChange(checked)
    }
  }, [form, isInit, onCheckChange, setState, userData])
  const onCheck = async (): Promise<void> => {
    if (disabled) {
      ZiModal.alert(
        '',
        t('za_invest_setup_can_not_accept_account_opening', '暂无法接受你的开户申请')
      )
      return
    }
    form.validateFields().then(
      async (values) => {
        const remarks: any[] = []
        if (!isArray(values.ckecked)) {
          values.ckecked = []
        }
        forEach(values, (val: any, k: string) => {
          if (k !== 'checked' && allOrterDataValues.includes(k)) {
            remarks.push({
              checkName: k,
              reason: val || ''
            })
          }
        })

        const requestParams = {
          remarks,
          checked: [...values.ckecked]
        }
        await submitSetup({
          dataType: OpenStep.STEP_OTHER_SHOW,
          openData: {
            otherDisclosureMsg: {
              otherDisclosureStr: JSON.stringify(requestParams)
            }
          }
        })
        dispatch(updateUserData(requestParams))
        if (values.ckecked.includes('orderDataSecuritiesPractitioner')) {
          nextPage()
        } else {
          jumpPage({ targetPage: PagePathSetup.PAGE_UPLOAD_CONTENT })
        }
      },
      () => {
        // reject branch
      }
    )
  }

  const onReasonChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>, name: string): void => {
      form.setFieldsValue({ [name]: ev.target.value })
    },
    [form]
  )

  const renderItem = ({
    value,
    label,
    defaultChecked,
    placeholder,
    needReason = false
  }: RenderItem): JSX.Element => {
    return (
      <div className={styles.checkItem}>
        <CheckBoxItem value={value} defaultChecked={defaultChecked}>
          {label}
        </CheckBoxItem>
        {needReason && !checkValues.includes(value) && (
          <Form.Item
            name={value}
            className={styles.input}
            rules={[
              {
                required: true,
                message: t('za_invest_setup_other_data_page_placeholder_needReason')
              },
              {
                type: 'string',
                max: 200,
                message: t(
                  'za_invest_setup_other_data_page_verify_length_needReason',
                  '说明原因不能超过200字符'
                )
              }
            ]}
          >
            <Input.TextArea
              className={styles.inputArea}
              placeholder={placeholder}
              onChange={(e): void => onReasonChange(e, value)}
            />
          </Form.Item>
        )}
      </div>
    )
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.commonPadding}>
        <ZiProgressBar
          current={4}
          title={t('za_invest_setup_progressBar_04Title', '身份披露')}
          progress={70}
        />
      </div>
      <h2 className={classnames(styles.commonPadding, 'page-title')}>
        {t('za_invest_setup_other_data_page_title', '其它资料披露')}
      </h2>
      <Form form={form} className={styles.panel}>
        <Form.Item name="ckecked" valuePropName="checked">
          <ZiCheckbox
            className={styles.checkboxs}
            onChange={onCheckChange}
            initialValues={userData.checked}
          >
            <CheckBoxItem
              value="orderDataFinalAndUniq"
              defaultChecked={userData.checked.includes('orderDataFinalAndUniq')}
              className={styles.checkItem}
            >
              {t(
                'za_invest_setup_other_data_page_form_label_orderDataFinalAndUniq',
                '本人是该证券账户的最终且唯一的收益拥有人'
              )}
            </CheckBoxItem>
            <CheckBoxItem
              value="orderDataIssuedTransactionInstructions"
              defaultChecked={userData.checked.includes('orderDataIssuedTransactionInstructions')}
              className={styles.checkItem}
            >
              {t(
                'za_invest_setup_other_data_page_form_label_orderDataIssuedTransactionInstruction',
                '本人是最终负责发出交易指示的人'
              )}
            </CheckBoxItem>
            <CheckBoxItem
              value="orderDataNotAmerican"
              defaultChecked={userData.checked.includes('orderDataNotAmerican')}
              className={styles.checkItem}
            >
              {t(
                'za_invest_setup_other_data_page_form_label_orderDataNotAmerican',
                '本人不是美国公民/居民或绿卡持有人'
              )}
            </CheckBoxItem>
            <CheckBoxItem
              value="orderDataBankruptcy"
              defaultChecked={userData.checked.includes('orderDataBankruptcy')}
              className={styles.checkItem}
            >
              {t(
                'za_invest_setup_other_data_page_form_label_orderDataBankruptcy',
                '本人没有破产或正面对破产呈请'
              )}
            </CheckBoxItem>
            <div className={styles.checkItem}>
              <CheckBoxItem
                value="orderDataSecuritiesPractitioner"
                defaultChecked={userData.checked.includes('orderDataSecuritiesPractitioner')}
              >
                {t(
                  'za_invest_setup_other_data_page_form_label_orderDataSecuritiesPractitioner',
                  '阁下不是证券及期货事务监察委员会的持牌/注册人士，或持牌法团/注册机构的董事，雇员或代表？'
                )}
              </CheckBoxItem>
              {showTips && (
                <div className={styles.tips}>
                  {t('za_invest_setup_other_data_page_form_tip1', '下一页请提供雇主同意书')}
                </div>
              )}
            </div>
            {renderItem({
              needReason: true,
              value: 'orderDataRelationWithAnAn',
              label: t(
                'za_invest_setup_other_data_page_form_label_orderDataRelationWithAnAn',
                '阁下不是安安投资或其集团公司之雇员或董事或任何董事、雇员或代表有任何关系'
              ),
              placeholder: t('za_invest_setup_order_data_reason_1'),
              defaultChecked: userData.checked.includes('orderDataRelationWithAnAn')
            })}
            {renderItem({
              needReason: true,
              value: 'orderDataImportantPublicOffice',
              label: t(
                'za_invest_setup_other_data_page_form_label_orderDataImportantPublicOffice',
                '阁下、配偶、子女或父母，不担任重要公职，包括国家元首、政府首长、司法或军事官员、国有企业高级行政人员及重要政党干事？'
              ),
              placeholder: t('za_invest_setup_order_data_reason_2'),
              defaultChecked: userData.checked.includes('orderDataImportantPublicOffice')
            })}
            {renderItem({
              needReason: true,
              value: 'orderDataIllegal',
              label: t(
                'za_invest_setup_other_data_page_form_label_orderDataIllegal',
                '阁下不涉及违法或违反监管守则而被捕/受审被判刑/被纪律处分？'
              ),
              placeholder: t('za_invest_setup_order_data_reason_3'),
              defaultChecked: userData.checked.includes('orderDataIllegal')
            })}
          </ZiCheckbox>
        </Form.Item>
      </Form>
      <div className={classnames(styles.commonPadding, 'footer-btn-box')}>
        <Button type="primary" htmlType="submit" block throttleTime={Timing.DEBOUNCE} onClick={onCheck}>
          {t('za_invest_setup_other_data_page_handle_next_button_text', '下一页')}
        </Button>
      </div>
    </div>
  )
}

export default OtherData
