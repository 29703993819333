import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './index.module.scss'
import CheckBoxItem from './CheckBoxItem'
import { useSetState } from '@/hooks'
interface CheckBoxProps {
  children?: any
  className?: string
  onChange?: (list: string[]) => void
  initialValues?: string[]
}

export interface InitState {
  list: string[]
}

export const CheckboxContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onContextChange(bool: boolean, val?: string): void {
    console.log(val, bool)
  }
})
function ZiCheckBox(props: CheckBoxProps): JSX.Element {
  const { className, children, onChange, initialValues } = props
  const [state, setState] = useSetState({
    list: new Set<string>(initialValues)
  })
  const { list } = state
  const handleChange = (checked: boolean, val: any): void => {
    if (checked) {
      list.add(val)
    } else {
      list.delete(val)
    }
    setState({
      list
    })
    onChange?.(Array.from(list))
  }
  return (
    <CheckboxContext.Provider value={{ onContextChange: handleChange }}>
      <div className={classnames(styles.checkbox, className)}>{children}</div>
    </CheckboxContext.Provider>
  )
}

ZiCheckBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  onChange: PropTypes.func,
  initialValues: PropTypes.array
}

ZiCheckBox.CheckBoxItem = CheckBoxItem

export default ZiCheckBox
