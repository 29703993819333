import React, { useMemo } from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

export interface ItemType {
  content: string
  itemChildren?: JSX.Element
}
interface IndexProps {
  tipsList: ItemType[]
  bootomBorder?: boolean
  children?: JSX.Element
}

function Index(props: IndexProps): JSX.Element {
  const { tipsList, bootomBorder = false, children } = props
  const tipsListDom = useMemo(
    () =>
      tipsList.map(({ content, itemChildren }, index: number) => (
        <li className={styles.tipsItem} key={uuidv4()}>
          <span className={styles.iconTips}>{index + 1}</span>
          <p className={styles.tipsItemContent}>
            <span
              dangerouslySetInnerHTML={{
                __html: content
              }}
            />
            {itemChildren}
          </p>
        </li>
      )),
    [tipsList]
  )
  return (
    <>
      <ul className={classnames(styles.tips, bootomBorder ? styles.bottomBorder : undefined)}>
        {tipsListDom}
      </ul>
      {children}
    </>
  )
}

export default Index
