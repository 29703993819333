import React from 'react'
import successFace from '@/assets/img/cashin/icon-success-face.svg'
import failureFace from '@/assets/img/cashin/icon-failure-face.png'
// import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './index.module.scss'
import inviteBanner from '@/assets/img/cashin/invite-banner.png'
import { Button } from 'zati-ui-web'
import { Timing } from '@/common/enums/time.enum'

export enum Status {
  Success = 'success',
  Failure = 'failure'
}

export interface ResultPageProps {
  className?: string
  title?: string
  content?: React.ReactNode
  type?: Status
  onJump?: () => void
  onClick?: () => void
}

function ResultPage(props: ResultPageProps): JSX.Element {
  const { t } = useTranslation()
  const { title, content, type = 'success', onJump, onClick } = props
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.imgWrapper}>
        <img
          className={styles.statusImg}
          src={type === Status.Success ? successFace : failureFace}
          alt={type}
        />
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.content}>{content}</p>
      {type === Status.Success && (
        <div className={styles.inviteBannerWraper}>
          <img
            className={styles.inviteBanner}
            src={t('za_invest_cash_result_banner', 'banner image') || inviteBanner}
            alt="invite banner"
            onClick={onClick}
          />
        </div>
      )}
      <div className={styles.footer}>
        {type === Status.Failure && (
          <Button
            className="zi-btn"
            type="primary"
            block
            throttleTime={Timing.DEBOUNCE}
            onClick={onClick}
          >
            {t('za_invest_cash_out_resutl_page_back_button_text', '回到“我的”页面')}
          </Button>
        )}
        <div className={styles.footerLink} onClick={onJump}>
          {t('za_invest_cash_out_resutl_page_jump_text', '查看资金记录')}
        </div>
      </div>
    </div>
  )
}

export default ResultPage
