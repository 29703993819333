// ca认证
import request from './lib/'
import * as Schemas from '@/common/schemas'
import * as Enums from '@/common/enums/index'

// 获取主题
export function getCACertDN(data: Schemas.getCertDNReqType): Promise<Schemas.getCertDNResType> {
  return request('/account/szca/getCertDN', {
    method: 'post',
    data: data,
    showErrorModal: location.pathname.includes(Enums.PagePathSetup.PAGE_CA)
  })
}

// 申请证书
export async function getCACerti(data: Schemas.applyCaReqType): Promise<Schemas.applyCaResType> {
  return request('/account/szca/apply', {
    method: 'post',
    data: data,
    showErrorModal: location.pathname.includes(Enums.PagePathSetup.PAGE_CA)
  })
}

// 查询申请结果
export async function queryCAApplyResult(data: Schemas.queryApplyResultReqType): Promise<any> { // 这个接口文档有点问题，待确认
  return request('/account/szca/queryApplyResult', {
    method: 'post',
    data: data,
    loading: false
  })
}

// CA签名
export async function signCA(data: Schemas.szcaSignReqType): Promise<Schemas.szcaSignResType> {
  return request('/account/szca/sign', {
    method: 'post',
    data: data,
    loading: false
  })
}