import type { AxiosRequestConfig } from 'axios'

export const joinUrl = (config: AxiosRequestConfig): string => {
  return [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join('&')
}

// 声明一个 Set 用于存储每个请求的标识
export const pending = new Set()
/**
 * 添加请求
 * @param {Object} config
 */
export const addPending = (config: AxiosRequestConfig): string => {
  const url = joinUrl(config)
  pending.add(url)
  return url
}
/**
 * 移除请求
 * @param {Object} config
 */
export const removePending = (config: AxiosRequestConfig, cb?: Function) => {
  const url = joinUrl(config)
  if (pending.has(url)) {
    // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    if (cb) cb(url)
    pending.delete(url)
    return url
  }
}

export const clearAll = () => {
  pending.clear()
}
