import request from './lib'
import * as Schemas from '@/common/schemas'
import { API_VERSION, APPKEY, APPNAME, CHANNELID, OS } from '@/common/enums/baseService.enum'
import { PdfCodeListType, PdfStatus } from '@/common/schemas/setup/'
import { v4 as uuidv4 } from 'uuid'

const extReqHeader = {
  apiversion: API_VERSION,
  appkey: APPKEY,
  appname: APPNAME,
  channelid: CHANNELID,
  language: ((): string => {
    const pathname = location.pathname ? location.pathname : ''
    const languagePrefix = pathname.split('/')[1]
    switch (languagePrefix) {
      case 'en':
        return 'US_en'
      case 'hk':
        return 'CN_hk'
      case 'zh':
        return 'CN_zh'
      default:
        return 'CN_hk'
    }
  })(),
  os: OS,
  reqid: uuidv4().slice(0, 16),
  timestamp: Date.now()
}

export async function downLoadFileBase64(
  data: { fileKey?: string; needBase64?: boolean } = {},
  otherParams?: any
): Promise<Schemas.DownloadFileBase64Type> {
  return request.post('/filegw/file/base64File/queryFileBase64', data, {
    headers: extReqHeader,
    timeout: 1000 * 60 * 5,
    loading: otherParams?.loading
  })
}

// 上传文件
export async function uploadFile(data = {}): Promise<Schemas.UploadFileResType> {
  return request.post('/filegw/file/baseFile/fileUpload', data, {
    headers: extReqHeader,
    timeout: 1000 * 60 * 5
  })
}

// 上传文件base64
export async function uploadFileBase64(data = {}): Promise<Schemas.UploadFileBase64ResType> {
  return request.post('/filegw/file/base64File/uploadFileBase64', data, {
    headers: { 'Content-Type': 'application/json', ...extReqHeader },
    timeout: 1000 * 60 * 5
  })
}

// 获取pdf code list
export async function getPdfListCode(data = {}): Promise<PdfCodeListType> {
  return request.post(`/file/pdf/createPdfAysnc`, data, {
    headers: { 'Content-Type': 'application/json', ...extReqHeader }
  })
}

// 查询生成的pdf生成状态
export async function queryPdfStatus(data: { pdfCode: string; bizId: string }): Promise<PdfStatus> {
  return request.post(`/file/pdf/queryPdfStatus`, data, {
    headers: { 'Content-Type': 'application/json', ...extReqHeader }
  })
}

// h5 -> backend file download
export async function downLoadFile(data = {}): Promise<any> {
  return request.post('/filegw/file/baseFile/fileDownload', data, {
    responseType: 'arraybuffer',
    headers: extReqHeader
  })
}
