import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import routes from './routes/index'
import '@/styles/index.scss'
import './app.scss'
import { getEnv, changeLanguage } from '@/utils'
import { Seagull } from '@/components'

const env = getEnv()

export default function App(): JSX.Element {
  const { i18n } = useTranslation()
  changeLanguage()

  return (
    <Router basename={i18n.language + '/msetup/'}>
      {env === 'dev' && (
        <div className="all-route">
          <Link to="/navs/">Routes</Link>
        </div>
      )}
      {env !== 'prd' && <Seagull />}
      <div>
        <Switch>
          {routes.map((route) => (
            <Route path={route.path} key={route.path}>
              <route.component />
            </Route>
          ))}
        </Switch>
      </div>
    </Router>
  )
}
