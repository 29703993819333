import request from './lib/'
import {
  CommonRetWithList,
  PageSizeNumReq,
  QueryMyMoneyFundRes,
  QueryAssetRes,
  PayPremiumReq,
  PayPremiumRes,
  QueryClientPolicyNoRes,
  DeleteClientPolicyNoReq,
  AddClientPolicyNoReq,
  QueryCompanyListRes,
  QueryClientBillRes,
  QueryPaymentDetailReq,
  QueryPaymentDetailRes
} from '@/common/schemas'

// 查询客户持仓货币基金列表
export function queryMyMoneyFund(data: PageSizeNumReq): Promise<QueryMyMoneyFundRes> {
  return request('/insurance/queryHoldingCurrencyFund', {
    method: 'post',
    data: data
  })
}

// 查询客户持仓及余额
export function queryAsset(data?: any): Promise<QueryAssetRes> {
  return request('/insurance/queryAsset', {
    method: 'post',
    data: data
  })
}

// 缴交保费
export function payPremium(data: PayPremiumReq): Promise<PayPremiumRes> {
  return request('/insurance/payPremium', {
    method: 'post',
    data: data
  })
}

// 上报开通保费通成功
export function openInsurance(data: any): Promise<any> {
  return request('/insurance/openInsurance', {
    method: 'post',
    data: data
  })
}

// 查询客户录入过的保单号
export function queryClientPolicyNo(data: PageSizeNumReq): Promise<QueryClientPolicyNoRes> {
  return request('/insurance/queryClientPolicyNo', {
    method: 'post',
    data: data
  })
}

// 删除客户录入过的保单号
export function deleteClientPolicyNo(data: DeleteClientPolicyNoReq): Promise<any> {
  return request('/insurance/deleteClientPolicyNo', {
    method: 'post',
    data: data
  })
}

// 添加客户录入保单号
export function addClientPolicyNo(data: AddClientPolicyNoReq): Promise<any> {
  return request('/insurance/addClientPolicyNo', {
    method: 'post',
    data: data
  })
}

// 查询保险公司列表
export function queryCompanyList(data: PageSizeNumReq): Promise<QueryCompanyListRes> {
  return request('/insurance/queryCompanyList', {
    method: 'post',
    data: data
  })
}

// 查询客户缴费记录列表
export function queryClientBill(
  data: PageSizeNumReq
): Promise<CommonRetWithList<QueryClientBillRes>> {
  return request('/insurance/queryClientBill', {
    method: 'post',
    data: data
  })
}

// 查询缴费记录详情
export function queryPaymentDetail(data: QueryPaymentDetailReq): Promise<QueryPaymentDetailRes> {
  return request('/insurance/queryPaymentDetail', {
    method: 'post',
    data
  })
}

// 查询7日年化
export function getSevenDayYield(data: {
  productId: string
}): Promise<{ name: string; sevenDayYield: string }> {
  return request('/market/fund/getSevenDayYield', {
    method: 'post',
    data
  })
}

// 查询是否开通了保费通
export function queryInsuranceStatus(data: {}): Promise<{ flag: boolean }> {
  return request('/insurance/queryInsuranceStatus', {
    method: 'post',
    data
  })
}
