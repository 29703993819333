export * from './recordList.interface'
export interface AssetCashProp {
  currency: string
  userStatus: string
}

type CashDirection = 'in' | 'out' //  入账|出账
type Ccy = 'HKD' | 'USD'
// cash_io-出入金,currency_exchange-货币兑换,ipo-新股易,premium_pass-保费通
// ,fund-基金,other-其他,coin_business-coin业务

// 0.查询全部，10：一个月订单，20: 三个月订单，30：近一年订单，40：今年订单，50：自选时间
export interface BusinessType {
  cash_io?: string
  currency_exchange?: string
  ipo?: string
  premium_pass?: string
  fund?: string
  other?: string
  coin_business?: string
}

export interface CashRecord {
  dateFlag: string
  pageNum: string | number
  pageSize: string | number
  accountType?: string
  beginDate?: string
  endDate?: string
  cashDirectionList?: CashDirection[]
  businessTypeCodeList?: BusinessType[]
  ccyList?: Ccy[]
}

export interface CashDetail {
  recordNo: string
}

export interface OptionItem {
  label: string
  value: string
}

export interface FilterPopData {
  type: 'multiple' | 'single'
  name: string
  defaultOptions: OptionItem[]
  title: string
  defaultValue?: any
}

export interface CashRecordV2Res {
  currency: string
  amount: string
  createTime: string
  recordNo: string
  recordName: string
  cashDirection: string
}

export const cashDirectionMap: any = {
  in: '+',
  out: '-'
}
