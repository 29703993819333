import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction
} from 'react'
import styles from './index.module.scss'
import classnames from 'classnames'
import deleteIcon from '@/assets/img/icon-delete.svg'
import { ReactComponent as IconPdf } from '@/assets/img/icon-pdf.svg'
import { Row, Col, Spin } from 'antd'
import { addUidToListItem, blobToDataUrl } from '@/utils'
import LoadingMask from './LoadingMask'
import { useSetState } from '@/hooks/index'
import TouchFeedback from 'rmc-feedback'
import { FileType, FileItem } from '@/common/types'
import { useTranslation } from 'react-i18next'
import { ZiToast, ZiPicturePreview } from '..'
import { cloneDeep } from 'lodash'
import Icon from '@ant-design/icons'
import { ReactComponent as IconUpload } from '@/assets/img/icon-upload.svg'

const isImage = (type: string): boolean => (type || '').startsWith('image')
const isPDF = (type: string): boolean => type === 'application/pdf'
interface FileUploadProps {
  className?: string
  max?: number
  maxSize?: number
  fileTypes?: string[]
  onClick?: () => void
  onChange?: (files: FileItem[], isDelete?: boolean) => void
  initialValue?: FileItem[]
  sampleImg?: string
  sample?: React.ReactNode
}

interface FileUploadState {
  loading: boolean
  show: boolean
  progressVal: number
  fileArray: FileItem[]
  isInit: boolean
  imgUrl: string
  showPreview: boolean
}

export interface FileUploadHandles {
  updateState: (state: Partial<FileUploadState>) => void
  onClick: () => void
}

const FileUpload: ForwardRefRenderFunction<FileUploadHandles, FileUploadProps> = (
  props: FileUploadProps,
  ref
): JSX.Element => {
  const { t } = useTranslation()
  const num = 8
  const {
    className,
    max = 5,
    onClick,
    fileTypes = ['png', 'jpeg', 'jpg', 'pdf'],
    maxSize = 1024 * 1024 * 10,
    onChange,
    initialValue = [],
    sampleImg,
    sample = '查看示例图'
  } = props
  const fileInput = useRef<HTMLInputElement>(null)
  const [state, setState] = useSetState<FileUploadState>({
    loading: false,
    show: false,
    progressVal: 0,
    fileArray: initialValue,
    isInit: false,
    imgUrl: '',
    showPreview: false
  })
  const { loading, progressVal, fileArray, imgUrl, showPreview } = state
  const getPicture = (): void => {
    /* istanbul ignore else */
    if (fileInput.current) {
      // fix bug 24453
      fileInput.current.value = ''
      fileInput.current.click()
    }
    setState({ show: false })
  }

  useImperativeHandle(ref, () => ({
    updateState(state: Partial<FileUploadState>): void {
      setState(state)
    },
    onClick() {
      handleClick()
    }
  }))

  useEffect(() => {
    /* istanbul ignore else */
    if (fileInput.current) {
      const acceptVal = fileTypes.map((type: string) => `.${type}`).join(',')
      fileInput.current.setAttribute('accept', acceptVal)
    }
  }, [fileTypes])

  const showMax = (): void => {
    ZiToast.info(t('za_invest_setup_upload_max_num') + ` ${max}`)
  }

  const handleClick = (): void => {
    /* istanbul ignore else */
    if (fileArray.length >= max) {
      showMax()
      return
    }
    getPicture()
    onClick?.()
  }
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    e.persist()
    const { files } = e.target
    let fileArr = Array.prototype.slice.call(files)
    /* istanbul ignore else */
    if (files) {
      fileArr = fileArr.filter((item: FileType) => {
        const type = item.type.split('/')[1]
        // check file type
        if (!fileTypes.includes(type)) {
          ZiToast.info(
            `${t('za_invest_setup_upload_allowed_file_types')} ${fileTypes
              .map((fType: string) => `.${fType}`)
              .join(',')}`
          )
          return false
        }
        // check file size
        if (item.size > maxSize) {
          ZiToast.info(t('za_invest_setup_upload_max_tip') + ` ${maxSize / 1024 / 1024} M`)
          return false
        }
        return true
      })
      addUidToListItem(fileArr)
      let newFileArray = cloneDeep(fileArray)
      for (const file of fileArr) {
        /* istanbul ignore else */
        if (!newFileArray.find((item: FileItem): boolean => item.id === file.id)) {
          const fileBase64 = await blobToDataUrl(file)
          newFileArray.push({
            fileBase64,
            fileType: file.type,
            fileName: file.name,
            id: file.id
          })
        }
      }
      /* istanbul ignore else */
      if (newFileArray.length > max) {
        showMax()
        newFileArray = newFileArray.slice(0, 5)
      }
      setState({
        fileArray: newFileArray
      })
      /* istanbul ignore else */
      if (fileArr.length) {
        onChange?.(newFileArray)
      }
    }
    /* istanbul ignore else */
    if (e.currentTarget) {
      e.currentTarget.value = ''
    }
  }

  const onDeletePreview = (index: number) => {
    return (): void => {
      const fileArr = fileArray.slice(0)
      fileArr.splice(index, 1)
      setState({ fileArray: fileArr })
      onChange?.(fileArr, true)
    }
  }

  const showSample = (): void => {
    setState({
      showPreview: true,
      imgUrl: sampleImg
    })
  }

  const openPreview = (img: string): void => {
    setState({
      showPreview: true,
      imgUrl: img
    })
  }

  const closePreview = (): void => {
    setState({
      showPreview: false
    })
  }
  const getFileTypeDesc = (): string => {
    // ignore jpeg
    return fileTypes
      .filter((v) => v !== 'jpeg')
      .join('/')
      .toUpperCase()
  }
  return (
    <div className={classnames(styles.uploadContainer, className)}>
      <div className={styles.fileInputContainer}>
        <label htmlFor="fileInput">fileInput</label>
        <input
          data-testid="fileInput"
          id="fileInput"
          type="file"
          ref={fileInput}
          onChange={onFileChange}
          multiple
        />
      </div>
      <Row>
        {fileArray.map((file, index) => (
          <Col
            data-testid="previewWrap"
            span={num}
            className={classnames(styles.previewWrap, index % 2 ? styles.even : styles.odd)}
            key={file.id}
          >
            <div className={classnames(styles.previewImg)}>
              {isImage(file.fileType) &&
                (file.loading ? (
                  <Spin />
                ) : (
                  <img
                    className={styles.uploadImg}
                    src={file.fileBase64}
                    alt=""
                    onClick={(): void => {
                      openPreview(file.fileBase64)
                    }}
                  />
                ))}
              {isPDF(file.fileType) && (
                <>
                  <Icon component={IconPdf} className={styles.uploadPdf} />
                  <p className={styles.fileName}>{file.fileName}</p>
                </>
              )}
            </div>
            <img
              className={styles.deleteIcon}
              src={deleteIcon}
              alt="delete"
              onClick={onDeletePreview(index)}
            />
            {loading && <LoadingMask data={[progressVal, 1 - progressVal]} />}
          </Col>
        ))}
        <Col span={num} className={styles.previewWrap}>
          <TouchFeedback activeClassName={styles.touchActive}>
            <div className={classnames(styles.uploadBox)} onClick={handleClick}>
              <p>
                <Icon component={IconUpload} className={styles.uploadIcon} />
              </p>
              <p className={styles.uploadText}>
                {t('za_invest_setup_upload_consent_page_upload')}({fileArray.length}/{max})<br />
                {getFileTypeDesc()}
              </p>
            </div>
          </TouchFeedback>
        </Col>
      </Row>
      {sampleImg && (
        <div className={styles.lookSample} onClick={showSample}>
          {sample}
        </div>
      )}
      <ZiPicturePreview show={showPreview} imgUrl={imgUrl} onClose={closePreview} />
    </div>
  )
}

export default forwardRef(FileUpload)
