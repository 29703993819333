import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Button, Icon } from 'zati-ui-web'
import IconSecurity from '@/assets/img/setup/security.svg'
import { useTranslation } from 'react-i18next'
import { SetupPageProps } from '@/common/types'
import { useSubmitSetup } from '@/hooks'
import { OpenStep } from '@/common/enums'
import { ZiLink, ZiModal, SetupTitle } from '@/components'
import { getLang } from '@/utils'
import { Timing } from '@/common/enums/time.enum'
import { callSystemInfo } from '@/service/nativeCall'

function OpenAccount({ nextPage }: SetupPageProps) {
  const { t } = useTranslation()
  const submitSetup = useSubmitSetup()
  const [showMsetup, setShowMsetup] = useState(false)
  useEffect(() => {
    const showMsetupFn = async () => {
      let bool = true
      const systemInfo = await callSystemInfo()
      console.log(systemInfo, 'systemInfo')
      const version = systemInfo?.version || ''
      if (!version || version?.includes('1.0.6') || Number(version.replace(/\./g, '')) < 107) {
        // 1.0.6及以下版本不展示
        // ios 1.0.7以下的版本没有返回version
        bool = false
      }
      setShowMsetup(bool)
    }
    showMsetupFn()
  }, [])

  const submit = async () => {
    await submitSetup({
      dataType: OpenStep.STEP_OPEN_ACCOUNT,
      openData: {}
    })
    nextPage()
  }
  const handleJump = (): void => {
    const openScope = window.localStorage.getItem('openScope')
    ZiModal.alert(<p>{t('4541_jumpto_msetup_tip', '确认跳转至内地开户吗？')}</p>, '', [
      {
        text: t('4541_jumpto_hksetup_tip_cancel', '取消'),
        onPress: (): void => {
          return
        }
      },
      {
        text: t('4541_jumpto_hksetup_tip_confirm', '确定'),
        onPress: (): void => {
          window.location.href = `/${getLang()}/msetup?openScope=${openScope}`
          return
        }
      }
    ])
  }
  console.log(showMsetup, 'showMsetup')
  return (
    <div className={styles.contentWrapper}>
      <SetupTitle text={t('za_invest_setup_open_title', '开立投资账户')} />
      <div className={styles.panel}>
        <p className={styles.panelTitle}>{t('za_invest_setup_open_tips_title', '开户贴士')}</p>
        <p className={styles.panelText}>
          <i>1</i>
          {t('za_invest_setup_open_tips_1')}
        </p>
        <p className={styles.panelText}>
          <i>2</i>
          {t('za_invest_setup_open_tips_2')}
        </p>
        <p className={styles.panelText}>
          <i>3</i>
          {t('za_invest_setup_open_tips_3')}
        </p>
      </div>
      <div className={styles.flex}>
        <div className={styles.left}>
          <Icon icon={IconSecurity} className={styles.iconSecurity} />
        </div>
        <p className={styles.right}>{t('za_invest_setup_open_company_info')}</p>
      </div>
      <div className="footer-btn-box">
        <p className={styles.mainTips}>
          {t('za_invest_setup_open__to_agree', '点击下一步即同意')}
          <span className="interactive-text">
            <ZiLink href={t('za_invest_setup_agreement_pdf_1')}>
              {t('za_invest_setup_agreement_1', '私隠政策聲明')}
            </ZiLink>
            {', '}
            <ZiLink href={t('za_invest_setup_agreement_pdf_2')}>
              {t('za_invest_setup_agreement_2', '收集個人資料聲明')}
            </ZiLink>
            {', '}
            <ZiLink href={t('za_invest_setup_agreement_pdf_3')}>
              {t('za_invest_setup_agreement_3', 'ZA Coin 條款及細則')}
            </ZiLink>
          </span>
        </p>
        <Button type="primary" block throttleTime={Timing.DEBOUNCE} onClick={submit}>
          {t('za_invest_setup_next_step', '下一步')}
        </Button>
        {showMsetup && (
          <p className={styles.footerDesc} onClick={handleJump}>
            {t('4541_setup_usecn', '使用内地身份证开户')}
          </p>
        )}
      </div>
    </div>
  )
}

export default OpenAccount
