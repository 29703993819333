import request from './lib/'
import { BankListType } from '@/common/schemas/card-mgmt/index.interface'
import * as Schemas from '@/common/schemas'

// request bosy 必须要传个参数
export function getBankList(data = { t: Math.random() }): Promise<BankListType> {
  return request('/pub/dict/banklist', {
    method: 'post',
    data: data
  })
}

// 获取初始化数据字典
export async function getInitDictionary(
  data = {}
): Promise<Schemas.DictionaryRetType<Schemas.InitDictionaryResType>> {
  return request('/pub/dict', {
    method: 'post',
    data: data
  })
}

export async function sendAuthCode(data = {}): Promise<any> {
  return request('/msg/message/getAuthCode', {
    method: 'post',
    data: data
  })
}

// 获取初始化国家信息
export async function getCountryDictionary(
  data = {}
): Promise<Schemas.DictionaryRetType<Schemas.CountryDictionaryType>> {
  return request('/pub/region', {
    method: 'post',
    data: data
  })
}

// 内地地址解析查询接口
export async function getAddress(
  data = {}
): Promise<Schemas.DictionaryRetType<Schemas.CountryDictionaryType>> {
  return request('/pub/address/resolution/query', {
    method: 'post',
    data: data
  })
}

export async function queryCurrOpenInfoByUserId(data = {}): Promise<any> {
  return request('/aggr/common/queryCurrOpenInfoByUserId', {
    method: 'post',
    data: data
  })
}

// 获取账户类型相关信息
export async function queryAccountInfoByAccountType(data = { accountType: 'fund' }): Promise<any> {
  return request('/user/queryAccountInfoByAccountType', {
    method: 'post',
    data: data,
    ignoreRepeat: true
  })
}

// APP策略，1-立即生效，2-下次生效，3-定时生效
type AppStrategy = '1' | '2' | '3'
export interface GrayDetailOut {
  [key: string]: {
    switchOpen: boolean
    appStrategy: AppStrategy
  }
}
export async function queryGrayDetail(grayKey?: string): Promise<GrayDetailOut> {
  return request('/pub/grayDetail', {
    method: 'post',
    ignoreRepeat: true,
    data: {
      grayKey
    }
  })
}
