import React, { useRef, useEffect, useState } from 'react'
import styles from './index.module.scss'
import lottie from 'lottie-web'
import classnames from 'classnames'

function SpreadFlowers() {
  const loadingRef = useRef<HTMLDivElement>(null)
  const [finish, setFinish] = useState(false)
  useEffect(() => {
    if (loadingRef.current) {
      const flowers = lottie.loadAnimation({
        name: 'flowers',
        container: loadingRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        animationData: require('../../assets/img/lottie/flowers.json')
      })
      flowers.addEventListener('complete', () => {
        // 动画结束
        setFinish(true)
      })
    }
  }, [])
  return (
    <div className={classnames(styles.flowersBox, finish ? styles.finish : '')}>
      <div ref={loadingRef} className={styles.flowers} />
    </div>
  )
}

export default SpreadFlowers
