import React from 'react'
import {
  callCloseWebview,
  callHideLeftBtn,
  callNativeSetBackBtn,
  navigateTo
} from '@/service/nativeCall'

export interface LinkProps {
  className?: string
  href: string
  children?: React.ReactNode
  style?: React.CSSProperties
  title?: string
}

function Link(props: LinkProps) {
  const { href, children, ...rest } = props
  const onClick = () => {
    callHideLeftBtn({ isHidden: false })
    callNativeSetBackBtn({
      onClick() {
        callCloseWebview()
      }
    }).then(() => {
      if (href) {
        navigateTo({ url: href })
      }
    })
  }
  return (
    <span onClick={onClick} {...rest}>
      {children}
    </span>
  )
}

export default Link
