import React from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'

export interface HeaderProp {
  title: string
  children?: React.ReactNode
}

function Header({ title, children }: HeaderProp): JSX.Element {
 return (
   <section className={classnames(styles.pagePadding, styles.titlePdTop)}>
     <div className={classnames('page-title', styles.title)}>{title}</div>
     {children}
   </section>
 )
}

export default Header
