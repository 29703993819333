import React from 'react'
import { SetupCareer } from '@/components'
import { selectCareerUserData, updateUserData } from '../../store/slices/career'
import { SetupPageProps } from '@/common/types'
import { selectInitDic } from '../../store/slices/init'
import { useSubmitSetup } from '@/hooks'
function Career(props: SetupPageProps): JSX.Element {
  const { nextPage } = props
  const submitSetup = useSubmitSetup()
  return (
    <SetupCareer
      nextPage={nextPage}
      submitSetup={submitSetup}
      selectInitDic={selectInitDic} // InitDictionaryResType
      selectCareerUserData={selectCareerUserData}
      updateUserData={updateUserData}
    />
  )
}

export default Career
