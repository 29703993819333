import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import './index.scss'
import Icon from '@ant-design/icons'
import { ReactComponent as IconClose } from '@/assets/img/icon-close.svg'

export interface Point {
  index: number
  x: number
  y: number
}

export interface PicturePreviewProps {
  className?: string
  show?: boolean
  imgUrl?: string
  closeOnClickModal?: boolean
  onClose?: (...args: unknown[]) => void
  onCancel?: (...args: unknown[]) => void
  minScale?: number
  maxScale?: number
}

const threshold = 10

const getLen = (points: Point[]): number => {
  try {
    const len = Math.sqrt(
      Math.pow(points[0].x - points[1].x, 2) + Math.pow(points[0].y - points[1].y, 2)
    )
    return len
  } catch (err) {
    return -1
  }
}
const touchPoint: Point[] = []
let movePoint: Point[] = []
function PicturePreview(props: PicturePreviewProps): JSX.Element | null {
  const { className, show = false, onClose, onCancel, closeOnClickModal = true, imgUrl } = props
  const [visible, setVisible] = useState(show)
  const [scale, setScale] = useState(1)
  const [preTime, setPreTime] = useState(Date.now())
  const [touching, setTouching] = useState(false)
  let container = document.querySelector('.zi-picture-preview-container')
  if (!container) {
    container = document.createElement('div')
    container.classList.add('zi-picture-preview-container')
    document.body.appendChild(container)
  }

  const handleCancel = (): void => {
    setVisible(false)
    onCancel?.()
    onClose?.()
  }

  const clickModal = (): void => {
    if (!closeOnClickModal) return
    handleCancel()
    setTimeout(() => {
      /* istanbul ignore else */
      if (container) {
        document.body.removeChild(container)
      }
    }, 0)
  }

  const handleTouchstart = (event: React.TouchEvent): void => {
    const touchList = [].slice.call(event.targetTouches)
    /* istanbul ignore else */
    if (touchList.length > 1) {
      touchList.forEach((item: Touch, index: number) => {
        const point = {
          index: item.identifier,
          x: item.clientX,
          y: item.clientY
        }
        touchPoint[index] = point
      })
      movePoint = JSON.parse(JSON.stringify(touchPoint))
    }
    setTouching(true)
  }
  const handleTouchmove = (event: React.TouchEvent): void => {
    const { minScale = 0.5, maxScale = 3 } = props
    const touchList = [].slice.call(event.changedTouches)
    touchList.forEach((item: Touch, index: number) => {
      const point = {
        index: item.identifier,
        x: item.clientX,
        y: item.clientY
      }
      const matchPoint = movePoint.find((item) => item.index === point.index)
      if (matchPoint) {
        matchPoint.x = point.x
        matchPoint.y = point.y
      } else {
        movePoint[index] = point
      }
    })
    /* istanbul ignore else */
    if (movePoint.length > 1) {
      const start = getLen(touchPoint)
      const end = getLen(movePoint)
      if (start < 0 || end < 0) {
        return
      }

      /* istanbul ignore else */
      if (threshold < Math.abs(start - end)) {
        const temp = end / start
        if (temp > maxScale) {
          setScale(maxScale)
        } else if (temp < minScale) {
          setScale(minScale)
        } else {
          setScale(temp)
        }
      }
    }
  }

  const scaleStyle = (): React.CSSProperties => {
    return {
      transform: `scale(${scale}, ${scale})`
    }
  }
  const handleDbClick = (): void => {
    if (touching) {
      return
    }
    if (scale < 1 || scale === 3) {
      setScale(1)
    } else if (scale < 2) {
      setScale(2)
    } else if (scale < 3) {
      setScale(3)
    }
  }

  const handleTouchend = (event: React.TouchEvent): void => {
    const idx1 = touchPoint.findIndex((item) => item.index === event.changedTouches[0].identifier)
    touchPoint.splice(idx1, 1)
    const idx2 = movePoint.findIndex((item) => item.index === event.changedTouches[0].identifier)
    movePoint.splice(idx2, 1)
    setTouching(false)
    /* istanbul ignore else */
    if (scale < 1) {
      setScale(1)
    }
  }

  useEffect(() => {
    setVisible(show)
  }, [show])

  useEffect(() => {
    const html = document.querySelector('html') as HTMLHtmlElement
    const body = document.querySelector('body') as HTMLBodyElement
    if (!visible) {
      html.style.overflow = ''
      body.style.overflow = ''
    } else {
      html.style.overflow = 'hidden'
      body.style.overflow = 'hidden'
    }
    return (): void => {
      html.style.overflow = ''
      body.style.overflow = ''
    }
  }, [visible])
  const onImgClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation()
    const now = Date.now()
    /* istanbul ignore else */
    if (now < preTime + 300) {
      handleDbClick()
    }
    setPreTime(now)
  }
  const Comp: JSX.Element = (
    <div className={classnames('zi-picture-preview', className)}>
      <div className="zi-picture-preview-content" onClick={clickModal}>
        <div>
          <Icon component={IconClose} onClick={handleCancel} />
        </div>
        <div
          className="zi-picture-preview-body"
          onTouchStart={handleTouchstart}
          onTouchMove={handleTouchmove}
          onTouchEnd={handleTouchend}
        >
          <span style={scaleStyle()} onClick={onImgClick} className="zi-picture-preview-wrapper">
            <img className="zi-picture-preview-img" src={imgUrl} alt={imgUrl} />
          </span>
        </div>
      </div>
      <div className="zi-picture-preview-mask" onClick={clickModal} />
    </div>
  )
  if (!visible) {
    return null
  }
  return ReactDOM.createPortal(Comp, container)
}

export default PicturePreview
