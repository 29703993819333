import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { DatePicker, List } from 'antd-mobile'
import styles from './index.module.scss'
import { PropsType as RawDatePickerProps } from 'antd-mobile/es/date-picker'
import iconArrowRight from '@/assets/img/icon-arrow-right.svg'
import { useTranslation } from 'react-i18next'

export interface DatePickerProps {
  rightArrow?: boolean
  border?: boolean
  readOnly?: boolean
  className?: string
  visible?: boolean
  value?: Date | string | undefined
  disabled?: boolean
  // format?: (value: Date) => Date | string
  title?: string | React.ReactElement
  label: string
  onHandleClick?: () => void
  onHandleOk?: (okRet: { dpValue: Date | string; visible: boolean }) => void
  onHandleDismiss?: (dismissRet: { visible: boolean }) => void
}

const locale_EN = {
  extra: '',
  okText: 'Ok',
  dismissText: 'Cancel',
  DatePickerLocale: {
    year: '',
    month: '',
    day: '',
    hour: '',
    minute: ''
  }
}

const ZiDatePicker = (props: DatePickerProps & RawDatePickerProps): JSX.Element => {
  const { i18n } = useTranslation()
  const language = i18n?.language || 'hk'
  const [defaultLocale, setDefaultLocale] = useState({})
  useEffect(() => {
    if (language === 'en') {
      setDefaultLocale({ locale: locale_EN })
    } else {
      setDefaultLocale({})
    }
  }, [language])
  const {
    rightArrow,
    border,
    readOnly,
    className,
    visible,
    label,
    value,
    onHandleOk,
    onHandleDismiss,
    onHandleClick,
    format,
    ...datePickerProps
  } = props

  const handleClick = (): void => {
    if (readOnly) return
    onHandleClick?.()
  }

  const handleOk = (date: Date | string): void => {
    onHandleOk?.({ dpValue: date, visible: false })
  }

  const handleDismiss = (): void => {
    onHandleDismiss?.({ visible: false })
  }

  const formatDate = (): Date | string => {
    if (!value) return ''
    if (typeof format === 'function') {
      return format(value)
    } else {
      return value
    }
  }

  return (
    <div className={classnames(styles.datePickerContainer, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <List.Item
        onClick={handleClick}
        className={classnames(styles.datePickerLine, { [styles.border]: border })}
      >
        {formatDate()}
        <div className={classnames(styles.selectArrow, { [styles.selectArrowHide]: !rightArrow })}>
          <img className={styles.iconSize} src={iconArrowRight} alt="select" />
        </div>
      </List.Item>
      <DatePicker
        {...defaultLocale}
        {...datePickerProps}
        visible={visible}
        value={value ? new Date(value) : new Date()}
        onOk={handleOk}
        onDismiss={handleDismiss}
      />
    </div>
  )
}

export default ZiDatePicker
