/* eslint-disable @typescript-eslint/no-require-imports */
export const lightTheme = {
  '--main-text-color': 'rgba(0, 0, 0, 0.87)',
  '--title-color': 'rgba(0, 0, 0, 0.87)',
  '--sub-text-color': 'rgba(0, 0, 0, 0.6)',
  '--page-bg-color': '#ffffff',
  '--main-bg-color': '#ffffff',
  '--popup-bg-color': '#fefefe',
  '--btn-disable-color': '#19f6b3',
  '--brand-color': '#19f6b3',
  '--border-color': '#eeeeee',
  '--check-active-icon': `url(${require('../theme/light/icons/icon-check-active.svg')})`,
  '--check-icon': `url(${require('../theme/light/icons/icon-check.svg')})`,
  '--radio-active-icon': `url(${require('../theme/light/icons/icon-radio-active.svg')})`,
  '--active-underline': `url(${require('../theme/dark/icons/active-underline.svg')})`
}
export const darkTheme = {
  '--main-text-color': 'rgba(255, 255, 255, 0.87)',
  '--title-color': 'rgba(255, 255, 255, 0.87)',
  '--sub-text-color': 'rgba(255, 255, 255, 0.6)',
  '--page-bg-color': '#13141b',
  '--popup-bg-color': '#262732',
  '--btn-disable-color': '#19f6b3',
  '--brand-color': '#19f6b3',
  '--check-active-icon': `url(${require('../theme/dark/icons/icon-check-active.svg')})`,
  '--check-icon': `url(${require('../theme/dark/icons/icon-check.svg')})`,
  '--radio-active-icon': `url(${require('../theme/dark/icons/icon-radio-active.svg')})`,
  '--active-underline': `url(${require('../theme/dark/icons/active-underline.svg')})`
}
