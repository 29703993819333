import React from 'react'
import { Link } from 'react-router-dom'

function SetupNav(): JSX.Element {
  return (
    <nav style={{ padding: 30 }}>
      <ul>
        <li>
          <Link to="/">首页</Link>
        </li>
        <li>
          <Link to="/open-account/">开户首页</Link>
        </li>
        <li>
          <Link to="/upload-id-card/">上传身份证（内地开户）</Link>
        </li>
        <li>
          <Link to="/confirm-identify/">身份证信息（内地开户）</Link>
        </li>
        <li>
          <Link to="/additional-identify/">补充身份证信息（内地开户）</Link>
        </li>
        <li>
          <Link to="/invite-code/">填写邀请码</Link>
        </li>
        <li>
          <Link to="/id-pre/">证件类型选择</Link>
        </li>
        <li>
          <Link to="/idcard/">身份证信息</Link>
        </li>
        <li>
          <Link to="/valid-bank/">银行四要素鉴权（内地开户）</Link>
        </li>
        <li>
          <Link to="/address/">你的居住地址</Link>
        </li>
        <li>
          <Link to="/taxation/">税务信息</Link>
        </li>
        <li>
          <Link to="/career/">你的职业信息</Link>
        </li>
        <li>
          <Link to="/finance/">风险问卷</Link>
        </li>
        <li>
          <Link to="/risk-confirm/">风险问卷结果确认</Link>
        </li>
        <li>
          <Link to="/other-data/">其他资料纰漏</Link>
        </li>
        <li>
          <Link to="/risk-show/">风险纰漏</Link>
        </li>
        <li>
          <Link to="/signature/">确认开户资料并签名</Link>
        </li>
        <li>
          <Link to="/upload-consent/">上传雇主同意书</Link>
        </li>
        <li>
          <Link to="/ca/">CA认证（驳回）</Link>
        </li>
        <li>
          <Link to="/result/">审核状态页</Link>
        </li>
      </ul>
    </nav>
  )
}

export default SetupNav
