import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import styles from './index.module.scss'
import classnames from 'classnames'

function IdTypeItem(props: any): JSX.Element {
  const { card, onItemClick } = props
  const { typeName, typeDesc, icon, disabled = false } = card

  const onIdTypeSelected = (): void => {
    onItemClick(disabled)
  }

  return (
    <div
      onClick={onIdTypeSelected}
      className={classnames(styles.idTypeItem, disabled ? styles.disabled : '')}
    >
      <Row>
        <Col span={15}>
          <div className={styles.typeName}>{typeName}</div>
          <div className={styles.typeDesc}>{typeDesc}</div>
        </Col>
        <Col span={9}>
          <img className={styles.typeIcon} src={icon} />
        </Col>
      </Row>
    </div>
  )
}

IdTypeItem.propTypes = {
  card: PropTypes.object,
  className: PropTypes.string,
  onItemClick: PropTypes.func
}

export default IdTypeItem
