import { createSlice } from '@reduxjs/toolkit'
import { SetupUserInfo, PdfItemType, PdfCodeItem } from '@/common/schemas/setup/'

interface UserSetupInfoState {
  userSetupInfo: {
    userSetupInfo: SetupUserInfo
    pdfList: PdfItemType[]
    pdfCodeList: PdfCodeItem[]
  }
}

export const slice = createSlice({
  name: 'userSetupInfo',
  initialState: {
    userSetupInfo: {},
    pdfList: [],
    pdfCodeList: []
  },
  reducers: {
    updateUserSetupInfo(state, action): void {
      state.userSetupInfo = action.payload
    },
    updatePdfList(state, action): void {
      state.pdfList = action.payload
    },
    updatePdfCodeList(state, action): void {
      state.pdfCodeList = action.payload
    }
  }
})

export const { updateUserSetupInfo, updatePdfList, updatePdfCodeList } = slice.actions

export const selectUserSetupInfo = (state: UserSetupInfoState): SetupUserInfo =>
  state.userSetupInfo.userSetupInfo

export const selectPdfList = (state: UserSetupInfoState): PdfItemType[] =>
  state.userSetupInfo.pdfList

export const selectPdfCodeList = (state: UserSetupInfoState): PdfCodeItem[] =>
  state.userSetupInfo.pdfCodeList

export default slice.reducer
