import { createSlice } from '@reduxjs/toolkit'
import * as Enums from '@/common/enums/index'
import { setupStep2Path } from '@/utils'

const PagePath = Enums.PagePathSetup

export const slice = createSlice({
  name: 'routes',
  initialState: {
    routes: [],
    defaultRoutes: [
      PagePath.PAGE_OPEN_ACCOUNT,
      PagePath.PAGE_INVITE_CODE,
      PagePath.PAGE_SETUP_PRE,
      PagePath.PAGE_IDCARD,
      PagePath.PAGE_ADDRESS,
      PagePath.PAGE_TAXATIOIN,
      PagePath.PAGE_CAREER,
      PagePath.PAGE_FINANCE,
      PagePath.PAGE_OTHER_DATA,
      PagePath.PAGE_RISK_SHOW,
      PagePath.PAGE_SIGNATURE,
      PagePath.PAGE_RESULT
    ]
  },
  reducers: {
    updateRoutes(state, action): void {
      state.routes = action.payload?.map((name: string) => {
        return setupStep2Path(name)
      })
    }
  }
})

export const { updateRoutes } = slice.actions

export const selectRoutes = (state: any): string[] => state.routes.routes
export const selectDefaultRoutes = (state: any): string[] => state.routes.defaultRoutes

export default slice.reducer
