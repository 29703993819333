export default [
  {
    title: 'za_invest_risk_result_page_risk_explain_title_conservative',
    desc: 'za_invest_risk_result_page_risk_explain_desc_conservative',
    titleDefault: '保守型',
    descDefault:
      '投資者傾向規避風險而比較著重資本保值及對抗通脹; 主要投資於保本產品或貨幣市場工具。合適產品的風險評級: 低'
  },
  {
    title: 'za_invest_risk_result_page_risk_explain_title_steadiness',
    desc: 'za_invest_risk_result_page_risk_explain_desc_steadiness',
    titleDefault: '穩健型',
    descDefault:
      '投資者願意承受低至中度風險以達致優於市場利率的回報; 主要投資於債務工具及少量股票以獲取穩定回報。合適產品的風險評級: 低至中或以下'
  },
  {
    title: 'za_invest_risk_result_page_risk_explain_title_balanced',
    desc: 'za_invest_risk_result_page_risk_explain_desc_balanced',
    titleDefault: '均衡型',
    descDefault:
      '投資者願意承受中度風險以尋求平穩回報; 主要投資於債券及股票的均衡組合以獲取收入及增長。合適產品的風險評級: 中或以下'
  },
  {
    title: 'za_invest_risk_result_page_risk_explain_title_growth',
    desc: 'za_invest_risk_result_page_risk_explain_desc_growth',
    titleDefault: '增長型',
    descDefault:
      '投資者願意承受較高的風險以尋求更高回報; 主要投資於價格波動相對較大的投資產品。合適產品的風險評級: 中至高或以下'
  },
  {
    title: 'za_invest_risk_result_page_risk_explain_title_aggressive',
    desc: 'za_invest_risk_result_page_risk_explain_desc_aggressive',
    titleDefault: '進取型',
    descDefault:
      '投資者願意承受重大的風險以獲取顯著的回報; 主要投資於有最高潛在總回報的高風險資產。合適產品的風險評級: 高或以下'
  }
]
