export enum BridgeProtocol {
  HTTP_REQUEST = 'invest://fetch.app.action?',
  CALL_EKYC = 'invest://startEKYC.app.action?',
  CALL_UPLOAD = 'invest://upload.app.action?',
  CALL_DOWNLOAD = 'invest://download.app.action?',
  CALL_SELECT_FILE = 'invest://selectFile.app.action?',
  CALL_OPEN_PDF = 'invest://openPDF.app.action?',
  CALL_SET_NAVBAR_BACK_BTN = 'invest://setNavigationBarBackButton.webView.action?',
  CALL_SET_NAVBAR_RIGHT_BTN = 'invest://setNavigationBarRightButton.webView.action?',
  CALL_SET_NAVBAR_TITLE = 'invest://setNavigationBarTitle.webView.action?',
  CALL_RESET_NAVBAR = 'invest://resetNavigationBarStyle.webView.action?',
  CALL_CLOSE_WEBVIEW = 'invest://close.webView.action?',
  CALL_HIDE_RIGHT_BUTTON = 'invest://setNavigationBarRightButtonIsHidden.webView.action?',
  CALL_HIDE_LEFT_BUTTON = 'invest://setNavigationBarBackButtonIsHidden.webView.action?',
  CALL_NAVIGATE_TO = 'invest://navigateTo.webView.action?',
  CALL_NAVIGATE_BACK = 'invest://navigateBack.webView.action?',
  CALL_POST_JS_EVENT = 'invest://postJsEvent.webView.action?',
  CALL_GET_SYSTEM_INFO = 'invest://getSystemInfo.webView.action?',
  CALL_LOGIN = 'invest://login.webView.action?',
  CALL_TRADEPASSWORD = 'invest://verify_tran_pass.zafam.action?'
}

export enum NativeFuncnName {
  FUNCTION_EKYC = 'startEKYC',
  FUNCTION_UPLOAD = 'upload',
  FUNCTION_DOWNLOAD = 'download',
  FUNCTION_SELECT_FILE = 'selectFile',
  FUNCTION_OPEN_PDF = 'openPDF',
  FUNCTION_NAVBAR_TITLE = 'setNavigationBarTitle',
  FUNCTION_NAVBAR_RIGHT_BTN = 'setNavigationBarRightButton',
  FUNCTION_NAVBAR_BACK_BTN = 'setNavigationBarBackButton',
  FUNCTION_NAVBAR_RESET = 'resetNavigationBarStyle',
  FUNCTION_CLOSE_WEBVIEW = 'closeWebview',
  FUNCTION_HIDE_RIGHT_BUTTON = 'hideRightButton',
  FUNCTION_HIDE_LEFT_BUTTON = 'hideLeftButton',
  FUNCTION_NAVIGATE_TO = 'navigateTo',
  FUNCTION_NAVIGATE_BACK = 'navigateBack',
  FUNCTION_POST_JS_EVENT = 'postJsEvent',
  FUNCTION_GET_SYSTEM_INFO = 'getSystemInfo',
  FUNCTION_LOGIN = 'login',
  FUNCTION_TRADEPASSWORD = 'tradePassword'
}

// native调用js事件
export enum NativeEvent {
  // 交易密码登录成功APP会调用H5的方法通知H5
  EVENT_TRADE_PASS = 'tradePass'
}

// js调用native事件
export enum JsEvent {
  // 风险测评后通知APP重新获取结果
  EVENT_AFTER_RISK_ASSESS = 'riskLevel',
  // 通知开户变更
  OPEN_ACCOUNT = 'fundAccount',
  // 通知全权委托账户变更
  IPO_ACCOUNT_STATUS = 'IPOAccountStatus'
}

export enum CallBackId {
  // 回退按钮callback id
  CALL_NATIVE_BACK_BTN_ID = 'callNativeSetBackBtnId',
  // 关闭按钮callback id
  CALL_NATIVE_RIGHT_BTN_ID = 'callNativeSetRightBtnId'
}
