import { getOpenAccNo, getMSetupAccountOpenInfo } from '@/service/accountCenter'
import { getInitDictionary, getCountryDictionary } from '@/service/commonModule'
import { updateUserData } from '../../store/slices/career'
import { updateRejectInfo } from '../../store/slices/reject'
import { updateOtherMsgShow } from '../../store/slices/otherData'
import { updateUserSetupInfo } from '../../store/slices/userSetupInfo'
import { updateIncomeInfo, updateRiskResult } from '../../store/slices/finance'
import { updateSignatureData } from '../../store/slices/riskShow'
import { updateAddressInfo } from '../../store/slices/address'
import { updateInitDic, updateInitCountryDic, updateOpenScope } from '../../store/slices/init'
import { updateIdcardInfo } from '../../store/slices/idCard'
import { forEach, isArray, camelCase } from 'lodash'
import { InitDictionary, AccountInfoResType } from '@/common/schemas'
import { SelectOptionItem } from '@/common/types'
import { OpenStatus } from '@/common/enums/index'
import { updateRoutes } from '../../store/slices/routes'
import { updateEmployerAgreeInfo } from '../../store/slices/uploadConsent'
import { updateTaxMag } from '../../store/slices/taxation'
import { updateValidBankInfo } from '../../store/slices/validBank'
import { updateIdentify } from '../../store/slices/identify'
import { updateUploadCard } from '../../store/slices/uploadCard'
import { urlQuery } from '@/utils'
import { reportCommonException } from '@/service/nativeCall'

// 有效的 openScope 值
const validOpenScope = ['fund', 'delegation', 'stock']

export default async function (store: any): Promise<void> {
  let openAccNo = ''
  const openScopeQueryString = urlQuery('openScope')
  const openScopeArr = openScopeQueryString
    .split(',')
    .filter((item) => validOpenScope.includes(item))
  // 确保 fund 存在数组中
  // openScopeArr.push('fund')
  try {
    const openAccNoData = await getOpenAccNo({
      openType: '2',
      openScope: [...new Set(openScopeArr)] as AccountInfoResType['openScope']
    })
    openAccNo = openAccNoData.openAccNo
    store.dispatch(
      updateUserSetupInfo({
        openAccNo: openAccNoData.openAccNo
      })
    )
  } catch (e) {
    reportCommonException('msetup-initData', Error('获取开户流水号失败'))
    console.log(e)
  }
  getInitDictionary().then((resp: any) => {
    const res: { [key: string]: SelectOptionItem[] } = {}
    if (resp && isArray(resp.list)) {
      forEach(resp.list, (item: InitDictionary): void => {
        const { code, desc, value, order } = item
        const newItem = {
          value,
          order,
          label: desc
        }
        const camelCaseCode = camelCase(code)
        if (isArray(res[camelCaseCode])) {
          res[camelCaseCode].push(newItem)
        } else {
          res[camelCaseCode] = [newItem]
        }
      })
    }
    store.dispatch(updateInitDic(res))
  })
  getCountryDictionary().then((res) => {
    if (res?.list) {
      let countryList = res.list.map((item) => {
        return {
          value: item.code,
          label: item.name,
          order: item.order
        }
      })
      countryList = countryList.filter((item) => !['UM', 'US'].includes(item.value))
      store.dispatch(updateInitCountryDic(countryList))
    }
  })
  getMSetupAccountOpenInfo({
    openChannel: 'H5',
    applyAccType: 'fund',
    openAccNo: openAccNo
  }).then((res) => {
    if (res?.openInfo) {
      const openInfo = res?.openInfo || {}
      let {
        professMsg,
        identifyMsg,
        addrMsg,
        riskMarkMsg,
        agreementSignatureMsg,
        taxMsg,
        otherDisclosureMsg,
        validBankMsg = { reservedPhone: '' },
        inlandIdentifyMsg = {},
        uploadIdCardMsg,
        replenishIdentifyMsg
      } = openInfo
      validBankMsg.reservedPhone = validBankMsg?.reservedPhone || addrMsg?.phone
      store.dispatch(updateUserData(professMsg))
      store.dispatch(updateRejectInfo(res.rejectInfo))
      store.dispatch(updateIdcardInfo(identifyMsg))
      store.dispatch(updateAddressInfo(addrMsg))
      store.dispatch(updateIncomeInfo(riskMarkMsg?.riskSubmitResult))
      store.dispatch(updateRiskResult(riskMarkMsg))
      store.dispatch(updateOtherMsgShow(otherDisclosureMsg?.otherDisclosureStr))
      store.dispatch(updateSignatureData(agreementSignatureMsg))
      store.dispatch(updateTaxMag(taxMsg?.taxAreaInfo))
      store.dispatch(updateValidBankInfo(validBankMsg))
      store.dispatch(updateIdentify(inlandIdentifyMsg))
      store.dispatch(updateIdentify(replenishIdentifyMsg))
      store.dispatch(updateUploadCard(uploadIdCardMsg))
      store.dispatch(
        updateUserSetupInfo({
          openAccNo: res.openAccNo,
          openStatus: res.openStatus,
          openStep: res.openStep,
          isApplyDelegation: res.isApplyDelegation,
          delegationOpenStatus: res?.delegationOpenStatus
        })
      )

      store.dispatch(updateOpenScope(res.openScope))
      store.dispatch(updateEmployerAgreeInfo(res.openInfo?.employerAgreeMsg))

      // 审核拒绝时，取rejectStep作路由数组
      if (res.openStatus === OpenStatus.REJECT) {
        let rejectStep = res.rejectInfo?.rejectStep
        // 如果返回了，其它资料披露，需要过滤雇主同意书页
        if (rejectStep.includes('otherMsgShow')) {
          rejectStep = rejectStep.filter((item) => item !== 'uploadEmployerAgree')
        }
        store.dispatch(updateRoutes(rejectStep))
      } else {
        console.log(`openTotalStep`, res.openTotalStep)
        store.dispatch(updateRoutes(res.openTotalStep))
      }
    }
  })
}
