import React from 'react'
import SetupNav from '@/components/SetupNav'

function NavsPage(): JSX.Element {
  return (
    <div>
      <SetupNav />
    </div>
  )
}

export default NavsPage
