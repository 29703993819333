import request from './lib/'
import { CashinReqType, SubAcctInfo, LastCashinInfo } from '@/common/schemas/cashin/index.interface'
import * as Schemas from '@/common/schemas'

export function applyCashIn(data: CashinReqType): Promise<any> {
  return request('/cashio/applyCashIn', {
    method: 'post',
    data: data
  })
}

export function getCompanyBankList(): Promise<Schemas.CompanyBankListResType> {
  return request('/cashio/companyBankList', {
    method: 'post',
    data: {},
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export async function getCashQuery(data = {}): Promise<Schemas.CashQueryResType> {
  return request('/cashio/cashQuery', {
    method: 'post',
    data: data
  })
}

export async function applyCashOut(data = {}): Promise<any> {
  return request('/cashio/applyCashOut', {
    method: 'post',
    data: data
  })
}

export async function getMoneyRecordList(
  data: Schemas.MoneyRecordListReqType
): Promise<Schemas.MoneyRecordListResType> {
  return request('/cashio/cashHistory', {
    method: 'post',
    data: data
  })
}

export async function queryAccountInfo(): Promise<Schemas.AccountInfoType> {
  return request('/user/queryAccountInfo', {
    method: 'post',
    data: {}
  })
}

export async function getRecordDetail(
  data: Schemas.MoneyRecordDetailReqType
): Promise<Schemas.MoneyRecordDetailResType> {
  return request('/cashio/cashHistoryDetail', {
    method: 'post',
    data: data
  })
}

export async function withdrawCashin(applyNo: string): Promise<any> {
  return request('/cashio/applyCashInCancel', {
    method: 'post',
    data: { applyNo }
  })
}

export interface ApplyCashInCanCancelOut {
  result: boolean
}
// 查询入金是否可撤回
export async function applyCashInCanCancel(applyNo: string): Promise<ApplyCashInCanCancelOut> {
  return request('/cashio/applyCashInCanCancel', {
    method: 'post',
    data: { applyNo }
  })
}

export async function querySubAccountInfo(): Promise<SubAcctInfo> {
  return request('/cashio/subAcct/info', {
    method: 'post',
    data: {},
    loading: true
  })
}

interface subAcctCashInParams {
  currency: string
  amt: string
  subAcctNo: string
}

export async function subAcctCashIn(params: subAcctCashInParams): Promise<SubAcctInfo> {
  return request('/cashio/subAcct/cashIn', {
    method: 'post',
    data: params
  })
}

export async function lastCashinInfo(params = {}): Promise<LastCashinInfo> {
  return request('/cashio/latestCashInSuccessInfo', {
    method: 'post',
    data: params
  })
}
// -获取入金展示卡的信息
export async function getCashInCardInfo() {
  return request('/cashio/getCashInCardInfo', {
    method: 'post',
    data: {}
  })
}

export async function exchangeRecordList(params = {}) {
  return request('/cashio/exchangeRecordList', {
    method: 'post',
    data: params
  })
}

// /cashio/getCashInCardInfo
