import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ZiFileUpload, ZiProgressBar, ZiToast, ZiInput } from '@/components'
import { Button, ToolTip, Icon } from 'zati-ui-web'
import { Form } from 'antd'
import styles from './index.module.scss'
import { useSetState, useSubmitSetup } from '@/hooks'
import { base64WithHeader, base64WithoutHeader, addUidToListItem } from '@/utils'
import { SetupPageProps, Base64Item, FileItem } from '@/common/types'
import { PagePathSetup, OpenStep } from '@/common/enums'
import { uploadFileBase64, downLoadFileBase64 } from '@/service/fileCenter'
import { FileUploadHandles } from '@/components/FileUpload'
import CircleTips from '@/assets/img/setup/icon-circle-tips.svg'
import {
  updateFiles,
  selectEmployerAgreeInfo,
  updateObjectKeys,
  updateEmployerAgreeInfo
} from '@/pages/setup/store/slices/uploadConsent'
import { useSelector, useDispatch } from 'react-redux'
import { pick, cloneDeep } from 'lodash'
import sampleGraph from '@/assets/img/consent-letter-template.jpg'
import classnames from 'classnames'
import { DownloadFileBase64Type } from '@/common/schemas'
import { Timing } from '@/common/enums/time.enum'

// 可上传的最大文件数量
const MAX_FILE_COUNT = 5

function UploadConsent(props: SetupPageProps): JSX.Element {
  const { t } = useTranslation()
  const { files: storeFiles, objectKeys, organizationName, centralNumber } = useSelector(
    selectEmployerAgreeInfo
  )

  const uploadRef = useRef<FileUploadHandles>(null)
  const dispatch = useDispatch()
  const submitSetup = useSubmitSetup()
  const [form] = Form.useForm()
  const [state, setState] = useSetState<{
    isInit: boolean
    files: FileItem[]
    base64Arr: Base64Item[]
  }>({
    isInit: false,
    files: storeFiles,
    base64Arr: []
  })
  const { files, isInit } = state
  const handleFileChange = (files: FileItem[]): void => {
    setState({
      files
    })
    dispatch(updateFiles(files))
    dispatch(updateObjectKeys(files.map((item) => item.objectKey)))
  }

  const updateUpload = (state: any): void => {
    if (uploadRef.current) {
      uploadRef.current.updateState(state)
    }
  }

  useEffect(() => {
    if (objectKeys.length === 0) {
      return
    }
    const func = (index: number): void => {
      // 请求前添加loading状态为true
      // isExist 记录是否存在，防止重复添加条相同的图片
      let isExist = false
      let len = 0
      setState((prevState) => {
        const newFiles = cloneDeep(prevState.files)
        len = newFiles.length
        if (newFiles.find((item) => item.objectKey === objectKeys[index])) {
          isExist = true
        }
        if (isExist || len >= MAX_FILE_COUNT) {
          return {}
        }
        newFiles.push({
          fileName: '',
          fileType: `${objectKeys[index].indexOf('pdf') >= 0 ? 'application/pdf' : 'image/png'}`,
          fileBase64: '',
          objectKey: objectKeys[index],
          loading: true
        })
        addUidToListItem(newFiles)
        return {
          files: newFiles
        }
      })
      if (isExist || len >= MAX_FILE_COUNT) {
        return
      }
      const handleReuqestSuccess = (result: DownloadFileBase64Type) => {
        setState((prevState) => {
          const newFiles = [...prevState.files]
          const newItem = base64WithHeader({
            fileName: result.fileName,
            fileType: result.fileType,
            fileBase64: result.fileBase64,
            uploaded: true,
            loading: false,
            objectKey: objectKeys[index]
          })

          const idx = newFiles.findIndex((item) => item.objectKey === objectKeys[index])
          if (idx >= 0) {
            newFiles[idx] = newItem
          } else {
            newFiles.push(newItem)
          }
          addUidToListItem(newFiles)
          return {
            files: newFiles
          }
        })
      }
      if (!objectKeys[index]) {
        return
      }
      downLoadFileBase64({
        fileKey: objectKeys[index],
        needBase64: true
      })
        .then(handleReuqestSuccess)
        .catch((err) => {
          console.log('err', err)
          // 请求失败时 loading 状态设置为 false
          setState((prevState) => {
            const newFiles = [...prevState.files]
            const idx = newFiles.findIndex((item) => item.objectKey === objectKeys[index])
            if (idx >= 0) {
              newFiles[idx] = {
                ...newFiles[idx],
                loading: false
              }
            }
            addUidToListItem(newFiles)
            return {
              files: newFiles
            }
          })
        })
    }
    for (let i = 0; i < objectKeys.length; i++) {
      func(i)
    }
  }, [objectKeys, setState])

  useEffect(() => {
    if (!isInit) {
      form.setFieldsValue({
        organizationName,
        centralNumber
      })
      setState({ isInit: true })
    }
  }, [organizationName, centralNumber, isInit, form, setState])

  useEffect(() => {
    updateUpload({ fileArray: files })
  }, [files])

  const handleSubmit = async (): Promise<void> => {
    try {
      dispatch(updateFiles(files))
      const uploadedObjectKeys = files.filter((item) => item.uploaded).map((item) => item.objectKey)
      const allObjectKeys = [...uploadedObjectKeys]
      let uploadFlag = true
      // 过滤已上传的文件
      if (files.filter((item) => !item.uploaded).length) {
        const uploadResponse = await uploadFileBase64({
          files: files
            .filter((item) => !item.uploaded)
            .map((item) => pick(base64WithoutHeader(item), ['fileBase64', 'fileName', 'fileType']))
        })
        allObjectKeys.push(...uploadResponse.objectKey)
        uploadFlag = uploadResponse.uploadFlag
        if (uploadResponse.uploadMsg) {
          ZiToast.info(uploadResponse.uploadMsg)
        }
      }
      form.validateFields().then(async (values) => {
        await submitSetup({
          dataType: OpenStep.STEP_EMPLOYER_AGREE,
          openData: {
            employerAgreeMsg: {
              employerAgree: JSON.stringify(allObjectKeys),
              ...values
            }
          }
        })
        dispatch(updateEmployerAgreeInfo(values))
        props.nextPage()
        if (!uploadFlag) {
          props.jumpPage({ targetPage: PagePathSetup.PAGE_UPLOAD_CONTENT })
        }
      })
    } catch (error) {
      props.jumpPage({ targetPage: PagePathSetup.PAGE_UPLOAD_CONTENT })
    }
  }

  return (
    <div className={styles.page}>
      <div className="commonPadding">
        <ZiProgressBar
          current={4}
          title={t('za_invest_setup_upload_consent_page_title')}
          progress={70}
        />
      </div>
      <p className="page-title commonPadding">
        {t('za_invest_setup_upload_consent_page_title')}
        <ToolTip
          placement="bottom"
          overlay={t('za_invest_setup_upload_consent_page_desc_1')}
          className={styles.tooltip}
          overlayClassName="uploadTipsOverlay"
          trigger="click"
        >
          <Icon icon={CircleTips} />
        </ToolTip>
      </p>
      <div className="panel">
        <ZiFileUpload
          ref={uploadRef}
          fileTypes={['png', 'jpeg', 'jpg']}
          onChange={handleFileChange}
          initialValue={files}
          sampleImg={t('consent_letter_sample_imgsrc') || sampleGraph}
          sample={t('za_invest_setup_upload_consent_page_upload_sample', '查看示例图')}
        />
      </div>
      <Form className={classnames('panel', styles.form)} form={form}>
        <Form.Item
          name="organizationName"
          rules={[
            {
              required: true,
              message: t('za_invest_setup_name_of_registration_agency_required')
            }
          ]}
        >
          <ZiInput placeholder={t('za_invest_setup_name_of_registration_agency')} maxLength={150} />
        </Form.Item>
        <Form.Item
          name="centralNumber"
          rules={[
            {
              required: true,
              message: t('za_invest_setup_central_number_required')
            }
          ]}
        >
          <ZiInput placeholder={t('za_invest_setup_central_number')} maxLength={64} />
        </Form.Item>
      </Form>
      <div className={classnames(styles.footer, 'footer-btn-box')}>
        <Button
          className="zi-btn"
          type="primary"
          block
          onClick={handleSubmit}
          throttleTime={Timing.DEBOUNCE}
          disabled={files.length === 0}
        >
          {t('za_invest_setup_upload_consent_page_next_step')}
        </Button>
      </div>
    </div>
  )
}

export default UploadConsent
