// const seagullHost: any = {
//   dev: 'home-test.zatech.com',
//   sit: 'home-test.zatech.com',
//   uat: 'home-uat.zatech.com',
//   prd: 'za.group'
// }

export enum SEAGULL_HOST {
  dev = 'home-dev.zatech.com',
  dev01 = 'home-dev.zatech.com',
  sit = 'home-sit.zatech.com',
  sit01 = 'home-sit.zatech.com',
  uat = 'home-uat.zatech.com',
  uat01 = 'home-uat.zatech.com',
  prd = 'za.group'
}

export enum INVEST_HOST {
  dev = 'https://invest-appgw-dev.zatech.com',
  dev01 = 'https://invest-appgw-dev01.zaintl.com',
  sit = 'https://invest-appgw-sit.zatech.com',
  sit01 = 'https://invest-appgw-sit01.zaintl.com',
  uat = 'https://invest-appgw-uat.zatech.com',
  uat01 = 'https://invest-appgw-uat01.zaintl.com',
  prd = 'https://invest-appgw.zajourney.com'
}
export enum INVEST_FILE_HOST {
  dev = 'https://invest-filegw-dev.zatech.com',
  dev01 = 'https://invest-filegw-dev01.zaintl.com',
  sit = 'https://invest-filegw-sit.zatech.com',
  sit01 = 'https://invest-filegw-sit01.zaintl.com',
  uat = 'https://invest-filegw-uat.zatech.com',
  uat01 = 'https://invest-filegw-uat01.zaintl.com',
  prd = 'https://invest-filegw.zajourney.com'
}
export enum INVEST_OFFICIAL_HOST {
  dev = 'https://invest-dev.zatech.com',
  dev01 = 'https://invest-dev01.zaintl.com',
  sit = 'https://invest-sit.zatech.com',
  sit01 = 'https://invest-sit01.zaintl.com',
  uat = 'https://invest-uat.zatech.com',
  uat01 = 'https://invest-uat01.zaintl.com',
  prd = 'https://invest.za.group'
}

export enum IP_HOST {
  dev = 'https://integrate-appgw-dev.zatech.com',
  dev01 = 'https://integrate-appgw-dev.zatech.com',
  sit = 'https://integrate-appgw-sit.zatech.com',
  sit01 = 'https://integrate-appgw-sit.zatech.com',
  uat = 'https://integrate-appgw-uat.zatech.com',
  uat01 = 'https://integrate-appgw-uat.zatech.com',
  prd = 'https://integrate-appgw.zajourney.com'
}
