import React, { useRef } from 'react'
import classnames from 'classnames'
import styles from './index.module.scss'
import { ReactComponent as CircleSuccess } from '@/assets/img/icon-circle-success.svg'
import Icon from '@ant-design/icons'

interface ProgressBarProps {
  className?: string
  max?: number
  current?: number
  progress?: number
  title?: string
}

function ProgressBar(props: ProgressBarProps): JSX.Element {
  const { className, max = 5, current = 1, title = '' } = props
  const titleRef = useRef<HTMLDivElement>(null)
  const renderIcon = (): JSX.Element | null => {
    if (current > 1) {
      return (
        <div className={styles.iconGroup}>
          {new Array(current - 1).fill(0).map((_, index: number) => (
            <Icon key={index} component={CircleSuccess} className={styles.successIcon} />
          ))}
        </div>
      )
    }
    return null
  }
  const renderRestStep = (): JSX.Element => {
    const restStep = new Array(max - current).fill(0).map((_, index) => max - index)
    restStep.reverse()
    return (
      <div className={styles.stepGroup}>
        {restStep.map((step: number, index: number) => (
          <span className={styles.step} key={index}>
            {step}
          </span>
        ))}
      </div>
    )
  }
  const getMarginLeft = () => {
    const baseLeft = 8
    if (current < 2) {
      return baseLeft
    }
    return (current - 1) * 24
  }

  return (
    <div className={classnames(styles.container, className)}>
      {renderIcon()}
      <div
        className={styles.title}
        ref={titleRef}
        style={{ paddingLeft: `${getMarginLeft() / 100}rem` }}
      >
        <span className={styles.currentStep}>{current}</span>
        {title}
      </div>
      {current < max && <div className={styles.bar} />}
      {renderRestStep()}
    </div>
  )
}

export default ProgressBar
