export const initDicData = {
  annualIncome: [
    {
      value: '1',
      order: '1',
      label: '< $120,001'
    },
    {
      value: '2',
      order: '2',
      label: '$120,001 - $500,000'
    },
    {
      value: '3',
      order: '3',
      label: '$500,001 - $1,000,000'
    },
    {
      value: '4',
      order: '4',
      label: '$1,000,001 - $2,000,000'
    },
    {
      value: '5',
      order: '5',
      label: '> $2,000,000'
    }
  ],
  eduDegree: [
    {
      value: '1',
      order: '1',
      label: '小學或以下'
    },
    {
      value: '2',
      order: '2',
      label: '中學'
    },
    {
      value: '3',
      order: '3',
      label: '大專或預科'
    },
    {
      value: '4',
      order: '4',
      label: '大學或本科'
    },
    {
      value: '5',
      order: '5',
      label: '碩士或以上'
    }
  ],
  investInterested: [
    {
      value: '1',
      order: '1',
      label: '潛在回報3%，潛在損失3%'
    },
    {
      value: '2',
      order: '2',
      label: '潛在回報8%，潛在損失8%'
    },
    {
      value: '3',
      order: '3',
      label: '潛在回報15%，潛在損失15%'
    },
    {
      value: '4',
      order: '4',
      label: '潛在回報25%，潛在損失25%'
    },
    {
      value: '5',
      order: '5',
      label: '潛在回報40%，潛在損失40%'
    }
  ],
  investLimit: [
    {
      value: '1',
      order: '1',
      label: '少於 1年'
    },
    {
      value: '2',
      order: '2',
      label: '3年或以下'
    },
    {
      value: '3',
      order: '3',
      label: '5年或以下'
    },
    {
      value: '4',
      order: '4',
      label: '10年或以下'
    },
    {
      value: '5',
      order: '5',
      label: '超過10年'
    }
  ],
  investTarget: [
    {
      value: '1',
      order: '1',
      label: '安全為本：接受以最低風險獲取優於存款利率的回報'
    },
    {
      value: '2',
      order: '2',
      label: '收入及穩定：達致穩定及適度回報，同時著重經常性收入'
    },
    {
      value: '3',
      order: '3',
      label: '財富累積：以任何投資工具爭取最高總回報，長遠而言以資本增值為首要目標'
    }
  ],
  isInvestDerivative: [
    {
      value: '1',
      order: '1',
      label: '有'
    },
    {
      value: '2',
      order: '2',
      label: '没有'
    }
  ],
  isInvestFixed: [
    {
      value: '1',
      order: '1',
      label: '有'
    },
    {
      value: '2',
      order: '2',
      label: '没有'
    }
  ],
  isInvestMutual: [
    {
      value: '1',
      order: '1',
      label: '有'
    },
    {
      value: '2',
      order: '2',
      label: '没有'
    }
  ],
  isInvestSecurities: [
    {
      value: '1',
      order: '1',
      label: '有'
    },
    {
      value: '2',
      order: '2',
      label: '没有'
    }
  ],
  needCash: [
    {
      value: '1',
      order: '1',
      label: '100%'
    },
    {
      value: '2',
      order: '2',
      label: '75%'
    },
    {
      value: '3',
      order: '3',
      label: '50%'
    },
    {
      value: '4',
      order: '4',
      label: '25%'
    },
    {
      value: '5',
      order: '5',
      label: '0%'
    }
  ],
  riskHandle: [
    {
      value: '1',
      order: '1',
      label: '立即出售投資，止損離場'
    },
    {
      value: '2',
      order: '2',
      label: '出售大部份的投資，並持有餘下的投資'
    },
    {
      value: '3',
      order: '3',
      label: '賣出售小部份的投資，並持有餘下的投資'
    },
    {
      value: '4',
      order: '4',
      label: '繼續持有投資，靜觀其變'
    },
    {
      value: '5',
      order: '5',
      label: '增持投資以捕捉跌市機會'
    }
  ],
  totalMoney: [
    {
      value: '1',
      order: '1',
      label: '< $100,001'
    },
    {
      value: '2',
      order: '2',
      label: '$100,001 - $500,000'
    },
    {
      value: '3',
      order: '3',
      label: '$500,001 - $4,000,000'
    },
    {
      value: '4',
      order: '4',
      label: '$4,000,001 - $8,000,000'
    },
    {
      value: '5',
      order: '5',
      label: '> $8,000,000'
    }
  ],
  accessAmount: [
    {
      value: '1',
      order: '1',
      label: '< $50,001'
    },
    {
      value: '2',
      order: '2',
      label: ' $50,001 - $500,000'
    },
    {
      value: '3',
      order: '3',
      label: ' $500,001 - $4,000,000'
    },
    {
      value: '4',
      order: '4',
      label: ' $4,000,001 - $8,000,000'
    },
    {
      value: '5',
      order: '5',
      label: ' > $8,000,000'
    }
  ],
  bankCode: [
    {
      value: '003',
      order: '1',
      label: '渣打银行(香港)有限公司'
    },
    {
      value: '004',
      order: '1',
      label: '香港上海汇丰银行有限公司'
    },
    {
      value: '005',
      order: '1',
      label: '法国东方汇理银行'
    },
    {
      value: '006',
      order: '1',
      label: '花旗银行 香港 分行'
    },
    {
      value: '007',
      order: '1',
      label: '摩根大通银行'
    },
    {
      value: '008',
      order: '1',
      label: '国民西敏寺资本市场银行有限公司'
    },
    {
      value: '009',
      order: '1',
      label: '中国建设银行(亚洲)股份有限公司'
    },
    {
      value: '012',
      order: '1',
      label: '中国银行(香港)有限公司'
    },
    {
      value: '014',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '015',
      order: '1',
      label: '东亚银行有限公司'
    },
    {
      value: '016',
      order: '1',
      label: '星展银行(香港)有限公司'
    },
    {
      value: '018',
      order: '1',
      label: '中信银行(国际)有限公司'
    },
    {
      value: '019',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '020',
      order: '1',
      label: '招商永隆銀行有限公司'
    },
    {
      value: '022',
      order: '1',
      label: 'OVERSEA - CHINESE BANKING CORPORATION LIMITED'
    },
    {
      value: '024',
      order: '1',
      label: '恒生银行有限公司'
    },
    {
      value: '025',
      order: '1',
      label: '上海商业银行有限公司'
    },
    {
      value: '026',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '027',
      order: '1',
      label: '交通银行股份有限公司 香港分行'
    },
    {
      value: '028',
      order: '1',
      label: '大众银行(香港)有限公司'
    },
    {
      value: '029',
      order: '1',
      label: '中国工商银行(亚洲)'
    },
    {
      value: '030',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '031',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '032',
      order: '1',
      label: '星展銀行 (香港)有限公司'
    },
    {
      value: '033',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '035',
      order: '1',
      label: '华侨永亨银行有限公司'
    },
    {
      value: '036',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '038',
      order: '1',
      label: '大有银行有限公司'
    },
    {
      value: '039',
      order: '1',
      label: '集友银行有限公司'
    },
    {
      value: '040',
      order: '1',
      label: '大新银行有限公司'
    },
    {
      value: '041',
      order: '1',
      label: '创兴银行有限公司'
    },
    {
      value: '043',
      order: '1',
      label: '南洋商业银行有限公司'
    },
    {
      value: '044',
      order: '1',
      label: '华侨永亨银行有限公司'
    },
    {
      value: '045',
      order: '1',
      label: 'UCO BANK HONG KONG'
    },
    {
      value: '046',
      order: '1',
      label: 'KEB HANA BANK'
    },
    {
      value: '047',
      order: '1',
      label: '三菱UFJ银行'
    },
    {
      value: '049',
      order: '1',
      label: 'BANGKOK BANK PUBLIC COMPANY LIMITED'
    },
    {
      value: '050',
      order: '1',
      label: 'INDIAN OVERSEAS BANK'
    },
    {
      value: '051',
      order: '1',
      label: '中信银行(国际)有限公司'
    },
    {
      value: '052',
      order: '1',
      label: '星展銀行 (香港)有限公司'
    },
    {
      value: '054',
      order: '1',
      label: '德意志银行香港分行'
    },
    {
      value: '055',
      order: '1',
      label: '美国银行'
    },
    {
      value: '056',
      order: '1',
      label: '法国巴黎银行香港分行'
    },
    {
      value: '058',
      order: '1',
      label: '印度银行'
    },
    {
      value: '060',
      order: '1',
      label: '巴基斯坦国民银行'
    },
    {
      value: '061',
      order: '1',
      label: '大生银行有限公司'
    },
    {
      value: '063',
      order: '1',
      label: '马来亚银行'
    },
    {
      value: '064',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '065',
      order: '1',
      label: '三井住友银行'
    },
    {
      value: '066',
      order: '1',
      label: '印尼国家银行'
    },
    {
      value: '067',
      order: '1',
      label: '金融银行有限公司'
    },
    {
      value: '070',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '071',
      order: '1',
      label: '大华银行有限公司'
    },
    {
      value: '072',
      order: '1',
      label: '中国工商银行(亚洲)'
    },
    {
      value: '074',
      order: '1',
      label: 'Barclays Bank PLC'
    },
    {
      value: '076',
      order: '1',
      label: 'The Bank of Nova Scotia'
    },
    {
      value: '080',
      order: '1',
      label: 'Royal Bank of Canada, Hong Kong Branch'
    },
    {
      value: '081',
      order: '1',
      label: '法国兴业银行'
    },
    {
      value: '082',
      order: '1',
      label: '印度國家銀行香港分行'
    },
    {
      value: '085',
      order: '1',
      label: '加拿大多倫多道明銀行'
    },
    {
      value: '086',
      order: '1',
      label: '滿地可銀行'
    },
    {
      value: '092',
      order: '1',
      label: '加拿大帝国商业银行'
    },
    {
      value: '097',
      order: '1',
      label: '德國商業銀行'
    },
    {
      value: '103',
      order: '1',
      label: 'UBS AG Hong Kong'
    },
    {
      value: '109',
      order: '1',
      label: '瑞穗银行  香港分行'
    },
    {
      value: '113',
      order: '1',
      label: '德國中央合作銀行香港分行'
    },
    {
      value: '118',
      order: '1',
      label: 'Woori Bank Hong Kong Branch'
    },
    {
      value: '119',
      order: '1',
      label: 'PHILIPPINE NATIONAL BANK'
    },
    {
      value: '128',
      order: '1',
      label: '富邦银行(香港)有限公司'
    },
    {
      value: '138',
      order: '1',
      label: '三菱UFJ信托银行'
    },
    {
      value: '139',
      order: '1',
      label: '纽约梅隆银行有限公司'
    },
    {
      value: '145',
      order: '1',
      label: 'ING Bank N.V., Hong Kong'
    },
    {
      value: '147',
      order: '1',
      label: '西班牙对外银行'
    },
    {
      value: '150',
      order: '1',
      label: 'National Australia Bank Limited'
    },
    {
      value: '151',
      order: '1',
      label: '澳大利亚西太平洋银行'
    },
    {
      value: '152',
      order: '1',
      label: '澳新银行'
    },
    {
      value: '153',
      order: '1',
      label: '澳洲联邦银行'
    },
    {
      value: '161',
      order: '1',
      label: 'Intesa Sanpaolo S.p.A., Hong Kong'
    },
    {
      value: '164',
      order: '1',
      label: '裕信(德国)银行股份有限公司'
    },
    {
      value: '165',
      order: '1',
      label: '瑞典商业银行'
    },
    {
      value: '170',
      order: '1',
      label: '千叶银行'
    },
    {
      value: '178',
      order: '1',
      label: '比利时联合银行香港分行'
    },
    {
      value: '180',
      order: '1',
      label: '富国银行香港分行'
    },
    {
      value: '183',
      order: '1',
      label: '荷兰合作银行'
    },
    {
      value: '185',
      order: '1',
      label: '星展银行, 香港分行'
    },
    {
      value: '186',
      order: '1',
      label: '静冈银行'
    },
    {
      value: '188',
      order: '1',
      label: 'The Hachijuni Bank Ltd'
    },
    {
      value: '198',
      order: '1',
      label: '华南商业银行香港分行'
    },
    {
      value: '199',
      order: '1',
      label: '滋賀銀行'
    },
    {
      value: '201',
      order: '1',
      label: '台湾银行'
    },
    {
      value: '202',
      order: '1',
      label: 'THE CHUGOKU BANK, LTD.'
    },
    {
      value: '203',
      order: '1',
      label: '第一商業銀行股份有限公司'
    },
    {
      value: '205',
      order: '1',
      label: 'Coutts & Co. Ltd.'
    },
    {
      value: '206',
      order: '1',
      label: '彰化商业银行 (香港分行)'
    },
    {
      value: '210',
      order: '1',
      label: '法国外贸银行香港'
    },
    {
      value: '214',
      order: '1',
      label: '中国工商银行'
    },
    {
      value: '220',
      order: '1',
      label: 'State Street Bank & Trust Company, Hong Kong'
    },
    {
      value: '221',
      order: '1',
      label: '中国建设银行股份有限公司香港分行'
    },
    {
      value: '222',
      order: '1',
      label: '中国农业银行股份有限公司香港分行'
    },
    {
      value: '227',
      order: '1',
      label: 'Erste Group Bank AG'
    },
    {
      value: '229',
      order: '1',
      label: '中国信托商业银行'
    },
    {
      value: '230',
      order: '1',
      label: '台湾中小企业银行股份有限公司'
    },
    {
      value: '233',
      order: '1',
      label: '瑞士信贷银行股份有限公司香港分行'
    },
    {
      value: '236',
      order: '1',
      label: '國泰世華銀行香港分行'
    },
    {
      value: '237',
      order: '1',
      label: '瑞士盈丰银行'
    },
    {
      value: '238',
      order: '1',
      label: '招商银行香港分行'
    },
    {
      value: '239',
      order: '1',
      label: '台北富邦商业银行股份有限公司'
    },
    {
      value: '241',
      order: '1',
      label: '永丰商业银行股份有限公司香港分行'
    },
    {
      value: '242',
      order: '1',
      label: '兆丰国际商业银行'
    },
    {
      value: '243',
      order: '1',
      label: '玉山商业银行股份有限公司'
    },
    {
      value: '245',
      order: '1',
      label: '台新国际商业银行'
    },
    {
      value: '248',
      order: '1',
      label: '丰隆银行香港分行'
    },
    {
      value: '250',
      order: '1',
      label: '花旗银行(香港)有限公司'
    },
    {
      value: '251',
      order: '1',
      label: 'ICICI BANK LIMITED'
    },
    {
      value: '254',
      order: '1',
      label: 'Melli Bank Plc'
    },
    {
      value: '258',
      order: '1',
      label: '华美銀行'
    },
    {
      value: '259',
      order: '1',
      label: '巴鲁达银行'
    },
    {
      value: '260',
      order: '1',
      label: '远东国际商业银行股份有限公司'
    },
    {
      value: '261',
      order: '1',
      label: 'AXIS BANK LIMITED'
    },
    {
      value: '262',
      order: '1',
      label: 'CANARA BANK HONG KONG'
    },
    {
      value: '263',
      order: '1',
      label: '国泰银行'
    },
    {
      value: '264',
      order: '1',
      label: '台湾土地银行股份有限公司'
    },
    {
      value: '265',
      order: '1',
      label: '合作金库商业银行'
    },
    {
      value: '266',
      order: '1',
      label: 'PUNJAB NATIONAL BANK'
    },
    {
      value: '267',
      order: '1',
      label: '西班牙桑坦德银行有限公司'
    },
    {
      value: '268',
      order: '1',
      label: 'UNION BANK OF INDIA'
    },
    {
      value: '269',
      order: '1',
      label: '上海商业储蓄银行香港分行'
    },
    {
      value: '271',
      order: '1',
      label: '中小企业银行'
    },
    {
      value: '272',
      order: '1',
      label: '新加坡银行有限公司'
    },
    {
      value: '273',
      order: '1',
      label: '新韓銀行香港分行'
    },
    {
      value: '274',
      order: '1',
      label: 'O-Bank Co., Ltd'
    },
    {
      value: '275',
      order: '1',
      label: '法国巴黎銀行全球托管行'
    },
    {
      value: '276',
      order: '1',
      label: '国家开发银行香港分行'
    },
    {
      value: '277',
      order: '1',
      label: 'First Abu Dhabi Bank PJSC'
    },
    {
      value: '278',
      order: '1',
      label: '瑞士嘉盛银行'
    },
    {
      value: '307',
      order: '1',
      label: 'ABN AMRO BANK N.V.'
    },
    {
      value: '308',
      order: '1',
      label: 'HDFC BANK LIMITED'
    },
    {
      value: '309',
      order: '1',
      label: '瑞联银行'
    },
    {
      value: '316',
      order: '1',
      label: 'Skandinaviska Enskilda Banken AB'
    },
    {
      value: '320',
      order: '1',
      label: '瑞士宝盛银行'
    },
    {
      value: '324',
      order: '1',
      label: 'Credit Industriel et Commercial, Hong Kong Branch'
    },
    {
      value: '337',
      order: '1',
      label: '臺灣新光商業銀行股份有限公司'
    },
    {
      value: '339',
      order: '1',
      label: '东方汇理财富管理'
    },
    {
      value: '341',
      order: '1',
      label: '工銀標準銀行'
    },
    {
      value: '342',
      order: '1',
      label: '皇家銀行(香港)'
    },
    {
      value: '344',
      order: '1',
      label: 'MACQUARIE BANK LIMITED'
    },
    {
      value: '345',
      order: '1',
      label: '上海浦东发展银行股份有限公司'
    },
    {
      value: '353',
      order: '1',
      label: '中国民生银行'
    },
    {
      value: '357',
      order: '1',
      label: '百達銀行（香港分行）'
    },
    {
      value: '360',
      order: '1',
      label: 'NatWest Markets N.V. Hong Kong Branch'
    },
    {
      value: '368',
      order: '1',
      label: '中国光大银行'
    },
    {
      value: '371',
      order: '1',
      label: '三井住友信托银行香港支店'
    },
    {
      value: '372',
      order: '1',
      label: '上海银行(香港)有限公司'
    },
    {
      value: '374',
      order: '1',
      label: '联昌银行有限公司'
    },
    {
      value: '377',
      order: '1',
      label: '兴业银行香港分行'
    },
    {
      value: '378',
      order: '1',
      label: '元大商业银行有限公司'
    },
    {
      value: '379',
      order: '1',
      label: 'Mashreq Bank Public Shareholding Company'
    },
    {
      value: '380',
      order: '1',
      label: '开泰银行(大众)有限公司'
    },
    {
      value: '381',
      order: '1',
      label: 'Kookmin Bank'
    },
    {
      value: '382',
      order: '1',
      label: '交通银行(香港)有限公司'
    },
    {
      value: '383',
      order: '1',
      label: '浙商银行股份有限公司'
    },
    {
      value: '384',
      order: '1',
      label: '摩根士丹利银行亚洲有限公司'
    },
    {
      value: '385',
      order: '1',
      label: '平安银行股份有限公司'
    },
    {
      value: '386',
      order: '1',
      label: '华夏银行股份有限公司'
    },
    {
      value: '387',
      order: '1',
      label: '众安银行 (ZA Bank)'
    },
    {
      value: '388',
      order: '1',
      label: 'LIVI VB LIMITED'
    },
    {
      value: '389',
      order: '1',
      label: 'Mox Bank Limited'
    },
    {
      value: '390',
      order: '1',
      label: 'Welab Bank Limited'
    },
    {
      value: '391',
      order: '1',
      label: '富融银行'
    },
    {
      value: '392',
      order: '1',
      label: '平安壹账通银行(香港)有限公司'
    },
    {
      value: '393',
      order: '1',
      label: '蚂蚁银行(香港)有限公司'
    },
    {
      value: '395',
      order: '1',
      label: '天星银行有限公司'
    },
    {
      value: '838',
      order: '1',
      label: '中国银行（香港）有限公司'
    },
    {
      value: '888',
      order: '1',
      label: '香港金融管理局'
    },
    {
      value: '929',
      order: '1',
      label: '侨达国际有限公司'
    },
    {
      value: '930',
      order: '1',
      label: '全球付技术有限公司'
    },
    {
      value: '931',
      order: '1',
      label: 'WeChat Pay Hong Kong Limited'
    },
    {
      value: '933',
      order: '1',
      label: '三三金融服务有限公司'
    },
    {
      value: '934',
      order: '1',
      label: 'UniCard Solution Limited'
    },
    {
      value: '935',
      order: '1',
      label: 'HKT Payment Limited'
    },
    {
      value: '947',
      order: '1',
      label: 'TNG (Asia) Limited'
    },
    {
      value: '948',
      order: '1',
      label: 'Alipay Financial Services (HK) Limited'
    },
    {
      value: '949',
      order: '1',
      label: '八达通卡有限公司'
    },
    {
      value: '952',
      order: '1',
      label: 'Autotoll Limited'
    },
    {
      value: '954',
      order: '1',
      label: 'PayMe'
    },
    {
      value: '955',
      order: '1',
      label: '银传集团有限公司'
    }
  ],
  bankCodeCommon: [
    {
      value: '387',
      order: '1',
      label: '众安银行 (ZA Bank)'
    },
    {
      value: '004',
      order: '2',
      label: '香港上海汇丰银行有限公司'
    },
    {
      value: '024',
      order: '3',
      label: '恒生银行有限公司'
    },
    {
      value: '012',
      order: '4',
      label: '中国银行(香港)有限公司'
    },
    {
      value: '003',
      order: '5',
      label: '渣打银行(香港)有限公司'
    },
    {
      value: '250',
      order: '6',
      label: '花旗银行(香港)有限公司'
    },
    {
      value: '015',
      order: '7',
      label: '东亚银行有限公司'
    },
    {
      value: '016',
      order: '8',
      label: '星展银行(香港)有限公司'
    }
  ],
  education: [
    {
      value: '1',
      order: '1',
      label: '小学或以下'
    },
    {
      value: '2',
      order: '2',
      label: '中学'
    },
    {
      value: '3',
      order: '3',
      label: '大专或预科'
    },
    {
      value: '4',
      order: '4',
      label: '大学或本科'
    },
    {
      value: '5',
      order: '5',
      label: '硕士或以上'
    }
  ],
  idType: [
    {
      value: 'ID_Y',
      order: '1',
      label: '香港永久性居民身份证'
    },
    {
      value: 'ID_N',
      order: '2',
      label: '香港居民身份证'
    }
  ],
  income: [
    {
      value: '1',
      order: '1',
      label: '< $100,001'
    },
    {
      value: '2',
      order: '2',
      label: ' $100,001 - $500,000'
    },
    {
      value: '3',
      order: '3',
      label: ' $500,001 - $1,000,000'
    },
    {
      value: '4',
      order: '4',
      label: ' $1,000,001 - $2,000,000'
    },
    {
      value: '5',
      order: '5',
      label: ' > $2,000,000'
    }
  ],
  incomeSource: [
    {
      value: '1',
      order: '1',
      label: '薪金 / 佣金 / 花红'
    },
    {
      value: '2',
      order: '2',
      label: '业务收入'
    },
    {
      value: '3',
      order: '3',
      label: '投资收益'
    },
    {
      value: '4',
      order: '4',
      label: '租金 / 利息 / 股息收入'
    },
    {
      value: '5',
      order: '5',
      label: '储蓄'
    },
    {
      value: '6',
      order: '6',
      label: '退休金'
    },
    {
      value: '7',
      order: '7',
      label: '其他'
    }
  ],
  industry: [
    {
      value: 'P001',
      order: '1',
      label: '进出口/批发'
    },
    {
      value: 'P002',
      order: '2',
      label: '金融/保险/银行'
    },
    {
      value: 'P003',
      order: '3',
      label: '物流/速递/仓库'
    },
    {
      value: 'P004',
      order: '4',
      label: '旅游业(如:主题乐园/展览馆)'
    },
    {
      value: 'P005',
      order: '5',
      label: '会计/法律服务'
    },
    {
      value: 'P006',
      order: '6',
      label: '画廊/古董交易/拍卖行'
    },
    {
      value: 'P007',
      order: '7',
      label: '建筑/测绘'
    },
    {
      value: 'P008',
      order: '8',
      label: '赌场/赌博业/麻将馆'
    },
    {
      value: 'P009',
      order: '9',
      label: '餐饮'
    },
    {
      value: 'P010',
      order: '10',
      label: '慈善/非政府组织/宗教团体/协会及组织'
    },
    {
      value: 'P011',
      order: '11',
      label: '教育'
    },
    {
      value: 'P012',
      order: '12',
      label: '政府机构/公营机构'
    },
    {
      value: 'P013',
      order: '13',
      label: '酒店/物业管理/保安'
    },
    {
      value: 'P014',
      order: '14',
      label: '贵金属及宝石交易'
    },
    {
      value: 'P015',
      order: '15',
      label: '制造业'
    },
    {
      value: 'P016',
      order: '16',
      label: '医疗卫生'
    },
    {
      value: 'P017',
      order: '17',
      label: '军事/武器'
    },
    {
      value: 'P018',
      order: '18',
      label: '地产代理'
    },
    {
      value: 'P019',
      order: '19',
      label: '零售'
    },
    {
      value: 'P020',
      order: '20',
      label: '科学/技术/工程研发'
    },
    {
      value: 'P021',
      order: '21',
      label: '通讯/信息科技'
    },
    {
      value: 'P022',
      order: '22',
      label: '当铺/找换店/汇款店'
    },
    {
      value: 'P023',
      order: '23',
      label: '公共服务/公共交通'
    },
    {
      value: 'P024',
      order: '24',
      label: '私营交通 (如: 出租车、客货车)'
    },
    {
      value: 'P025',
      order: '25',
      label: '传媒/出版/广告及设计'
    },
    {
      value: 'P026',
      order: '26',
      label: '发廊/美容/水疗/健身中心'
    },
    {
      value: 'P027',
      order: '27',
      label: '财务公司'
    },
    {
      value: 'P028',
      order: '28',
      label: '娱乐（电影，音乐及电视制作）'
    },
    {
      value: 'P030',
      order: '29',
      label: '夜总会/迪士高/卡拉OK/桑拿/按摩/酒吧'
    },
    {
      value: 'P031',
      order: '30',
      label: '大使馆/领事馆'
    },
    {
      value: 'P032',
      order: '31',
      label: '建造业'
    },
    {
      value: 'P033',
      order: '32',
      label: '杂货店/便利店/烘焙店'
    },
    {
      value: 'P034',
      order: '33',
      label: '钟表及珠宝商店'
    },
    {
      value: 'P035',
      order: '34',
      label: '游戏机中心'
    },
    {
      value: 'P036',
      order: '35',
      label: '信托或公司服务提供者'
    },
    {
      value: 'P037',
      order: '36',
      label: '持牌赌博机构：（如：香港赛马会）'
    },
    {
      value: 'P038',
      order: '37',
      label: '家务助理/家庭佣工'
    }
  ],
  occupation: [
    {
      value: 'A',
      order: '1',
      label: '全职'
    },
    {
      value: 'B',
      order: '2',
      label: '非全职'
    },
    {
      value: 'C',
      order: '3',
      label: '自雇'
    },
    {
      value: 'D',
      order: '4',
      label: '学生'
    },
    {
      value: 'E',
      order: '5',
      label: '家务料理者'
    },
    {
      value: 'F',
      order: '6',
      label: '退休人士'
    },
    {
      value: 'G',
      order: '7',
      label: '待业'
    }
  ],
  riskLevel: [
    {
      value: '1',
      order: '1',
      label: '保守型'
    },
    {
      value: '2',
      order: '2',
      label: '穏定收入型'
    },
    {
      value: '3',
      order: '3',
      label: '平衡型'
    },
    {
      value: '4',
      order: '4',
      label: '增长型'
    },
    {
      value: '5',
      order: '5',
      label: '进取型'
    }
  ],
  sex: [
    {
      value: '2',
      order: '1',
      label: '男'
    },
    {
      value: '3',
      order: '2',
      label: '女'
    }
  ],
  position: [
    {
      value: '1008',
      order: '8',
      label: '董事 / 总裁 / 高级管理人员'
    },
    {
      value: '1011',
      order: '11',
      label: '合伙人/业务所有人'
    },
    {
      value: '1027',
      order: '27',
      label: '一般员工'
    },
    {
      value: '1030',
      order: '30',
      label: '经理/主管'
    }
  ],
  rejectIdPre: [
    {
      value: 'reject_idPre01',
      order: '1',
      label: '身份證照片不符合要求'
    },
    {
      value: 'reject_idPre02',
      order: '2',
      label: '身份證資料與身份證不符'
    },
    {
      value: 'reject_idPre03',
      order: '3',
      label: '身份證資料被遮蓋'
    },
    {
      value: 'reject_idPre04',
      order: '4',
      label: '身份證與人臉不符'
    },
    {
      value: 'reject_idPre05',
      order: '5',
      label: '國籍與其他個人資料不符'
    }
  ],
  rejectAddrMsg: [
    {
      value: 'reject_addrMsg01',
      order: '1',
      label: '地址不存在或不完整'
    }
  ],
  rejectTaxMsg: [
    {
      value: 'reject_taxMsg01',
      order: '1',
      label: '稅務編號不符合要求'
    },
    {
      value: 'reject_taxMsg02',
      order: '2',
      label: '稅務管轄區與其他個人資料不符'
    }
  ],
  rejectProfessMsg: [
    {
      value: 'reject_professMsg01',
      order: '1',
      label: '職業資料, 財富來源及財務狀況資訊不一致'
    },
    {
      value: 'reject_professMsg02',
      order: '2',
      label: '職業資料不符合要求'
    },
    {
      value: 'reject_professMsg03',
      order: '3',
      label: '職業資料, 財富來源及財務狀況資訊不一致'
    }
  ],
  rejectOtherMsgShow: [
    {
      value: 'reject_otherMsgShow01',
      order: '1',
      label: '請提供更詳細的身份披露説明内容'
    }
  ],
  rejectUploadEmployerAgree: [
    {
      value: 'reject_uploadEmployerAgree01',
      order: '1',
      label: '開戶同意書資料不全或錯誤'
    },
    {
      value: 'reject_uploadEmployerAgree02',
      order: '2',
      label: '持牌人士或法團名稱與中央編號錯誤'
    }
  ],
  rejectOther: [
    {
      value: 'reject_other01',
      order: '1',
      label: '不接受兒童身份證開戶'
    },
    {
      value: 'reject_other02',
      order: '2',
      label: '身份證無效'
    },
    {
      value: 'reject_other03',
      order: '3',
      label: '按監管要求，暫時無法開戶'
    }
  ]
}
