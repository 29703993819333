import * as Schemas from '@/common/schemas'
import * as Enums from '@/common/enums'

interface Options {
  [Enums.StringOptionLetter.LETTER_A]?: string
  [Enums.StringOptionLetter.LETTER_B]?: string
  [Enums.StringOptionLetter.LETTER_C]?: string
  [Enums.StringOptionLetter.LETTER_D]?: string
  [Enums.StringOptionLetter.LETTER_E]?: string
}

export interface ChildQuestion {
  serialNo: Schemas.AnswerMultipleSerialNo
  label: string
  labelDefault: string
  options: {
    [key: string]: string
  }
  optionsDefault: {
    [key: string]: string
  }
}

export interface RadioQuestion {
  serialNo: Schemas.AnswerRadioSerialNo
  type: 'radio'
  label: string
  options: Options
  labelDefault: string
  optionsDefault: Options
}

export interface MultipleQuestion {
  serialNo: Schemas.AnswerMultipleSerialNo
  type: 'multiple'
  label: string
  labelDefault: string
  children: ChildQuestion[]
}

export type RiskQuestion = RadioQuestion | MultipleQuestion
