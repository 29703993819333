import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback
} from 'react'

import { ActionSheet } from '@/components'
import { Row, Col } from 'antd'
import classnames from 'classnames'
import styles from './index.module.scss'
import iconArrowRight from '@/assets/img/icon-arrow-right.svg'
import { useSetState } from '@/hooks'
import TouchFeedback from 'rmc-feedback'
import { useTranslation } from 'react-i18next'
import { SelectOptionItem } from '@/common/types'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'
interface CascaderState {
  show: boolean
  vals: SelectOptionItem[]
  index: number
  innerOptions: SelectOptionItem[]
  optionsLength: number
}

interface CascaderProps {
  className?: string
  label?: string
  delimiter?: string
  onChange?: (values: string[], items?: SelectOptionItem[]) => void
  options?: SelectOptionItem[]
  value?: string[]
  disabled?: boolean
  readOnly?: boolean
  closeOnClickModal?: boolean
}

export interface CascaderHandles {
  updateVals: () => void
}

const Cascader: ForwardRefRenderFunction<CascaderHandles, CascaderProps> = (
  props: CascaderProps,
  ref
): JSX.Element => {
  const { t } = useTranslation()
  const {
    className,
    label,
    delimiter = '-',
    onChange,
    value = [],
    options = [],
    disabled = false,
    readOnly = false,
    closeOnClickModal= true
  } = props
  const [state, setState] = useSetState<CascaderState>({
    show: false,
    vals: [],
    index: 0,
    innerOptions: options,
    optionsLength: options.length
  })
  const { show, vals, index, innerOptions, optionsLength } = state
  const updateValues = useCallback(() => {
    const newVals: SelectOptionItem[] = []
    let tempOptions: SelectOptionItem[] = cloneDeep(options)
    let newIndex = 0
    for (const item of value) {
      const res = tempOptions.find((it) => it.value === item)
      if (res) {
        if (res.children) {
          tempOptions = res.children
          newIndex++
        }
        newVals.push(res)
      } else {
        break
      }
    }
    // 防止触发死循环
    if (options.length || value.length) {
      setState({ vals: newVals, index: newIndex, innerOptions: tempOptions })
    }
  }, [options, setState, value])

  useEffect(() => {
    if (value.length) {
      updateValues()
    }
  }, [updateValues, value.length])

  // options 变化时更新组件渲染
  useEffect(() => {
    if (options.length && optionsLength !== options.length) {
      updateValues()
      setState({ optionsLength: options.length })
    }
  }, [updateValues, options.length, optionsLength, setState])

  useImperativeHandle(ref, () => ({
    updateVals() {
      updateValues()
    }
  }))

  const handleClose = (): void => {
    console.log(111)
    setState({ show: false })
  }
  const openSelect = (): void => {
    if (!disabled && !readOnly) {
      setState({ show: true })
    }
  }
  const getLabel = (): any => {
    return vals.map((item, index: number) => (
      <span key={uuidv4()}>
        <span className={styles.selectTagLabel}>{item.label}</span>
        {index < vals.length - 1 && <span className={styles.delimiter}>{delimiter}</span>}
      </span>
    ))
  }
  const handleClickItem = (item: SelectOptionItem): void => {
    const values: SelectOptionItem[] = vals.slice(0)
    values[index] = item
    // if children is exist, then mean there has next selection
    // index value change, value after index should be clear
    const newVals = values.slice(0, index + 1)
    if (item.children) {
      setState({ vals: newVals, innerOptions: item.children, index: index + 1 })
    } else {
      setState({ vals: newVals, show: false })
    }
    const ids = newVals.map((item) => item.value)
    onChange?.(ids, newVals)
  }
  const onClick = (item: SelectOptionItem, index: number): void => {
    // if children is exist, then mean there has next selection
    if (index === 0 || vals[index - 1].children) {
      setState({ innerOptions: index === 0 ? options : vals[index - 1].children, index })
    } else {
      setState({ index })
    }
  }
  const showPlease = (): boolean => {
    if (vals.length === 0) return true
    const { children } = vals[vals.length - 1]
    return Array.isArray(children) ? children.length !== 0 : !!children
  }
  return (
    <div className={classnames(styles.selectContainer, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <Row className={styles.selectLine} onClick={openSelect}>
        <Col span={22} className={styles.selectInput}>
          {getLabel()}
        </Col>
        <Col span={2} className="textAlignRight">
          <img className={styles.iconSize} src={iconArrowRight} alt="select" />
        </Col>
      </Row>

      <ActionSheet
        show={show}
        onClose={handleClose}
        className={styles.actionSheet}
        closeOnClickModal={closeOnClickModal}
        header={
          <div className={styles.tagsGroup}>
            {vals.map((item, idx) => (
              <span
                className={classnames(styles.selectedTag, { [styles.activeTag]: idx === index })}
                key={item.value}
                onClick={(): void => {
                  onClick(item, idx)
                }}
              >
                {item.label}
              </span>
            ))}
            {showPlease() && (
              <span
                className={classnames(styles.selectedTag, {
                  [styles.activeTag]: index === vals.length
                })}
              >
                {t('za_invest_select_component_placeholder') || '请选择'}
              </span>
            )}
          </div>
        }
      >
        {innerOptions.map((item: SelectOptionItem) => (
          <div key={`${item.value}`}>
            <TouchFeedback activeClassName={styles.touchActive}>
              <Row
                className={styles.selectItem}
                onClick={(): void => {
                  handleClickItem(item)
                }}
              >
                <Col span={22} className={styles.selectLabel}>
                  {item.label}
                </Col>
                {vals[index] && vals[index].value === item.value && (
                  <Col span={2} className={styles.radioActive}>
                    <i className="icon-radio-active" />
                  </Col>
                )}
              </Row>
            </TouchFeedback>
          </div>
        ))}
        {options.length === 0 && (
          <div className={styles.noData}>{t('za_invest_setup_no_data') || '暂无数据'}</div>
        )}
      </ActionSheet>
    </div>
  )
}

export default forwardRef(Cascader)
