import { InitDictionaryResType } from '@/common/schemas/setup/enum.interface'
import { TFunction } from 'i18next'
export default function (t: TFunction, dics: InitDictionaryResType) {
  const {
    eduDegree = [],
    annualIncome = [],
    totalMoney = [],
    investTarget = [],
    isInvestSecurities = [],
    isInvestDerivative = [],
    isInvestMutual = [],
    isInvestFixed = [],
    investLimit = [],
    investInterested = [],
    riskHandle = [],
    needCash = []
  } = dics

  return [ // 先抽出来，便于后续做成配置化
    {
      name: 'eduDegree',
      message: t(
        '4413_setup_finance_input_error4',
        'Please input your eduDegree!'
      ),
      dataSource: eduDegree,
      selectLabel: t('4413_setup_finance_label4', '教育程度')
    }, {
      name: 'annualIncome',
      message: t(
        '4413_setup_finance_input_error2',
        'Please input your annualIncome!'
      ),
      dataSource: annualIncome,
      selectLabel: t('4413_setup_finance_label2', '年收入')
    }, {
      name: 'totalMoney',
      message: t(
        '4413_setup_finance_input_error3',
        'Please input your totalMoney!'
      ),
      dataSource: totalMoney,
      selectLabel: t('4413_setup_finance_label3', '總資產淨值')
    }, {
      name: 'investTarget',
      message: t(
        '4413_setup_finance_investTarget_error',
        'Please input your investTarget!'
      ),
      dataSource: investTarget,
      selectLabel: t('4413_setup_finance_investTarget_label', '投資目標')
    }, {
      name: 'isInvestSecurities',
      message: t(
        '4413_setup_finance_isInvestSecurities_error',
        'Please input your isInvestSecurities!'
      ),
      dataSource: isInvestSecurities,
      selectLabel: t('4413_setup_finance_isInvestSecurities_label', '過去一年是否曾買賣交易所買賣證券(包括交易所買賣基金及股票)')
    }, {
      name: 'isInvestDerivative',
      message: t(
        '4413_setup_finance_isInvestDerivative_error',
        'Please input your isInvestDerivative!'
      ),
      dataSource: isInvestDerivative,
      selectLabel: t('4413_setup_finance_isInvestDerivative_label', '過去一年是否曾買賣交易所買賣衍生產品')
    }, {
      name: 'isInvestMutual',
      message: t(
        '4413_setup_finance_isInvestMutual_error',
        'Please input your isInvestMutual!'
      ),
      dataSource: isInvestMutual,
      selectLabel: t('4413_setup_finance_isInvestMutual_label', '過去一年是否曾買賣互惠基金 / 單位信託基金(不包括強積金)')
    }, {
      name: 'isInvestFixed',
      message: t(
        '4413_setup_finance_isInvestFixed_error',
        'Please input your isInvestFixed!'
      ),
      dataSource: isInvestFixed,
      selectLabel: t('4413_setup_finance_isInvestFixed_label', '過去一年是否曾買賣固定收益證券 (例如債券)')
    }, {
      name: 'investLimit',
      message: t(
        '4413_setup_finance_investLimit_error',
        'Please input your investLimit!'
      ),
      dataSource: investLimit,
      selectLabel: t('4413_setup_finance_investLimit_label', '可接受的最長投資年期')
    }, {
      name: 'investInterested',
      message: t(
        '4413_setup_finance_investInterested_error',
        'Please input your investInterested!'
      ),
      dataSource: investInterested,
      selectLabel: t('4413_setup_finance_investInterested_label', '以下哪一項投資組合的1年表現最吸引你?'),
      initialValue: 1
    }, {
      name: 'riskHandle',
      message: t(
        '4413_setup_finance_riskHandle_error',
        'Please input your riskHandle!'
      ),
      dataSource: riskHandle,
      selectLabel: t('4413_setup_finance_riskHandle_label', '假設你的投資於一個月內下跌超過20%，你會如何處理? ')
    }, {
      name: 'needCash',
      message: t(
        '4413_setup_finance_needCash_error',
        'Please input your needCash!'
      ),
      dataSource: needCash,
      selectLabel: t('4413_setup_finance_needCash_label', '在未來12個月內，你預計會套現多少投資以滿足你的資金需要?')
    }
  ]
}