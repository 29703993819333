export enum TimeRange {
  // 全部
  ALL = 'all',
  // 近一个月
  ONE_MONTH = 'oneMonth',
  // 近3个月
  THREE_MONTH = 'threeMonth',
  // 近一年
  ONE_YEAR = 'oneYear',
  // 今年
  THIS_YEAR = 'thisYear',
  // 自定义
  CUSTOM = 'custom'
}
