import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styles from './index.module.scss'
import { queryPaymentDetail } from '@/service/insurance'
import iconCashout from '@/assets/img/money-record/icon-cashout.svg'
import { QueryPaymentDetailRes, InsuranceStatus } from '@/common/schemas'
import { downLoadFileBase64 } from '@/service/fileCenter'
import { useSetState } from '@/hooks'
import ZiPicturePreview from '@/components/PicturePreview'
import { urlQuery } from '@/utils'

const renderStatus = (statusDetail: QueryPaymentDetailRes) => {
  const { t } = useTranslation()
  const { redeemAmt, status, rejectReason } = statusDetail
  if (!redeemAmt) {
    return null
  }
  const hasReason = rejectReason.length > 0
  // @ts-ignore
  const statusStr = InsuranceStatus[status]
  let dom = null
  switch (status) {
    case 'APPLICATION_SUBMITTED':
      dom = (
        <p className={styles.explain}>
          {t('za_invest_insurance_detail_reason_normal', '将在3个工作日内处理')}
        </p>
      )
      break
    case 'TURN_DOWN':
      dom = (
        <p className={styles.explain}>
          {hasReason
            ? rejectReason.map((reason) => <p key={reason}>{reason}</p>)
            : t('za_invest_insurance_detail_reason_normal', '将在3个工作日内处理')}
        </p>
      )
      break
    case 'FUNDS_TRANSFERRED':
      dom = (
        <p className={styles.explain}>
          {t('za_invest_insurance_detail_reason_company', '保险公司将在3个工作日内处理')}
        </p>
      )
      break
    case 'FUNDS_TRANSFER_FAILURE':
    case 'COMPLETED':
    case 'REFUNDED':
      dom = null
      break
    default:
      break
  }
  return (
    <div className={styles.statusWrap}>
      <img src={iconCashout} alt="" />
      <p className={styles.money}>
        {t('za_invest_insurance_detail_redeemAmt', 'HKD')} {redeemAmt}
      </p>
      <p className={hasReason ? styles.statusError : styles.status}>{statusStr}</p>
      {dom}
    </div>
  )
}

const renderDetail = (detail: QueryPaymentDetailRes) => {
  const { t } = useTranslation()
  const { applyTime, companyName, policyNo } = detail
  if (!applyTime) {
    return null
  }
  return (
    <div className={styles.details}>
      <p className={styles.flexLine}>
        <span className={styles.leftText}>
          {t('za_invest_insurance_detail_applyTime', '申请时间')}
        </span>
        <span className={styles.rightText}>{applyTime}</span>
      </p>
      <p className={styles.flexLine}>
        <span className={styles.leftText}>
          {t('za_invest_insurance_detail_companyName', '收款公司')}
        </span>
        <span className={styles.rightText}>{companyName}</span>
      </p>
      <p className={styles.flexLine}>
        <span className={styles.leftText}>
          {t('za_invest_insurance_detail_policyNo', '保单号')}
        </span>
        <span className={styles.rightText}>{policyNo}</span>
      </p>
    </div>
  )
}

interface PhotoState {
  showPreview: boolean
  currentImgUrl: string
}
const renderPhotos = (detail: QueryPaymentDetailRes) => {
  const { t } = useTranslation()
  const { photos = [] } = detail
  const [imgUrls, setImgUrls] = useState([])
  const [state, setState] = useSetState<PhotoState>({
    showPreview: false,
    currentImgUrl: ''
  })

  const { showPreview, currentImgUrl } = state

  const fetchFile = useCallback(async (fileKey) => {
    const downloadRet = await downLoadFileBase64(
      {
        fileKey: fileKey,
        needBase64: true
      },
      {
        loading: false
      }
    )
    const imgUrl = 'data:image/png;base64,' + downloadRet.fileBase64
    setImgUrls((prevImgUrls: any) => {
      return prevImgUrls.concat([imgUrl])
    })
  }, [])

  useEffect(() => {
    for (const fileKey of photos) {
      fetchFile(fileKey)
      // console.log('==== fileKey', fileKey)
    }
  }, [fetchFile, photos])

  const openPreview = (img: string): void => {
    setState({
      showPreview: true,
      currentImgUrl: img
    })
  }

  const closePreview = (): void => {
    setState({
      showPreview: false
    })
  }
  if (photos.length === 0) {
    return null
  }

  return (
    <div className={styles.photos}>
      <p className={styles.title}>
        {t('za_invest_insurance_detail_certificate_title', '保费凭证')}
      </p>
      <div className={styles.imgWrap}>
        {!imgUrls.length ? (
          <div className={styles.loadingWrap}>
            <img className={styles.loadingThumbnail} src={window.loadingGifBase64} alt="loading" />
          </div>
        ) : (
          imgUrls.map((imgUrl, index) => {
            return (
              <div className={styles.thumbnailWrap} key={index}>
                <img
                  className={styles.thumbnail}
                  src={imgUrl}
                  alt="certificate"
                  onClick={(): void => {
                    openPreview(imgUrl)
                  }}
                />
              </div>
            )
          })
        )}
      </div>
      <ZiPicturePreview show={showPreview} imgUrl={currentImgUrl} onClose={closePreview} />
    </div>
  )
}

function InsuranceDetail(): JSX.Element {
  const [detail, setDetail] = useState<QueryPaymentDetailRes>({} as QueryPaymentDetailRes)
  let { applyNo = '' } = useParams<any>()
  if (!applyNo) {
    applyNo = urlQuery('applyNo')
  }
  useEffect(() => {
    queryPaymentDetail({ bizNo: applyNo })
      .then((data) => {
        if (data) {
          setDetail(data)
        }
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [applyNo])
  return (
    <div className={styles.wrapper}>
      {renderStatus(detail)}
      {renderDetail(detail)}
      {renderPhotos(detail)}
    </div>
  )
}

export default InsuranceDetail
