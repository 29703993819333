import { getEnv, isInvest } from '@/utils'
import JsBridge from '@za/jsbridge'
import * as Schemas from '@/common/schemas'
import * as NativeCallInterfaces from '@/common/schemas/nativeCall.interface'
import { INVEST_FILE_HOST } from '@/common/enums/host.enum'
import { isObject, isString, throttle } from 'lodash'
import { getDid } from '@/service/lib/cryptoRequest'
JsBridge.debug = false
JsBridge.env = 'zati'
JsBridge.init({
  protocolScheme: 'invest'
})

// 上报业务异常
export async function reportBusinessException(functionName: string, err?: Error) {
  const did = await getDid()
  if (window && window!.Performance && (window.Performance as any)?.addError) {
    ;(window.Performance as any)?.addError({
      msg: err?.message
        ? `调用jsBridge [${functionName}] 异常: ${err.message}`
        : JSON.stringify(err),
      col: 'jsBridge',
      line: functionName,
      resourceUrl: window?.location?.href,
      did
    })
  }
}

export async function reportCommonException(functionName: string, err?: Error) {
  const did = await getDid()
  if (window && window!.Performance && (window.Performance as any)?.addError) {
    let msg
    if (isString(err)) {
      msg = err
    }
    if (isObject(err)) {
      msg = JSON.stringify(err)
    }
    ;(window.Performance as any)?.addError({
      msg: `[${functionName}] 异常: ${msg}`,
      col: 'commonBussiness',
      line: functionName,
      resourceUrl: window?.location?.href,
      did
    })
  }
}

export const throttleJsBridge = (cb: any, wait: number, ...param: any[]) => {
  if (wait) {
    throttle(
      () => {
        cb!(...param)
      },
      wait,
      {
        trailing: false
      }
    )()
  } else {
    cb!(...param)
  }
}

// 配置回退按钮
export async function callNativeSetBackBtn(
  data: NativeCallInterfaces.SetBackBtnDto = {},
  wait = 500
) {
  return new Promise((resolve, reject) => {
    const param = {
      ...data,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('setNavigationBarBackButton', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.setNavigationBarBackButton, wait, param)
  })
}

// 配置右边按钮
export async function callNativeSetRightBtn(data: NativeCallInterfaces.SetRightBtnDto, wait = 500) {
  return new Promise((resolve, reject) => {
    const param = {
      ...data,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('setNavigationBarRightButton', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.setNavigationBarRightButton, wait, param)
  })
}

// 关闭webview
export async function callCloseWebview(wait = 500) {
  return new Promise((resolve, reject) => {
    const param = {
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('close', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.close, wait, param)
  })
}

// 重置导航栏样式
export function callNativeResetNavbar() {
  return new Promise((resolve, reject) => {
    JsBridge.resetNavigationBarStyle!({
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('resetNavigationBarStyle', err)
        reject(err)
      },
      complete: () => {}
    })
  })
}

// 隐藏右边关闭按钮
export async function callHideRightBtn(
  data: NativeCallInterfaces.SetButtonIsHiddenDto = {},
  wait = 500
) {
  return new Promise((resolve, reject) => {
    const param = {
      visible: !data.isHidden,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('setNavigationBarRightButtonVisible', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.setNavigationBarRightButtonVisible, wait, param)
  })
}

// 隐藏左边回退按钮
export async function callHideLeftBtn(
  data: NativeCallInterfaces.SetButtonIsHiddenDto = {},
  wait = 500
) {
  return new Promise((resolve, reject) => {
    const param = {
      visible: !data.isHidden,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('setNavigationBarBackButtonVisible', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.setNavigationBarBackButtonVisible, wait, param)
  })
}

// 向native发送事件
export function postEventToNative(data: NativeCallInterfaces.PostJsEventDto): void {
  JsBridge.postJSEvent!({ ...data })
}

// 设置导航栏标题
export async function callNativeSetBarTitle(
  data: NativeCallInterfaces.SetNavigationBarTitleDto,
  wait = 500
) {
  return new Promise((resolve, reject) => {
    const param = {
      ...data,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('setNavigationBarTitle', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.setNavigationBarTitle, wait, param)
  })
}

// 调用EKYC
export async function callNativeEKYC(
  data: NativeCallInterfaces.StartEkycDto,
  wait = 500
): Promise<Schemas.EkycReturnType> {
  return new Promise((resolve, reject) => {
    const action = {
      moduleName: 'business',
      functionName: 'startEKYC',
      params: { params: data },
      needCallback: true
    }
    const option = {
      success: (res?: NativeCallInterfaces.StartEkycDtoRO) => {
        resolve(res!.data)
      },
      fail: (err?: Error) => {
        reportBusinessException('startEKYC', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.useAction, wait, action, option)
  })
}

export async function navigateTo(data: NativeCallInterfaces.NavigateToDto, wait = 500) {
  return new Promise((resolve, reject) => {
    const param = {
      ...data,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('navigateTo', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.navigateTo, wait, param)
  })
}

export async function redirectTo(data: NativeCallInterfaces.NavigateToDto, wait = 500) {
  return new Promise((resolve, reject) => {
    const param = {
      ...data,
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('redirectTo', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.redirectTo, wait, param)
  })
}

// 文件下载
export async function callDownload(
  data: NativeCallInterfaces.DownloadDto,
  wait = 500
): Promise<NativeCallInterfaces.DownloadRO> {
  return new Promise((resolve, reject) => {
    const action = {
      moduleName: 'app',
      functionName: 'download',
      params: {
        path: `${INVEST_FILE_HOST[getEnv()]}${data.path}`,
        header: data.header,
        params: {
          fileName: data.fileName,
          objectKey: data.objectKey
        },
        ext: { channel: 'invest', method: 'POST' }
      },
      needCallback: true
    }
    const option = {
      success: (res: any) => {
        resolve(res?.data)
      },
      fail: (err?: Error) => {
        reportBusinessException('download', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.useAction, wait, action, option)
  })
}

// 打开文件
export async function callOpenPDF(data: NativeCallInterfaces.OpenPdfDto, wait = 500) {
  return new Promise((resolve, reject) => {
    const action = {
      moduleName: 'app',
      functionName: 'openPDF',
      params: {
        params: data
      },
      needCallback: true
    }
    const option = {
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('openPDF', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.useAction, wait, action, option)
  })
}

// 获取APP系统信息
export async function callSystemInfo(wait = 500): Promise<NativeCallInterfaces.SystemInfoRO> {
  if (!isInvest()) {
    return Promise.resolve({ did: false })
  } else {
    return new Promise((resolve, reject) => {
      const param = {
        success: (res: any) => {
          resolve(res)
        },
        fail: (err?: Error) => {
          reportBusinessException('getSystemInfo', err)
          reject(err)
        },
        complete: () => {}
      }
      throttleJsBridge(JsBridge.getSystemInfo, wait, param)
    })
  }
}

// 调用登陆
export async function callLogin(wait = 500) {
  return new Promise((resolve, reject) => {
    const param = {
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('login', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.login, wait, param)
  })
}

// 新版本的退出登陆
export async function syncLogout(wait = 500) {
  return new Promise((resolve, reject) => {
    const actions = {
      moduleName: 'business',
      functionName: 'syncLogout',
      params: {},
      needCallback: false
    }
    const options = {
      success: () => {
        resolve(null)
      },
      fail: (err?: Error) => {
        reportBusinessException('syncLogout', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.useAction, wait, actions, options)
  })
}

// 调用安全密码
export async function callTradePassword(wait = 500, needCallback = false) {
  return new Promise((resolve, reject) => {
    const action = {
      moduleName: 'zafam',
      functionName: 'verify_tran_pass',
      needCallback: needCallback,
      params: {}
    }
    const option = {
      success: (data: any) => {
        resolve(data)
      },
      fail: (err?: Error) => {
        reportBusinessException('verify_tran_pass', err)
        reject(err)
      },
      complete: () => {}
    }
    throttleJsBridge(JsBridge.useAction, wait, action, option)
  })
}

/**
 * 查看如何入金
 */
export const onViewProtocol = (data: any = {}): void => {
  callDownload(data).then((res: any) => {
    callOpenPDF({ path: res.filePath })
  })
}

/**
 * 设置监听事件
 */
export async function callAddEventListener(
  data: NativeCallInterfaces.AddEventListenerDto
): Promise<string> {
  return new Promise((resolve) => {
    JsBridge.addEventListener!({
      ...data,
      success: (callbackId: string) => {
        resolve(callbackId)
      }
    })
  })
}

/**
 * 取消监听事件
 */
export function callRemoveEventListener(data: NativeCallInterfaces.RemoveEventListenerDto): void {
  JsBridge.removeEventListener!(data)
}

export function startTakeID(
  data: Schemas.TakeIDReqType,
  wait = 500
): Promise<Schemas.SelectImageResType> {
  return new Promise((resolve, reject) => {
    const options = {
      success: (res: any) => {
        resolve(res)
      },
      fail: (err?: Error) => {
        reportBusinessException('selectImage', err)
        reject(err)
      },
      complete: () => {}
    }
    const action = {
      functionName: 'selectImage',
      moduleName: 'business',
      needCallback: true,
      params: {
        ...data,
        cameraType: 'takeID'
      }
    }
    throttleJsBridge(JsBridge.useAction, wait, action, options)
  })
}

export function startSelfie(
  data: Schemas.SelfieReqType,
  wait = 500
): Promise<Schemas.SelfieResType> {
  return new Promise((resolve, reject) => {
    const options = {
      success: (res: any) => {
        resolve(res)
      },
      fail: (err?: Error) => {
        reportBusinessException('startSelfie', err)
        reject(err)
      },
      complete: () => {}
    }
    const action = {
      functionName: 'startSelfie',
      moduleName: 'business',
      needCallback: true,
      params: data
    }
    throttleJsBridge(JsBridge.useAction, wait, action, options)
  })
}

/**
 * 获取渠道码
 */
export async function getChannel(wait = 500) {
  if (!isInvest()) {
    return Promise.resolve({ data: { channelCode: '' } })
  } else {
    return new Promise((resolve, reject) => {
      const options = {
        success: (res: any) => {
          resolve(res)
        },
        fail: (err?: Error) => {
          reportBusinessException('getChannel', err)
          reject(err)
        },
        complete: () => {}
      }
      const action = {
        functionName: 'getChannel',
        moduleName: 'app',
        needCallback: true,
        params: {}
      }
      throttleJsBridge(JsBridge.useAction, wait, action, options)
    })
  }
}

/**
 * App Tab 跳转
 * @param tabName 需要跳转的Tab name
 */
export async function callSwitchTab(tabName: 'home' | 'mine', wait = 500) {
  if (isInvest()) {
    const action = {
      functionName: 'switch',
      moduleName: 'tabs',
      needCallback: false,
      params: {
        tabName
      }
    }
    const option = {}
    throttleJsBridge(JsBridge.useAction, wait, action, option)
  }
}

// export function startTakeID(data: Schemas.TakeIDReqType): Promise<Schemas.TakeIDResType> {
//   return new Promise((resolve, reject) => {
//     const options = {
//       success: (res: any) => {
//         resolve(res)
//       },
//       fail: (err?: Error) => {
//         reject(err)
//       },
//       complete: () => {}
//     }
//     JsBridge.useAction!(
//       {
//         functionName: 'startTakeID',
//         moduleName: 'business',
//         needCallback: true,
//         params: data
//       },
//       options
//     )
//   })
// }

// export function startSelfie(data: Schemas.SelfieReqType): Promise<Schemas.SelfieResType> {
//   return new Promise((resolve, reject) => {
//     const options = {
//       success: (res: any) => {
//         resolve(res)
//       },
//       fail: (err?: Error) => {
//         reject(err)
//       },
//       complete: () => {}
//     }
//     JsBridge.useAction!(
//       {
//         functionName: 'startSelfie',
//         moduleName: 'business',
//         needCallback: true,
//         params: data
//       },
//       options
//     )
//   })
// }

interface FundInfo {
  productId: string
  fundName: string
  isinNo: string
}

/**
 * 跳转到基金详情页面
 * @param info
 */
export const navigateToFundDetail = (info?: FundInfo) => {
  if (!info || !info.productId) {
    console.log('navigateToFundDetail', `invalid fund info: ${info}`)
    return
  }
  const url = `invest://detail_page.fund.navigate?productId=${info.productId}&fundName=${info?.fundName}&isinNo=${info?.isinNo}`
  navigateTo({ url })
}

export const setNavigationBarStyle = (theme: any) => {
  return JsBridge?.setNavigationBarStyle!(theme)
}
