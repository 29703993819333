import styles from './index.module.scss'
import React, { useState } from 'react'

function Seagull() {
  const [visible, showVisible] = useState(true)
  return (
    visible ? <div className={styles.seagull} onDoubleClick={() => {
      showVisible(false)
    }} onClick={() => {
      if (window.location.pathname.indexOf('/key/') > -1) {
        window.location.href = location.pathname.replace(/\/(key)\//, '/hk/')
        return
      }
      window.location.href = location.pathname.replace(/\/(?:en|hk|key|zh)\/([^/]+\/).*/, '/key/$1')
    }}>
      {window.location.pathname.indexOf('/key/') > -1 ? 'HK' : 'KEY'}
    </div> : null
  )
}

export default Seagull